export const mainStyles = {
  backDrop: '#F0F1F3',
  basicFontSize: '1.6rem',
  background: '#FFFFFF',
  labelGray: '#5B5550',
  bad: '#F74040',
  disabled: '#AEAEAE',
  disabledGray: '#D3D4D8',
  good: '#00CD39',
  highlight: '#505050',
  interactive: '#C1C1C1',
  lightBlack: '#353535',
  lightGray: '#A29C95',
  mainFont: 'Lato',
  primaryColor: '#FF8000',
  secondaryColor: '#FF9B21',
  subtitle: '#5B5550',
  title: '#0E0E0E',
  selected: '#76726E',
};

export const calculatePxToRem = value => {
  return `${parseInt(value) / 10}rem`;
};

// Converts bytes to mb (1 decimal place)
export const bytesToMega = value => Math.round(value / 100000) / 10;

// Make text shorter if it's longer than 20 chars.
export const shortifyText = (text, cut = 17) => {
  if (text.length < 20) return text;
  else return `${text.substring(0, cut)}...`;
};
