import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { API_KEY } from '../env.config.js';
import { debounce } from 'lodash';

const GoogleMap = ({ lat, lng, zoom, initMarker, className, onClick }) => {
  const ref = useRef();
  const scriptRef = useRef();
  const gmarker = useRef();
  const map = useRef();
  const [clicked, setClicked] = useState(false);
  let mapListener;

  const createGoogleMap = () => {
    return new window.google.maps.Map(ref.current, {
      zoom,
      center: {
        lat,
        lng,
      },
      disableDefaultUI: true,
    });
  };

  const removeMarker = () => gmarker.current.setMap(null);

  const createMarker = (pos, title = '', label = '') => {
    const position = pos ? pos : { lat, lng };
    gmarker.current && removeMarker();
    gmarker.current = new window.google.maps.Marker({
      animation: window.google.maps.Animation.DROP,
      position,
      map: map.current,
      title,
      label,
    });
  };

  const debouncer = debounce((f) => f(), 1000);

  const initMap = () => {
    map.current = createGoogleMap();
    initMarker.show &&
      createMarker(undefined, initMarker.label, initMarker.label);
    mapListener = map.current.addListener('click', (e) => {
      setClicked(true);
      createMarker(e.latLng);
      debouncer(() => onClick({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
    });
  };

  useEffect(() => {
    scriptRef.current = document.createElement('script');
    scriptRef.current.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
    window.document.body.appendChild(scriptRef.current);
    scriptRef.current.addEventListener('load', initMap);

    return () => {
      window.google.maps.event.removeListener(mapListener);
      window.document.body.removeChild(scriptRef.current);
    };
  }, []);

  useEffect(() => {
    if (!clicked)
      window.google && window.google.maps && gmarker.current && createMarker();
    else setClicked(false);
  }, [lat, lng]);

  return <StyledMap ref={ref} className={className}></StyledMap>;
};

GoogleMap.defaultProps = {
  initMarker: { show: false, label: '' },
  lat: 51.0616218,
  lng: 3.7291086,
  zoom: 17,
  onClick: (address) => alert(address),
};

GoogleMap.propTypes = {
  initMarker: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  onClick: PropTypes.func,
};

const StyledMap = styled.div`
  margin: 3.5rem 0;
  width: 100rem;
  height: 35rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
`;

export default GoogleMap;
