import { getAddress } from './apiRouterService';
import { debounce } from 'lodash';

export const callAddress = async (query = '', type, terms = '') => {
  const q = `&q=${query}`;
  const [resp, status] = await getAddress(`${type}?${terms}${q}`);
  if (status !== 200) return [];
  if (type === 'cities')
    return resp.map((c) => ({
      label: c.name,
      value: { val: c.name, zipcode: c.zipcode },
    }));
  return resp.map((c) => ({
    label: c.name,
    value: c.name,
  }));
};

export const debounceCall = debounce((q, type, terms, cb) => {
  callAddress(q, type, terms).then((res) => cb(res));
}, 1000);

/**
 * Default debouncer for calling zipcode
 */
export const debouncer = debounce((f) => f(), 500);

export const callCities = (q, cb, entity) => {
  if (q.length < 2 && !entity.zipcode) {
    return cb([]);
  }
  return debounceCall(q, 'cities', `zipcode=${entity.zipcode || ''}`, cb);
};

export const callStreets = (q, cb, entity) => {
  if (!entity.zipcode || q.length < 3) return cb([]);
  debounceCall(q, 'streets', `zipcode=${entity.zipcode || ''}`, cb);
};

export const parseGoogleAddress = (address) => ({
  housenr: address.housenr,
  boxnr: address.boxnr,
  street: address.street,
  zipcode: address.zipcode,
  city: address.city,
});

export const checkIfFullAddress = ({ city, street, housenr, zipcode }) =>
  city && street && housenr && zipcode;
