import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import {
  CarLogoContainer,
  CarContainer,
  CarSelectedRect,
  CarInfoHeader,
  CarInfoContainer,
  CarDisabledInput,
  CarCardColumn,
  CarCardLabel,
  CarDateInput,
  CarCardSearchSelect,
  CarButtonsContainer,
} from '../styledComponents';
import ButtonInput from './ButtonInput';
import {
  CarTerrainwagenIcon,
  CarCabrioIcon,
  CarSportwagenIcon,
} from '../icons';
import { ActionButton } from 'wg-fe-ui';
import Item from './CarCardItem';
import { useTranslation } from 'react-i18next';
import SuffixInput from './SuffixInput';
import VatButtonInput from './VatButtonInput';
import CarIMG from './CarIMG';
import { parseDateStringToObject } from '../helpers/dateService';
import { engineTypes } from '../constants';
import CarCardExtrasButton from './CarCardExtrasButton';
import { mapValues } from 'lodash';

const CarCard = ({
  className,
  data,
  setCarData,
  handleFormData,
  validateForms,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({ technical: {} });
  const ref = useRef();

  const enginesList = Object.entries(engineTypes).map(([k, v]) => ({
    label: v,
    value: k,
  }));

  const formatCurrency = (number, currency = 'EUR') =>
    new Intl.NumberFormat('de-DE', { style: 'currency', currency }).format(
      number,
    );

  const handleEdit = () => setIsEditing(!isEditing);

  const handleChange = ({ name, value }) => {
    if (name.includes('technical.body')) {
      const fixedName = name.replace(/^technical.body./, '');
      setCarData(old => ({
        ...old,
        technical: {
          ...old.technical,
          body: { ...old.technical.body, [fixedName]: value },
        },
      }));
    } else if (name.includes('technical')) {
      const fixedName = name.replace(/^technical./, '');
      setCarData(old => ({
        ...old,
        technical: { ...old.technical, [fixedName]: value ? value : 0 },
      }));
    } else if (name.includes('registration')) {
      const convertedVal = value.split('/');
      const parsedDate = `${convertedVal[2]}-${convertedVal[1]}-${convertedVal[0]}`;
      setCarData(old => ({ ...old, [name]: parsedDate }));
    } else setCarData(old => ({ ...old, [name]: value }));
  };

  if (!data.technical) return null;

  const handleSubmit = async () => {
    if (isEditing) {
      const [validationErrors, hasErrors] = await validateForms(
        {
          ...data,
          registrationFirst: parseDateStringToObject(
            data.registrationFirst,
            '-',
            'us',
          ),
          registrationLast: parseDateStringToObject(
            data.registrationLast,
            '-',
            'us',
          ),
        },
        'carCard',
      );
      if (hasErrors) {
        setErrors(() => mapValues(validationErrors, e => t(e)));
        return;
      }
    }
    handleFormData();
  };

  return (
    <CarContainer className={className}>
      <CarLogoContainer>
        <CarIMG src={data.img} color={data.technical.color_hex} />
        <CarSelectedRect>
          <span className="title">Auto</span>
          <span className="value">{data.technical.name}</span>
        </CarSelectedRect>
      </CarLogoContainer>
      <CarInfoContainer>
        <CarInfoHeader>{t('Specificaties')}</CarInfoHeader>
        <ItemsContainer>
          <CarCardColumn>
            {isEditing ? (
              <CarCardSearchSelect
                name="technical.motorType"
                options={enginesList}
                onSelected={handleChange}
                value={{
                  label: engineTypes[data.technical.motorType] || '',
                  value: data.technical.motorType,
                }}
                placeholder={t('Choose your option')}
                error={errors['technical']['motorType']}
              >
                {t('Fuel type')}
              </CarCardSearchSelect>
            ) : (
              <Item
                title={t('Fuel type')}
                value={engineTypes[data.technical.motorType] || ''}
                name="technical.motorType"
              />
            )}
            <Item
              title={t('Model year')}
              value={data.technical.model_year}
              name="technical.model_year"
              error={errors['technical']['model_year']}
              input={isEditing}
              onChange={handleChange}
            />
            {isEditing ? (
              <CarDateInput
                name="registrationLast"
                value={parseDateStringToObject(
                  data.registrationLast,
                  '-',
                  'us',
                )}
                onChange={handleChange}
                ref={ref}
                error={
                  errors['registrationLast'] &&
                  Object.values(errors['registrationLast']).length
                    ? t('Invalid date')
                    : undefined
                }
              >
                {t('Last registration')}
              </CarDateInput>
            ) : (
              <Item
                title={t('Last registration')}
                name="registrationLast"
                value={data.registrationLast}
              />
            )}
            <CarCardLabel isEditing={isEditing}>
              {t('When was the')}
              <span className="bold"> {t('first registration')}?</span>
            </CarCardLabel>

            <CarCardLabel>
              {t('What is the')}
              <span className="bold"> {t('catalog value')}?</span>
            </CarCardLabel>
          </CarCardColumn>
          <CarCardColumn>
            {isEditing ? (
              <>
                <SuffixInput
                  suffix="g CO2/km"
                  name="technical.co2"
                  value={data.technical.co2}
                  onChange={handleChange}
                  error={errors['technical']['co2']}
                >
                  {t('Emissions')}
                </SuffixInput>
                <SuffixInput
                  suffix="KW"
                  name="technical.kw"
                  value={data.technical.kw}
                  onChange={handleChange}
                  error={errors['technical']['kw']}
                >
                  {t('Power')}
                </SuffixInput>
                <SuffixInput
                  suffix="CC"
                  name="technical.cc"
                  value={data.technical.cc}
                  onChange={handleChange}
                  error={errors['technical']['cc']}
                >
                  {t('Cylinder capacity')}
                </SuffixInput>
              </>
            ) : (
              <>
                <Item
                  title={t('Emissions')}
                  value={`${data.technical.co2} CO2/km`}
                />
                <Item title={t('Power')} value={`${data.technical.kw} KW`} />
                <Item
                  title={t('Cylinder capacity')}
                  value={`${data.technical.cc} CC`}
                />
              </>
            )}
            {isEditing ? (
              <>
                <CarDateInput
                  name="registrationFirst"
                  value={parseDateStringToObject(
                    data.registrationFirst,
                    '-',
                    'us',
                  )}
                  onChange={handleChange}
                  ref={ref}
                  error={
                    errors['registrationLast'] &&
                    Object.values(errors['registrationLast']).length
                      ? t('Invalid date')
                      : undefined
                  }
                />
                <VatButtonInput
                  value={data.car_value}
                  name="car_value"
                  setCarData={setCarData}
                  handleChange={handleChange}
                  error={errors['car_value']}
                />
              </>
            ) : (
              <>
                <CarDisabledInput
                  disabled
                  value={data.registrationFirst}
                  isEditing={isEditing}
                />
                <ButtonInput disabled value={formatCurrency(data.car_value)}>
                  EXCL VAT
                </ButtonInput>
              </>
            )}
          </CarCardColumn>
        </ItemsContainer>
        <CarInfoHeader>{t('Extras')}</CarInfoHeader>
        <ItemsContainer>
          <CarCardExtrasButton
            name="technical.body.suv"
            value={data.technical.body.suv}
            onClick={isEditing ? handleChange : null}
          >
            <CarTerrainwagenIcon />
            {t('Suv')}
          </CarCardExtrasButton>
          <CarCardExtrasButton
            name="technical.body.coupe"
            value={data.technical.body.coupe}
            onClick={isEditing ? handleChange : null}
          >
            <CarSportwagenIcon />
            {t('Coupe')}
          </CarCardExtrasButton>
          <CarCardExtrasButton
            name="technical.body.cabriolet"
            value={data.technical.body.cabriolet}
            onClick={isEditing ? handleChange : null}
          >
            <CarCabrioIcon />
            {t('Cabriolet')}
          </CarCardExtrasButton>
        </ItemsContainer>
        <CarButtonsContainer>
          <ContinueButton className="disabled-color" onClick={handleEdit}>
            {t(isEditing ? 'Stop editing' : 'Edit')}
          </ContinueButton>
          <ContinueButton onClick={handleSubmit} data-test-id='continueButton'>
            {t('Continue')}
          </ContinueButton>
        </CarButtonsContainer>
      </CarInfoContainer>
    </CarContainer>
  );
};

CarCard.displayName = 'CarCard';

const ItemsContainer = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    text-align: center;

    button {
      margin-right: 0;
    }

    & > button:nth-child(2n) {
      margin: 3rem 0;
    }
  }
`;

const ContinueButton = styled(ActionButton)`
  margin-top: 3.5rem;
  align-self: flex-end;
  width: 9vw;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 2.4rem;
  height: 4rem;
  font-family: Lato;

  @media (max-width: 600px) {
    width: 100%;
  }
  margin-left: 3rem;
  &.disabled-color {
    background-color: #cdcdcd;
  }
`;

export default CarCard;
