import React from 'react';
import { string } from 'prop-types';

const WorkAccident = ({ className, width = 54, height = 69 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M52.069 39.7085L38.6344 33.3178C37.9799 33.0221 37.2599 32.8578 36.5399 32.8578H29.4544L22.6799 18.0721H22.909H34.1835C35.8199 18.0721 37.3253 16.955 37.5871 15.345C37.9308 13.275 36.3435 11.5007 34.3635 11.5007H22.909L17.8853 10.6628C14.8253 10.1535 11.6835 10.9092 9.19623 12.7821C7.46169 14.0964 6.13623 15.8707 5.3835 17.9078L1.84896 27.3707C1.70169 27.7321 1.63623 28.1264 1.63623 28.5207V37.6057C1.63623 39.2485 2.74896 40.76 4.35259 41.0228C6.41441 41.3678 8.18169 39.7742 8.18169 37.7864V28.7014C8.18169 28.1921 8.29623 27.6828 8.52532 27.2392L9.81805 24.6435L15.1362 37.9671C15.8726 39.84 17.6726 41.0721 19.6853 41.0721H26.018L34.8381 60.3428C35.5581 61.9035 37.3253 62.8071 38.9453 62.2485C40.8108 61.6078 41.6617 59.5214 40.8599 57.7635L32.4653 39.4292H35.4271V39.4457L49.2217 45.6392C50.7108 46.3128 52.5435 45.9021 53.4435 44.5385C54.589 42.7971 53.8853 40.53 52.069 39.7085Z" />
      <path d="M5.72727 11.5C8.89036 11.5 11.4545 8.92564 11.4545 5.75C11.4545 2.57436 8.89036 0 5.72727 0C2.56419 0 0 2.57436 0 5.75C0 8.92564 2.56419 11.5 5.72727 11.5Z" />
      <path d="M19.6366 69.0003C19.473 69.0003 19.3257 68.9838 19.162 68.9346C18.4748 68.721 18.0002 68.0803 18.0002 67.3574V59.866L7.11841 63.9567C6.4475 64.2196 5.67841 63.9896 5.25295 63.4146C4.81113 62.8396 4.79477 62.051 5.22022 61.4596L12.2893 51.5203L4.18932 47.446C3.50204 47.101 3.15841 46.3453 3.32204 45.606C3.48568 44.8831 4.15659 44.3574 4.90932 44.3574H18.0002C18.9002 44.3574 19.6366 45.0967 19.6366 46.0003C19.6366 46.9039 18.9002 47.6431 18.0002 47.6431H11.8475L15.4639 49.4667C15.8893 49.6803 16.2002 50.0746 16.3148 50.5346C16.4293 50.9946 16.3311 51.4874 16.053 51.8817L10.9966 58.9953L19.0639 55.956C19.5711 55.7589 20.1275 55.841 20.5693 56.1367C21.0111 56.4653 21.273 56.9581 21.273 57.5003V61.936L24.8239 56.5967C25.3311 55.841 26.3457 55.6438 27.0984 56.1367C27.8511 56.646 28.0475 57.6646 27.5566 58.4203L21.0111 68.2774C20.6839 68.7374 20.1766 69.0003 19.6366 69.0003Z" />
    </svg>
  );
};

WorkAccident.propTypes = {
  className: string,
};

export default WorkAccident;
