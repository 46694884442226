import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  ActionButton,
  AddEntityButton,
  Label,
  Section,
  FlightSelectBar,
} from 'wg-fe-ui';
import styled from 'styled-components';
import FlightIcon from '../../../icons/Flight';
import { useHistory, useParams } from 'react-router-dom';
import useNewClaimData from '../../../hooks/useNewClaimData';
import useQueryParams from '../../../hooks/useQueryParams';
import { updateClaim } from '../../../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';


const Flights = forwardRef(({ handleSection }, ref) => {
  const { id } = useParams();
  const history = useHistory();
  const { getClaimData } = useNewClaimData();
  const { addParam, removeParams } = useQueryParams();
  const {
    claim_data: { flights_taken },
    _claim_id,
  } = getClaimData();
  const { t } = useTranslation();

  const updateFlight = async () => {
    const [, status] = await updateClaim(_claim_id, {
      claim: {
        flights_taken: flights_taken.map(f => {
          delete f.arrival_airport;
          delete f.departure_airport;
          return f;
        })
      },
    });
    return status;
  };

  const handleFormData = async e => {
    if (e) e.preventDefault();
    const status = await updateFlight();
    status < 300 && addParam({ submit: true });
  };

  useImperativeHandle(ref, () => ({
    click: () => handleFormData()
  }));

  // const getClaim = async() => {
  //   const [resp, status] =  await request(`v1/api/claims/v1/api/claims/${data._claim_id}`, 'GET');
  //   status === 200 && setFlightsTaken(resp.values[0].flights_taken);
  // }

  useEffect(() => {
    if (!flights_taken || !flights_taken.length) {
      addParam({ disabled: true });
    } else {
      removeParams(['disabled']);
    }
  }, []);


  const onClick = () => {
    history.push(`/new-claim/flight-info/${id}`);
  };

  useEffect(() => {
    handleSection({ section: 0, subsection: 0 });
  }, []);

  return (
    <>
      <Section style={{ marginBottom: '25px' }}>
        <Section.Title>{t('Flight List')}</Section.Title>
        <Section.Content>
          <Desc>
            {t(`Flight list description`)}
          </Desc>
          {flights_taken && flights_taken.length
            ? flights_taken.map(f => (
              <StyledFlight key={f.flight_number} handleChange={() => { }}>
                <FlightSelectBar.FlightData
                  border
                  data={f.flight_number}
                  label={f.airline}
                />
                <FlightSelectBar.FlightData
                  border={false}
                  data={`${f.departure_date.hour}:${f.departure_date.minute}`}
                  label={t("Departure")}
                />
                <FlightSelectBar.FlightData
                  border={false}
                  data={f.departure_airport_iata}
                  label={f.departure_airport}
                />
                <FlightBox>
                  <FlightIcon />
                </FlightBox>
                <FlightSelectBar.FlightData
                  border={false}
                  data={f.arrival_airport_iata}
                  label={f.arrival_airport}
                />
                <FlightSelectBar.FlightData
                  border={false}
                  data={`${f.departure_date.day}/${f.departure_date.month}/${f.departure_date.year}`}
                  label={t("Departure Date")}
                />
              </StyledFlight>
            ))
            : null}
        </Section.Content>
        <StyledEntityButton data-test-id='add-flight' onClick={onClick} name={t("Add flight")} />
      </Section>
      <StyledButton data-test-id='continue-flight' disabled={!flights_taken} onClick={handleFormData}>
        {t('Continue')}
      </StyledButton>
    </>
  );
});

const FlightBox = styled.div`
  transform: translateX(-3%);
  position: relative;

  &::before,
  &::after {
    content: '- - - - - - - - - - - - -';
    color: ${({ theme }) => theme.typo.interactive};
    display: inline-block;
    transform: translate(-2%, -20%);
  }

  &::after {
    transform: translate(2%, -20%);
  }

  @media (max-width: 1000px) {
    &::before, &::after {
      display: none;
    }
  }
`;

const Desc = styled(Label)`
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.924996px;
  color: #666666;
`;

const StyledButton = styled(ActionButton)`
  margin-top: 25px;
   @media(max-width: 600px) {
    width: 100%;
  }
`;

StyledButton.displayName = 'StyledButton';

const StyledEntityButton = styled(AddEntityButton)`
  margin-top: 18px;
  width: 100%;
  height: 95px;
  background: ${({ theme }) => theme.ui.toolbar};
  border: 0.5px dashed ${({ theme }) => theme.ui.outline};
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;

  svg {
    margin-bottom: 0;
  }
`;

const StyledFlight = styled(FlightSelectBar)`
  width: 100%;
  box-sizing: border-box;
  height: 95px;
  justify-content: space-between;
  & > span:last-child {
    display: none;
  }

  & > div {
    &:nth-child(2) {
      margin-right: 73px;
    }
    &:nth-child(3) {
      margin-right: 55px;
    }
    &:nth-child(4) {
      margin-right: 8px;
    }
    &:nth-child(6) {
      margin-right: 55px;
    }
    &:nth-child(7) {
      margin-right: 0;
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;
    height: 260px;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;

    & > div {
      margin-left: 0 !important;
      margin-right: 0 !important; 

      &:nth-child(1) {
        border-right: none;
        border-bottom: 1px solid #FF9B21;
      }
    }
    & span:last-child {
      display: none;
    }
  }
`;

StyledFlight.displayName = 'Flight';

export default Flights;
