import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { ActionButton, SubTitle, Uploader } from 'wg-fe-ui';
import styled from 'styled-components';
import PlusIcon from '../../../icons/Plus';
import { supportedFiles } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { deleteFile, uploadFile } from './../../../helpers/apiRouterService';

const AccidentForm = forwardRef(
  ({ handleChange, handleFormData, claimId }, ref) => {
    const uploaderRef = useRef();
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const onClick = async ({ name, value }) => {
      if (value.dispatchConfig) return;
      let statuses = [];
      try {
        statuses = await Promise.all(
          [...value].map(async (file, i) => {
            return await uploadNewFile(name, file);
          }),
        );
        if (statuses.find(f => f !== 200)) return;
        handleChange({ name: name, value: [...value].map(val => val.name) });
      } catch (error) {
        console.error('error: ', error);
      }
    };

    const uploadNewFile = async (name, f) => {
      const [, status] = await uploadFile(claimId, {
        title: f.title,
        file: f.data.split(',')[1],
        filename: f.name.toLowerCase(),
        category: name,
        description: 'this is cool file',
      });
      return status;
    };

    return (
      <>
        <StyledH2>{t('European accident form')}</StyledH2>
        <p>
          {t(
            "We'd like to ask you to upload European accident form so we can categorize the damage.",
          )}
        </p>
        <StyledUploader
          ref={uploaderRef}
          name="upload"
          icon={<PlusIcon />}
          multiple={true}
          onClick={onClick}
          supported={supportedFiles}
        >
          {({ files, loaded, handleClose }) =>
            files.map((file, i) => (
              <SmallFileBox
                loaded={loaded[i]}
                name={file.name}
                size={file.size}
                source={file.img}
                onClose={handleClose}
              />
            ))
          }
        </StyledUploader>
        <StyledButton
          onClick={handleFormData}
          ref={ref}
          data-test-id="continueButton"
        >
          {t('Continue')}
        </StyledButton>
      </>
    );
  },
);

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 23px;
`;

StyledButton.displayName = 'StyledButton';

const StyledUploader = styled(Uploader)`
  width: 100%;
  margin-left: 0;
  & > label {
    background-color: ${({ theme }) => theme.ui.toolbar};
    margin-top: 18px;
    width: 100%;
    height: 95px;
    border: 1px solid ${({ theme }) => theme.ui.outline};
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

const SmallFileBox = styled(Uploader.FileBox)`
  width: 30%;
  margin-right: 3%;
  margin-top: 45px;
`;

const StyledH2 = styled(SubTitle)`
  margin-bottom: 5px;
`;

export default AccidentForm;
