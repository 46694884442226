import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { isObjectEmpty } from '../../helpers/objectService';

import Category from './claim/Category';
import Issues from './claim/Issues';
import HomeCurrentResidence from './claim/HomeCurrentResidence';
import HomeAccidentDescription from './claim/HomeAccidentDescription';
import AddressConfirm from './claim/AddressConfirm';
import Flights from './claim/Flights';
import FlightInfo from './claim/FlightInfo';
import FlightIssues from './claim/FlightIssues';
import RepairerSelect from './claim/RepairerSelect';
import TimeFrame from './claim/TimeFrame';
import ClaimSubtype from './claim/ClaimSubtype';
import CompanyContact from './claim/CompanyContact';

import AccidentForm from './accident/AccidentForm';
import AccidentUpload from './accident/AccidentUpload';
import DetailsOccurrence from './accident/DetailsOccurrence';
import DetailsOccurrenceHome from './accident/DetailsOccurrenceHome';
import SpecialCircumstances from './accident/SpecialCircumstances';

import Passengers from './client/Passengers';
import PassengerContact from './client/PassengerContact';
import ContactInfo from './client/ContactInfo';
import TravelDocs from './client/TravelDocs';
import Last from './client/Last';
import WitnessList from './client/WitnessList';

import Involved from './personal/Involved';
import InjuredInfo from './personal/InjuredInfo';
import WitnessInfo from './personal/WitnessInfo';
import InsureeInfo from './personal/InsureeInfo';
import ThirdPartyInfo from './personal/ThirdPartyInfo';

import ContactOptions from './submit/ContactOptions';
import useQueryParams from '../../hooks/useQueryParams';
import useForm from './../../hooks/useForm';
import useNewClaimData from '../../hooks/useNewClaimData.js';
import useRouting from '../../hooks/useRouting';
import { getSchema } from './../../helpers';
import schema from '../../constants/validationSchemas';
import ThankYou from './submit/ThankYou';
import Summary from './submit/Summary';
import { getCase } from '../../helpers/apiRouterService';
import CarLookup from './claim/CarLookup';
import AccidentPersonal from './accident/AccidentPersonal';

const RouteSwitch = ({
  handleSection,
  prefix = '/new-claim',
  childRef,
  userRole,
}) => {
  const { nextRoute, summaryRoute } = useRouting();
  let { pathname } = useLocation();

  let change = false;
  const [claim, setClaim] = useState({});
  const [claimCategory, setClaimCategory] = useState('');
  const {
    initializeStorage,
    initializeClaimType,
    storeClaimData,
    getClaimData,
  } = useNewClaimData();

  const claimData = getClaimData().claim_data;

  const form = useForm({
    validationSchema: getSchema(claimCategory),
    change: () => {
      change = false;
      return !change;
    },
  });

  const validateForms = async (data, schemaName) => {
    const validationErrors = await form.validate(data, schema[schemaName]);
    const hasErrors = !isObjectEmpty(validationErrors);
    return [validationErrors, hasErrors];
  };

  useEffect(() => {
    setClaimCategory(prev => {
      change = claimCategory !== prev;
      return claimCategory;
    });
  }, [claimCategory]);

  const paths = pathname.replace('?disabled=true', '').split('/');
  const id = paths[paths.length - 1];

  const updateClaimId = async () => {
    const [res, status] = await getCase(id);
    if (status !== 200) return;
    if (res.values && res.values[0]) {
      setClaim(res.values[0].claim);
    }
  };

  useEffect(() => {
    if (!id) return setClaim({});
    if (!pathname.endsWith('/category')) updateClaimId();
  }, [pathname]);

  const { hasParam, removeParams } = useQueryParams();

  useEffect(() => {
    if (hasParam('submit')) {
      removeParams(['submit']);
      form.handleSubmit(onSubmit);
    }
  });

  const onSubmit = data => {
    storeClaimData(data);
    if (prefix === '/summary') summaryRoute();
    else nextRoute();
  };

  const additionalProps = {
    claimData, // storage data
    storeClaimData,
    handleSection,
    claim, // api data
    claimId: claim.metadata ? claim.metadata.uid : '',
    validateForms,
    prefix,
    ref: childRef,
  };

  return (
    <Switch>
      <Route
        path={`${prefix}/category/:id?`}
        render={props => (
          <Category
            {...props}
            {...form}
            setCategory={setClaimCategory}
            initializeStorage={initializeStorage}
            initializeClaimType={initializeClaimType}
            {...additionalProps}
          />
        )}
      />
      <Route
        path={`${prefix}/car-lookup/:id`}
        render={props => (
          <CarLookup
            {...props}
            {...form}
            {...additionalProps}
            userRole={userRole}
          />
        )}
      />
      <Route
        path={`${prefix}/flights/:id`}
        render={props => <Flights {...props} {...form} {...additionalProps} />}
      />
      <Route
        path={`${prefix}/flight-info/:id`}
        render={props => (
          <FlightInfo {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/residence/:id`}
        render={props => (
          <HomeCurrentResidence {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/accident-desc/:id`}
        render={props => (
          <HomeAccidentDescription {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/address-confirm/:id`}
        render={props => (
          <AddressConfirm {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/claim-subtype/:id`}
        render={props => (
          <ClaimSubtype {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/time-frame/:id`}
        render={props => (
          <TimeFrame {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/company-contact/:id`}
        render={props => (
          <CompanyContact {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/repairer-select/:id`}
        render={props => (
          <RepairerSelect {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/flight-issues/:id`}
        render={props => (
          <FlightIssues {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/passengers/:id`}
        render={props => (
          <Passengers {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/passenger-contact/:id`}
        render={props => (
          <PassengerContact {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/contact-info/:id`}
        render={props => (
          <ContactInfo {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/travel/:id`}
        render={props => (
          <TravelDocs {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/last/:id`}
        render={props => <Last {...props} {...form} {...additionalProps} />}
      />
      <Route
        path={`${prefix}/issues/:id`}
        render={props => <Issues {...props} {...form} {...additionalProps} />}
      />
      <Route
        path={`${prefix}/involved/:id`}
        render={props => <Involved {...props} {...form} {...additionalProps} />}
      />
      <Route
        path={`${prefix}/injured-info/:id`}
        render={props => (
          <InjuredInfo {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/witness-info/:id`}
        render={props => (
          <WitnessInfo {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/insuree-info/:id`}
        render={props => (
          <InsureeInfo {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/third-party-info/:id`}
        render={props => (
          <ThirdPartyInfo {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/details-occurrence/:id`}
        render={props => (
          <DetailsOccurrence {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/details-occurrence-home/:id`}
        render={props => (
          <DetailsOccurrenceHome {...props} {...form} {...additionalProps} />
        )}
      />
      />
      <Route
        path={`${prefix}/special-circumstances/:id`}
        render={props => (
          <SpecialCircumstances {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/accident-form/:id`}
        render={props => (
          <AccidentForm {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/accident-personal/:id`}
        render={props => (
          <AccidentPersonal {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/accident-upload/:id`}
        render={props => (
          <AccidentUpload {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/contact-options/:id`}
        render={props => (
          <ContactOptions {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/witness-list/:id`}
        render={props => (
          <WitnessList {...props} {...form} {...additionalProps} />
        )}
      />
      <Route
        path={`${prefix}/summary/:id`}
        render={props => <Summary {...props} {...form} {...additionalProps} />}
      />
      <Route
        path={`${prefix}/thank-you/:id`}
        render={props => (
          <ThankYou
            {...props}
            {...form}
            {...additionalProps}
            userRole={userRole}
          />
        )}
      />
    </Switch>
  );
};
export default RouteSwitch;
