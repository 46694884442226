import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import styled from 'styled-components';
import { RadioOption, ActionButton } from 'wg-fe-ui';
import Description from '../../../components/IssuesTextArea';
import {
  FlightCanceled,
  FlightDelayed,
  FlightDenied,
  FlightMissed,
  Other,
} from '../../../icons';
import useQueryParams from '../../../hooks/useQueryParams';
import useNewClaimData from '../../../hooks/useNewClaimData.js';
import { updateClaim } from './../../../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';


const options = [
  {
    label: 'My flight was delayed',
    value: 'DELAYED_FLIGHT',
    icon: <FlightDelayed />,
  },
  {
    label: 'I missed my connecting flight',
    value: 'MISSED_CONNECTION',
    icon: <FlightMissed />,
  },
  {
    label: 'My flight got cancelled or rerouted',
    value: 'CANCELLED_OR_REROUTED',
    icon: <FlightCanceled />,
  },
  {
    label: "I've been denied access to my flight",
    value: 'ENTRY_DENIED',
    icon: <FlightDenied />,
  },
  { label: 'Other flight issues', value: 'OTHER', icon: <Other /> },
];

const FlightIssues = forwardRef(({
  setValues,
  handleSection,
}, ref) => {
  const [checked, setChecked] = useState('');
  const [description, setDescription] = useState('');
  const { removeParams, addParam, hasParam } = useQueryParams();

  const { t } = useTranslation();

  const { getClaimData } = useNewClaimData();
  const data = getClaimData();

  const sendIssues = async () => {
    const [, status] = await updateClaim(data._claim_id, {
      claim: {
        cause: checked,
        description: description.length ? description : null,
      },
    });
    return status;
  };

  const handleFormData = async e => {
    if (e) e.preventDefault();
    const status = await sendIssues();
    status < 300 && addParam({ submit: true });
  };

  useImperativeHandle(ref, () => ({
    click: () => handleFormData()
  }));

  const handleClick = ({ value }) => {
    setChecked(value);
  };

  useEffect(() => {
    if (!checked) {
      addParam({ disabled: true });
    } else {
      removeParams(['disabled']);
      setValues(values => ({ ...values, issue: checked, description }));
    }
  }, [checked, description]);

  useEffect(() => {
    handleSection({ section: 0, subsection: 0 });
  }, []);

  return (
    <>
      <Container>
        <h2>{t('What issue did you encounter')}</h2>
        <OptionsContainer data-test-id="issues">
          {options.map(option => (
            <RadioOption
              icon={option.icon}
              value={option.value}
              key={option.value}
              name="issue"
              checked={checked === option.value}
              handleChange={handleClick}
            >
              {t(option.label)}
            </RadioOption>
          ))}
        </OptionsContainer>
        <>
          <h2>{t('Description')}</h2>
          <p>
            {t(`Fill in all the relevant details. What did the flight crew tell you? Did the pilot make an announcement? The more information provided, the more effectively we can investigate your claim.`)}
          </p>
          <StyledTextArea
            type="text"
            onChange={e => setDescription(e.target.value)}
            name="description"
            value={description}
          />
        </>
        <StyledBtn data-test-id='continue-flight' onClick={handleFormData} disabled={hasParam('disabled')}>
          {t('Continue')}
        </StyledBtn>
      </Container>
    </>
  );
});

const StyledBtn = styled(ActionButton)`
  @media(max-width: 600px) {
    width: 100%;
  }
`

StyledBtn.displayName = 'StyledBtn';

const StyledTextArea = styled(Description)`
  height: 240px;
  width: 100%;
  margin-bottom: 27px;
`;

StyledTextArea.displayName = 'TextArea';

const Container = styled.div`
  margin-bottom: 41px;
  h2 {
    margin-bottom: 19px;
  }
  p {
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.highlight};
    margin-bottom: 1rem;
  }

  h3 {
    line-height: 2.4rem;
    color: ${({ theme }) => theme.typo.text};
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 47px;
  label {
    margin: 0 2.6vw 15px 0;
    flex-basis: 20%;
  }

  @media (max-width: 600px) {
    label {
      width: 100%;
      flex-basis: 100%;
      padding: 0 10px;
    }
  }
`;

export default FlightIssues;
