import React from 'react';
import {
  Flex,
  File,
  SubTitle,
  StyledLabel,
  FileBox,
  Icons,
  Preview,
  SmallText,
  BigText,
} from '../../styledComponents';
import {
  Clock,
  Download,
  Flash,
  Info,
  Envelope,
  Phone,
  Storm,
} from '../../icons';
import { useTranslation } from 'react-i18next'
// import { files } from '../../helpers/mockedData';
import Summary from '../../components/DetailsSummary';
import Section from '../../components/DetailsSection';
import DataColumn from '../../components/DetailsDataColumn';
import WindspeedChart from '../../components/ChartWindspeed';
import PrecipitationChart from '../../components/ChartPrecipitation';
import { formatBytes } from './utils';

const DetailHome = ({ claim, refs }) => {
  const { t } = useTranslation();
  const occurenceDate = `${claim.metadata.created_at.day}/${claim.metadata.created_at.month}/${claim.metadata.created_at.year}`;
  console.log(claim)
  const summary = [
    { title: 'Category', value: claim.type, id: 0 },
    { title: 'Claim type', value: claim.peril, id: 1 },
    { title: 'Occurrence date', value: occurenceDate, id: 2 },
    { title: 'Recommendation', value: 'Execute expertise', id: 3 },
    { title: 'Payout', value: 'Repair Service', id: 4 },
    { title: 'Value', value: '€ 1580', id: 5 },
  ];

  const claimInfo = {
    mainData: [
      { title: 'Claim type', value: claim.peril, id: 0 },
      { title: 'Involved spaces', value: claim.rooms, id: 1 },
    ],
    subData: [
      {
        title: 'Description',
        value: claim.description,
        id: 0,
      },
    ],
  };

  const detailedReport = {
    mainData: [
      { id: 0, title: 'Measured on', value: '09/17/2019 12:31 PM' },
      {
        id: 1, title: 'Timezone', value: `${t('Europe')} / ${t('Brussels')} (GMT+1)`
      },
    ],
    subData: [
      {
        id: 0,
        title: 'Description',
        value:
          'Heavy rain that starts at night and continues through morning, paired with heavy winds and hail.',
      },
    ],
  };
  return (
    <>
      <Summary>
        <Storm />
        {summary.map(s => (
          <Summary.Column key={s.id}>
            <Summary.Title>{t(s.title)}</Summary.Title>
            <Summary.Value>{t(s.value)}</Summary.Value>
          </Summary.Column>
        ))}
      </Summary>
      <Section ref={refs[1][1]}>
        {claim.contact  && (
          <Section.Title>{t('Company details')}</Section.Title>
        )}
            <Section.Content background={true} key={claim.contact.email}>
              <Section.MainData>
                <DataColumn darkColors bigValue boldValue>
                  {t('')}
                  <span className="title">{t('Name')}</span>
                  <span className="value">{`${claim.contact.first_name} ${claim.contact.last_name}`}</span>
                </DataColumn>
                <Icons>
                  <Envelope />
                  <Phone />
                </Icons>
              </Section.MainData>
              <Section.SubData>
                <DataColumn>
                  <span className="title">{t('Address')}</span>
                  <span className="value">{`${claim.place_occurred.address.street}, ${claim.place_occurred.address.zipcode} ${claim.place_occurred.address.city}`}</span>
                </DataColumn>
                <DataColumn>
                  <span className="title">{t('Email')}</span>
                  <span className="value">{claim.contact.email}</span>
                </DataColumn>
                {claim.contact.telephonenr && <DataColumn basis="45.6%" style={{ marginTop: 5 }}>
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{claim.contact.telephonenr}</span>
                </DataColumn>}
                {claim.contact.mobilephonenr && <DataColumn style={{ marginTop: 5 }}>
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{claim.contact.mobilephonenr}</span>
                </DataColumn>}
              </Section.SubData>
            </Section.Content>
      </Section>
      <Section ref={refs[1][2]}>
        <Section.Title>{t('Claim information')}</Section.Title>
        <Section.Content background={true}>
          <Section.MainData wrap direction="row">
            <Flash />
            <Flex margin="0 0 0 4vw">
              {claimInfo.mainData.map(data => (
                <DataColumn key={data.id} bigValue boldValue>
                  <span className="title">{t(data.title)}</span>
                  <span className="value">{t(data.value)}</span>
                </DataColumn>
              ))}
            </Flex>
          </Section.MainData>
          <Section.SubData wrap={false}>
            <Info />
            <Flex justify="flex-start" direction="column" margin="0 0 0 4vw">
              <p>{claim.description}</p>
            </Flex>
          </Section.SubData>
        </Section.Content>
      </Section>
      {/* <Section ref={refs[1][3]}>
        <Section.Title>{t('Detailed report')}</Section.Title>
        <Section.Content>
          <Section.MainData wrap direction="row">
            <Clock />
            <Flex margin="0 0 0 4vw">
              {detailedReport.mainData.map(data => (
                <DataColumn key={data.id} bigValue boldValue>
                  <span className="title">{t(data.title)}</span>
                  <span className="value">{data.value}</span>
                </DataColumn>
              ))}
            </Flex>
          </Section.MainData>
          <Section.SubData wrap={false}>
            <Info />
            <Flex margin="0 0 0 4vw">
              {detailedReport.subData.map(data => (
                <DataColumn key={data.id}>
                  <span className="title">{t(data.title)}</span>
                  <span className="value">{t(data.value)}</span>
                </DataColumn>
              ))}
            </Flex>
          </Section.SubData>
        </Section.Content>
      </Section> */}
      <Section ref={refs[1][4]}>
        <Section.Content>
          <DataColumn bigValue boldValue>
            <span className="title">
              {t('Measured between')} 09/19/2019 - 09/20/2019
            </span>
            <span className="value">{t('Windspeed')}</span>
          </DataColumn>
          <WindspeedChart
            width={830}
            height={230}
            top={20}
            bottom={30}
            left={50}
            right={0}
          />
        </Section.Content>
      </Section>
      <Section ref={refs[1][5]}>
        <Section.Content>
          <Section.MainData noBorder>
            <DataColumn bigValue boldValue>
              <span className="title">
                {t('Measured between')} 09/19/2019 - 09/20/2019
              </span>
              <span className="value">{t('Precipitation')}</span>
            </DataColumn>
            <DataColumn bigValue boldValue>
              <span className="title">{t('Daily average')}</span>
              <span className="value">8.06 mm/h</span>
            </DataColumn>
          </Section.MainData>
          <PrecipitationChart
            width={1000}
            height={300}
            top={20}
            bottom={30}
            left={50}
            right={0}
          />
        </Section.Content>
      </Section>
      {claim.files && claim.files.length ? (
        <Section ref={refs[1][6]}>
          <Section.Title>{t('Uploaded documents')}</Section.Title>
          <Section.Content>
            <Flex>
              <DataColumn>
                <SubTitle>{t('Picture report')}</SubTitle>
                <Flex direction="column" justify="flex-start">
                  {claim.files.map(f => (
                    <FileBox key={f.id} marginTop>
                      <Flex>
                        <Preview />
                        <File direction="column">
                          <span className="name">{f.metadata.name}</span>
                          <span className="size">{formatBytes(f.size_in_bytes)}</span>
                        </File>
                      </Flex>
                      <Download />
                    </FileBox>
                  ))}
                </Flex>
              </DataColumn>
            </Flex>
          </Section.Content>
        </Section>
      ) : null}
      {claim.expenses ? (
        <Section ref={refs[1][7]}>
          <Section.Title>{t('Extra expenses')}</Section.Title>
          <Section.Content>
            <Flex>
              <DataColumn darkColors bigValue boldValue basis="40%">
                <SubTitle>{t('PRICING OVERVIEW')}</SubTitle>
                <SmallText>{t('Materials')}</SmallText>
                <Flex justify="space-between">
                  <BigText>{t('Baseboards')} (4)</BigText>
                  <span>€ 40</span>
                </Flex>
                <Flex justify="space-between">
                  <BigText>{t('Parquet')} (220 ft²)</BigText>
                  <span>€ 1260</span>
                </Flex>
                <Flex justify="space-between">
                  <BigText>{t('Curtains')}</BigText>
                  <span>€ 280</span>
                </Flex>
              </DataColumn>
              <DataColumn basis="45%">
                <SubTitle>{t('DOCUMENTS REGARDING DAMAGED GOODS')}</SubTitle>
                <FileBox small>
                  <File small>
                    <span className="name">bk-receipt.pdf</span>
                    <span className="size">1.3mb</span>
                  </File>
                  <Download className="icon noPos" />
                </FileBox>
                <FileBox small>
                  <File small>
                    <span className="name">press-shop.jpg</span>
                    <span className="size">1.3mb</span>
                  </File>
                  <Download />
                </FileBox>
              </DataColumn>
              <Section.SubData borderTop basis="40%">
                <Flex justify="space-between" basis="100%">
                  <BigText>{t('Expenses')}</BigText>
                  <span>15.25</span>
                </Flex>
              </Section.SubData>
            </Flex>
          </Section.Content>
        </Section>
      ) : null}
    </>
  );
};

export default DetailHome;
