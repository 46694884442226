import React, { useState } from 'react';
import { ThemeProvider as ExternalThemeProvider } from 'wg-fe-ui';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { getThemeObject } from './helpers/themingService';


const ThemeContextProvider = ({ theme, children }) => {
  const [themeObject,] = useState(getThemeObject(theme));

  return (
    <ExternalThemeProvider theme={themeObject}>
      <ThemeProvider theme={themeObject}>{children}</ThemeProvider>
    </ExternalThemeProvider>
  );
};

export { ThemeContext, ThemeContextProvider as ThemeProvider };
