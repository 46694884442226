import React from 'react';
import { string } from 'prop-types';

const Lugage = ({ className, width = 20, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0 4.01671V14.4264C0 15.1423 0.558767 15.719 1.25241 15.719H3.02505V2.73413H1.25241C0.558767 2.73413 0 3.31079 0 4.01671Z"
          fill="#C1C1C1"
        />
        <path
          d="M17.0904 13.5317C17.3024 13.3129 17.6588 13.3129 17.8708 13.5317C18.0827 13.7504 18.0827 14.1183 17.8708 14.337L17.0615 15.1722L17.8708 16.0073C18.0827 16.2261 18.0827 16.5939 17.8708 16.8127C17.6588 17.0314 17.3024 17.0314 17.0904 16.8127L16.2812 15.9775L15.4719 16.8127C15.26 17.0314 14.9035 17.0314 14.6916 16.8127C14.4796 16.5939 14.4796 16.2261 14.6916 16.0073L14.9613 15.7289H13.7282V12.3883C13.0153 13.0842 12.5625 14.0785 12.5625 15.1722C12.5625 17.2899 14.2292 19.0099 16.2812 19.0099C18.3332 19.0099 19.9999 17.2899 19.9999 15.1722C19.9999 13.2234 18.5837 11.6128 16.7629 11.3741V13.8796L17.0904 13.5317Z"
          fill="#C1C1C1"
        />
        <path
          d="M13.7383 12.3783V15.719H14.9714L15.5109 15.1622L14.7017 14.327C14.4897 14.1083 14.4897 13.7404 14.7017 13.5217C14.9136 13.303 15.2701 13.303 15.482 13.5217L16.2913 14.3569L16.7633 13.8697V11.3642C16.6092 11.3443 16.4454 11.3344 16.2913 11.3344C15.299 11.3344 14.403 11.732 13.7383 12.3783Z"
          fill="#C1C1C1"
        />
        <path
          d="M16.7633 9.96234V4.01676C16.7633 3.3009 16.2046 2.72424 15.5109 2.72424H13.7383V10.6484C14.4897 10.201 15.3568 9.93251 16.2913 9.93251C16.4454 9.94245 16.5996 9.95239 16.7633 9.96234Z"
          fill="#C1C1C1"
        />
        <path
          d="M12.09 12.2391V2.73417H11.3963V1.09367C11.3963 0.487179 10.9243 0 10.3366 0H6.42524C5.83758 0 5.36552 0.487179 5.36552 1.09367V2.73417H4.67188V15.7289H11.2518C11.2326 15.55 11.2229 15.3611 11.2229 15.1722C11.2229 14.0884 11.5408 13.0743 12.09 12.2391ZM10.3366 2.73417H6.42524V1.09367H10.3366V2.73417Z"
          fill="#C1C1C1"
        />
        <path
          d="M16.2812 11.3344C14.2292 11.3344 12.5625 13.0544 12.5625 15.1721C12.5625 17.2899 14.2292 19.0099 16.2812 19.0099C18.3332 19.0099 19.9999 17.2899 19.9999 15.1721C19.9999 13.0544 18.3332 11.3344 16.2812 11.3344ZM17.8708 16.0073C18.0827 16.226 18.0827 16.5939 17.8708 16.8126C17.6588 17.0314 17.3024 17.0314 17.0904 16.8126L16.2812 15.9775L15.4719 16.8126C15.26 17.0314 14.9035 17.0314 14.6916 16.8126C14.4796 16.5939 14.4796 16.226 14.6916 16.0073L14.9613 15.7289L15.5008 15.1721L14.6916 14.337C14.4796 14.1182 14.4796 13.7504 14.6916 13.5316C14.9035 13.3129 15.26 13.3129 15.4719 13.5316L16.2812 14.3668L16.7532 13.8796L17.0904 13.5316C17.3024 13.3129 17.6588 13.3129 17.8708 13.5316C18.0827 13.7504 18.0827 14.1182 17.8708 14.337L17.0615 15.1721L17.8708 16.0073Z"
          fill="#C1C1C1"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Lugage.propTypes = {
  className: string,
};

export default Lugage;
