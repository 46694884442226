import React from 'react';
import { string } from 'prop-types';

const CheckedBox = ({ className, width = 15, height = 21, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6001 4.17324H11.1778C11.0407 4.17324 10.9294 4.06291 10.9294 3.92758V0.551328C10.9294 0.415996 10.8175 0.305664 10.6804 0.305664H0.538075C0.400347 0.305664 0.289062 0.415996 0.289062 0.551328V19.9496C0.289062 20.0843 0.400347 20.1952 0.538075 20.1952H14.6001C14.7378 20.1952 14.8491 20.0843 14.8491 19.9496V4.41891C14.8491 4.28303 14.7378 4.17324 14.6001 4.17324ZM11.4213 8.26149L8.06463 13.153C7.91919 13.3655 7.6812 13.4976 7.42172 13.5107C7.40795 13.5112 7.39417 13.5117 7.3804 13.5117C7.1358 13.5117 6.90331 13.4047 6.74575 13.2182L4.64788 10.735C4.3559 10.3888 4.40327 9.87461 4.75365 9.58655C5.10458 9.29849 5.6252 9.34523 5.91773 9.6909L7.31209 11.3415L10.0523 7.34786C10.3085 6.97501 10.8225 6.87718 11.1999 7.12937C11.5778 7.38209 11.677 7.88864 11.4213 8.26149Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6987 2.98151H12.2978C12.1601 2.98151 12.0488 2.87172 12.0488 2.73584V0.367236C12.0488 0.312342 12.116 0.285167 12.1552 0.323756L14.7428 2.87661C14.7819 2.91574 14.7538 2.98151 14.6987 2.98151Z"
      />
    </svg>
  );
};

CheckedBox.propTypes = {
  className: string,
};

export default CheckedBox;
