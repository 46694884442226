import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { ActionButton, Section, TextInput, SearchSelectInput } from 'wg-fe-ui';
import useQueryParams from '../../../hooks/useQueryParams';
import GoogleMap from '../../../components/GoogleMap';
import { getCoordinatesByAddress } from '../../../helpers/googleMapService';
import { initResidenceObject } from '../../../helpers/mockedData';
import {
  callCities,
  callStreets,
  debouncer,
  parseGoogleAddress,
} from '../../../helpers/addressService';
import { getAddressbyLatLng } from '../../../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';

const CurrentResidence = forwardRef(
  ({ setValues, validateForms, handleSection }, ref) => {
    const [currentResidence, setCurrentResidence] = useState(
      initResidenceObject,
    );
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);
    const [coordinates, setCoordinates] = useState({});
    const [errors, setErrors] = useState({});
    const { addParam, removeParams } = useQueryParams();
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    useEffect(() => {
      if (!Object.keys(coordinates).length) addParam({ disabled: true });
      else {
        removeParams(['disabled']);
      }
    }, [coordinates]);

    useEffect(() => {
      handleSection({ section: 0, subsection: 0 });
    }, []);

    const onMapClick = async (data) => {
      const [resp, status] = await getAddressbyLatLng({ ...data });
      if (status !== 200) return;
      resp.address &&
        setCurrentResidence({ ...parseGoogleAddress(resp.address) });
    };

    // Used to rerender searcSelect after filling zipcode
    const [cityKey, setCityKey] = useState(0);
    const handleSearchClick = async () => {
      const [validationErrors, hasErrors] = await validateForms(
        currentResidence,
        'residence',
      );
      setErrors(() => mapValues(validationErrors, (e) => t(e)));
      if (hasErrors) return;
      setSearchButtonDisabled(true);
      const address = `${currentResidence.street} ${currentResidence.housenr}/${currentResidence.boxnr}, ${currentResidence.zipcode} ${currentResidence.city}`;
      const res = await getCoordinatesByAddress(address);
      res.results[0] && setCoordinates(res.results[0].geometry.location);
    };

    const updateCurrentResidence = ({ name, value }) => {
      if (name === 'zipcode')
        debouncer(() => {
          setCityKey((val) => (val === 0 ? 1 : 0));
        });
      else if (name === 'city') {
        return setCurrentResidence((data) => ({
          ...data,
          [name]: value.val,
          zipcode: value.zipcode,
        }));
      }
      setCurrentResidence((old) => ({
        ...old,
        [name]: name === 'zipcode' ? parseInt(value) : value,
      }));
      setSearchButtonDisabled(false);
    };

    const handleFormData = (e) => {
      if (e) e.preventDefault();
      setValues((old) => ({ ...old, currentResidence }));
      addParam({ submit: true });
    };

    return (
      <>
        <StyledSection>
          <Section.Title>{t('Current residence')}</Section.Title>
          <Section.Content background>
            <InputsRow>
              <TextInput
                name="zipcode"
                onChange={({ name, value }) =>
                  updateCurrentResidence({ name, value: parseInt(value) })
                }
                value={currentResidence.zipcode || ''}
                error={errors['zipcode']}
              >
                {t('Zip-code')}
              </TextInput>
              <StyledSearchSelectInput
                async
                name="city"
                loadOptions={(q, cb) => callCities(q, cb, currentResidence)}
                defaultOptions={currentResidence.zipcode ? true : []}
                onSelected={updateCurrentResidence}
                value={
                  currentResidence.city
                    ? {
                        label: currentResidence.city,
                        value: currentResidence.city,
                      }
                    : undefined
                }
                error={errors['city']}
                cacheOptions={false}
                disabled={!currentResidence.zipcode}
                key={cityKey}
                placeholder={t('Choose your option')}
              >
                {t('City')}
              </StyledSearchSelectInput>
              <StyledSearchSelectInput
                async
                name="street"
                defaultOptions={
                  currentResidence.zipcode && currentResidence.city ? true : []
                }
                loadOptions={(q, cb) => callStreets(q, cb, currentResidence)}
                onSelected={updateCurrentResidence}
                value={
                  currentResidence.street
                    ? {
                        label: currentResidence.street,
                        value: currentResidence.street,
                      }
                    : undefined
                }
                error={errors['street']}
                cacheOptions={false}
                disabled={!currentResidence.zipcode || !currentResidence.city}
                placeholder={t('Choose your option')}
              >
                {t('Street')}
              </StyledSearchSelectInput>
              <TextInput
                name="housenr"
                onChange={updateCurrentResidence}
                value={currentResidence.housenr}
                error={errors['housenr']}
              >
                {t('House number')}
              </TextInput>
              <TextInput
                name="boxnr"
                onChange={updateCurrentResidence}
                value={currentResidence.boxnr}
                error={errors['boxnr']}
              >
                {t('Box')}
              </TextInput>
            </InputsRow>
          </Section.Content>
        </StyledSection>
        <StyledBtn
          onClick={handleSearchClick}
          disabled={searchButtonDisabled}
          data-test-id="searchButton"
        >
          {t('Search')}
        </StyledBtn>
        {Object.keys(coordinates).length ? (
          <>
            <StyledMap
              {...coordinates}
              initMarker={{
                show: true,
              }}
              onClick={onMapClick}
            />
            <StyledBtn onClick={handleFormData} data-test-id="continueButton">
              {t('Confirm')}
            </StyledBtn>
          </>
        ) : null}
      </>
    );
  },
);

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

StyledBtn.displayName = 'ActionButton';

const StyledMap = styled(GoogleMap)`
  margin: 3.5rem 0;
  width: 100rem;
  height: 35rem;
  border: 1px solid ${({ theme }) => theme.typo.outline};
  border-radius: 5px;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

StyledMap.displayName = 'Map';

const StyledSection = styled(Section)`
  margin-bottom: 2rem;
  ${Section.Content} {
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.typo.outline};
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    p,
    span,
    label {
      color: #666666;
      font-size: 1.4rem;
    }
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const StyledSearchSelectInput = styled(SearchSelectInput)`
  span {
    font-size: 0.4rem !important;
  }
`;

StyledSearchSelectInput.displayName = 'SearchSelectInput';

const InputsRow = styled.div`
  display: flex;
  & > div {
    margin-right: 1.2rem;
    &:nth-of-type(1) {
      width: 15.5%;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 29.1%;
    }
    &:nth-of-type(4) {
      width: 13.8%;
    }
    &:nth-of-type(5) {
      width: 9.1%;
    }
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;

    & > div {
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 36.1%;
      }
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: 45%;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    & > div {
      width: 100% !important;
    }
  }
`;

export default CurrentResidence;
