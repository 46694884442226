import React from 'react';
import { string } from 'prop-types';

const Witness = ({ className, width = 26, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M13 1.71429C17.3905 1.71429 21.515 4.10286 23.9673 8C21.515 11.8971 17.3905 14.2857 13 14.2857C8.60954 14.2857 4.485 11.8971 2.03273 8C4.485 4.10286 8.60954 1.71429 13 1.71429ZM13 0C7.44545 0 2.59409 3.22286 0 8C2.59409 12.7771 7.44545 16 13 16C18.5545 16 23.4059 12.7771 26 8C23.4059 3.22286 18.5545 0 13 0Z" />
      <path d="M18.318 8.00004C18.318 10.84 15.9366 13.1429 12.9998 13.1429C10.063 13.1429 7.68164 10.84 7.68164 8.00004C7.68164 6.73146 8.16028 5.57146 8.94619 4.67432L12.4089 7.42861L11.0912 3.20575C11.6821 2.97718 12.3262 2.85718 12.9998 2.85718C15.9366 2.85718 18.318 5.16004 18.318 8.00004Z" />
    </svg>
  );
};

Witness.propTypes = {
  className: string,
};

export default Witness;
