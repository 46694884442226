import React from 'react';
import { set } from '../helpers/objectService';
import useQueryParams from '../hooks/useQueryParams';

const defaultOptions = {
  mode: 'onSubmit',
  validationSchema: {},
};

const useForm = passedOptions => {
  const [options, setOptions] = React.useState(
    Object.assign({}, defaultOptions, passedOptions),
  );
  const { addParam } = useQueryParams();

  React.useEffect(() => {
    if (!passedOptions.change) return;
    setOptions(prev => Object.assign(prev, passedOptions));
  }, [passedOptions.change]);

  const [values, setValues] = React.useState(options.initialValues || {});
  const [errors, setErrors] = React.useState({});

  const handleChange = ({ name, value }) => {
    setValues(values => ({
      ...values,
      [name]: value,
    }));
  };

  // React.useEffect(() => {
  //   if (options.mode === 'onChange') {
  //     const valuesObject = values;
  //     validate(valuesObject).then(callbackErrors => {
  //       setErrors(callbackErrors);
  //     });
  //   }
  // }, [values]);

  const validate = (valuesObject, schema) =>
    new Promise(resolve => {
      let { validationSchema } = options || {};
      if (schema) validationSchema = schema;
      const tempErrors = {};

      const validators = validationSchema._nodes.map(field =>
        validationSchema.validateAt(field, valuesObject),
      );

      Promise.all(validators.map(p => p.catch(e => e))).then(errors => {
        const inValidResults = errors.filter(result => result instanceof Error);
        inValidResults.forEach(err => {
          set(tempErrors, err.path, err.errors[0]);
        });
        resolve(tempErrors);
      });
    });

  const handleSubmit = async callback => {
    setOptions(values => ({
      ...values,
      mode: 'onChange',
    }));
    // const callbackErrors = await validate(values);
    // setErrors(callbackErrors);
    // if (isObjectEmpty(callbackErrors)) {
    callback(values);
    // }
  };

  const resetForm = () => {
    if (passedOptions.mode !== 'onChange') options.mode = 'onSubmit';

    setValues({});
    setErrors({});
  };

  const handleFormData = e => {
    if (e) e.preventDefault();
    addParam({ submit: true });
  };

  return {
    handleChange,
    handleSubmit,
    handleFormData,
    resetForm,
    values,
    setValues,
    errors,
    setErrors,
    validate,
  };
};

export default useForm;
