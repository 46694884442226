import React, { useEffect, useState, createRef } from 'react';
import styled from 'styled-components';
import { SubNavigation, TrackingBar } from 'wg-fe-ui';
import DetailCar from './DetailCar';
import DetailHome from './DetailHome';
import DetailFlight from './DetailFlight';
import DetailLiability from './DetailLiability';
import {
  sections,
  stages,
  detailSections,
} from '../../helpers/mockedData';
import {
  StyledSubNavigation,
  StyledToolBar,
  Content,
} from '../../styledComponents';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getClaim } from './../../helpers/apiRouterService';
import useNewClaimData from '../../hooks/useNewClaimData';

const getDetailPage = ({ claim, refs }) => {
  if (claim.type.toLowerCase() === 'car')
    return <DetailCar claim={claim} refs={refs} />;
  if (claim.type.toLowerCase() === 'personal')
    return <DetailLiability claim={claim} refs={refs} />;
  if (claim.type.toLowerCase() === 'home')
    return <DetailHome claim={claim} refs={refs} />;
  if (claim.type.toLowerCase() === 'aviation')
    return <DetailFlight claim={claim} refs={refs} />;
  return null;
};

const generateSubNavigation = (
  trans,
  sectionsTemplate,
  currentSection,
  handleClick,
) => {
  if (!sectionsTemplate) return null;
  return (
    <StyledSubNavigation>
      {sectionsTemplate.map(section => (
        <React.Fragment key={section.id}>
          <SubNavigation.Section onClick={() => handleClick(section.id, 1)}>
            {trans(section.label)}
          </SubNavigation.Section>
          <SubNavigation.SectionContainer
            active={section.id === currentSection.section}
          >
            {section.subsections.map(subsection => (
              <SubNavigation.SubSection
                key={subsection.id}
                onClick={() => handleClick(section.id, subsection.id)}
                active={
                  currentSection.section === section.id &&
                  currentSection.subsection === subsection.id
                }
              >
                {trans(subsection.label)}
              </SubNavigation.SubSection>
            ))}
          </SubNavigation.SectionContainer>
        </React.Fragment>
      ))}
    </StyledSubNavigation>
  );
}

const Detail = () => {
  const { id } = useParams();
  const refs = {};
  const [claim, setClaim] = useState({});
  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });
  const { getClaimData } = useNewClaimData();
  const { t } = useTranslation();

  const callClaim = async () => {
    // Mocked Pete
    if (id === 'b9527f5a-f928-4908-9eed-4dfdb839ec78')
      return setClaim(getClaimData(id));
    const [resp, status] = await getClaim(id);
    status === 200 && setClaim(resp.values[0]);
  };

  React.useEffect(() => {
    callClaim();
  }, []);

  /**
   * Checks if scrolled to particular section and
   * sets currentSectionId var {sectionId.subsectionId}
   */
  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleSubmit = () => {
    console.log('clicked');
  };

  const handleClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;
    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  sections.forEach(s => {
    refs[s.id] = {};
    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  return (
    <>
      <StyledToolBar onClick={handleSubmit} btnName="Exporteer PDF" />
      <StyledContent>
        {generateSubNavigation(
          t,
          claim.type && detailSections[claim.type.toLowerCase()],
          currentSection,
          handleClick,
        )}
        <MainContainer>
          <StyledTrackingBar
            stages={stages}
            active={
              claim && claim.status ? claim.status : 'uploading_documents'
            }
          />
          {claim && claim.type
            ? getDetailPage({ claim, refs })
            : t('claim of given id does not exist')}
        </MainContainer>
      </StyledContent>
    </>
  );
};

const StyledContent = styled(Content)`
  @media (max-width: 1600px) {
    grid-template-columns: minmax(190px, 20vw) auto;
  }

  @media (max-width: 1200px) {
    grid-template-columns: minmax(190px, 22vw) auto;
  }

  ${StyledSubNavigation} {
    padding-left: 0 !important;

    ul {
      opacity: 1;
      overflow: visible;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: Content;
  margin-top: 2.5rem;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledTrackingBar = styled(TrackingBar)`
  margin-top: 0;
  margin-bottom: 85px;
`;

export default Detail;
