import React from 'react';
import { string } from 'prop-types';

const FlightDenied = ({ className, width = 22, height = 24, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10.9944 0L0 4.94614C0 13.0848 4.42923 20.2005 10.9944 24C17.5708 20.2005 21.9888 13.0848 21.9888 4.94614L10.9944 0ZM11.3316 17.3902C10.1063 17.3902 8.98211 16.9967 8.0603 16.3222C7.58814 15.985 7.18344 15.5691 6.84619 15.1082C6.17169 14.1864 5.77823 13.0623 5.77823 11.837C5.77823 8.77939 8.27389 6.28384 11.3316 6.28384C12.557 6.28384 13.6811 6.67728 14.603 7.35176C15.0751 7.68899 15.4798 8.10492 15.8171 8.56581C16.4916 9.48759 16.885 10.6117 16.885 11.837C16.885 14.9059 14.4006 17.3902 11.3316 17.3902Z" />
      <path d="M11.3316 7.84631C9.12825 7.84631 7.34082 9.63367 7.34082 11.8369C7.34082 12.6238 7.56565 13.3657 7.97036 13.984L13.4788 8.47582C12.8605 8.08238 12.1185 7.84631 11.3316 7.84631Z" />
      <path d="M9.19568 15.198C9.81397 15.5915 10.5447 15.8275 11.3428 15.8275C13.5462 15.8275 15.3336 14.0402 15.3336 11.8369C15.3336 11.05 15.1088 10.3081 14.7041 9.68982L9.19568 15.198Z" />
    </svg>
  );
};

FlightDenied.propTypes = {
  className: string,
};

export default FlightDenied;
