import React from 'react';
import { string } from 'prop-types';

const Clock = ({ className, width = 25, height = 25, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.2 0C18.9375 0 24.3994 5.46206 24.3994 12.1993C24.3994 18.9366 18.9375 24.3993 12.2001 24.3993C5.46269 24.3993 0.000389099 18.9368 0.000389099 12.1993C0.000389099 5.46181 5.46261 0 12.2 0ZM11.3576 13.6911C11.3582 13.7231 11.363 13.7552 11.3675 13.7873C11.3707 13.8114 11.3722 13.836 11.3776 13.8597C11.383 13.8843 11.3924 13.9087 11.4004 13.9333C11.4099 13.9631 11.4188 13.9927 11.4312 14.0208C11.4336 14.0257 11.4344 14.0309 11.4365 14.0358C11.4549 14.075 11.4762 14.1117 11.4995 14.1465C11.501 14.1487 11.5029 14.1508 11.5044 14.1529C11.531 14.1919 11.5613 14.2274 11.5932 14.2607C11.6021 14.2698 11.6111 14.278 11.6205 14.2866C11.6485 14.3134 11.678 14.3378 11.7094 14.3602C11.7191 14.3672 11.7284 14.3745 11.7385 14.3813C11.7788 14.4076 11.821 14.4308 11.8649 14.4498C11.8745 14.4541 11.8844 14.4566 11.8941 14.4604C11.9311 14.4747 11.9687 14.4866 12.0075 14.4956C12.0214 14.499 12.0352 14.502 12.0493 14.5045C12.0991 14.5136 12.1495 14.5199 12.2006 14.5199C12.2109 14.5199 12.2215 14.5167 12.2318 14.5163C12.2808 14.5144 12.3293 14.5106 12.3759 14.5007C12.3904 14.4975 12.4047 14.4911 12.4193 14.4872C12.4627 14.4754 12.5052 14.4623 12.5457 14.4441C12.5503 14.4418 12.5552 14.4414 12.5598 14.4393L17.2376 12.2328C17.6591 12.0341 17.8396 11.5313 17.6409 11.1098C17.4422 10.6886 16.9394 10.5078 16.5179 10.7065L13.0438 12.3448V5.0628C13.0438 4.59685 12.666 4.21907 12.2 4.21907C11.7341 4.21907 11.3563 4.59685 11.3563 5.0628V13.6759C11.3563 13.6809 11.3576 13.686 11.3576 13.6911Z"
        fill="#D3D4D8"
      />
    </svg>
  );
};

Clock.propTypes = {
  className: string,
};

export default Clock;
