import { useHistory, useLocation } from 'react-router-dom';
import useNewClaimData from './useNewClaimData';
import { getClaimRoutes } from './../helpers';
import useQueryParams from './useQueryParams.js';

const useRouting = () => {
  const history = useHistory();
  const { getClaimData } = useNewClaimData();
  let { pathname } = useLocation();
  const { getParam, hasParam, removeParams } = useQueryParams();

  const nextRoute = () => {
    let { routingObject } = getClaimRoutes(getClaimData);
    let id;
    if (hasParam('id')) {
      id = getParam('id');
      removeParams(['id']);
      pathname = `${pathname}/${id}`;
    } else if (pathname.split('/').length > 3) {
      id = [...pathname.split('/')].pop();
    } else {
      id = sessionStorage.getItem('_id');
    }
    const currentRoute = routingObject[pathname.replace(`/${id}`, '')] || '';
    const nextRoute = `${currentRoute}/${id}`;
    history.push({ pathname: nextRoute, state: pathname });
  };

  const summaryRoute = () => {
    let id = [...pathname.split('/')].pop();
    history.push({ pathname: `/new-claim/summary/${id}`, state: pathname });
  };

  const previousRoute = () => {
    history.goBack();
  };

  return { nextRoute, previousRoute, summaryRoute };
};

export default useRouting;
