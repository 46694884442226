import { monthNames } from '../constants';
import { parseIncDate } from './dateService';
import { statuses } from '../constants';
import { sample } from 'lodash';
import { getClaims } from './apiRouterService';
export const setItem = (key, value) => sessionStorage.setItem(key, value);
export const getItem = key => sessionStorage.getItem(key);
export const removeItem = key => sessionStorage.removeItem(key);

/**
 * Generate object with claim clients from localStorage
 */
export const fetchClaimClients = async () => {
  let mocked = {};
  try {
    let fetchedClaims = await getClaims();
    const claims = fetchedClaims[0] ? fetchedClaims[0].values : [];
    if (!claims) return mocked;
    mocked = parseClients(claims);
  } catch (error) {
    console.error('storageService: ', error);
    sessionStorage.removeItem('claimStorage');
  }
  return mocked;
};

export const parseClients = clients => {
  let mocked = {};
  const undefinedValString = 'N/a';
  for (let u of clients) {
    let name;
    const date = u.creation_date
      ? new Date(u.creation_date)
      : parseIncDate(u.metadata.created_at);
    const claimData = u.claim_data || {};
    // object init
    if (!mocked[date.getFullYear()]) mocked[date.getFullYear()] = {};
    if (!mocked[date.getFullYear()][monthNames[date.getMonth()]])
      mocked[date.getFullYear()][monthNames[date.getMonth()]] = [];

    // Assign valid value to the name (mocked data, TBR)
    if (claimData.clients && claimData.clients.length)
      name = `${claimData.clients[0].first_name} ${claimData.clients[0].last_name}`;
    else if (u.clients && u.clients.length)
      name = `${u.clients[0].first_name} ${u.clients[0].last_name}`;
    else if (claimData.passengers && claimData.passengers.length)
      name = `${claimData.passengers[0].first_name} ${claimData.passengers[0].last_name}`;
    // Fetched data
    else if (u.insuree) name = `${u.insuree.first_name} ${u.insuree.last_name}`;
    const outputObject = {
      _id: u._id || u.metadata.uid || undefinedValString,
      name: name || undefinedValString,
      created_on: `${date.getDate()} ${monthNames[date.getMonth()]}`,
      insured_at: claimData.insuredAt || u.insured_at || undefinedValString,
      damage_type: claimData.claimType || u.peril || undefinedValString,
      status: claimData.status || u.status || sample(statuses),
    };
    mocked[date.getFullYear()][monthNames[date.getMonth()]].push(outputObject);
  }
  return mocked;
};

/**
 * Generate sections based on claimClients object
 */
export const fetchSections = async () => {
  const clients = await fetchClaimClients();
  // Sorted section names (years)
  const sectionsNames = Object.keys(clients).sort((a, b) => b - a);
  const result = sectionsNames.map((s, i) => ({
    id: i,
    label: s,
    subsections: Object.keys(clients[s])
      .map((s, i) => ({
        id: i,
        label: s,
      }))
      .sort(sortByMonths),
  }));
  return result;
};

const sortByMonths = (a, b) => monthNames.indexOf(b) - monthNames.indexOf(a);
