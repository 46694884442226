import React from 'react';
import { string } from 'prop-types';

const PVActive = ({ className, width = 24, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.5882 5.16129V3.22581C22.5882 2.51613 21.9529 1.93548 21.1765 1.93548H13.4118C13.4118 0.870968 12.4588 0 11.2941 0H1.41176C0.635294 0 0 0.580645 0 1.29032V18.7097C0 19.4194 0.635294 20 1.41176 20H5.64706H21.1765H22.5882C23.3647 20 24 19.4194 24 18.7097V6.45161C24 5.74194 23.3647 5.16129 22.5882 5.16129ZM2.11765 17.4194V2.58065C2.11765 2.22581 2.43529 1.93548 2.82353 1.93548H11.2941C11.2941 3 12.2471 3.87097 13.4118 3.87097H19.7647C20.1529 3.87097 20.4706 4.16129 20.4706 4.51613V5.16129H5.64706C4.87059 5.16129 4.23529 5.74194 4.23529 6.45161V18.0645H2.82353C2.43529 18.0645 2.11765 17.7742 2.11765 17.4194Z"
      />
    </svg>
  );
};

PVActive.propTypes = {
  className: string,
};

export default PVActive;
