import styled from 'styled-components';
import { mainStyles } from '../helpers/utils';
const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${({ basis }) => (basis ? basis : '50%')};
  white-space: pre-wrap;
  .title {
    color: ${mainStyles.lightGray};
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
  .value {
    font-size: ${({ bigValue }) => (bigValue ? '2.1rem' : '1.6rem')};
    line-height: 2.8rem;
    font-weight: ${({ boldValue }) => (boldValue ? 900 : 400)};
    color: ${({ darkColors, theme }) =>
    darkColors ? theme.typo.title : theme.typo.subTitle};
  }
  div:first-child > svg :not(.icon.noPos) {
    position: absolute;
    left: 0;
  }
`;

export default DataColumn;
