import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { ActionButton, TextInput, Section, PhoneInput } from 'wg-fe-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useNewClaimData from './../../../hooks/useNewClaimData';
import useQueryParams from '../../../hooks/useQueryParams';
import schema from '../../../constants/validationSchemas';
import { getItem } from './../../../helpers/storageService';
import { isObjectEmpty } from '../../../helpers/objectService';
import { mapValues } from 'lodash';

const ContactInfo = forwardRef(
  ({ setValues, handleSection, validate }, ref) => {
    const [contactPerson, setContactPerson] = useState({});
    const [errors, setErrors] = useState({});
    const { getClaimData } = useNewClaimData();
    const { addParam, hasParam, removeParams } = useQueryParams();
    const { t } = useTranslation();

    const getContact = () => {
      const claim = getClaimData();
      if (claim._entity) setContactPerson({ ...claim._entity });
    };

    useEffect(() => {
      if (Object.values(contactPerson).length < 4) {
        addParam({ disabled: true });
      } else {
        removeParams(['disabled']);
        setValues(values => ({ ...values }));
      }
    }, [contactPerson]);

    const handleContactPersonChange = ({ name, value }) => {
      setContactPerson(data => ({ ...data, [name]: value }));
    };

    const handleFormData = async e => {
      if (e) e.preventDefault();
      const validationErrors = await validate(
        contactPerson,
        schema.contactPerson,
      );
      setErrors(() => mapValues(validationErrors, (e) => t(e)));;
      if (!isObjectEmpty(validationErrors)) return;
      let contacts = sessionStorage['contacts']
        ? JSON.parse(getItem('contacts'))
        : [];
      contacts = contacts.filter(c => c.id !== contactPerson.id);
      const contactPeople = [
        ...contacts,
        { ...contactPerson, type: 'CONTACT' },
      ];
      sessionStorage.setItem('contacts', JSON.stringify(contactPeople));
      addParam({ submit: true });
    };

    useImperativeHandle(ref, () => ({
      click: () => handleFormData(),
    }));

    useEffect(() => {
      getContact();
      handleSection({ section: 1, subsection: 0 });
    }, []);

    return (
      <form onSubmit={handleFormData}>
        <Section>
          <Section.Title>{t('Contact information')}</Section.Title>
          <StyledContent>
            <FormField>
              <Input
                name="first_name"
                onChange={handleContactPersonChange}
                value={contactPerson['first_name']}
                error={errors['first_name']}
              >
                {t('First name')}
              </Input>
            </FormField>
            <FormField>
              <Input
                name="last_name"
                onChange={handleContactPersonChange}
                value={contactPerson['last_name']}
                error={errors['last_name']}
              >
                {t('Last name')}
              </Input>
            </FormField>
            <FormField>
              <Input
                type="email"
                name="email"
                onChange={handleContactPersonChange}
                value={contactPerson['email']}
                error={errors['email']}
              >
                {t('Email')}
              </Input>
            </FormField>
            <FormField>
              <PhoneInput
                name="telephonenr"
                handleChange={handleContactPersonChange}
                symbol="IconActionPhone"
                symbolSide="right"
                value={contactPerson['telephonenr']}
                error={errors['telephonenr']}
              >
                {t('Phone number')}
              </PhoneInput>
            </FormField>
            <FormField>
              <Input
                name="mobilephonenr"
                onChange={handleContactPersonChange}
                value={contactPerson['mobilephonenr']}
                error={errors['mobilephonenr']}
              >
                {t('Mobile number')}
              </Input>
            </FormField>
          </StyledContent>
        </Section>
        <StyledButton disabled={hasParam('disabled')} data-test-id='continueButton'>
          {t('Continue')}
        </StyledButton>
      </form>
    );
  },
);

const FormField = styled.div`
  display: inline-block;
  width: 38%;
  margin: 13px 2.75vw;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const Input = styled(TextInput)`
  font-family: Lato;
  font-size: 1.6rem;
  line-height: 2.8rem;

  input {
    border-radius: 3px;
    height: 3.5rem;
  }
`;
Input.displayName = 'Input';

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 6px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledContent = styled(Section.Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px 57px 34px 37px;

  @media (max-width; 600px) {
    flex-direction: column;
  }
`;

export default ContactInfo;
