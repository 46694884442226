import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { ActionButton, Section, DateInput, TimeInput } from 'wg-fe-ui';
import { AccidentSection } from '../../../styledComponents';
import Description from '../../../components/IssuesTextArea';
import { initResidenceObject } from '../../../helpers/mockedData';
import GoogleMap from '../../../components/GoogleMap';
import AccidentDescAddress from '../../../components/AccidentDescAddress';
import { getCoordinatesByAddress } from '../../../helpers/googleMapService';
import useQueryParams from '../../../hooks/useQueryParams';
import {
  parseDateStringToObject,
  parseTimeStringToObject,
} from '../../../helpers/dateService';
import {
  getAddressbyLatLng,
  updateClaim,
} from '../../../helpers/apiRouterService';
import { parseGoogleAddress } from '../../../helpers/addressService';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';

const HomeAccidentDescription = forwardRef(
  (
    {
      claimData,
      claimId,
      validateForms,
      childRef,
      handleSection,
      claim,
      setValues,
    },
    ref,
  ) => {
    // TODO: Refactor state logic (mby classic state?)
    const [occurenceResidence, setOccurenceResidence] = useState(
      claimData.currentResidence || initResidenceObject,
    );
    const [coordinates, setCoordinates] = useState({});
    const [newResidence, setNewResidence] = useState({});
    const { addParam } = useQueryParams();
    const dateRef = useRef();
    const timeRef = useRef();
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();
    const [accidentTime, setAccidentTime] = useState();
    const [accidentDate, setAccidentDate] = useState();
    const [desc, setDesc] = useState('');

    // Used to rerender searcSelect after filling zipcode

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    useEffect(() => {
      handleSection({ section: 0, subsection: 0 });
    }, []);

    useEffect(() => {
      if (!claim || !claim.type || claim.type !== 'CAR') return;
      getCoordinates();
    }, [claim]);

    const onMapClick = async data => {
      const [resp, status] = await getAddressbyLatLng({ ...data });
      if (status !== 200) return;
      setCoordinates(data);
      if (!claim || !claim.type || claim.type !== 'CAR') {
        resp.address &&
          setOccurenceResidence({ ...parseGoogleAddress(resp.address) });
      } else {
        resp.address &&
          setNewResidence({ ...parseGoogleAddress(resp.address) });
      }
    };

    const onSaveClick = () => {
      setOccurenceResidence(newResidence);
      setNewResidence({});
    };

    const onCancelClick = async () => {
      await getCoordinates();
      setNewResidence({});
    };

    const getCoordinates = async () => {
      const address = `${occurenceResidence.street} ${occurenceResidence.housenr}/${occurenceResidence.boxnr}, ${occurenceResidence.zipcode} ${occurenceResidence.city}`;
      const res = await getCoordinatesByAddress(address);
      res.results[0] && setCoordinates(res.results[0].geometry.location);
    };

    const handleFormData = async e => {
      if (e) e.preventDefault();

      const [validationErrors, hasErrors] = await validateForms(
        {
          accidentDate: parseDateStringToObject(accidentDate),
          accidentTime: parseTimeStringToObject(accidentTime),
        },
        'accidentDesc',
      );
      setErrors(() => mapValues(validationErrors, e => t(e)));
      if (hasErrors) return;
      const data = {
        place_occurred: { address: occurenceResidence },
        date_occurred: {
          ...parseDateStringToObject(accidentDate),
          ...parseTimeStringToObject(accidentTime),
        },
        description: desc || null,
      };
      const [, status] = await updateClaim(claimId, {
        claim: data,
      });
      setValues(prev => ({ ...prev, ...data }));
      status < 300 && addParam({ submit: true });
    };

    return (
      <>
        {claim && claim.type && claim.type !== 'CAR' && (
          <AccidentDescAddress
            occurenceResidence={occurenceResidence}
            errors={errors}
            setOccurenceResidence={setOccurenceResidence}
            validateForms={validateForms}
            setErrors={setErrors}
            getCoordinates={getCoordinates}
          />
        )}
        {Object.values(coordinates).length ? (
          <>
            <StyledMap
              {...coordinates}
              initMarker={{
                show: true,
              }}
              onClick={onMapClick}
            />
            {claim && claim.type && claim.type === 'CAR' && (
              <ButtonsContainer>
                <StyledBtn
                  onClick={onCancelClick}
                  disabled={!newResidence.zipcode}
                >
                  {t('Cancel')}
                </StyledBtn>
                <StyledBtn
                  onClick={onSaveClick}
                  disabled={!newResidence.zipcode}
                >
                  {t('Save')}
                </StyledBtn>
              </ButtonsContainer>
            )}
          </>
        ) : null}
        <AccidentSection padding>
          <Section.Title>
            {t('When did your accident take place?')}
          </Section.Title>
          <Section.Content background>
            <InputsRow>
              <DateInput
                name="accidentDate"
                value={accidentDate}
                onChange={({ value }) => setAccidentDate(value)}
                ref={dateRef}
                error={
                  errors.accidentDate &&
                  Object.values(errors.accidentDate).length
                    ? t('Invalid date')
                    : undefined
                }
              >
                {t('Date')}
              </DateInput>
              <TimeInput
                name="accidentTime"
                error={
                  errors.accidentTime &&
                  Object.values(errors.accidentTime).length
                    ? t('Invalid time')
                    : undefined
                }
                value={accidentTime}
                onChange={({ value }) => setAccidentTime(value)}
                is12HourFormat={false}
                ref={timeRef}
              >
                {t('Time (optional)')}
              </TimeInput>
            </InputsRow>
          </Section.Content>
        </AccidentSection>
        <AccidentSection padding="15px 0">
          <Section.Title>{t('Description')}</Section.Title>
          <Section.Content>
            <h3>
              {t(
                'Fill in all the relevant details. The more information provided, the more effectively we can investigate your claim',
              )}
            </h3>
            <StyledDescription
              type="text"
              name="accident_description"
              placeholder={t('Type here to add an description')}
              value={desc}
              onChange={e => setDesc(e.target.value)}
            />
          </Section.Content>
        </AccidentSection>
        <StyledBtn
          onClick={handleFormData}
          ref={childRef}
          data-test-id="continueButton"
        >
          {t('Continue')}
        </StyledBtn>
      </>
    );
  },
);

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledMap = styled(GoogleMap)`
  margin: 3.5rem 0;
  width: 100rem;
  height: 35rem;
  border: 1px solid ${({ theme }) => theme.ui.outline};
  border-radius: 5px;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

const StyledDescription = styled(Description)`
  width: 100%;
  padding: 1.3rem;
  margin-top: 1.5rem;
`;

const InputsRow = styled.div`
  display: flex;
  label {
    width: 400px;
  }

  & > div > div {
    justify-content: flex-start;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    label {
      width: 100%;

      input {
        width: 25%;

        &:last-child {
          width: 50%;
        }
      }
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  button {
    margin: 0 2rem 3.5rem 0;
  }
`;

export default HomeAccidentDescription;
