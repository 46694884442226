import React from 'react';
import { string } from 'prop-types';

const Settings = ({ className, width = 30, height = 30 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19.7213 0L16.8661 1.12245L17.3759 3.59184C17.1771 3.79082 16.9655 4.02041 16.7437 4.26531L14.2964 3.71429L13.0931 6.53061L15.1734 7.91837C15.1504 8.26276 15.1504 8.6148 15.1734 8.95918L13.032 10.2653L14.1536 13.1224L16.5602 12.6327C16.7769 12.8929 17.0139 13.1301 17.274 13.3469L16.7233 15.7551L19.5581 16.9592L20.9449 14.8776C21.2916 14.9005 21.6179 14.9005 21.9647 14.8776L23.2903 17.0204L26.1455 15.898L25.5948 13.2857C25.8523 13.0714 26.0741 12.8265 26.2882 12.5714L28.7763 13.2041L30 10.3469L27.8382 9.02041C27.8586 8.68112 27.8586 8.35969 27.8382 8.02041L29.9796 6.69388L28.8579 3.83673L26.3698 4.38775C26.1557 4.1301 25.9135 3.88775 25.656 3.67347L26.2067 1.26531L23.3719 0.0612245L22.0462 2.14286C21.7046 2.10969 21.3605 2.09184 21.0265 2.12245L19.7213 0ZM21.5364 5.83673C22.9793 5.83673 24.1468 7.0051 24.1468 8.44898C24.1468 9.89286 22.9793 11.0612 21.5364 11.0612C20.0935 11.0612 18.9259 9.89286 18.9259 8.44898C18.9259 7.0051 20.0935 5.83673 21.5364 5.83673ZM7.52549 11.7143L7.11761 14.3265C6.75816 14.4413 6.39361 14.6046 6.03671 14.7959L3.85452 13.2041L1.57036 15.4898L3.07954 17.6939C2.89854 18.051 2.75578 18.4107 2.63086 18.7755L0 19.2653V22.449L2.61047 22.9184C2.72519 23.2806 2.88579 23.6607 3.07954 24.0204L1.48878 26.1429L3.77294 28.4286L5.97553 26.9184C6.32988 27.0995 6.69443 27.2423 7.05642 27.3673L7.46431 30H10.6662L11.1353 27.3878C11.4998 27.273 11.8771 27.1122 12.2366 26.9184L14.4188 28.5102L16.7029 26.2245L15.1326 24.0204C15.311 23.6658 15.4359 23.301 15.5608 22.9388L18.2733 22.449V19.2449L15.6016 18.8367C15.4869 18.477 15.3238 18.1097 15.1326 17.7551L16.7029 15.5714L14.4392 13.2041L12.2162 14.7959C11.8669 14.6173 11.5151 14.4719 11.1557 14.3469L10.7478 11.7143H7.52549ZM9.13664 18.2449C10.5719 18.2449 11.7471 19.4209 11.7471 20.8571C11.7471 22.2934 10.5719 23.4694 9.13664 23.4694C7.70139 23.4694 6.52617 22.2934 6.52617 20.8571C6.52617 19.4209 7.70139 18.2449 9.13664 18.2449Z" />
    </svg>
  );
};

Settings.propTypes = {
  className: string,
};

export default Settings;
