import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { ActionButton, AddEntityButton, Section } from 'wg-fe-ui';
import styled from 'styled-components';
import DataBar from '../../../components/DataBar';
import { modifyEntityStorage, padDate } from '../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { getEntities, deleteEntity } from '../../../helpers/apiRouterService';
import useNewClaimData from '../../../hooks/useNewClaimData';
import useQueryParams from '../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';


const Passengers = forwardRef(({ handleSection, handleFormData }, ref) => {
  const { id } = useParams();
  const history = useHistory();
  const { getClaimData } = useNewClaimData();
  const data = getClaimData();
  const [passengers, setPassengers] = useState([]);
  const { addParam, removeParams } = useQueryParams();

  const { t } = useTranslation();

  const handleClick = () => {
    history.push(`/new-claim/passenger-contact/${id}`);
  };

  useImperativeHandle(ref, () => ({
    click: () => handleClick()
  }));

  useEffect(() => {
    if (!passengers.length) {
      addParam({ disabled: true });
    } else {
      removeParams(['disabled']);
    }
  }, [passengers]);

  const getPassengers = async () => {
    const [resp, status] = await getEntities(data._claim_id);
    if (status !== 200) return;
    const passengersPeople = resp.values.filter(r => r.type === 'PASSENGER');;
    setPassengers(passengersPeople);
  };

  React.useEffect(() => {
    if (!passengers.length) getPassengers();
    handleSection({ section: 1, subsection: 0 });
  }, [passengers.length]);

  const onEdit = p => {
    modifyEntityStorage(false, p);
    handleClick();
  };

  const onDelete = async id => {
    const [, status] = await deleteEntity(data._claim_id, id);
    status === 200 &&
      setPassengers(passengers => passengers.filter(p => p.id !== id));
    modifyEntityStorage(true);
  };

  modifyEntityStorage(true);

  return (
    <Section>
      <Section.Title>{t('Passengers on the booking')}</Section.Title>
      {passengers.map(p => (
        <DataBar
          key={p.id}
          data={{
            keys: [`${t('Name')}`, `${t('Birth')}`, `${t('Passport')}`],
            values: [
              `${p.first_name} ${p.last_name}`,
              padDate(p.birth),
              p.identification.nr,
            ],
          }}
          isEdit
          isDelete
          onEdit={() => onEdit(p)}
          onDelete={() => onDelete(p.id)}
        />
      ))}
      <StyledEntityButton data-test-id="add-passenger" name={t("Add passenger")} onClick={handleClick} />
      <StyledButton data-test-id="add-passenger-continue" disabled={!passengers.length} onClick={handleFormData}>
        {t('Continue')}
      </StyledButton>
    </Section>
  );
});

const StyledEntityButton = styled(AddEntityButton)`
  margin-top: 17px;
  width: 100%;
  height: 95px;

  background: ${({ theme }) => theme.ui.toolbar};
  border: 0.5px dashed ${({ theme }) => theme.ui.outline};
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;

  svg {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(ActionButton)`
  margin-top: 25px;

  @media(max-width: 600px) {
    width: 100%;
  }
`;

export default Passengers;
