import styled from 'styled-components';

const IssuesTextArea = styled.textarea`
  box-sizing: border-box;
  border-radius: 3px;
  height: 18.5vh;
  min-height: 100px;
  resize: none;
  font-size: 1.6rem;
  line-height: 2.1rem;
  padding: 0 0.7vw;
`;

export default IssuesTextArea;
