import React from 'react';
import { string } from 'prop-types';

const Dialogs = ({ className, width = 20, height = 20, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447714 0 0.999999V9.38964C0 9.94192 0.447714 10.3896 0.999999 10.3896H1.29871V12.7273L3.89812 10.3896H14.5845C15.1367 10.3896 15.5845 9.94193 15.5845 9.38964V1C15.5845 0.447715 15.1367 0 14.5845 0H1Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0008 11.4285C19.5531 11.4285 20.0008 11.8762 20.0008 12.4285V16.922C20.0008 17.4743 19.5531 17.922 19.0008 17.922H18.7021V20L16.3915 17.922H9.3125C8.76022 17.922 8.3125 17.4743 8.3125 16.922V12.4285C8.3125 11.8762 8.76021 11.4285 9.3125 11.4285H19.0008Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

Dialogs.propTypes = {
  className: string,
};

export default Dialogs;
