import {
  AddEntityButton,
  SubNavigation,
  ToolBar,
  TextArea,
  TextInput,
  DateInput,
  LoadingSpinner,
  SearchSelectInput,
  ActionButton,
  Section
} from 'wg-fe-ui';
import styled, { css } from 'styled-components';
import { mainStyles } from './helpers/utils';

// Cases
const Content = styled.div`
  display: grid;
  grid-template-areas: ${({ userRole }) =>
    userRole && userRole.includes('customer')
      ? "'Content Content'"
      : "'SubMenu Content'"};
  grid-template-columns: minmax(190px, 16.5vw) auto;
  ${({ userRole }) =>
    userRole && userRole.includes('customer')
      ? css`
          padding: 2rem 22rem 0;
        `
      : css`
          padding-right: 2vw;
          padding-top: 2rem;
        `}
  @media (max-width: 1200px) {
    padding-right: 0;
  }

  @media (max-width: 1000px) {
    position: initial;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

Content.displayName = 'Content';

const StyledSubNavigation = styled(SubNavigation)`
  padding-left: 8rem !important;
  margin-top: 2.5rem;
  grid-area: SubMenu;
  position: fixed;

  @media (max-width: 1600px) {
    padding-left: 2.2vw !important;
  }

  @media (max-width: 1000px) {
    position: initial;
    display: flex;
    flex-direction: column;
    grid-template-areas: auto;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      flex-direction: row;
      max-height: initial;
      max-width: 100vw;
      transition: opacity 0.5s, max-width 0.5s;
    }

    @media (max-width: 1000px) {
      display: none;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const StyledToolBar = styled(ToolBar)`
  min-height: 55px;
`;

StyledToolBar.displayName = 'ToolBar';

// Claim Information
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5vw;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  button {
    max-height: 40px;
  }
  & > div {
    width: 11vw;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  flex-basis: ${({ basis }) => basis || 'initial'};
  align-items: ${({ align }) => align || 'flex-start'};
  margin: ${({ margin }) => margin || 0};
  width: 100%;
  height: ${({ height }) => height || 'initial'};

  p {
    white-space: normal;
  }

  svg:last-child {
    margin-left: 36px;
  }
`;

const SubTitle = styled.h4`
  font-family: Lato;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.typo.SubTitle};
  margin-bottom: 15px;
`;

const File = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  margin-left: 15px;
  .name {
    font-family: Lato;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.typo.SubTitle};
  }
  .size {
    font-family: Lato;
    font-size: 1.4rem;
    line-height: 2.4rem;
    ${({ small }) =>
      small &&
      css`
        margin-left: 15px;
      `};
    color: #76726e;
  }
`;

const FileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: ${({ small }) => (small ? '40px' : '75px')};

  background: #ffffff;
  padding: 12px 34px 12px 12px;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  ${({ marginTop }) =>
    marginTop &&
    css`
      &:last-child {
        margin-top: 10px;
      }
    `};
`;

const Preview = styled.div`
  width: 51px;
  height: 51px;
  background: #c4c4c4;
`;

const SmallText = styled.span`
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  color: #a29c95;
`;

const BigText = styled.span`
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme }) => theme.typo.SubTitle};
`;

const Icons = styled.div`
  position: absolute;
  right: 0;
  bottom: 38px;
  display: flex;
  align-items: flex-end;

  svg:first-child {
    margin-right: 36px;
  }
`;

const SVGWrapper = styled.div`
  background-color: ${({ theme }) => theme.brand.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-self: center;
  svg {
    margin: 0 !important;
  }
`;

const StyledLabel = styled.h3`
  color: ${mainStyles.lightGray};
  line-height: 2rem;
  margin-bottom: 0.5rem;
`;

const Input = styled(TextInput)`
  font-family: Lato;
  font-size: 1.6rem;
  line-height: 2.8rem;

  input {
    border-radius: 3px;
  }
`;

Input.displayName = 'Input';

const DateField = styled(DateInput)`
  font-family: Lato;
  font-size: 1.6rem;
  line-height: 2.8rem;

  input {
    line-height: 36px;
  }

  @media (max-width: 600px) {
    input {
      width: 25%;
      &:last-child {
        width: 50%;
      }
    }
  }
`;

const FormField = styled.div`
  display: inline-block;
  width: 44%;
  margin: 13px 0;
  box-sizing: border-box;

  label {
    color: ${({ theme }) => theme.typo.SubTitle};
  }

  @media (max-width: 600px) {
    display: block;
    min-width: 100%;
  }
`;

const StyledTextArea = styled(TextArea)`
  margin: 13px 0;
  width: 100%;
  textarea {
    min-height: 140px;
    max-height: 240px;
    box-sizing: border-box;
    resize: none;
  }
`;

const StyledEntityButton = styled(AddEntityButton)`
  background-color: ${({ theme }) => theme.ui.toolbar};
  margin-top: 18px;
  width: 100%;
  height: 95px;
  font-family: Lato;
`;

const StyledLoader = styled(LoadingSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

// Car flow

const CarContainer = styled.div`
  display: flex;
  width: 52vw;
  max-width: 100rem;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
  }
`;
const CarInfoHeader = styled.h3`
  font-weight: 900;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const CarLogoContainer = styled.div`
  display: flex;
  flex-basis: 44%;
  flex-direction: column;
  align-items: center;
  background: #fff;
  position: relative;
  padding: 7rem 4.5% 0 4.5%;
  min-width: 462px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    & > img {
      margin: 0 auto;
    }
  }

  @media (max-width: 600px) {
    min-width: initial;
  }
`;

const CarInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f0f1f3;
  padding: 4rem 1.5vw 0 3.6vw;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 3rem;
  }
`;

const CarSelectedRect = styled.div`
  border-radius: 0.8rem;
  height: 4rem;
  width: 33rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;
  padding: 1.2rem 2rem;
  .title {
    font-size: 1.2rem;
    color: #a29c95;
    line-height: 1.5rem;
    letter-spacing: 1.2px;
  }

  .value {
    line-height: 2rem;
    font-size: 1.6rem;
    font-weight: 900;
    color: ${({ theme }) => theme.typo.highlight};
  }
`;

const CarInput = styled(Input)`
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
  letter-spacing: 0.9px;
  width: 100%;
  height: 3.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const CarDisabledInput = styled.input`
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
  letter-spacing: 0.9px;
  width: 100%;
  border: 0.1rem solid;
  border-color: ${({ theme }) => theme.typo.outline};
  border-radius: 0.3rem;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  padding-left: 0.7rem;
  box-sizing: border-box;
  margin-bottom: 2.1rem;
  &:first-of-type {
    margin-top: 8.6rem;
  }
`;

const CarCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 1.8vw;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const CarCardLabel = styled.label`
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.92px;
  color: #a29c95;
  width: 20rem;
  margin-bottom: 2rem;
  height: 3.5rem;
  &:first-of-type {
    margin-top: ${({ isEditing }) => (isEditing ? '2rem' : '9.2rem')};
  }
  &:last-of-type {
    margin: auto 0;
  }
  .bold {
    font-weight: 900;
    color: ${({ theme }) => theme.typo.highlight};
  }
`;

const CarDateInput = styled(DateInput)`
  height: 7.5rem;
  label {
    color: #a29c95;
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 1.43px;
  }
`;

const CarCardSearchSelect = styled(SearchSelectInput)`
  height: 7.5rem;
  label {
    color: #a29c95;
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 1.43px;
  }
`;

const CarStyledTextInput = styled(Input)`
  height: 7.5rem;
  label {
    color: #a29c95;
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 1.43px;
  }
  /* Error */
  & > div {
    margin-top: 0;
  }
`;

const CarButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: flex-end;
`;

const CarLogoIMG = styled.img`
  width: 21rem;
  height: 21rem;
`;

const CarContinueButton = styled(ActionButton)`
  margin-top: 3.5rem;
  align-self: flex-end;
  width: 9vw;
  margin-left: 3rem;
  font-weight: 900;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor}` : null)};

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const AccidentSection = styled(Section)`
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 3.4rem;
  }
  ${Section.Content} {
    p,
    span,
    label,
    h3 {
      font-size: 1.4rem;
      color: #666666;
    }
    @media (max-width: 600px) {
      padding: ${({ padding }) => padding || '15px 30px'};
    }
  }
`;

export {
  Content,
  StyledSubNavigation,
  StyledToolBar,
  StyledLabel,
  Column,
  Icons,
  Row,
  Flex,
  SubTitle,
  File,
  FileBox,
  Preview,
  BigText,
  SmallText,
  SVGWrapper,
  Input,
  DateField,
  StyledTextArea,
  StyledEntityButton,
  FormField,
  StyledLoader,
  CarContainer,
  CarInfoContainer,
  CarInfoHeader,
  CarLogoContainer,
  CarSelectedRect,
  CarInput,
  CarCardColumn,
  CarCardLabel,
  CarDateInput,
  CarCardSearchSelect,
  CarButtonsContainer,
  CarLogoIMG,
  CarStyledTextInput,
  CarDisabledInput,
  CarContinueButton,
  AccidentSection
};
