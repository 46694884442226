import React from 'react';
import { useHistory } from 'react-router-dom'
import Layout from '../layout';
import styled from 'styled-components';
import { ActionButton, Section } from 'wg-fe-ui';
import { ArrowLeft } from '../icons';

const Fallback = ({ onClick }) => {
  const history = useHistory();
  const handleClick = () => { history.push('/cases'); onClick() };
  return (
    <Layout>
      <StyledContent>
        <StyledContent.Title>Something went wrong: </StyledContent.Title>
        <StyledContent.Content>
          <ActionButton onClick={handleClick}><ArrowLeft /> Go back to Home page</ActionButton>
        </StyledContent.Content>
      </StyledContent>
    </Layout>
  );
}

const StyledContent = styled(Section)`
  display: flex;  
  flex-direction: column;
  margin-left: 190px;
  width: 1000px;
  margin: 20px auto;
`;

export default Fallback;