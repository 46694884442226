import React from 'react';
import { string } from 'prop-types';

const Flash = ({ className, width = 26, height = 25 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.3256 8.57417C20.2546 8.42749 20.1015 8.33335 19.933 8.33335H14.798L19.8658 0.6396C19.9503 0.511279 19.9555 0.348779 19.8793 0.215869C19.803 0.0825195 19.6574 0 19.4996 0H12.5663C12.4021 0 12.2521 0.0891602 12.1785 0.23042L5.67851 12.7304C5.61132 12.8592 5.61869 13.0125 5.69755 13.1354C5.77687 13.2583 5.91596 13.3333 6.06632 13.3333H10.5223L5.66678 24.4216C5.584 24.6112 5.65769 24.8312 5.84009 24.9387C5.91027 24.98 5.98827 24.9999 6.06587 24.9999C6.19023 24.9999 6.31287 24.9487 6.39782 24.852L20.2645 9.0187C20.3732 8.89458 20.3966 8.72124 20.3256 8.57417Z"
        fill="#D3D4D8"
      />
    </svg>
  );
};

Flash.propTypes = {
  className: string,
};

export default Flash;
