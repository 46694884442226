import React from 'react';
import styled, { css } from 'styled-components';
import {
  CarKey,
  Flash,
  Lugage,
  FlightContacted,
  Collision,
  Info,
  Envelope,
  Phone,
  Wheel,
} from '../../icons';
import {
  Flex,
  Icons,
  StyledLabel,
  SVGWrapper,
} from '../../styledComponents';
import BMW from './../../assets/icons/bmw.png';
import Summary from '../../components/DetailsSummary';
import Section from '../../components/DetailsSection';
import DataColumn from '../../components/DetailsDataColumn';
import { useTranslation } from 'react-i18next';

const Option = styled.div`
  display: flex;
  align-items: center;
  width: 48%;
  height: 75px;
  min-width: 300px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid;
  border-color: ${({ theme }) => theme.ui.interactive};
  border-radius: 2px;
  font-family: lato;
  font-size: 1.6rem;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  svg {
    margin: auto 1.6vw;
    fill: ${({ theme }) => theme.ui.interactive};
  }
  ${({ checked }) =>
    checked
      ? css`
          background-color: ${({ theme }) => theme.brand.lightest};
          border-color: ${({ theme }) => theme.ui.active};
          ${Text} {
            color: ${({ theme }) => theme.ui.active};
          }
          svg,
          svg path {
            fill: ${({ theme }) => theme.ui.active};
          }
        `
      : null};

      @media (max-width: 768px) {
        width: 100%;

        margin: 20px 0;

        &:first-child {
          margin-top: 0;
        }

      }
`;

const Text = styled.span`
  margin: 0 auto 0 0.2vw;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.typo.interactive};
`;

const Img = styled.img`
  position: absolute;
  right: 0;
`;

const carInfo = {
  mainData: {
    car: 'BMW X1(2015) (F45)',
    val: '28.797,53',
  },
  subData: [
    { title: 'Brand', value: 'BMW', icon: <Wheel /> },
    { title: 'Modal', value: 'X1 (2015) (F45)' },
    { title: 'License plate', value: '1UWJ768', icon: <CarKey /> },
    { title: 'Chassisnumber', value: 'WBAHT710205J37359' },
  ],
};

const circumstances = [
  { id: 0, icon: <Lugage />, label: 'There is a witness' },
  { id: 1, icon: <FlightContacted />, label: 'Someone was injured' },
  {
    id: 2,
    icon: <Lugage />,
    label: 'the client wants to work with a certified reparier',
  },
  {
    id: 3,
    icon: <FlightContacted />,
    label: 'There is material damage to other cars then A and B',
  },
  {
    id: 4,
    icon: <FlightContacted />,
    label: 'There is material damage to other objects other then car A and B',
  },
  { id: 5, icon: <FlightContacted />, label: 'There is already is PV active' },
];

const DetailCar = ({ claim, refs }) => {
  const { t } = useTranslation();
  const occurenceDate = `${claim.metadata.created_at.day}/${claim.metadata.created_at.month}/${claim.metadata.created_at.year}`;
  const summary = [
    { title: 'Category', value: claim.type, id: 0 },
    { title: 'Claim type', value: claim.peril, id: 1 },
    { title: 'Occurrence date', value: occurenceDate, id: 2 },
    { title: 'Recommendation', value: '-', id: 3 },
    { title: 'Reimbursements', value: '-', id: 4 },
    { title: 'Value', value: '--', id: 5 },
  ];

  claim = {
    ...claim,
    clients: [
      {
        first_name: 'Test',
        last_name: 'Test',
        email: 'test@gmail.com',
        iban: '876',
        phone: '+48986969',
        street: 'Bahome',
        postal: '987-65',
        city: 'Szczecin',
      }
    ],
    policy_nr: 'GJH27-876FB-965DG-8758BH',
    description: 'Test is test',
    place_occurred: {
      street: 'Boeheme',
      house_nr: '12',
      zipcode: '897-87',
      city: 'Gent',
      country: 'BRU'
    },
    date_occurred: '04/10/2018'
  };

  return (
    <>
      <Summary>
        <SVGWrapper>
          <Collision />
        </SVGWrapper>
        <>
          {summary.map(s => (
            <Summary.Column key={s.id}>
              <Summary.Title>{t(s.title)}</Summary.Title>
              <Summary.Value>{t(s.value)}</Summary.Value>
            </Summary.Column>
          ))}
        </>
      </Summary>
      {claim.clients && claim.clients.length ? (
        <Section ref={refs[1][1]}>
          <Section.Title>{t('Client information')}</Section.Title>
          {claim.clients.map((c, i) => (
            <Section.Content key={i} background>
              <Section.MainData>
                <DataColumn darkColors bigValue boldValue basis="45.6%">
                  <span className="title">{t('Name')}</span>
                  <span className="value">{`${c.first_name} ${c.last_name}`}</span>
                </DataColumn>
                <DataColumn darkColors bigValue boldValue basis="54.4%">
                  <span className="title">{t('Policy nummer')}</span>
                  <span className="value">{claim.policy_nr}</span>
                </DataColumn>
                <Icons>
                  <Envelope />
                  <Phone fill="#FF8000" />
                </Icons>
              </Section.MainData>
              <Section.SubData style={{ paddingBottom: 1 }}>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Email')}</span>
                  <span className="value">{c.email}</span>
                </DataColumn>
                <DataColumn basis="54.4%">
                  <span className="title">{t('IBAN')}</span>
                  <span className="value">{c.iban}</span>
                </DataColumn>
                <DataColumn basis="45.6%" style={{ marginTop: 5 }}>
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{c.phone}</span>
                </DataColumn>
                <DataColumn basis="45.6%" style={{ marginTop: 5 }}>
                  <span className="title">{t('Address')}</span>
                  <span className="value">{`${c.street}, ${c.postal} ${c.city}`}</span>
                </DataColumn>
              </Section.SubData>
              <Section.SubData borderTop wrap={false}>
                <Info />
                <Flex margin="0 0 0 4vw">
                  <DataColumn basis="92%">
                    <StyledLabel>{t('Comments')}</StyledLabel>
                    <p>{claim.description}</p>
                  </DataColumn>
                </Flex>
              </Section.SubData>
            </Section.Content>
          ))}
        </Section>
      ) : null}
      <Flex>
        <Option checked>
          <Lugage />
          <Text>{t('The client can deduct VAT')}</Text>
        </Option>
        <Option>
          <FlightContacted />
          <Text>{t('The VAT is 6%')}</Text>
        </Option>
      </Flex>
      <Section ref={refs[1][2]}>
        <Section.Title>{t('Claim information')}</Section.Title>
        <Section.Content background>
          <Section.MainData wrap direction="row">
            <Flash />
            <Flex margin="0 0 0 4vw">
              <DataColumn basis="43%" bigValue boldValue>
                <span className="title">{t('Type of damage')}</span>
                <span className="value">{t(claim.peril)}</span>
              </DataColumn>
              <DataColumn basis="57%" bigValue boldValue>
                <span className="title">{t('Location occurrence')}</span>
                <span className="value">
                  {claim.place_occurred
                    ? `${claim.place_occurred.street} ${claim.place_occurred.house_nr} ${claim.place_occurred.zipcode}, ${claim.place_occurred.city}`
                    : ''}
                </span>
              </DataColumn>
              <DataColumn basis="43%" style={{ marginTop: 3 }}>
                <span className="title">{t('Occurrence date')}</span>
                <span className="value">{claim.date_occurred}</span>
              </DataColumn>
              <DataColumn basis="57%" style={{ marginTop: 3 }}>
                <span className="title">{t('Country occurrence')}</span>
                <span className="value">
                  {claim.place_occurred ? claim.place_occurred.country : ''}
                </span>
              </DataColumn>
            </Flex>
          </Section.MainData>
          <Section.SubData wrap={false}>
            <Info />
            <Flex margin="0 0 0 4vw" direction="column">
              <StyledLabel>{t('List of events')}</StyledLabel>
              <p>{/* TODO */}</p>
            </Flex>
          </Section.SubData>
        </Section.Content>
      </Section>
      <Section ref={refs[1][3]}>
        <Section.Title>{t('Car information')}</Section.Title>
        <Section.Content background>
          <Section.MainData>
            <DataColumn darkColors bigValue boldValue basis="46.4%">
              <span className="title">{t('Car')}</span>
              <span className="value">{carInfo.mainData.car}</span>
            </DataColumn>
            <DataColumn darkColors bigValue boldValue basis="54.6%">
              <span className="title">{t('Catalogoue value')}</span>
              <span className="value">{carInfo.mainData.val}</span>
            </DataColumn>
            <Img src={BMW} alt="BMW" />
          </Section.MainData>
          <Section.SubData wrap={false}>
            {carInfo.subData.slice(0, 2).map(d => (
              <DataColumn key={d.id} basis={d.id === 1 ? '54%' : '46%'}>
                {d.icon ? (
                  <Flex justify="flex-start" wrap="nowrap">
                    {d.icon}
                    <Flex direction="column" margin="0 0 0 1.8vw">
                      <span className="title">{t(d.title)}</span>
                      <span className="value">{d.value}</span>
                    </Flex>
                  </Flex>
                ) : (
                    <>
                      <span className="title">{t(d.title)}</span>
                      <span className="value">{d.value}</span>
                    </>
                  )}
              </DataColumn>
            ))}
          </Section.SubData>
          <Section.SubData borderTop wrap={false}>
            {carInfo.subData.slice(2).map(d => (
              <DataColumn key={d.id} basis={d.id === 1 ? '54%' : '46%'}>
                {d.icon ? (
                  <Flex justify="flex-start" wrap="nowrap">
                    {d.icon}
                    <Flex direction="column" margin="0 0 0 1.8vw">
                      <span className="title">{t(d.title)}</span>
                      <span className="value">{d.value}</span>
                    </Flex>
                  </Flex>
                ) : (
                    <>
                      <span className="title">{t(d.title)}</span>
                      <span className="value">{d.value}</span>
                    </>
                  )}
              </DataColumn>
            ))}
          </Section.SubData>
        </Section.Content>
      </Section>
      {claim.opposing_parties && claim.opposing_parties.length ? (
        <Section ref={refs[1][4]}>
          <Section.Title>{t('Involved parties')}</Section.Title>
          {claim.opposing_parties.map((c, i) => (
            <Section.Content key={i} background>
              <Section.MainData>
                <DataColumn darkColors bigValue boldValue basis="45.6%">
                  <span className="title">{t('Name')}</span>
                  <span className="value">{`${c.first_name} ${c.last_name}`}</span>
                </DataColumn>
                <DataColumn darkColors bigValue boldValue basis="54.4%">
                  <span className="title">{t('Policy nummer')}</span>
                  <span className="value">{c.policy_nr}</span>
                </DataColumn>
                <Icons>
                  <Envelope />
                  <Phone />
                </Icons>
              </Section.MainData>
              <Section.SubData>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Email')}</span>
                  <span className="value">{c.email}</span>
                </DataColumn>
                <DataColumn basis="54.4%">
                  <span className="title">{t('IBAN')}</span>
                  <span className="value">{c.iban}</span>
                </DataColumn>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{c.phone}</span>
                </DataColumn>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Address')}</span>
                  <span className="value">{`${c.street}, ${c.postal} ${c.city}`}</span>
                </DataColumn>
              </Section.SubData>
              <Section.SubData borderTop wrap={false}>
                <Info />
                <Flex margin="0 0 0 4vw">
                  <DataColumn basis="92%">
                    <StyledLabel>{t('Comments')}</StyledLabel>
                    <p>{c.description}</p>
                  </DataColumn>
                </Flex>
              </Section.SubData>
            </Section.Content>
          ))}
        </Section>
      ) : null}
      <Section ref={refs[1][5]}>
        <Section.Title>{t('Special Circumstances')}</Section.Title>
        <Flex justify="space-between" margin="15px 0 0 0" height="300px">
          {circumstances.map(c => (
            <Option key={c.id} checked={c.id === 0 ? true : false}>
              {c.icon}
              <Text>{t(c.label)}</Text>
            </Option>
          ))}
        </Flex>
      </Section>
    </>
  );
};


export default DetailCar;
