import React from 'react';
import { string } from 'prop-types';

const Expertise = ({ className, width = 12, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path
        d="M5.97266 0C2.93504 0 0.472656 2.43121 0.472656 5.43037C0.472656 7.43128 1.56931 9.17982 3.20166 10.1217V15.8242C3.20166 15.9606 3.35495 16.0431 3.47114 15.9691L5.85484 14.4435C5.91191 14.4069 5.9857 14.4065 6.04359 14.4427L8.4754 15.9727C8.59159 16.046 8.74365 15.963 8.74365 15.8274V10.1217C10.3756 9.17982 11.4727 7.43168 11.4727 5.43037C11.4727 2.43121 9.00987 0 5.97266 0ZM8.64622 4.99323L7.40891 6.05145C7.35999 6.09331 7.33879 6.15852 7.35347 6.22091L7.72445 7.79476C7.75951 7.94288 7.59604 8.06042 7.46435 7.98152L6.06275 7.1467C6.00731 7.1137 5.938 7.1137 5.88256 7.1467L4.48137 7.98152C4.34928 8.06042 4.1858 7.94328 4.22086 7.79476L4.59185 6.22091C4.60652 6.15852 4.58532 6.09331 4.5364 6.05145L3.2991 4.99323C3.1825 4.89341 3.24487 4.70422 3.39857 4.69094L5.02929 4.55288C5.0937 4.54724 5.15037 4.50699 5.17524 4.44822L5.81203 2.95971C5.87237 2.81923 6.07335 2.81923 6.13369 2.95971L6.77048 4.44822C6.79576 4.50739 6.85161 4.54724 6.91643 4.55288L8.54715 4.69094C8.70003 4.70382 8.76281 4.89301 8.64622 4.99323Z"
        fill="#D3D4D8"
      />
    </svg>
  );
};

Expertise.propTypes = {
  className: string,
};

export default Expertise;
