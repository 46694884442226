import React, { createRef, useEffect, useState } from 'react';
import {
  OverviewClaimStatus,
  OverviewTable,
  SearchInput,
  SubNavigation,
} from 'wg-fe-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useFilter from './../../hooks/useFilter';
import {
  StyledSubNavigation,
  StyledToolBar,
  Content,
} from '../../styledComponents';
import { fetchClaimClients, fetchSections } from '../../helpers/storageService';
import { statuses, insurersEnum } from '../../constants';
import { StyledLoader } from '../../styledComponents';
import { capitalize } from '../../helpers';

const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: Content;
  max-width: 1200px;
  th {
    width: 20%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

TablesContainer.displayName = 'TablesContainer';

const Cases = ({ history }) => {
  const refs = {};
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState({
    section: 0,
    subsection: 0,
  });
  const [query, setQuery] = useState('');
  const [claimClients, setClaimClients] = useState([]);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const users = useFilter(claimClients, query, 500);
  const handleSubmit = () => {
    history.push('/new-claim/category');
  };

  const handleClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;
    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const setFetchedData = async () => {
    setLoading(true);
    setClaimClients(await fetchClaimClients());
    setSections(await fetchSections());
    setLoading(false);
  };

  useEffect(() => {
    setFetchedData();
  }, []);

  /**
   * Checks if scrolled to particular section and
   * sets currentSectionId var {sectionId.subsectionId}
   */
  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[]);

  sections.forEach(s => {
    refs[s.id] = {};
    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const onClick = id => history.push(`/claim/${id}`);

  const sortByDays = (a, b) => parseInt(b.created_on) - parseInt(a.created_on);

  return (
    <>
      <StyledToolBar onClick={handleSubmit} btnName={t('New claim')}>
        <SearchInput
          text={query}
          onChange={setQuery}
          placeholder={t('Search for name, status, ...')}
        />
      </StyledToolBar>
      <Content>
        {loading ? (
          <StyledLoader />
        ) : (
            <>
              <StyledSubNavigation>
                {sections.map(section => (
                  <React.Fragment key={section.id}>
                    <SubNavigation.Section
                      onClick={() => handleClick(section.id, 0)}
                    >
                      {section.label}
                    </SubNavigation.Section>
                    <SubNavigation.SectionContainer
                      active={section.id === currentSection.section}
                    >
                      {section.subsections.map(subsection => (
                        <SubNavigation.SubSection
                          key={subsection.id}
                          onClick={() => handleClick(section.id, subsection.id)}
                          active={
                            currentSection.section === section.id &&
                            currentSection.subsection === subsection.id
                          }
                        >
                          {t(subsection.label)}
                        </SubNavigation.SubSection>
                      ))}
                    </SubNavigation.SectionContainer>
                  </React.Fragment>
                ))}
              </StyledSubNavigation>
              <TablesContainer>
                {!Object.keys(users).length
                  ? t('No claims filled yet')
                  : sections.map(section => {
                    return section.subsections.map(subsection => (
                      <OverviewTable
                        key={subsection.id}
                        ref={refs[section.id][subsection.id]}
                      >
                        <OverviewTable.Head>
                          <OverviewTable.HeaderCell title={true}>{`${t(
                            subsection.label,
                          )} ${section.label}`}</OverviewTable.HeaderCell>
                          <OverviewTable.HeaderCell width="25% !important">
                            {t('created_on')}
                          </OverviewTable.HeaderCell>
                          <OverviewTable.HeaderCell>
                            {t('insured_at')}
                          </OverviewTable.HeaderCell>
                          <OverviewTable.HeaderCell>
                            {t('damage_type')}
                          </OverviewTable.HeaderCell>
                          <OverviewTable.HeaderCell>
                            {t('status')}
                          </OverviewTable.HeaderCell>
                        </OverviewTable.Head>
                        <OverviewTable.Body>
                          {users[section.label] &&
                            users[section.label][subsection.label]
                              .sort(sortByDays)
                              .map(u => TableItem(u, onClick, t))}
                        </OverviewTable.Body>
                      </OverviewTable>
                    ));
                  })}
              </TablesContainer>
            </>
          )}
      </Content>
    </>
  );
};

/**
 *
 * @param {object} u - user
 * @param {function} onClick
 * @param {function} t - translate
 */
const TableItem = (u, onClick, t) => (
  <OverviewTable.Row
    id={u._id}
    key={u._id}
    onClick={() => onClick(u._id)}
    style={{ cursor: 'pointer' }}
  >
    <OverviewTable.BodyCell>{t(u.name)}</OverviewTable.BodyCell>
    <OverviewTable.BodyCell>{t(u.created_on)}</OverviewTable.BodyCell>
    <OverviewTable.BodyCell>
      {insurersEnum[u.insured_at] || t(u.insured_at)}
    </OverviewTable.BodyCell>
    <OverviewTable.BodyCell>
      {capitalize(t(u.damage_type))}
    </OverviewTable.BodyCell>
    <OverviewTable.BodyCell>
      <OverviewClaimStatus currentStatus={t(u.status)} statuses={statuses} />
    </OverviewTable.BodyCell>
  </OverviewTable.Row>
);

export default Cases;
