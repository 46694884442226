import React from 'react';
import { string } from 'prop-types';

const Cabrio = ({ className, width = 34, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M36.7871 5.49757C34.4444 2.96932 24.5634 3.15488 24.5634 3.15488L20.7826 0.696221C20.2259 0.325101 19.5765 -0.138798 18.8574 0.046762C18.811 0.046762 18.7414 0.069957 18.6951 0.0931519L23.3108 3.20127L7.028 3.22447L8.3733 2.01833L8.07177 1.76319C4.19821 2.34306 1.69316 4.70895 0.487022 5.63674C0.0695135 5.96147 -0.0928511 6.49496 0.0463186 7.00525L0.649387 9.09279C0.811752 9.64947 1.32204 10.0206 1.87872 10.0206H35.1403C35.7201 10.0206 36.2304 9.64947 36.3696 9.09279L37.0423 6.72691C37.1814 6.2862 37.0886 5.8223 36.7871 5.49757Z"
      />
      <path
        d="M8.37302 12.3875C10.1024 12.3875 11.5043 10.9856 11.5043 9.2562C11.5043 7.52682 10.1024 6.12488 8.37302 6.12488C6.64364 6.12488 5.2417 7.52682 5.2417 9.2562C5.2417 10.9856 6.64364 12.3875 8.37302 12.3875Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M29.6425 12.3875C31.3719 12.3875 32.7739 10.9856 32.7739 9.2562C32.7739 7.52682 31.3719 6.12488 29.6425 6.12488C27.9132 6.12488 26.5112 7.52682 26.5112 9.2562C26.5112 10.9856 27.9132 12.3875 29.6425 12.3875Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

Cabrio.propTypes = {
  className: string,
};

export default Cabrio;
