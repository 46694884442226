import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  SearchSelectInput,
  TextInput,
  DateInput,
  ActionButton,
  Section,
} from 'wg-fe-ui';
import {
  getAirlines,
  getAirports,
  getFlights,
} from './../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';
import { parseDateStringToObject } from '../helpers/dateService';

const NewClaimFlightInfoLookup = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  setFlights,
  handleSection,
  setAirports,
  setLoading,
  validateForms,
  setErrors,
}) => {
  const ref = useRef();
  const { t } = useTranslation();

  const callAirports = async (code = '') => {
    const query = code ? `?q=${code}` : '';
    if (
      code.length < 3 ||
      (values.departureAirport && values.departureAirport.includes(code))
    )
      return [];
    const [resp, status] = await getAirports(query);
    if (status !== 200) return;
    const airports = resp.airports.map((a) => ({
      label: a.name,
      value: a.IATA,
    }));
    setAirports((a) => [...a, ...airports]);
    return airports;
  };

  const callAirlines = async (code = '') => {
    const query = code ? `?q=${code}` : '';
    if (code.length < 3) return [];
    const [resp, status] = await getAirlines(query);
    if (status !== 200) return;
    const airlines = resp.airlines.map((a) => ({ label: a, value: a }));
    return airlines;
  };

  const callFlights = async () => {
    const parts = values.departure_date ? values.departure_date.split('/') : [];
    const [resp, status] = await getFlights({
      flight_number: values.flight_number,
      departure_airport_iata: values.departure_airport,
      arrival_airport_iata: values.arrival_airport,
      airline: values.airline,
      departure_date: {
        day: parseInt(parts[0]),
        month: parseInt(parts[1]),
        year: parseInt(parts[2]),
      },
    });
    return [resp, status];
  };

  const onSubmit = async () => {
    setLoading(true);
    handleSection({ section: 0, subsection: 1 });
    const [resp, status] = await callFlights();
    setLoading(false);
    if (status !== 200) return;
    setFlights(resp.flights);
  };

  const handleFormData = async (e) => {
    if (e) e.preventDefault();
    const {
      departure_airport,
      airline,
      arrival_airport,
      departure_date,
      flight_number,
    } = values;
    const [validationErrors, hasErrors] = await validateForms(
      {
        departure_airport,
        airline,
        arrival_airport,
        flight_number,
        departure_date: parseDateStringToObject(departure_date),
      },
      'flightInfo',
    );
    if (hasErrors)
      return setErrors(() => mapValues(validationErrors, (e) => t(e)));
    handleSubmit(onSubmit);
  };

  const isDisabled = () => {
    if (!values.departure_airport) return true;
    if (!values.arrival_airport) return true;
    if (!values.airline) return true;
    if (!values.departure_date) return true;
    return false;
  };

  return (
    <Section>
      <Section.Title>{t('Lookup flight info')}</Section.Title>
      <Section.Content background>
        <Wrapper>
          <Column>
            <SearchSelectInput
              async
              error={errors.departure_airport}
              defaultOptions={[]}
              name="departure_airport"
              loadOptions={callAirports}
              onSelected={handleChange}
              placeholder={t('Choose your option')}
            >
              {t('Departure Airport')}
            </SearchSelectInput>
            <SearchSelectInput
              async
              error={errors.airline}
              defaultOptions={[]}
              name="airline"
              loadOptions={callAirlines}
              onSelected={handleChange}
              placeholder={t('Choose your option')}
            >
              {t('Airline')}
            </SearchSelectInput>
          </Column>
          <Column>
            <SearchSelectInput
              async
              error={errors.arrival_airport}
              defaultOptions={[]}
              name="arrival_airport"
              loadOptions={callAirports}
              onSelected={handleChange}
              placeholder={t('Choose your option')}
            >
              {t('Arrival Airport')}
            </SearchSelectInput>
            <Flex style={{ width: '100%' }} justify="space-between">
              <StyledDate
                name="departure_date"
                error={
                  errors['departure_date'] &&
                  Object.values(errors['departure_date']).length
                    ? t('Invalid date')
                    : undefined
                }
                value={values.departure_date}
                onChange={handleChange}
                ref={ref}
              >
                {t('Departure date')}
              </StyledDate>
              <StyledText
                name="flight_number"
                error={errors.flight_number}
                value={values.flight_number}
                onChange={handleChange}
              >
                {t('Flight number')}
              </StyledText>
            </Flex>
          </Column>
        </Wrapper>
      </Section.Content>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <StyledBtn
          data-test-id="submit-flight-lookup"
          disabled={isDisabled()}
          onClick={handleFormData}
        >
          {t('Search')}
        </StyledBtn>
      </div>
    </Section>
  );
};

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

StyledBtn.displayName = 'StyledBtn';

const StyledDate = styled(DateInput)`
  width: 58%;

  @media(max-width: 600px) {
    width: 100%;

    input {
      width: 25%;
      &:last-child {
        50%;
      }
    }
  }
`;

StyledDate.displayName = 'StyledDate';

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
`;

const Column = styled(Flex)`
  flex-direction: column;
  width: calc(50% - 2.7rem);

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
`;

const StyledText = styled(TextInput)`
  width: 35%;
  margin-left: 2.7rem;
  input {
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

export default React.memo(NewClaimFlightInfoLookup);
