import React from 'react';
import { FlightSelectBar } from 'wg-fe-ui';
import styled, { css } from 'styled-components';
import { files } from '../../helpers/mockedData';
import { getCityWithCode } from './../../helpers';
import { parseIncDateToString } from './../../helpers/dateService';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  File,
  SubTitle,
  StyledLabel,
  FileBox,
  Preview,
  BigText,
  SmallText,
  SVGWrapper,
} from '../../styledComponents';
import {
  ArrowLeft,
  Clock,
  Download,
  Envelope,
  Flight,
  FlightMissed,
  Info,
  Lugage,
  Phone,
} from '../../icons';
import Summary from '../../components/DetailsSummary';
import Section from '../../components/DetailsSection';
import DataColumn from '../../components/DetailsDataColumn';
import DataBar from './../../components/DataBar';

const DetailFlight = ({ claim, refs }) => {
  const summary = [
    { title: 'Category', value: claim.type, id: 0 },
    { title: 'Claim type', value: claim.peril, id: 1 },
    {
      title: 'Occurrence date',
      value: parseIncDateToString(claim.cancellation_notice_date),
      id: 2,
    },
    { title: 'Recommendation', value: '-', id: 3 },
    { title: 'Reimbursemeents', value: '-', id: 4 },
    { title: 'Value', value: '- -', id: 5 },
  ];
  const undefinedString = 'N/A';

  const { t } = useTranslation();

  const type = claim.cause ? claim.cause.toLowerCase() : undefinedString;

  const claimInfo = {
    mainData: [
      {
        title: 'Type of fight claim',
        value: `Flight ${type}`,
        id: 0,
        bigValue: true,
        boldValue: true,
      },
      {
        title: `Flight ${type === 'delayed' ? `${type} by` : type}`,
        value: type === 'delayed' ? '3h 29m' : undefinedString,
        id: 1,
        bigValue: true,
        boldValue: true,
      },
    ],
    subData: {
      title: 'Description',
      value: '',
    },
  };

  return (
    <>
      <Summary>
        <SVGWrapper>
          <Flight fill="#ffffff" />
        </SVGWrapper>
        <>
          {summary.map(s => (
            <Summary.Column key={s.id}>
              <Summary.Title>{t(s.title)}</Summary.Title>
              <Summary.Value>{t(s.value)}</Summary.Value>
            </Summary.Column>
          ))}
        </>
      </Summary>
      {claim.entities && claim.entities.length ? (
        <Section ref={refs[1][1]}>
          <Section.Title>{t('Contact information')}</Section.Title>
          {claim.entities.map((p, i) => (
            <Section.Content key={i} background={true}>
              <Section.MainData>
                <DataColumn darkColors bigValue boldValue basis="45.6%">
                  <span className="title">{t('Name')}</span>
                  <span className="value">{`${p.first_name} ${p.last_name}`}</span>
                </DataColumn>
                <DataColumn basis="54.4%">
                  <Flex justify="flex-start">
                    <Envelope />
                    <Phone />
                  </Flex>
                </DataColumn>
              </Section.MainData>
              <Section.SubData>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Address')}</span>
                  <span className="value">{`${p.correspondence_address.street}, ${p.correspondence_address.zipcode} ${p.correspondence_address.city}`}</span>
                </DataColumn>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Email')}</span>
                  <span className="value">{p.email}</span>
                </DataColumn>
                <DataColumn basis="45.6%">
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{p.telephonenr}</span>
                </DataColumn>
              </Section.SubData>
            </Section.Content>
          ))}
        </Section>
      ) : null}
      <Section ref={refs[1][2]}>
        <Section.Title>{t('Claim information')}</Section.Title>
        <Section.Content background={true}>
          <Section.MainData wrap direction="row">
            <DataColumn basis="8%">
              <Clock />
            </DataColumn>
            <Flex>
              {claimInfo.mainData.map(c => (
                <DataColumn key={c.id} basis="50%" {...c}>
                  <span className="title">{t(c.title)}</span>
                  <span className="value">{t(c.value)}</span>
                </DataColumn>
              ))}
            </Flex>
          </Section.MainData>
          {claimInfo.subData.value ? (
            <Section.SubData wrap={false}>
              <DataColumn basis="8%">
                <Info />
              </DataColumn>
              <Flex>
                <DataColumn basis="92%">
                  <StyledLabel>{t(claimInfo.subData.title)}</StyledLabel>
                  <p>{t(claimInfo.subData.value)}</p>
                </DataColumn>
              </Flex>
            </Section.SubData>
          ) : null}
        </Section.Content>
      </Section>
      <Section ref={refs[1][3]}>
        {claim.entites && claim.entities.length ? (
          <Section.Title>{t('Passengers')}</Section.Title>
        ) : null}
        <Flex>
          {claim.entities &&
            claim.entities.map((p, i) => (
              <DataBar
                key={i}
                data={{
                  keys: [t('Name'), t('Birth'), t('Passport')],
                  values: [
                    `${p.first_name} ${p.last_name}`,
                    parseIncDateToString(p.birth),
                    p.passport,
                  ],
                }}
              >
                <FileBox small>
                  <File small>
                    <span className="name">Passport.jpg</span>
                    <span className="size">1.3mb</span>
                  </File>
                  <Download />
                </FileBox>
              </DataBar>
            ))}
        </Flex>
      </Section>
      <Section ref={refs[1][4]}>
        <Section.Title>{t('Flights')}</Section.Title>
        {claim.flights_taken.map(f => (
          <StyledFlightBar name="flight">
            <FlightSelectBar.FlightData
              border={true}
              label={f.airLine}
              data={f.flight_number}
            />
            <FlightSelectBar.FlightData
              label={getCityWithCode(f.departure_airport_iata)}
              data={f.departure_airport_iata}
            />
            <FlightSelectBar.FlightData
              label={t('Departure')}
              data={parseIncDateToString(f.departure_date)}
            />
            <FlightSelectBar.FlightData>
              <Flight />
            </FlightSelectBar.FlightData>
            <FlightSelectBar.FlightData
              label={getCityWithCode(f.arrival_airport_iata)}
              data={f.arrival_airport_iata}
            />
            <FlightSelectBar.FlightData
              label={t('Arrival')}
              data={f.arrivalDate || undefinedString}
            />
            <ArrowLeft />
          </StyledFlightBar>
        ))}
      </Section>
      {claim.files && claim.files.length ? (
        <Section ref={refs[1][5]}>
          <Section.Title>{t('Uploaded documents')}</Section.Title>
          <Section.Content>
            <Flex>
              {claim.files && claim.files.length ? (
                <DataColumn>
                  <SubTitle>{t('Boarding passes')}</SubTitle>
                  <Flex height="252px" direction="column" justify="flex-start">
                    {claim.files.map(b => (
                      <FileBox key={b.id} marginTop>
                        <Flex>
                          <Preview />
                          <File direction="column">
                            <span className="name">{b.name}</span>
                            <span className="size">{b.size}</span>
                          </File>
                        </Flex>
                        <Download />
                      </FileBox>
                    ))}
                  </Flex>
                </DataColumn>
              ) : null}
              {claim.files && claim.files.length ? (
                <DataColumn>
                  <SubTitle>{t('Booking confirmation')}</SubTitle>
                  <Flex height="252px" justify="flex-start" direction="column">
                    {claim.files.map(b => (
                      <FileBox key={b.id}>
                        <Flex>
                          <Preview />
                          <File direction="column">
                            <span className="name">{b.name}</span>
                            <span className="size">{b.size}</span>
                          </File>
                        </Flex>
                        <Download />
                      </FileBox>
                    ))}
                  </Flex>
                </DataColumn>
              ) : null}
            </Flex>
          </Section.Content>
        </Section>
      ) : null}
      <Section ref={refs[1][6]}>
        <Section.Title>{t('Special circumstances')}</Section.Title>
        <Flex margin="22px 0 0 0">
          <Option checked={claim.cause}>
            <Lugage />
            <Text>{t('I encountered issues with my lugage.')}</Text>
          </Option>
          <Option>
            <FlightMissed />
            <Text>
              {t('I recieved and accepted another flight from the airline.')}
            </Text>
          </Option>
        </Flex>
      </Section>
      {claim.expenses ? (
        <Section ref={refs[1][7]}>
          <Section.Title>{t('Extra expenses')}</Section.Title>
          <Section.Content>
            <Flex>
              <DataColumn darkColors bigValue boldValue basis="40%">
                <SubTitle>{t('PRICE OVERVIEW')}</SubTitle>
                <SmallText>{t('Food')} & {t('drinks')}</SmallText>
                <Flex justify="space-between">
                  <BigText>Burger King</BigText>
                  <span>14.95</span>
                </Flex>
                <Flex justify="space-between">
                  <BigText>Press Shop</BigText>
                  <span>2.35</span>
                </Flex>
              </DataColumn>
              <DataColumn basis="45%">
                <SubTitle>{t('INVOICES')} & {t('RECEIPTS')}</SubTitle>
                <FileBox small>
                  <File small>
                    <span className="name">bk-receipt.pdf</span>
                    <span className="size">1.3mb</span>
                  </File>
                  <Download />
                </FileBox>
                ))}
              </DataColumn>
              <DataColumn>
                <SubTitle>{t('Booking confirmation')}</SubTitle>
                <Flex height="252px" justify="flex-start" direction="column">
                  {files.slice(2).map(f => (
                    <FileBox key={f.id}>
                      <Flex>
                        <Preview />
                        <File direction="column">
                          <span className="name">{f.name}</span>
                          <span className="size">{f.size}</span>
                        </File>
                      </Flex>
                      <Download />
                    </FileBox>
                  ))}
                </Flex>
              </DataColumn>
            </Flex>
          </Section.Content>
        </Section>
      ) : null}
    </>
  );
};

const Option = styled.div`
  display: flex;
  align-items: center;
  width: 48%;
  height: 75px;
  min-width: 300px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid;
  border-color: ${({ theme }) => theme.ui.interactive};
  border-radius: 2px;
  font-family: lato;
  font-size: 1.6rem;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  svg {
    margin: auto 1.6vw;
    fill: ${({ theme }) => theme.ui.interactive};
  }
  ${({ checked }) =>
    checked
      ? css`
          background-color: ${({ theme }) => theme.brand.lightest};
          border-color: ${({ theme }) => theme.typo.selected};
            color: ${({ theme }) => theme.typo.selected};
          }
          svg,
          svg path {
            fill: ${({ theme }) => theme.typo.selected};
          }
        `
      : null};
`;

const Text = styled.span`
  margin: 0 auto 0 0.2vw;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.typo.interactive};
`;

const StyledFlightBar = styled(FlightSelectBar)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;

  & > span {
    display: none;
  }

  & > svg {
    fill: none;
  }
`;

export default DetailFlight;
