import React from 'react';
import { string } from 'prop-types';

const Plane = ({ className, width = 46, height = 24, fill }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.415751 9.14668C1.25147 9.02158 2.05893 8.87559 2.87375 8.77876C2.99665 8.76036 3.16988 8.91034 3.29028 9.04311C4.99186 10.6732 6.69343 12.3033 8.41591 13.9052C8.58915 14.0551 8.81154 14.1978 9.00818 14.1683C11.8349 13.7452 14.6825 13.2939 17.5092 12.8707C17.5829 12.8597 17.6284 12.8278 17.7021 12.8167C13.9206 9.21108 13.4463 8.72919 9.69304 5.14443C9.71026 5.09159 9.72748 5.03875 9.7447 4.98591C9.83935 4.94662 9.90941 4.911 10.0077 4.89628C11.2576 4.68406 12.4866 4.5001 13.7402 4.31246C13.9368 4.28303 14.1765 4.37282 14.3669 4.46996C22.0873 7.863 22.4806 7.80413 29.0238 10.72C29.3236 10.8761 29.7107 10.9439 30.0302 10.896C32.5657 10.5417 35.09 10.1135 37.5972 9.73825C39.3915 9.46967 41.1377 9.88682 42.8493 10.9123C43.2659 11.1766 43.6688 11.5184 44.03 11.9167C44.5079 12.4232 44.5889 12.9639 44.3085 13.609C44.1252 14.0637 43.8609 14.4802 43.5855 14.823C42.302 16.3219 40.7938 17.3267 39.0277 17.6162C32.9884 18.5705 26.9245 19.5284 20.8778 20.4335C18.5427 20.783 16.1476 20.5635 13.7906 20.2629C11.0219 19.8983 8.28394 19.4034 5.53247 18.9859C5.17364 18.9391 4.96846 18.7437 4.78302 18.3442C3.39644 15.4607 2.03811 12.598 0.679791 9.73542C0.580296 9.5744 0.526283 9.38144 0.415751 9.14668Z"
        fill={fill}
      />
    </svg>
  );
};

Plane.propTypes = {
  className: string,
};

export default Plane;
