import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';
import { ActionButton, DownloadBox, Section } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import documentsUrls from '../../../constants/documentsUrls.json';

const getDocsUrls = (company) => {
  if (company in documentsUrls) return documentsUrls[company];
  return documentsUrls.default;
};

const ClaimSubType = forwardRef(({ handleFormData, claim, handleSection }, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    click: () => {
      handleFormData();
    },
  }));

  useEffect(() => {
    handleSection({ section: 0, subsection: 0 });
  }, []);

  return (
    <>
      <StyledSection>
        <Section.Title>{t('Choose a claim category to start')}</Section.Title>
        <Section.Content>
          <p>
            {t(
              'In order to be able to report your occupational accident, the following documents must be filled in:',
            )}
          </p>
          <List>
            <ListItem>
              <span>{t('Medical certificate: ')}</span>
              {t(
                'This document must be completed by the doctor of the employee in which the accident occurred.',
              )}
            </ListItem>
            <ListItem>
              <span>{t('Declaration form accidents at work: ')}</span>
              {t(
                'This document must be filled in by the employer as an official declaration of the occupational accident.',
              )}
            </ListItem>
          </List>
          <p>{t('You can download both documents below.')}</p>
          <DownloadBoxRow>
            {claim && (
              <>
                <DownloadBox
                  href={getDocsUrls(claim.insured_at).med}
                  filename={t('Medical certificate')}
                />
                <DownloadBox
                  href={getDocsUrls(claim.insured_at).dec}
                  filename={t('Declaration form')}
                />
              </>
            )}
          </DownloadBoxRow>
          <p>
            {t(
              'Both documents must be completed and scanned. If you have the completed documents available, you can click on "Continue".',
            )}
          </p>
          <p>
            <span>{t('Attention')}: </span>
            {t(
              'There is a legal obligation to report the work-related accident ',
            )}
            <span>{t('within 8 days!')}</span>{' '}
            {t(
              "So don't let your broker wait too long for the completed documents.",
            )}
          </p>
        </Section.Content>
        {/* {cogoToast.error('Error message goes here', {
          hideAfter: 5,
        })
        } */}
      </StyledSection>
      <StyledButton onClick={handleFormData} data-test-id="continueButton">
        {t('Continue')}
      </StyledButton>
    </>
  );
});

const StyledSection = styled(Section)`
  margin-bottom: 2rem;
  ${Section.Content} {
    h3 {
      font-size: 1.4rem;
      font-weight: bold;
      color: #696969;
      margin: 21px auto 21px;
      line-height: 130%;
      letter-spacing: 0.924996px;
    }

    p {
      margin: 20px 0;
      line-height: 2rem;
      font-size: 1.4rem;
    }

    span {
      color: #696969;
      font-weight: bold;
    }

    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const StyledButton = styled(ActionButton)`
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const DownloadBoxRow = styled.div`
  display: flex;
  div:first-child {
    margin-right: 15px;
  }
`;

const List = styled.ul`
  font-size: 1.4rem;
  list-style-type: disc;
  list-style-position: inside;
`;

const ListItem = styled.li`
  line-height: 2.8rem;
`;

export default ClaimSubType;
