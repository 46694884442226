import React from 'react';
import { string } from 'prop-types';

const MaterialDamageOtherObj = ({ className, width = 26, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.9412 18.3086C21.2512 18.3086 19.8824 19.6701 19.8824 21.3511C19.8824 21.9444 20.0583 22.4997 20.3565 22.9713L18.353 25.1163V22.8192L16.8236 25.1011L16.0589 24.3404L15.2942 25.1011V28.4707C14.7436 28.1893 14.1318 28.0143 13.4665 28.0143C11.2489 28.0143 9.45182 29.7942 9.43652 31.9925H15.2942H17.4965H18.353V27.3374L21.4806 23.9982C21.9165 24.234 22.4136 24.3861 22.9412 24.3861C24.6312 24.3861 26.0001 23.0245 26.0001 21.3435C26.0001 19.6701 24.6312 18.3086 22.9412 18.3086Z"
      />
      <path
        d="M10.7059 20.7122L14.9194 22.5909L15.2941 23.6558L15.7682 22.6898L16.8235 23.5798L17.5882 21.2978L15.7224 15.6311C19.1788 13.6763 20.8612 9.48516 19.5 5.61351C17.9706 1.24746 13.1759 -1.05727 8.78647 0.464001C4.39706 1.98527 2.08 6.75447 3.60941 11.1205C3.76235 11.5693 3.96118 11.9877 4.18294 12.3832C1.77412 13.0602 0 15.2584 0 17.875C0 21.024 2.56941 23.5798 5.73529 23.5798C7.86118 23.5798 9.71177 22.4236 10.7059 20.7122ZM14.2312 20.6209L11.2871 19.305C11.4094 18.8486 11.4782 18.3694 11.4782 17.875C11.4782 17.4795 11.44 17.0915 11.3635 16.7264C11.8529 16.7416 12.35 16.7112 12.8471 16.6351L14.2312 20.6209Z"
      />
    </svg>
  );
};

MaterialDamageOtherObj.propTypes = {
  className: string,
};

export default MaterialDamageOtherObj;
