import React, { useState } from 'react';
import ButtonInput from './ButtonInput';

const VatButtonInput = ({
  className,
  handleChange,
  setCarData,
  value,
  name,
  error,
  ...rest
}) => {
  const [vatCalculated, setVatCalculated] = useState(false);

  const calculateVat = () => {
    const val = value;
    let calculated;
    if (!vatCalculated) {
      calculated = val * 1.21;
      setVatCalculated(true);
    } else {
      calculated = val / 1.21;
      setVatCalculated(false);
    }
    setCarData(old => ({ ...old, [name]: Math.round(calculated * 100) / 100 }));
  };

  return (
    <ButtonInput
      name={name}
      suffix="€"
      onClick={calculateVat}
      value={value}
      onChange={handleChange}
      className={className}
      error={error}
      {...rest}
    >
      {`${vatCalculated ? 'EXCL' : 'INCL'} VAT`}
    </ButtonInput>
  );
};

export default VatButtonInput;
