import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { ActionButton, Label, Section } from 'wg-fe-ui';
import styled from 'styled-components';
import DataBar from './../../../components/DataBar';
import { useHistory, useParams } from 'react-router-dom';
import { getEntities, deleteEntity } from '../../../helpers/apiRouterService';
import { modifyEntityStorage } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { StyledEntityButton } from './../../../styledComponents';
const Involved = forwardRef(
  ({ handleSection, handleFormData, claimId, claimData, prefix }, ref) => {
    const { id } = useParams();
    const history = useHistory();
    const [insurees, setInsurees] = useState([]);
    const [thirdParties, setThirdParties] = useState([]);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const getAllEntities = async () => {
      const [resp, status] = await getEntities(claimId);
      if (status > 300) return;
      const insureePeople = resp.values.filter(r => r.type === 'INSUREE');
      const opposingPeople = resp.values.filter(r => r.type === 'OPPOSING');
      setInsurees(insureePeople);
      setThirdParties(opposingPeople);
    };

    const clientClick = () => {
      history.push(`${prefix}/insuree-info/${id}`);
    };
    const thirdPartyClick = () => {
      history.push(`${prefix}/third-party-info/${id}`);
    };

    const onEdit = (e, type) => {
      modifyEntityStorage(false, e);
      if (type === 'INSUREE') clientClick();
      else if (type === 'OPPOSING') thirdPartyClick();
    };

    const onDelete = async (id, type) => {
      const [, status] = await deleteEntity(claimId, id);
      if (status !== 200) return;

      if (type === 'INSUREE') setInsurees(i => i.filter(i => i.id !== id));
      else if (type === 'OPPOSING')
        setThirdParties(tp => tp.filter(p => p.id !== id));

      modifyEntityStorage(true);
    };

    modifyEntityStorage(true);

    useEffect(() => {
      getAllEntities();
      handleSection({ section: 1, subsection: 0 });
    }, []);

    return (
      <>
        <Section>
          <Section.Title>{t('Insuree information')}</Section.Title>
          <Desc>{t('Add a a insuree and fill in all contact information')}</Desc>
          {insurees.map((c, id) => (
            <DataBar
              key={id}
              isEdit
              isDelete
              data={{
                keys: [t('Name'), t('Phone'), t('Email')],
                values: [
                  `${c.first_name} ${c.last_name}`,
                  c.telephonenr,
                  c.email,
                ],
              }}
              onEdit={() => onEdit(c, 'INSUREE')}
              onDelete={() => onDelete(c.id, 'INSUREE')}
            />
          ))}
          <StyledEntityButton onClick={clientClick} name={t('Add a insuree')} />
        </Section>
        {claimData.thirdParty === 'Know' && (
          <Section>
            <Section.Title>{t('Register opposing party')}</Section.Title>
            <Desc>
              {t('If a opposing party is known click')} <Underlined>{t('here')}</Underlined>
              {t('to add one, if not skip this step')}
            </Desc>
            {thirdParties.map((t, id) => (
              <DataBar
                key={id}
                isEdit
                isDelete
                data={{
                  keys: [t('Name'), t('Phone'), t('Email')],
                  values: [
                    `${t.first_name} ${t.last_name}`,
                    t.telephonenr,
                    t.email,
                  ],
                }}
                onEdit={() => onEdit(t, 'OPPOSING')}
                onDelete={() => onDelete(t.id, 'OPPOSING')}
              />
            ))}
            <StyledEntityButton
              onClick={thirdPartyClick}
              name={t('Add third party')}
            />
          </Section>
        )}
        <StyledButton onClick={handleFormData}>{t('Continue')}</StyledButton>
      </>
    );
  },
);

const Desc = styled(Label)`
  margin-bottom: 1.1rem;
  color: ${({ theme }) => theme.typo.highlight};
  line-height: 2rem;
  margin-top: 0.5rem;
`;

const Underlined = styled.span`
  text-decoration: underline;
`;

const StyledButton = styled(ActionButton)`
  margin-top: 25px;

   @media (max-width: 600px) {
    width: 100%;
  }
`;

export default Involved;
