import React from 'react';
import { string } from 'prop-types';

const ArrowLeft = ({ className, width = 9, height = 15, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1 14L7 7.5L0.999999 1" stroke="#505050" strokeWidth="1.5" />
    </svg>
  );
};

ArrowLeft.propTypes = {
  className: string,
};

export default ArrowLeft;
