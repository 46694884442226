import React from 'react';
import { string } from 'prop-types';

const SMS = ({ className, width = 22, height = 29 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M17.0031 6.08494V1.52124C17.0031 0.684556 16.3186 0 15.4819 0H1.79077C0.954087 0 0.269531 0.684556 0.269531 1.52124V26.6216C0.269531 27.4583 0.954087 28.1429 1.79077 28.1429H15.4819C16.3186 28.1429 17.0031 27.4583 17.0031 26.6216V20.5367H13.9606V22.0579H3.312V3.04247H13.9606V6.08494H17.0031ZM8.63633 23.5792C9.47301 23.5792 10.1576 24.2637 10.1576 25.1004C10.1576 25.9371 9.47301 26.6216 8.63633 26.6216C7.79965 26.6216 7.11509 25.9371 7.11509 25.1004C7.11509 24.2637 7.79965 23.5792 8.63633 23.5792Z" />
      <path d="M6.35547 8.36706V18.2551C6.35547 18.6734 6.69775 19.0157 7.11609 19.0157H20.8072C21.2255 19.0157 21.5678 18.6734 21.5678 18.2551V8.36706C21.5678 7.94872 21.2255 7.60645 20.8072 7.60645H7.11609C6.69775 7.60645 6.35547 7.94872 6.35547 8.36706ZM16.9128 9.8883L14.1746 12.5733C14.0301 12.7102 13.8019 12.7178 13.6574 12.5885L10.6377 9.8883H16.9128ZM19.286 16.7339H8.63732V11.1509L13.4672 15.4941C13.7639 15.7603 14.2279 15.7527 14.5093 15.4712L19.286 10.7402V16.7339Z" />
    </svg>
  );
};

SMS.propTypes = {
  className: string,
};

export default SMS;
