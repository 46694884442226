import React from 'react';
import styled from 'styled-components';

const CarIMGColor = ({ className, src, alt, color }) => {
  return (
    <div className={className}>
      <CarIMG src={src} alt={alt} />
      <CarColor color={color} />
    </div>
  );
};

const CarColor = styled.div`
  width: 8rem;
  height: 8rem;
  background-color: ${({ color }) => (color ? color : '#fff')};
  border-radius: 50%;
  border: 5px solid #fff;
  position: absolute;
  top: 63%;
  left: 76%;
`;

const CarIMG = styled.div`
  width: 33rem;
  height: 33rem;
  border-radius: 50%;
  background-image: ${({ src }) => (src ? `url("${src}")` : '')};
  background-size: cover;
  background-position: center;
`;

const StyledCarIMGColor = styled(CarIMGColor)`
  position: relative;
`;
StyledCarIMGColor.displayName = 'CarIMG';

export default StyledCarIMGColor;
