export const subsections = {
  category: {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Claim category',
      },
      {
        id: 1,
        label: 'Claim type',
      },
    ],
  },
  flights: {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Flights',
      },
    ],
  },
  'flight-info': {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Flight info',
      },
      {
        id: 1,
        label: 'Select a flight',
      },
    ],
  },
  last: {
    section: 0,
    subsections: [{ id: 0, label: 'Issues' }],
  },
  'car-lookup': {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Car lookup',
      },
    ],
  },
  residence: {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Current residence',
      },
    ],
  },
  'accident-desc': {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Time frame',
      },
      {
        id: 1,
        label: 'Description',
      },
    ],
  },
  'witness-list': {
    section: 1,
    subsections: [
      {
        id: 0,
        label: 'Witness list',
      },
      {
        id: 1,
        label: 'Injured list',
      },
    ],
  },
  'injured-list': {
    section: 1,
    subsections: [
      {
        id: 0,
        label: 'Witness list',
      },
      {
        id: 1,
        label: 'Injured list',
      },
    ],
  },
  'witness-info': {
    section: 1,
    subsections: [
      {
        id: 0,
        label: 'Witness list',
      },
      {
        id: 1,
        label: 'Injured list',
      },
    ],
  },
  issues: {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Issues',
      },
      {
        id: 1,
        label: 'Description',
      },
    ],
  },
  passengers: {
    section: 1,
    subsections: [
      {
        id: 0,
        label: 'Passenger list',
      },
    ],
  },
  'passenger-contact': {
    section: 1,
    subsections: [
      {
        id: 0,
        label: 'Contact info',
      },
      {
        id: 1,
        label: 'Address',
      },
      {
        id: 2,
        label: 'Passport/ID-card',
      },
    ],
  },
  travel: {
    section: 2,
    subsections: [
      { id: 0, label: 'Extra travel documentation' },
      { id: 1, label: 'Booking confirmation' },
      { id: 2, label: 'Boarding pass' },
    ],
  },
  expenses: {
    section: 1,
    subsections: [{ id: 0, label: 'Extra expences documentation' }],
  },
  'claim-details': {
    section: 0,
    subsections: [
      { id: 0, label: 'Circusmstances' },
      { id: 1, label: 'Remarks' },
    ],
  },
  involved: {
    section: 1,
    subsections: [
      { id: 0, label: 'Client list' },
      { id: 1, label: 'Register third party' },
    ],
  },
  'insuree-info': {
    section: 1,
    subsections: [
      { id: 0, label: 'Contact' },
      { id: 1, label: 'Address' },
      { id: 2, label: 'Insurance' },
    ],
  },
  'third-party': {
    section: 1,
    subsections: [
      { id: 0, label: 'Contact' },
      { id: 1, label: 'Address' },
      { id: 2, label: 'Insurance' },
    ],
  },
  'contact-options': {
    section: 1,
    subsections: [
      { id: 0, label: 'Contact person' },
      { id: 1, label: 'Contact options' },
    ],
  },
  'repairer-select': {
    section: 2,
    subsections: [{ id: 0, label: 'Certified repairer' }],
  },
  'details-occurrence-home': {
    section: 2,
    subsections: [
      { id: 0, label: 'Affected rooms' },
      { id: 1, label: 'Damaged goods' },
    ],
  },
  'claim-subtype': {
    section: 0,
    subsections: [{ id: 0, label: 'Documents' }],
  },
  'time-frame': {
    section: 0,
    subsections: [
      {
        id: 0,
        label: 'Time frame',
      },
      {
        id: 1,
        label: 'Description',
      },
    ],
  },
  'company-contact': {
    section: 1,
    subsections: [{ id: 0, label: 'Company contact information' }],
  },
  'details-occurrence': {
    section: 2,
    subsections: [
      { id: 0, label: 'Occurence place' },
      { id: 1, label: 'List of events' },
    ],
  },
  'accident-personal': {
    section: 2,
    subsections: [
      {
        id: 0,
        label: 'Declaration form',
      },
      {
        id: 1,
        label: 'Medical certificate',
      },
    ],
  },
  'accident-upload': {
    section: 2,
    subsections: [
      { id: 0, label: 'Accident pictures' },
      { id: 1, label: 'Invoices damaged goods' },
    ],
  },
  'special-circumstances': {
    section: 0,
    subsections: [
      { id: 0, label: 'Special circumstances' },
      { id: 1, label: 'Email police zone' },
    ],
  },
  summary: {
    section: 3,
    subsections: [{ id: 0, label: 'Summary' }],
  },
  'thank-you': {
    section: 3,
    subsections: [{ id: 0, label: 'Thank you!' }],
  },
};

export const stagedSections = (() => {
  let result = { 0: [], 1: [], 2: [], 3: [] };
  Object.entries(subsections).forEach(([k, v]) => {
    result[v.section].push(k);
  });
  return result;
})();
