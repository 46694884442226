import React from 'react';
import styled, { css } from 'styled-components';
import {
  Clock,
  Download,
  Flash,
  Collision,
  Info,
  Envelope,
  Phone,
} from '../../icons';
import {
  Flex,
  File,
  SubTitle,
  FileBox,
  Preview,
  SmallText,
  StyledLabel,
  BigText,
  SVGWrapper,
} from '../../styledComponents';
import Summary from '../../components/DetailsSummary';
import Section from '../../components/DetailsSection';
import DataColumn from '../../components/DetailsDataColumn';
import { useTranslation } from 'react-i18next';
import { formatBytes } from './utils';

const DetailLiability = ({ claim, refs }) => {
  const { t } = useTranslation();
  const occurenceDate = `${claim.date_occurred.day}/${claim.date_occurred.month}/${claim.date_occurred.year}`;
  const summary = [
    { title: 'Category', value: claim.type, id: 0 },
    { title: 'Type claim', value: claim.peril, id: 1 },
    { title: 'Occurrence date', value: occurenceDate, id: 2 },
    { title: 'Recommendation', value: '-', id: 3 },
    { title: 'Payout', value: '-', id: 4 },
    { title: 'Value', value: '- -', id: 5 },
  ];

  // claim = {
  //   ...claim,
  //   clients: [
  //     {
  //       first_name: 'Test',
  //       last_name: 'Test',
  //       email: 'test@gmail.com',
  //       iban: '876',
  //       phone: '+48986969',
  //       street: 'Bahome',
  //       postal: '987-65',
  //       city: 'Szczecin',
  //     }
  //   ],
  //   policy_nr: 'GJH27-876FB-965DG-8758BH',
  //   description: 'Test is test',
  //   place_occurred: {
  //     street: 'Boeheme',
  //     house_nr: '12',
  //     zipcode: '897-87',
  //     city: 'Gent',
  //     country: 'BRU'
  //   },
  //   date_occurred: '04/10/2018'
  // };

  const detailedReport = {
    mainData: [
      { id: 0, title: 'Measured on', value: '09/17/2019 12:31 PM' },
      {
        id: 1, title: 'Timezone', value: `${t('Europe')} / ${t('Brussels')} (GMT+1)`
      },
    ],
    subData: [
      {
        id: 0,
        title: 'Description',
        value:
          'Heavy rain that starts at night and continues through morning, paired with heavy winds and hail.',
      },
    ],
  };
  console.log(claim)
  return (
    <>
      <Summary>
        <SVGWrapper>
          <Collision />
        </SVGWrapper>
        <>
          {summary.map(s => (
            <Summary.Column key={s.id}>
              <Summary.Title>{t(s.title)}</Summary.Title>
              <Summary.Value>{t(s.value)}</Summary.Value>
            </Summary.Column>
          ))}
        </>
      </Summary>
        <Section ref={refs[1][1]}>
          <Section.Title>{t('Company details')}</Section.Title>
            <Section.Content background={true}>
              <Section.MainData>
                <DataColumn darkColors bigValue boldValue basis="45.6%">
                  <span className="title">{t('Name')}</span>
                  <span className="value">{`${claim.contact.first_name} ${claim.contact.last_name}`}</span>
                </DataColumn>
                <Icons basis="54.4%">
                  <Envelope />
                  <Phone fill="#FF8000" />
                </Icons>
              </Section.MainData>
              <Section.SubData>
                <DataColumn>
                  <span className="title">{t('Address')}</span>
                  <span className="value">{`${claim.place_occurred.address.street}, ${claim.place_occurred.address.zipcode} ${claim.place_occurred.address.city}`}</span>
                </DataColumn>
                <DataColumn>
                  <span className="title">{t('Email')}</span>
                  <span className="value">{claim.contact.email}</span>
                </DataColumn>
                {claim.contact.telephonenr && <DataColumn basis="45.6%" style={{ marginTop: 5 }}>
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{claim.contact.telephonenr}</span>
                </DataColumn>}
                {claim.contact.mobilephonenr && <DataColumn style={{ marginTop: 5 }}>
                  <span className="title">{t('Phone number')}</span>
                  <span className="value">{claim.contact.mobilephonenr}</span>
                </DataColumn>}
              </Section.SubData>
            </Section.Content>
        </Section>
      <Section ref={refs[1][2]}>
        <Section.Title>{t('Claim information')}</Section.Title>
        <Section.Content background>
          <Section.MainData wrap direction="row">
            <Flash />
            <Flex margin="0 0 0 4vw">
              <DataColumn basis="43%" bigValue boldValue>
                <span className="title">{t('Type of claim')}</span>
                <span className="value">{t(claim.peril)}</span>
              </DataColumn>
            </Flex>
          </Section.MainData>
          <Section.SubData wrap={false}>
            <Info />
            <Flex margin="0 0 0 4vw" direction="column">
              <StyledLabel>{t('List of events')}</StyledLabel>
              <p>{claim.description}</p>
            </Flex>
          </Section.SubData>
        </Section.Content>
      </Section>
    {/* <Section ref={refs[1][3]}>
      <Section.Title>{t('Detailed report')}</Section.Title>
      <Section.Content>
        <Section.MainData wrap direction="row">
          <Clock />
          <Flex margin="0 0 0 4vw">
            {detailedReport.mainData.map(data => (
              <DataColumn key={data.id} bigValue boldValue>
                <span className="title">{t(data.title)}</span>
                <span className="value">{data.value}</span>
              </DataColumn>
            ))}
          </Flex>
        </Section.MainData>
        <Section.SubData wrap={false}>
          <Info />
          <Flex margin="0 0 0 4vw">
            {detailedReport.subData.map(data => (
              <DataColumn key={data.id}>
                <span className="title">{t(data.title)}</span>
                <span className="value">{t(data.value)}</span>
              </DataColumn>
            ))}
          </Flex>
        </Section.SubData>
      </Section.Content>
    </Section> */}
      {claim.files && claim.files.length ? (
        <Section ref={refs[1][4]}>
          <Section.Title>{t('Uploaded documents')}</Section.Title>
          <Section.Content>
            <Flex>
              {claim.files && claim.files.length ? (
                <DataColumn>
                  <SubTitle>{t('Boarding passes')}</SubTitle>
                  <Flex height="252px" direction="column" justify="flex-start">
                    {claim.files.map(b => (
                      <FileBox key={b.id} marginTop>
                        <Flex>
                          <Preview />
                          <File direction="column">
                            <span className="name">{b.name}</span>
                            <span className="size">{b.size}</span>
                          </File>
                        </Flex>
                        <Download />
                      </FileBox>
                    ))}
                  </Flex>
                </DataColumn>
              ) : null}

              {claim.files && claim.files.length ? (
                <Section ref={refs[1][6]}>
                  <Section.Title>{t('Uploaded documents')}</Section.Title>
                  <Section.Content>
                    <Flex>
                      <DataColumn>
                        <SubTitle>{t('Picture report')}</SubTitle>
                        <Flex direction="column" justify="flex-start">
                          {claim.files.map(f => (
                            <FileBox key={f.id} marginTop>
                              <Flex>
                                <Preview />
                                <File direction="column">
                                  <span className="name">{f.metadata.name}</span>
                                  <span className="size">{formatBytes(f.size_in_bytes)}</span>
                                </File>
                              </Flex>
                              <Download />
                            </FileBox>
                          ))}
                        </Flex>
                      </DataColumn>
                    </Flex>
                  </Section.Content>
                </Section>
              ) : null}
            </Flex>
          </Section.Content>
        </Section>
      ) : null}
    </>
  );
};

const Icons = styled(DataColumn)`
  flex-direction: row;

  svg:last-child {
    margin-left: 36px;
  }
`;

export default DetailLiability;
