import React, { useMemo } from 'react';
import {
  MainNavigation,
  IconFamilyFilled,
  IconActivePVFilled } from 'wg-fe-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  DashboardIcon,
  DialogsIcon,
  CheckedIcon,
  ToolsIcon,
  MailIcon,
  SettingsIcon,
} from '../icons';
import { mainStyles } from '../helpers/utils';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../env.config'
const sideMenuElements = (rows, activeRoute, history) =>
  rows.map(row => {
    const isActive = activeRoute === row.path;
    
    const onClick = () => row.path.includes('https') ? window.location.replace(row.path) : history.push(row.path);
    return (
      <MainNavigation.Item
        key={row.id}
        icon={
          <row.icon.component
            className={`${row.icon.className} ${isActive ? 'selected' : ''}`}
            isActive={isActive}
          />
        }
        onClick={onClick}
        isActive={isActive}
      >
        {row.text}
      </MainNavigation.Item>
    );
  });

const SideMenu = ({ activeRoute }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const url = useMemo(() => {
    if (BASE_URL.includes('qa')) return 'https://marketplace.dev.qa.wegroup.be/';
    if (BASE_URL.includes('staging')) return 'https://marketplace.dev.staging.wegroup.be/';
    return 'https://marketplace.wegroup.be/';
  }, [])

  const sideMenuRows = {
    dashboard: [
      {
        path: `${url}#/dashboard`,
        text: t('dashboard'),
        icon: { component: DashboardIcon, className: 'dashboardIcon' },
        id: 1,
      },
    ],
    underwriting: [
      {
        path: `${url}#/insure`,
        text: t('insure'),
        icon: { component: DialogsIcon, className: 'dialogsIcon' },
        id: 2,
      },
      {
        path: `${url}#/broker-history`,
        text: t('Past conversations'),
        icon: { component: DialogsIcon, className: 'dialogsIcon' },
        id: 3,
      },      
    ],
    management: [
      {
        path: `${url}#/customer-management/customers`,
        text: t('Portfolio'),
        icon: { component: IconFamilyFilled, className: 'dialogsIcon' },
        id: 4,
      },
      {
        path: `${url}#/customer-management/contracts`,
        text: t('Offers and contracts'),
        icon: { component: IconActivePVFilled, className: 'dialogsIcon' },
        id: 5,
      },
    ],
    claims: [
      {
        path: '/cases',
        text: t('cases'),
        icon: { component: CheckedIcon, className: 'checkedIcon' },
        id: 6,
      },
    ],
    extras: [
      {
        path: `${url}#/toolkit/car-lookup`,
        text: t('toolkit'),
        icon: { component: ToolsIcon, className: 'toolsIcon' },
        id: 7,
      },
      {
        path: `${url}#/academy`,
        text: t('academy'),
        icon: { component: MailIcon, className: 'mailIcon' },
        id: 8,
      },
    ],
    account: [
      {
        path: `${url}#/settings/account-settings`,
        text: t('settings'),
        icon: { component: SettingsIcon, className: 'settingsIcon' },
        id: 9,
      },
    ],
  };

  return (
    <StyledSideMenu activeRoute={activeRoute}>
      {sideMenuElements(sideMenuRows.dashboard, activeRoute, history)}
      {Object.entries(sideMenuRows).map(([k, v]) =>
        k === t('dashboard') ? null : (
          <React.Fragment key={k}>
            <MainNavigation.Title>{t(k)}</MainNavigation.Title>
            {sideMenuElements(v, activeRoute, history)}
          </React.Fragment>
        ),
      )}
    </StyledSideMenu>
  );
};

const StyledSideMenu = styled(MainNavigation)`
  grid-area: SideMenu;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 7.4vh);
  top: 7.9rem;
  background-color: ${({ theme }) => theme.ui.background};
  padding-bottom: 1vh;

  span {
    text-transform: capitalize;
  }
  & > div {
    width: initial;
    &:first-of-type {
      height: 7.5rem;
    }
  }
 
  svg.selected {
    :not(.dashboardIcon) {
      fill: ${mainStyles.selected};
      & > path {
        fill: ${mainStyles.selected};
      }
    }
  }
  path {
    fill: #c4c4c4
  }

  svg:not(.arrowIcon) {
    width: 28px;
    height: 28px;
    margin-right: 1.5vw;

    @media (max-width: 1200px) {
      margin-right: 3vw;
    }
  }
  svg.dashboardIcon {
    stroke: #c4c4c4;
    rect:last-child {
      fill: #c4c4c4;
    }
    &.selected {
      stroke: ${mainStyles.selected};
      rect:last-child {
        fill: ${mainStyles.selected};
      }
    }
  }
  svg:not(.dashboardIcon) {
    fill: #c4c4c4;
  }

  @media (max-width: 1200px) {
    position: fixed;
    top: 10.5vh;
    right: 0;
    width: 28vw;
    transform: ${({ menu_opened }) =>
    menu_opened ? 'translate(0)' : 'translate(100%)'};
    transition: transform 1s;
    justify-items: center;
    z-index: 1;
    font-size: 1rem;
    height: calc(100% - 12vh);
  }

  @media (max-width: 900px) {
    width: 50%;
  }

  svg:not(.arrowIcon) {
    width: 28px;
    height: 28px;
    margin-right: 1.5vw;

    @media (max-width: 1200px) {
      margin-right: 3vw;
    }
  }
  svg.dashboardIcon {
    stroke: #c4c4c4;
    rect:last-child {
      fill: #c4c4c4;
    }
  }
  svg:not(.dashboardIcon) {
    fill: #c4c4c4;
  }
`;

export default SideMenu;
