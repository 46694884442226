import React from 'react';
import { string } from 'prop-types';

const BagageClaim = ({ className, width = 69, height = 63 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 16.0536V57.5505C0 60.3977 2.30202 62.6997 5.14925 62.6997H12.4491V10.9043H5.14925C2.30202 10.9043 0 13.2063 0 16.0536Z" />
      <path d="M63.8203 10.874H56.5205L56.5508 62.6695H63.8506C66.6979 62.6695 68.9999 60.3674 68.9999 57.5202V15.993C68.9696 13.176 66.6676 10.874 63.8203 10.874Z" />
      <path d="M46.8889 4.36172C46.8889 1.96883 44.9201 0 42.5272 0H26.4131C24.0202 0 22.0513 1.96883 22.0513 4.36172V10.9043H19.2041L19.2344 62.6997H49.7664L49.7361 10.9043H46.8889V4.36172ZM26.4131 4.36172H42.5272V10.9043H26.4131V4.36172Z" />
    </svg>
  );
};

BagageClaim.propTypes = {
  className: string,
};

export default BagageClaim;
