import React from "react";
import Fallback from '../Fallback';
import LogRocket from 'logrocket';

class ErrorBoundary extends React.Component {
  state = { error: '' };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    localStorage.clear();
    console.error('error: ', error.toString());
    console.log('info of error: ', info);
    LogRocket.captureException(error, {
      info
    });
    this.setState({ error: error.toString() });
  }

  render() {
    return this.state.error ? <Fallback onClick={() => this.setState({ error: '' })} /> : this.props.children;
  }
}

export default ErrorBoundary