import React from 'react';
import styled from 'styled-components';
import { ActionButton } from 'wg-fe-ui';
import { CarInput } from '../styledComponents';

const ButtonInput = ({
  className,
  children,
  name,
  value,
  onChange,
  onClick,
  disabled,
  suffix,
  error,
}) => {
  return (
    <div className={className}>
      <CarInput
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
      />
      {suffix && <span>{suffix}</span>}
      <ActionButton onClick={onClick}>{children}</ActionButton>
    </div>
  );
};

const StyledButtonInput = styled(ButtonInput)`
  display: flex;
  position: relative;
  input {
    margin-top: 0 !important;
    background-color: #fff;
  }

  button {
    flex-basis: 70%;
    width: 7.3rem;
    height: 4rem;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    min-width: 0;
    margin-left: 0.5rem;
  }
  /* Suffix span */
  & > span {
    position: absolute;
    font-weight: 900;
    color: #555555;
    top: 22%;
  right: ${({error}) => error ? '52%' : '46%'};
    font-size: 1.4rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    input,
    button {
      width: 100%;
    }

    button {
      margin: 2rem 0;
    }
  }
`;

export default StyledButtonInput;
