import React from 'react';
import styled from 'styled-components';
import { ImageSelectBox, Section } from 'wg-fe-ui';
import { PlaneIcon, BagageClaim, TaxClaim } from '../icons'
import { useTranslation } from 'react-i18next';


const icons = [<PlaneIcon />, <BagageClaim />, <TaxClaim />];

const CategoryFlight = ({ value, handleChange, perils }) => {
  const { t } = useTranslation();
  return (
    <StyledSection>
      <Section.Title>{t('Claim type flight')}</Section.Title>
      <Section.Content>
        <SectionInputWrapper>
          {perils.map((p, i) => (
            <ImageSelectBox
              key={i}
              onChange={handleChange}
              selected={value}
              value={p.value}
              name="peril"
              label={p.label}
              icon={icons[i]}
            />
          ))}
        </SectionInputWrapper>
      </Section.Content>
    </StyledSection>
  );
};

const SectionInputWrapper = styled.div`
  display: flex;

  label {
    margin: 0 2rem;
    width: 14rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media(max-width: 480px) {
    flex-direction: column;
    align-items: center;
    label {
      margin: 10px auto;
      width: 50%;
      height: 40vw;

      &:first-child {
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }
    }
  }
`;

const StyledSection = styled(Section)`
  margin-bottom: 2rem;

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

export default CategoryFlight;
