import React from 'react';
// import { getIcon } from './../helpers';
import CarIcon from './../icons/Car';
import FamilyIcon from './../icons/Family';
import HomeIcon from './../icons/Property';
import PdfIcon from './../icons/Pdf';

export const icons = {
  car: props => <CarIcon {...props} />,
  family: props => <FamilyIcon {...props} />,
  home: props => <HomeIcon {...props} />,
  pdf: props => <PdfIcon {...props} />,
};

export const riskTypes = [
  {
    id: 2,
    type: 'car',
    name: 'Car',
    path: '/insurance/car',
    // icon: active => getIcon('car', active),
    items: [],
  },
  {
    id: 3,
    type: 'family',
    name: 'Personal Liability',
    path: '/insurance/family',
    // icon: active => getIcon('family', active),
    items: [],
  },
  {
    id: 4,
    type: 'home',
    name: 'Property',
    path: '/insurance/home',
    // icon: active => getIcon('home', active),
    items: [],
  },
];

export const flightClaimsList = [
  { value: 'Flight', label: 'Flight' },
  { value: 'Baggage', label: 'Baggage' },
  { value: 'Tax', label: 'Tax' },
];

export const propertyClaimsList = [
  { value: 'Liability Building', label: 'Liability Building' },
  { value: 'Burglary', label: 'Burglary' },
  { value: 'Cracks & tears', label: 'Cracks & tears' },
  { value: 'Dryrot', label: 'Dryrot' },
  { value: 'Electricity', label: 'Electricity' },
  { value: 'Environmental/fueloil', label: 'Environmental/fueloil' },
  { value: 'Fire/smoke/ignition', label: 'Fire/smoke/ignition' },
  { value: 'Glass breakage', label: 'Glass breakage' },
  { value: 'Natural disaster', label: 'Natural disaster' },
  { value: 'Snow pressure', label: 'Snow pressure' },
  { value: 'Theft', label: 'Theft' },
  { value: 'Vandalism', label: 'Vandalism' },
  { value: 'Water damage', label: 'Water damage' },
  { value: 'Scorch damage', label: 'Scorch damage' },
  { value: 'Thawing', label: 'Thawing' },
];

export const personalLiabilityClaimsList = [
  { value: 'Liability', label: 'Liability' },
];

export const automotiveClaimsList = [
  { value: 'Collision/Touch', label: 'Collision/Touch (Accident)' },
  { value: 'Theft', label: 'Theft' },
  { value: 'Fire investigation', label: 'Fire investigation' },
  { value: 'Glass breakage', label: 'Glass breakage' },
  { value: 'Mechanical breakdown', label: 'Mechanical breakdown' },
];

export const thirdPartyList = [
  { value: 'Not involved', label: 'Not involved' },
  { value: 'Not known', label: 'Not known' },
  { value: 'Know', label: 'Know' },
];

export const DATE_FORMAT = `DD-MM-YYYY`;

export const insurersList = [
  { value: 'Actel', label: 'Actel' },
  { value: 'AG Insurance', label: 'AG Insurance' },
  { value: 'Allianz Benelux', label: 'Allianz Benelux' },
  { value: 'Allianz Global Assistance', label: 'Allianz Global Assistance' },
  { value: 'Arag SE - Branch Belgium', label: 'Arag SE - Branch Belgium' },
  { value: 'Arces', label: 'Arces' },
  { value: 'Argenta Assuranties ', label: 'Argenta Assuranties ' },
  { value: 'ATV', label: 'ATV' },
  { value: 'Aviva Vie', label: 'Aviva Vie' },
  { value: 'AXA Belgium', label: 'AXA Belgium' },
  { value: 'Baloise Insurance', label: 'Baloise Insurance' },
  { value: 'Belfius verzekeringen', label: 'Belfius verzekeringen' },
  { value: 'Cardif Leven', label: 'Cardif Leven' },
  { value: 'CDA', label: 'CDA' },
  { value: 'Corona', label: 'Corona' },
  { value: 'Crelan Insurance', label: 'Crelan Insurance' },
  { value: 'DAS', label: 'DAS' },
  { value: 'Dela verzekeringen', label: 'Dela verzekeringen' },
  { value: 'Delta Lloyd Life', label: 'Delta Lloyd Life' },
  { value: 'DKV Belgium', label: 'DKV Belgium' },
  { value: 'ERGO Insurance', label: 'ERGO Insurance' },
  { value: 'Ethias', label: 'Ethias' },
  { value: 'Euromex', label: 'Euromex' },
  { value: 'Europ Assistance', label: 'Europ Assistance' },
  {
    value: 'Europese Goederen en Reisbagage verzekeringsmaatschappij',
    label: 'Europese Goederen en Reisbagage verzekeringsmaatschappij',
  },
  {
    value: 'Federale Verzekering (BOAR)',
    label: 'Federale Verzekering (BOAR)',
  },
  {
    value: 'Federale Verzekering (Leven)',
    label: 'Federale Verzekering (Leven)',
  },
  { value: 'Fidea', label: 'Fidea' },
  { value: 'Garantiefonds Reizen', label: 'Garantiefonds Reizen' },
  { value: 'Generali Belgium', label: 'Generali Belgium' },
  { value: 'NN Insurance Belgium', label: 'NN Insurance Belgium' },
  {
    value: 'NN Insurance Services Belgium',
    label: 'NN Insurance Services Belgium',
  },
  { value: 'Inter Partner Assistance', label: 'Inter Partner Assistance' },
  { value: 'KBC verzekeringen', label: 'KBC verzekeringen' },
  { value: 'LAR', label: 'LAR' },
  { value: "L'Ardenne Prevoyante", label: "L'Ardenne Prevoyante" },
  { value: 'Mapfre Asistencia SA/NV', label: 'Mapfre Asistencia SA/NV' },
  {
    value: 'Nationale Suisse verzekeringen',
    label: 'Nationale Suisse verzekeringen',
  },
  { value: 'Optimco', label: 'Optimco' },
  { value: 'P&V verzekeringen', label: 'P&V verzekeringen' },
  { value: 'Partners verzekeringen', label: 'Partners verzekeringen' },
  { value: 'Patronale Life', label: 'Patronale Life' },
  { value: 'Piette en Partners', label: 'Piette en Partners' },
  { value: 'Servis', label: 'Servis' },
  { value: 'Touring', label: 'Touring' },
  { value: 'Touring Verzekeringen', label: 'Touring Verzekeringen' },
  { value: 'Vivium', label: 'Vivium' },
  { value: 'VAB', label: 'VAB' },
  { value: 'ZA verzekeringen', label: 'ZA verzekeringen' },
];

export const insurersEnum = {
  aedes: 'Aedes',
  ag: 'AG Insurance',
  allianz: 'Allianz Benelux',
  amlin: 'Amlin',
  argenta: 'Argenta Assuranties',
  athora: 'Athora Belgium',
  axa: 'AXA Belgium',
  baloise: 'Baloise Insurance',
  belfius: 'Belfius Verzekeringen',
  corona: 'Corona Direct',
  ethias: 'Ethias',
  federale: 'Federale Verzekering',
  fidea: 'FIDEA',
  generali: 'Generali',
  hdi: 'HDI Global SE',
  kbc: 'KBC Verzekeringen',
  nn: 'NN Insurance Belgium',
  optimco: 'Optimco',
  partners: 'Partners Verzekeringen',
  pnp: 'Piette en Partners',
  pv: 'P&V Verzekeringen',
  touring: 'Touring Verzekeringen',
  tvm: 'TVM Belgium',
  vivium: 'Vivium',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const cities = {
  ber: 'Berlin',
  bru: 'Brussels',
  lis: 'Lisbon',
  waw: 'Warsaw',
};

export const countries = {
  be: 'Belgium',
  ge: 'Germany',
  pl: 'Poland',
};

export const statuses = [
  'Claim Opened',
  'Uploading',
  'Data Analysis',
  'Performing Expertise',
  'Approved',
  'Authorized Repairer',
];

export const engineTypes = {
  PET: 'Petrol',
  DIE: 'Diesel',
  LPG: 'LPG',
  DIE_LPG: 'Hybrid (Diesel / LPG)',
  ELE: 'Electricity',
  ELE_PET: 'Hybrid (Electricity / Petrol)',
  ELE_DIE: 'Hybrid (Electricity / Diesel)',
  ELE_LPG: 'Hybrid (Electricity / LPG)',
  ELE_HYDRO: 'Hybrid (Electricity / Hydrogen)',
  HYDRO: 'Hydrog',
  CNG_PET: 'Hybrid (CNG / Petrol)',
  BIO: 'Bioethanol',
  OTHER: 'Other',
};

export const supportedFiles = [
  { type: 'image', extension: 'jpeg' },
  { type: 'image', extension: 'jpg' },
  { type: 'image', extension: 'png' },
  { type: 'video', extension: 'mp4' },
  { type: 'application', extension: 'pdf' },
];
