import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { ActionButton, Section, DateInput, TimeInput } from 'wg-fe-ui';
import { updateClaim } from '../../../helpers/apiRouterService';
import {
  parseDateStringToObject,
  parseTimeStringToObject,
} from '../../../helpers/dateService';
import Description from '../../../components/IssuesTextArea';
import useQueryParams from '../../../hooks/useQueryParams';
import { mapValues } from 'lodash';
import { useTranslation } from 'react-i18next';

const TimeFrame = forwardRef(
  ({  validateForms, claimId, handleSection, setValues }, ref) => {
    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));
    useEffect(() => {
      handleSection({ section: 0, subsection: 0 });
    }, []);

    const { t } = useTranslation();
    const { addParam } = useQueryParams();
    const dateRef = useRef();
    const timeRef = useRef();
    const [accidentTime, setAccidentTime] = useState();
    const [accidentDate, setAccidentDate] = useState();
    const [desc, setDesc] = useState('');
    const [errors, setErrors] = useState({});

    const handleFormData = async () => {
      const [validationErrors, hasErrors] = await validateForms(
        {
          accidentDate: parseDateStringToObject(accidentDate),
          accidentTime: parseTimeStringToObject(accidentTime),
        },
        'accidentDesc',
      );
      setErrors(() => mapValues(validationErrors, e => t(e)));
      if (hasErrors) return;
      const data = {
        date_occurred: {
          ...parseDateStringToObject(accidentDate),
          ...parseTimeStringToObject(accidentTime),
        },
        description: desc || null,
      };
      const [, status] = await updateClaim(claimId, {
        claim: data,
      });
      setValues(prev => ({ ...prev, ...data }));
      status < 300 && addParam({ submit: true });
    };

    return (
      <>
        <StyledSection>
          <Section.Title>
            {t('When did your accident take place?')}
          </Section.Title>
          <Section.Content background>
            <InputsRow>
              <DateField
                name="accidentDate"
                error={
                  errors.accidentDate &&
                  Object.values(errors.accidentDate).length
                    ? t('Invalid date')
                    : undefined
                }
                value={accidentDate}
                onChange={({ value }) => setAccidentDate(value)}
                ref={dateRef}
              >
                {t('Date')}
              </DateField>
              <TimeField
                ref={timeRef}
                touched={false}
                error={errors.accidentTime}
                is12HourFormat={false}
                name="time"
                disabled={false}
                onChange={({ value }) => setAccidentTime(value)}
                value={accidentTime}
              >
                {t('Time')} ({t('optional')})
              </TimeField>
            </InputsRow>
          </Section.Content>
        </StyledSection>

        <StyledSection style={{ marginTop: '35px' }}>
          <Section.Title>{t('Description')}</Section.Title>
          <Section.Content>
            <h3>
              {t(
                'Fill in all the relevant details. The more information provided, the more effectively we can investigate your claim',
              )}
            </h3>
            <StyledDescription
              type="text"
              name="accident_description"
              placeholder={t('Type here to add an description')}
              value={desc}
              onChange={e => setDesc(e.target.value)}
            />
          </Section.Content>
        </StyledSection>
        <StyledButton onClick={handleFormData} data-test-id="continueButton">
          {t('Continue')}
        </StyledButton>
      </>
    );
  },
);

const StyledSection = styled(Section)`
  margin-bottom: 2rem;
  ${Section.Content} {
    span,
    h3 {
      margin: 11px auto 21px;
    }

    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const DateField = styled(DateInput)`
  max-height: 9rem;
  width: 21vw;
`;

const TimeField = styled(TimeInput)`
  max-height: 9rem;
  width: 21vw;
`;

const StyledButton = styled(ActionButton)`
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledDescription = styled(Description)`
  width: 100%;
  padding: 1.3rem;
  margin-top: 1.5rem;
  height: 24rem;
`;

const InputsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${DateField}, ${TimeField} {
    width: 40%;
    font-size: 1.4rem;
  }
`;

export default TimeFrame;
