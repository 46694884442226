import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Cases from './cases';
import NewClaim from './cases/NewClaim';
import Detail from './cases/Detail';
import SummaryLayout from './cases/SummaryLayout';
import Login from './AdminLogin';
import { verifyInit } from '../helpers/apiRouterService';
import LogRocket from 'logrocket';

const StyledClaim = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = ({ className }) => {
  const { pathname } = useLocation();
  const customerMethods = async () => {
    const resp = await verifyInit();
    if (!resp || !resp.me) return;
    localStorage.setItem('lng', resp.me.language);
    if (process.env.NODE_ENV !== 'development') {
      LogRocket.identify('Email Login', {
        email: resp.me.email,
        name: resp.me.username,
      });
    }
  };

  useEffect(() => {
    if (pathname === '/dashboard') {
      window.location.href = "https://marketplace.dev.staging.wegroup.be"
    }
    if (pathname !== '/admin-login' && !pathname.startsWith('/broker')) {
      customerMethods();
    }
  }, []);

  return (
    <StyledClaim className={className}>
      <Switch>
        <Redirect exact from="/" to="/cases" />
        <Route exact path="/cases" component={Cases} />
        <Route path="/new-claim" component={NewClaim} />
        <Route path="/summary" component={SummaryLayout} />
        <Route path="/broker/:id" component={NewClaim} />
        <Route path="/claim/:id" component={Detail} />
        {process.env.NODE_ENV === 'development' ? (
          <Route path="/admin-login" component={Login} />
        ) : null}
        <Redirect to="/admin-login" />
      </Switch>
    </StyledClaim>
  );
};
export default Main;
