import React, { useState, useEffect } from 'react';
import { Section, SearchSelectInput, TextInput } from 'wg-fe-ui';
import styled from 'styled-components';
import { getInsuranceCompanies } from '../helpers/apiRouterService';
import { handleCompaniesResponse } from '../helpers/objectService';
import { useTranslation } from 'react-i18next';
import {
  flightClaimsList,
  propertyClaimsList,
  personalLiabilityClaimsList,
  automotiveClaimsList,
  thirdPartyList,
} from '../constants/index';

const getSubCategoryTitle = (category) => {
  switch (category) {
    case 'car':
      return 'What kind of incident did you have with your car?';
    case 'personal':
      return 'Which company is your occupational accident insurance policy with?';
    default:
      return `What kind of ${category.toLowerCase()} claim are we talking about?`;
  }
};

const CategoryTypeClaimSection = ({
  claimCategory,
  handleChange,
  perils,
  resetForm,
  values,
  errors,
  isEditing,
}) => {
  const [, setClaimTypeOptions] = useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [thirdParties, setThirdParties] = useState([]);
  const { t } = useTranslation();

  const fetchInsuranceCompanies = async () => {
    const [resp, status] = await getInsuranceCompanies();
    if (status !== 200) return;
    resp &&
      setInsuranceCompanies(handleCompaniesResponse(resp.insurance_companies));
  };

  useEffect(() => {
    const thirdPartiesList = thirdPartyList.map((third) => ({
      label: t(third.label),
      value: third.value,
    }));
    setThirdParties(thirdPartiesList);
    fetchInsuranceCompanies();
  }, []);

  useEffect(() => {
    if (isEditing) return;
    resetForm();
    if (claimCategory.toLowerCase() === 'personal')
      handleChange({ name: 'peril', value: 'OCCUPATIONAL_ACCIDENT' });
  }, [claimCategory]);

  return (
    <StyledSection>
      <Section.Title>
        {t(getSubCategoryTitle(claimCategory.toLowerCase()))}
      </Section.Title>
      <Section.Content background>
        <SectionInputWrapper>
          {claimCategory.toLowerCase() !== 'personal' && (
            <SearchSelectInput
              name="peril"
              error={errors.peril}
              options={perils}
              value={
                values.peril
                  ? { label: t(values.peril), value: values.peril }
                  : null
              }
              onSelected={handleChange}
              placeholder={t('Choose your option')}
              disabled={isEditing}
            >
              <Row>
                {t('Claim type')} <Required>*</Required>
              </Row>
            </SearchSelectInput>
          )}
          <SearchSelectInput
            name="insuredAt"
            error={errors.insuredAt}
            options={insuranceCompanies}
            value={
              values.insuredAt
                ? insuranceCompanies.find((c) => c.value === values.insuredAt)
                : null
            }
            onSelected={handleChange}
            placeholder={t('Choose your option')}
          >
            <Row>
              {t('Insured at')} <Required>*</Required>
            </Row>
          </SearchSelectInput>
          <StyledText
            error={errors.policyNumber}
            value={values.policyNumber}
            name="policyNumber"
            onChange={handleChange}
            data-test-id="policyNumber"
          >
            {t('Policy number')}
          </StyledText>
          {claimCategory !== 'PERSONAL' && (
            <SearchSelectInput
              name="thirdParty"
              error={errors.thirdParty}
              options={thirdParties}
              value={
                values.thirdParty
                  ? { label: t(values.thirdParty), value: values.thirdParty }
                  : null
              }
              onSelected={handleChange}
              placeholder={t('Choose your option')}
              disabled={isEditing}
            >
              {t('Third party')}
            </SearchSelectInput>
          )}
        </SectionInputWrapper>
      </Section.Content>
    </StyledSection>
  );
};

const Row = styled.div`
  display: flex;
`;

const Required = styled.span`
  color: ${({ theme }) => theme.status.error};
`;

const StyledText = styled(TextInput)`
  input {
    box-sizing: border-box;
  }
`;

StyledText.displayName = 'TextInput';

const SectionInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: 45%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const StyledSection = styled(Section)`
  margin-bottom: 2rem;

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

export default React.memo(CategoryTypeClaimSection);
