import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { HeaderNav } from 'wg-fe-ui';
import SideMenu from './SideMenu';
import { mainStyles } from '../helpers/utils';
import background from './../assets/icons/background.svg';
import Logo from '../assets/icons/logo.svg';
import { getMeData } from '../helpers/meService';
import { StyledLoader } from '../styledComponents';

export default function Layout({ children }) {
  const logo = localStorage.getItem('logo');
  const [userRole, setUserRole] = useState('');
  const [loading,setLoading] = useState(false);
  const { pathname } = useLocation();

  const getUserRole = async () => {
    if (pathname.startsWith('/broker'))
      return setUserRole('social_login');
    setLoading(true);
    const meData = await getMeData();
    setLoading(false);
    meData && meData.me && setUserRole(meData.me.role);
  };

  useEffect(() => {
    getUserRole();
  }, []);

  if (loading) return <StyledLoader />;

  return !pathname.includes('admin-login') ? (
    <StyledMainContainer userRole={userRole}>
      <StyledHeader logo={userRole.includes('broker') ? Logo : logo} />
      {userRole.includes('broker') && <SideMenu activeRoute={pathname} />}
      <StyledMain>{children}</StyledMain>
    </StyledMainContainer>
  ) : (
      <MainLogin>{children}</MainLogin>
    );
}

const MainLogin = styled.div`
  background-color: none;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

const StyledHeader = styled(HeaderNav)`
  position: static;
  grid-area: Header;
`;

const StyledMainContainer = styled.div`
  display: grid;
  height: 100vh;
  font-size: ${mainStyles.basicFontSize};
  background-color: ${({ theme }) => theme.ui.background};;
  font-family: ${mainStyles.mainFont};

  grid-template-rows: 8rem auto;
  grid-template-columns: 13vw auto;
  grid-template-areas: ${({ userRole }) =>
    userRole.includes('broker')
      ? "'Header Header' 'SideMenu Content'"
      : "'Header Header' 'Content Content'"};

  @media (max-width: 1200px) {
    grid-template-areas: 'Header' 'Content';
    grid-template-columns: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  grid-area: Content;
  padding-bottom: 9.4vh;
  background-color: ${({ theme }) => theme.ui.background};;
  background-size: cover;

  @media (min-width: 1200px) {
    margin-left: 5rem;
  }

  @media (max-width: 1200px) {
    align-items: center;
    padding: 4.4vh 2.9vw;
    width: 100%;
    box-sizing: border-box;
  }
`;
