import styled from 'styled-components';

const FlightInfoForm = styled.form`
  display: flex;
  justify-content: space-evenly;
  &,
  label {
    width: 100%;
  }
  button {
    margin-top: 20px;
    width: 7.3vw;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 0 5vw;
    label {
      width: auto;
    }
  }
`;
export default FlightInfoForm;
