import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  ActionButton,
  Label,
  TextArea,
  TextInput,
  Section,
  PhoneInput,
} from 'wg-fe-ui';
import styled from 'styled-components';
import {
  modifyEntity,
  getCurrentEntity,
} from '../../../helpers/entitiesService';
import useQueryParams from '../../../hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import Address from '../../../components/Address';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';

const WitnessInfo = forwardRef(
  ({ setValues, handleSection, claimId, validateForms }, ref) => {
    const [witness, setWitness] = useState({ country: 'Belgium' });
    const { addParam } = useQueryParams();
    const { id } = useParams();
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const handleWitnessChange = ({ name, value }) => {
      setWitness((data) => ({ ...data, [name]: value }));
    };

    const handleFormData = async (e) => {
      if (e) e.preventDefault();

      const [validationErrors, hasErrors] = await validateForms(
        witness,
        'witnessInfo',
      );
      setErrors(() => mapValues(validationErrors, (e) => t(e)));
      if (hasErrors) return;

      setValues((oldValues) => ({
        ...oldValues,
        witnesses: oldValues.witnesses
          ? [...oldValues.witnesses, witness]
          : [witness],
      }));
      const [status] = await modifyEntity(witness, claimId, 'WITNESS');
      status === 201 && addParam({ submit: true });
    };

    useEffect(() => {
      const currentEntity = getCurrentEntity(id);
      setWitness((prev) =>
        Object.keys(currentEntity).length ? currentEntity : prev,
      );
      handleSection({ section: 1, subsection: 0 });
    }, []);

    return (
      <form onSubmit={handleFormData}>
        <Section>
          <Section.Title>
            {t('Witness information')} ({t('optional')})
          </Section.Title>
          <StyledContent>
            <FormField>
              <Input
                name="first_name"
                onChange={handleWitnessChange}
                value={witness['first_name']}
                error={errors['first_name']}
              >
                {t('First name')}
              </Input>
            </FormField>
            <FormField>
              <Input
                name="last_name"
                onChange={handleWitnessChange}
                value={witness['last_name']}
                error={errors['last_name']}
              >
                {t('Last name')}
              </Input>
            </FormField>
            <FormField>
              <Input
                type="email"
                name="email"
                onChange={handleWitnessChange}
                value={witness['email']}
                error={errors['email']}
              >
                {t('Email')}
              </Input>
            </FormField>
            <FormField>
              <PhoneInput
                name="telephonenr"
                handleChange={handleWitnessChange}
                symbol="IconActionPhone"
                symbolSide="right"
                value={witness['telephonenr']}
                error={errors['telephonenr']}
              >
                {t('Phone')}
              </PhoneInput>
            </FormField>
          </StyledContent>
        </Section>
        <Section>
          <Section.Title>
            {t('Address')} ({t('optional')})
          </Section.Title>
          <StyledContent>
            <Address
              handleEntityChange={setWitness}
              entity={witness}
              errors={errors}
            />
          </StyledContent>
        </Section>
        <Section>
          <Section.Title>{t('Description')}</Section.Title>
          <Desc>
            {t(
              'Fill in all the relevant details. What did the flight crew tell you? Did the pilot make an announcement? The more information provided, the more effectively we can investigate your claim.',
            )}
          </Desc>
          <StyledTextArea
            name="comment"
            onChange={handleWitnessChange}
            error={errors.comment}
            value={witness.comment}
            placeholder={t('Type here to add extra information')}
          />
        </Section>
        <StyledButton data-test-id="continueButton">
          {t('Continue')}
        </StyledButton>
      </form>
    );
  },
);

const Desc = styled(Label)`
  margin: 1.1rem 0;
  color: ${({ theme }) => theme.typo.highlight};
`;

const FormField = styled.div`
  display: inline-block;
  width: 44%;
  margin: 13px 0;
  box-sizing: border-box;
  label {
    color: ${({ theme }) => theme.typo.subTitle};
  }
`;

const Input = styled(TextInput)`
  font-family: Lato;
  font-size: 1.6rem;
  line-height: 2.8rem;

  input {
    border-radius: 3px;
    height: 3.5rem;
  }
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 15px;
`;

const StyledTextArea = styled(TextArea)`
  margin: 13px 0;
  width: 100%;
  textarea {
    min-height: 140px;
    max-height: 240px;
    box-sizing: border-box;
  }
`;

const StyledContent = styled(Section.Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px 57px 34px 37px;
`;

export default WitnessInfo;
