import { useState } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

const defaultOptions = {
  mode: 'replace', // replace or push
};

const useQueryParams = passedOptions => {
  const [options, setOptions] = useState(
    Object.assign({}, defaultOptions, passedOptions),
  );

  const { search } = useLocation() || {};
  const { push, replace } = useHistory();

  const hasParam = key => {
    const queryObject = queryString.parse(search);
    return key in queryObject;
  };

  const getParam = key => {
    const queryObject = queryString.parse(search);
    return queryObject[key];
  };

  const addParam = params => {
    const queryObject = queryString.parse(search);
    const addedParams = Object.assign(params, queryObject);
    const stringParams = queryString.stringify(addedParams);
    changeLocation({ search: stringParams });
  };

  const removeParams = keys => {
    const queryObject = queryString.parse(search);
    keys.forEach(key => {
      if (key in queryObject) {
        delete queryObject[key];
      }
    });
    const stringParams = queryString.stringify(queryObject);
    changeLocation({ search: stringParams });
  };

  const clearParams = () => {
    changeLocation({ search: '' });
  };

  const changeLocation = historyOptions => {
    switch (options.mode) {
      case 'replace':
        replace(historyOptions);
        break;
      case 'push':
        push(historyOptions);
        break;
      default:
        break;
    }
  };

  return {
    hasParam,
    getParam,
    addParam,
    removeParams,
    clearParams,
    setOptions,
  };
};

export default useQueryParams;
