import React from 'react';
import styled from 'styled-components';
import { ActionButton } from 'wg-fe-ui';

const CarCardExtrasButton = ({ className, name, onClick, value, children }) => {
  const handleClick = () => {
    if (onClick) onClick({ name, value: !value });
  };

  return (
    <StyledCarCardExtrasButton
      className={className}
      name={name}
      onClick={handleClick}
      value={value}
    >
      {children}
    </StyledCarCardExtrasButton>
  );
};

const StyledCarCardExtrasButton = styled(ActionButton)`
  border-radius: 3rem;
  min-width: 0;
  width: auto;
  font-size: 1.5rem;
  padding: 1rem;
  margin-right: 1rem;
  background-color: ${({ value, theme }) =>
    value ? theme.brand.primary : theme.ui.disabled};
  svg {
    fill: white;
    margin-right: 0.7rem;
  }
`;

export default CarCardExtrasButton;
