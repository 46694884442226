import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { ActionButton, Uploader, TextInput } from 'wg-fe-ui';
import styled from 'styled-components';
import PlusIcon from './../../../icons/Plus';
import { deleteFile, uploadFile } from './../../../helpers/apiRouterService';
import useNewClaimData from './../../../hooks/useNewClaimData';
import { useTranslation } from 'react-i18next';
import { supportedFiles } from '../../../constants';


const TravelDocs = forwardRef(({ handleChange, handleSection, handleFormData }, ref) => {
  const refBookings = useRef();
  const refBoardings = useRef();
  const refReceipts = useRef();
  const [bookings, setBookings] = useState([]);
  const [boardings, setBoardings] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [expense, setExpense] = useState('');

  const { getClaimData } = useNewClaimData();
  const data = getClaimData();
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    click: () => handleFormData()
  }));

  const onExpenseChange = ({ value }) => {
    const val = value.replace(/[^\d]/g, '');
    setExpense(val);
  }
  const callFile = async (name, f) => {
    const [resp, status] = await uploadFile(data._claim_id, {
      title: f.title,
      file: f.data.split(',')[1],
      filename: f.name.toLowerCase(),
      category: name,
      description: 'this is cool file',
    });
    if (name === 'BOOKING')
      setBookings(b => [
        ...b,
        { id: resp.id, name: f.name, data: f.data, size: f.size },
      ]);
    else if (name === 'BOARDING_PASS')
      setBoardings(b => [
        ...b,
        { id: resp.id, name: f.name, data: f.data, size: f.size },
      ]);
    else if (name === 'RECEIPT')
      setReceipts(r => [
        ...r,
        { id: resp.id, name: f.name, data: f.data, size: f.size },
      ]);
    return status;
  };

  const removeFile = async (id, cat) => {
    const [, status] = await deleteFile(id);
    if (status !== 200) return;
    if (cat === 'BOOKING') setBookings(book => book.filter(b => b.id !== id));
    if (cat === 'BOARDING')
      setBoardings(board => board.filter(b => b.id !== id));
    if (cat === 'RECEIPT')
      setReceipts(receipt => receipt.filter(r => r.id !== id));
  };

  const onClick = async ({ name, value }) => {
    if (value.dispatchConfig) return;
    let statuses = [];
    try {
      statuses = await Promise.all(
        [...value].map(async (file, i) => {
          return await callFile(name, file);
        }),
      );
      if (statuses.find(s => s !== 200)) return;
      handleChange({ name: name, value: [...value].map(val => val.name) });
    } catch (error) {
      console.error('error: ', error);
    }
  };

  useEffect(() => {
    handleSection({ section: 2, subsection: 0 });
  }, []);

  return (
    <Container>
      <StyledH2>{t('Extra travel documentation')}</StyledH2>
      <p>
        {t(`We would like to ask you to upload as many travel documents as possible. However, this is not a required step and you can continue without uploading further documents.`)}
      </p>
      <StyledH2>{t('Booking confirmation')}</StyledH2>
      <StyledUploader
        icon={<PlusIcon />}
        name="BOOKING"
        onClick={onClick}
        ref={refBookings}
        supported={supportedFiles}
        errorText={t('File type not allowed!')}
      >
        {({ loaded }) =>
          bookings.map((b, i) => (
            <BigFileBox
              key={b.id}
              loaded={loaded[i]}
              name={b.name}
              size={b.size}
              source={b.data}
              onClose={() => removeFile(b.id, 'BOOKING')}
            >
              <>
                <TextInput name="booking_no" placeholder="E.G. W84SEJ">
                  {t('Booking no')}.
                </TextInput>
              </>
            </BigFileBox>
          ))
        }
      </StyledUploader>
      <StyledH2>{t('Boarding passes')}</StyledH2>
      <StyledUploader
        icon={<PlusIcon />}
        multiple
        name="BOARDING_PASS"
        onClick={onClick}
        ref={refBoardings}
        supported={supportedFiles}
        errorText={t('File type not allowed!')}
      >
        {({ loaded }) =>
          boardings.map((b, i) => (
            <SmallFileBox
              key={b.id}
              loaded={loaded[i]}
              name={b.name}
              size={b.size}
              source={b.data}
              onClose={() => removeFile(b.id, 'BOARDING')}
            />
          ))
        }
      </StyledUploader>
      <StyledH2>{t('Receipts')}</StyledH2>
      <StyledUploader
        icon={<PlusIcon />}
        multiple
        name="RECEIPT"
        ref={refReceipts}
        onClick={onClick}
        supported={supportedFiles}
        errorText={t('File type not allowed!')}
      >
        {({ loaded }) =>
          receipts.map((r, i) => (
            <BigFileBox
              key={r.id}
              loaded={loaded[i]}
              name={r.name}
              size={r.size}
              source={r.data}
              onClose={() => removeFile(r.id, 'RECEIPT')}
            >
              <GroupEntries>
                <StyledInput name="expense_name" placeholder="E.G. Burger King">
                  {t('Expense name')}
                </StyledInput>
                <TextInput name="expense_cost" placeholder="€ 14,45" value={`${expense} €`} onChange={onExpenseChange} pattern="\d*">
                  {t('Expense cost')}
                </TextInput>
              </GroupEntries>
            </BigFileBox>
          ))
        }
      </StyledUploader>
      <StyledButton data-test-id="submit-travel" onClick={handleFormData}>{t('Continue')}</StyledButton>
    </Container>
  );
});

const StyledInput = styled(TextInput)`
  margin-right: 19px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  width: 140px;
  margin-top: 25px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

StyledButton.displayName = 'StyledButton';

const StyledH2 = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.typo.title};
  margin-top: 40px;
  margin-bottom: 13px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledUploader = styled(Uploader)`
  width: 100%;
  margin-left: 0;

  & > div {
    width: 100%;
  }

  & > label {
    background-color: ${({ theme }) => theme.ui.toolbar};
    margin-top: 18px;
    width: 100%;
    height: 95px;
  }
`;

StyledUploader.displayName = 'Uploader';

const BigFileBox = styled(Uploader.FileBox)`
  width: 100%;
  margin-top: 45px;

  svg {
    margin-left: 20px;
  }
`;

const SmallFileBox = styled(Uploader.FileBox)`
  width: 30%;
  margin-right: 3%;
  margin-top: 45px;
`;

const GroupEntries = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 10px;

  input:first-child {
    margin-right: 19px;
  }
`;

export default TravelDocs;
