import React, { useRef, useEffect, useState } from 'react';
import { mainStyles } from './../helpers/utils';
import * as d3 from 'd3';

const XAxis = ({ bottom, left, right, height, scale }) => {
  const axis = useRef();
  const hourFormat = hour => (hour < 13 ? `${hour} AM` : `${hour - 12} PM`);
  useEffect(() => {
    d3.select(axis.current)
      .call(
        d3
          .axisBottom(scale)
          .ticks(d3.timeHour.every(3))
          .tickFormat(val =>
            val <= d3.timeHour(val) ? hourFormat(val.getHours()) : null,
          ),
      )
      .call(g =>
        g
          .selectAll('text')
          .style('font-size', '1.47rem')
          .style('font-family', 'Lato'),
      )
      .attr('color', mainStyles.lightGray)
      .attr('font-weight', 900)
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('line').remove());
  });

  return (
    <g
      className="axis x"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};

const YAxis = ({ top, left, right, scale, width, height }) => {
  const axis = useRef();

  useEffect(() => {
    d3.select(axis.current)
      .call(
        d3
          .axisRight(scale)
          .tickSize(width - right)
          .tickValues(d3.range(0, 90, 15)),
      )
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke-opacity', 0.5)
          .attr('stroke-dasharray', '2,2'),
      )
      .call(g => g.selectAll('text').style('font-size', '1.3rem'))
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('x', -1)
          .attr('dy', -4),
      )
      .attr('color', mainStyles.lightGray);
  });

  return (
    <>
      <text
        x={0 - height / 2}
        y={0}
        dy="1em"
        fill={mainStyles.lightGray}
        fontFamily="lato"
        fontSize="9"
        transform="rotate(-90)"
      >
        mm/h
      </text>
      <g
        className="axis y"
        ref={axis}
        transform={`translate(${left - 30}, ${top})`}
      />
    </>
  );
};

const Line = ({ data, x, y }) => {
  const line = useRef();
  useEffect(() => {
    d3.select(line.current)
      .datum(data)
      .attr(
        'd',
        d3
          .line()
          .x(d => x(d.date))
          .y(d => y(d.value)),
      );
  });

  return <path stroke="#FF9B21" strokeWidth={1.5} ref={line} />;
};

const Area = ({ data, height, x, y }) => {
  const area = useRef();
  useEffect(() => {
    d3.select(area.current)
      .datum(data)
      .attr(
        'd',
        d3
          .area()
          .x(d => x(d.date))
          .y0(height - 50)
          .y1(d => y(d.value)),
      );
  });

  return <path fill="rgba(255, 128, 0, 0.1)" strokeWidth={1.5} ref={area} />;
};

const ChartPrecipitation = props => {
  const generateData = (value, length = 23) =>
    d3.range(length).map((_, index) => ({
      index: index,
      date: new Date(2019, 10, 31, index + 1),
      value: !value ? Math.floor(Math.random() * 60) + 1 : value,
    }));

  // Data will be changed to prop
  const [data, setData] = useState(generateData);

  useEffect(() => {
    setData(generateData());
  }, []);

  const highestVal = Math.max(...data.map(d => d.value), 0);

  const x = d3
    .scaleTime()
    .range([0, props.width - props.left - props.right - 20])
    .domain(d3.extent(data, d => d.date));
  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 0])
    .domain([0, d3.max(data, d => d.value)]);

  return (
    <>
      <svg width={props.width} height={props.height}>
        <XAxis
          scale={x}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          height={props.height}
        />
        <YAxis
          scale={y}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          width={props.width}
          height={props.height}
        />
        <g
          transform={`translate(${props.left}, ${props.top})`}
          fill="transparent"
        >
          <Line
            data={data}
            x={x}
            y={y}
            top={props.top}
            bottom={props.bottom}
            height={props.height}
            highestVal={highestVal}
          />
          <Area
            data={data}
            x={x}
            y={y}
            top={props.top}
            bottom={props.bottom}
            height={props.height}
          />
        </g>
      </svg>
    </>
  );
};

export default ChartPrecipitation;
