import React from 'react';
import { string } from 'prop-types';

const Envelope = ({ className, width = 35, height = 23, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 22.4076C0 15.5864 0 8.82212 0 1.65991C3.97708 5.46839 7.67798 8.99265 11.3789 12.5737C11.9312 13.0853 12.4836 13.6538 13.036 14.1653C16.0188 17.0075 18.0626 17.0075 21.1006 14.1085C25.3539 10.0727 29.5519 5.97997 34.0262 1.65991C34.0262 8.70843 34.0262 15.4727 34.0262 22.4076C22.8682 22.4076 11.4894 22.4076 0 22.4076Z"
        fill={fill}
      />
      <path
        d="M1.6593 0C12.0626 0 21.9625 0 32.3658 0C29.9048 2.39358 27.7235 4.49525 25.5422 6.65531C23.3049 8.81537 21.0676 11.0338 18.8303 13.1939C17.7676 14.2447 16.5931 14.4782 15.4744 13.4274C10.9999 9.10727 6.52536 4.72877 1.6593 0Z"
        fill={fill}
      />
    </svg>
  );
};

Envelope.defaultProps = {
  fill: '#FF8000'
};

Envelope.propTypes = {
  className: string,
  fill: string
};

export default Envelope;
