import React from 'react';
import { string } from 'prop-types';

const Storm = ({ className, width = 30, height = 36 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="15" cy="15" r="15" fill="#FF8000" />
      <g filter="url(#filter0_d)">
        <path
          d="M19.6617 13.2876C19.6165 13.1886 19.5191 13.125 19.4119 13.125H16.1444L19.3691 7.93173C19.4229 7.84511 19.4262 7.73543 19.3777 7.64571C19.3292 7.5557 19.2365 7.5 19.1361 7.5H14.7244C14.6199 7.5 14.5245 7.56018 14.4776 7.65553L10.3416 16.0931C10.2988 16.18 10.3035 16.2835 10.3537 16.3664C10.4042 16.4494 10.4927 16.5 10.5883 16.5H13.4237L10.3341 23.9846C10.2814 24.1126 10.3283 24.2611 10.4444 24.3337C10.4891 24.3615 10.5387 24.375 10.5881 24.375C10.6672 24.375 10.7452 24.3404 10.7993 24.2752L19.6228 13.5876C19.692 13.5039 19.7069 13.3868 19.6617 13.2876Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="1.4803"
          y="1.19129"
          width="27.0394"
          height="34.5394"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2.52349" />
          <feGaussianBlur stdDeviation="4.4161" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

Storm.propTypes = {
  className: string,
};

export default Storm;
