import React, { forwardRef, useState } from 'react';
import { Section, ActionButton } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import { Input, StyledLoader } from '../../../styledComponents';
import styled from 'styled-components';
import CarCard from '../../../components/CarCard';
import CarCardInputs from '../../../components/CarCardInputs';
import { getDataAboutPlateNr } from '../../../helpers/apiRouterService';
import CarSearch from '../../../components/CarSearch';
import useQueryParams from '../../../hooks/useQueryParams';
import { isObjectEmpty } from '../../../helpers/objectService';
import { getMeData } from '../../../helpers/meService';

const carIdentifySteps = { 0: 'finding_by_brand', 1: 'filling_data' };

const CarLookup = forwardRef(({ validateForms }, ref) => {
  const { t } = useTranslation();
  const [plateNumber, setPlateNumber] = useState('');
  const [carData, setCarData] = useState({});
  const [loading, setLoading] = useState(false);
  const [carIdentifyStep, setCarIdentifyStep] = useState();
  const { addParam } = useQueryParams();
  const [userRole, setUserRole] = useState('');

  const searchPlateInfo = async e => {
    if (e) e.preventDefault();
    setLoading(true);
    const [res, status] = await getDataAboutPlateNr(plateNumber);
    if (status === 200) setCarData(res);
    setLoading(false);
  };

  const getUserRole = async () => {
    const meData = await getMeData();
    meData && meData.me && setUserRole(meData.me.role);
  };

  React.useEffect(() => {
    addParam({ disabled: true });
    if (!userRole) getUserRole();
  }, []);

  const handleFormData = e => {
    if (e) e.preventDefault();
    // TODO: validating, send data, etc.
    addParam({ submit: true });
  };

  return (
    <>
      {!carIdentifyStep && userRole.startsWith('broker') && (
        <form onSubmit={searchPlateInfo}>
          <StyledSection>
            <Section.Title>{t('Car lookup')}</Section.Title>
            <Section.Content background>
              <Input
                name="platenumber"
                value={plateNumber}
                onChange={({ value }) => setPlateNumber(value)}
              >
                {t('Numberplate')}
              </Input>
            </Section.Content>
          </StyledSection>
          <DontKnowPlate>
            <span onClick={() => setCarIdentifyStep(carIdentifySteps[0])}>
              {t("I don't know my plate")}
            </span>
          </DontKnowPlate>
          <StyledButton name="submit" className="search">
            {t('Search')}
          </StyledButton>
        </form>
      )}
      {loading && <StyledLoader />}
      {!isObjectEmpty(carData) && !loading && !carIdentifyStep && (
        <CarCard
          data={carData}
          setCarData={setCarData}
          handleFormData={handleFormData}
          validateForms={validateForms}
        />
      )}
      {(carIdentifyStep === carIdentifySteps[0] ||
        userRole === 'social_login') && (
          <CarSearch
            cannotFindClick={() => setCarIdentifyStep(carIdentifySteps[1])}
            handleFormData={handleFormData}
          />
        )}
      {carIdentifyStep === carIdentifySteps[1] && (
        <CarCardInputs
          handleFormData={handleFormData}
          validateForms={validateForms}
        />
      )}
    </>
  );
});

const StyledSection = styled(Section)`
  ${Section.Content} {
    padding: 1rem 1.5vw;
    border: none;
  }
  margin-bottom: 2rem;
`;

const StyledButton = styled(ActionButton)`
  margin: 2rem 0;

  &.search {
    margin: 0 0 3rem;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
StyledButton.displayName = 'ActionButton';

const DontKnowPlate = styled.div`
  text-align: end;
  width: 100%;
  max-width: 100rem;
  span {
    cursor: pointer;
    line-height: 2.1rem;
    color: #050505;
  }

  @media (max-width: 600px) {
    margin-bottom: 2rem;
  }
`;

export default CarLookup;
