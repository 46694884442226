import React from 'react';
import { string } from 'prop-types';

const Terrainwagen = ({ className, width = 30, height = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.2567 3.37359L17.1355 0.55146C17.0248 0.330116 16.8219 0.182553 16.5637 0.164108C14.1842 0.108772 2.08408 -0.186353 1.95496 0.182553C1.38315 1.73196 0.294881 5.71615 -0.000244141 7.30245L0.682233 10.4013C0.81135 10.844 1.21715 11.1391 1.65983 11.1391H28.1104C28.5716 11.1391 28.9774 10.844 29.088 10.4013L29.7336 8.64896V6.84132H29.1618L28.4424 4.7201C27.9813 4.42498 19.2567 3.37359 19.2567 3.37359Z"
      />
      <path
        d="M6.99976 13.9963C8.65661 13.9963 9.99976 12.6532 9.99976 10.9963C9.99976 9.33948 8.65661 7.99634 6.99976 7.99634C5.3429 7.99634 3.99976 9.33948 3.99976 10.9963C3.99976 12.6532 5.3429 13.9963 6.99976 13.9963Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.9998 13.9963C25.6566 13.9963 26.9998 12.6532 26.9998 10.9963C26.9998 9.33948 25.6566 7.99634 23.9998 7.99634C22.3429 7.99634 20.9998 9.33948 20.9998 10.9963C20.9998 12.6532 22.3429 13.9963 23.9998 13.9963Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

Terrainwagen.propTypes = {
  className: string,
};

export default Terrainwagen;
