import React, { useState } from 'react';
import { ActionButton, Section, PhoneInput } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Address from './Address';
import { Input, FormField } from '../styledComponents';
import useQueryParams from '../hooks/useQueryParams';
import { updateClaim } from '../helpers/apiRouterService';
import { mapValues } from 'lodash';

const RepairerInfo = ({ validateForms, setValues, claimId }) => {
  const [repairer, setRepairer] = useState({ country: 'Belgium' });
  const [errors, setErrors] = useState({});
  const { addParam } = useQueryParams();
  const { t } = useTranslation();
  const handleRepairerChange = ({ name, value }) => {
    setRepairer(data => ({ ...data, [name]: value }));
  };

  const mapRepairer = oldRepairer => {
    const {
      name,
      street,
      zipcode,
      housenr,
      city,
      telephonenr,
      email,
    } = oldRepairer;
    return {
      name,
      telephonenr,
      email,
      iban: oldRepairer.iban || null,
      address: {
        street,
        zipcode,
        housenr,
        city,
        country_code: 'BE',
      },
      is_authorized: false,
    };
  };

  const handleFormData = async e => {
    if (e) e.preventDefault();
    const [validationErrors, hasErrors] = await validateForms(
      repairer,
      'repairer',
    );
    setErrors(() => mapValues(validationErrors, (e) => t(e)));;
    if (hasErrors) return;
    const mappedRepairer = mapRepairer(repairer);
    setValues(oldValues => ({
      ...oldValues,
      repairers: oldValues.repairers
        ? [...oldValues.repairers, mappedRepairer]
        : [mappedRepairer],
    }));

    const [, status] = await updateClaim(claimId, {
      claim: { repairer: mappedRepairer },
    });

    status < 300 && addParam({ submit: true });
  };
  return (
    <>
      <StyledSection>
        <Section.Title>{t('Repairer information')}</Section.Title>
        <Section.Content background>
          <FormField>
            <Input
              name="name"
              error={errors.name}
              onChange={handleRepairerChange}
              value={repairer.name}
            >
              {t('Name')}
            </Input>
          </FormField>
          <Address
            handleEntityChange={setRepairer}
            entity={repairer}
            errors={errors}
          />
          <FormField>
            <PhoneInput
              name="telephonenr"
              error={errors.telephonenr}
              handleChange={handleRepairerChange}
              value={repairer.telephonenr}
              symbol="IconActionPhone"
              symbolSide="right"
            >
              {t('Phone')}
            </PhoneInput>
          </FormField>
          <FormField>
            <Input
              name="email"
              error={errors.email}
              onChange={handleRepairerChange}
              value={repairer.email}
            >
              {t('Email')}
            </Input>
          </FormField>
          <FormField>
            <Input
              name="iban"
              error={errors.iban}
              onChange={handleRepairerChange}
              value={repairer.iban}
            >
              {t('IBAN')} ({t('optional')})
            </Input>
          </FormField>
        </Section.Content>
      </StyledSection>
      <StyledBtn onClick={handleFormData}>{t('Continue')}</StyledBtn>
      <StyledText>
        <p>
          <span>{t(`What are the advantages of choosing for a certified repairer`)}</span>
        </p>
        <p>
          {t(`Advantages certified repairer`)}
        </p>
      </StyledText>
    </>
  );
};

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`

const StyledSection = styled(Section)`
  ${Section.Content} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > * {
      flex-basis: 50%;
      max-width: 400px;
      margin-bottom: 1rem;
    }
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const StyledText = styled.div`
  margin-top: 3.5rem;

  font-size: 1.4rem;
  line-height: 1.8rem;

  letter-spacing: 0.92px;

  color: #696969;

  span {
    font-weight: 900;
  }
  p {
    margin-bottom: 1.4rem;
  }
`;

RepairerInfo.displayName = 'RepairerInfo';

export default RepairerInfo;
