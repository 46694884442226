import React from 'react';
import { CarStyledTextInput } from '../styledComponents';
import styled from 'styled-components';

const SuffixInput = ({
  className,
  suffix,
  children,
  error,
  name,
  value,
  onChange,
}) => (
  <div className={className}>
    <CarStyledTextInput value={value} onChange={onChange} name={name} error={error}>
      {children}
    </CarStyledTextInput>
    <span className="suffix">{suffix}</span>
  </div>
);

const StyledSuffixInput = styled(SuffixInput)`
  position: relative;
  .suffix {
    position: absolute;
    font-weight: 900;
    color: #555555;
    top: 56%;
    right: 15%;
    font-size: 1.4rem;
  }
  .title {
    color: #a29c95;
  }
`;

StyledSuffixInput.displayName = 'SuffixInput';

export default StyledSuffixInput;
