import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  ActionButton,
  DateInput,
  Section,
  MaskedInput,
  Uploader,
  PhoneInput,
} from 'wg-fe-ui';
import styled from 'styled-components';
import {
  addOrEditEntity,
  uploadFileByEntity,
} from './../../../helpers/apiRouterService';
import useQueryParams from '../../../hooks/useQueryParams';
import { getPassenger } from '../../../helpers';
import useNewClaimData from '../../../hooks/useNewClaimData.js';
import PlusIcon from '../../../icons/Plus';
import cogoToast from 'cogo-toast';
import { Input, FormField } from '../../../styledComponents';
import Address from '../../../components/Address';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';
import { parseDateStringToObject } from '../../../helpers/dateService';
import { supportedFiles } from '../../../constants';

const PassengerContact = forwardRef(
  ({ setValues, handleSection, validateForms, values }, ref) => {
    const { addParam } = useQueryParams();
    const { getClaimData } = useNewClaimData();
    const [passenger, setPassenger] = useState(getPassenger(getClaimData));
    const [errors, setErrors] = useState({});
    const refDate = useRef();
    const refPassport = useRef();

    const { t } = useTranslation();

    const data = getClaimData();

    const handlePassengerChange = ({ name, value }) => {
      setPassenger((data) => ({ ...data, [name]: value }));
    };

    const uploadPassport = async (id) => {
      const f = passenger['docs'][0];
      const [, status] = await uploadFileByEntity(data._claim_id, id, {
        filename: f.name.toLowerCase(),
        file: f.data.split(',')[1],
        category: 'IDENTITY_CARD',
        description: 'this is Passport ID file',
        title: f.title,
      });
      return status;
    };

    const modifyPassenger = async () => {
      const method = passenger['id'] ? 'PUT' : 'POST';
      const resource = passenger['id'] ? `/${passenger['id']}` : '';
      const nationality = passenger.country.toUpperCase().slice(0, 2);
      const [resp, status] = await addOrEditEntity(
        data._claim_id,
        resource,
        method,
        {
          type: 'PASSENGER',
          person_type: 'NATURAL',
          first_name: passenger.first_name,
          last_name: passenger.last_name,
          telephonenr: passenger.telephonenr,
          email: passenger.email,
          birth: parseDateStringToObject(passenger.birth),
          // national_register_nr: passenger.national_register_nr,
          identification: {
            nr: passenger.identity_nr,
          },
          nationality,
          correspondence_address: {
            street: passenger.street,
            zipcode: passenger.zipcode,
            housenr: passenger.housenr,
            boxnr: passenger.boxnr,
            city: passenger.city,
            country_code: nationality,
          },
          //insured_at: 'ag',
          // policy_nr: 123456,
          // nr_plate: 123456
        },
      );
      return [status, resp];
    };

    const handleFormData = async (e) => {
      if (e) e.preventDefault();
      const [validationErrors, hasErrors] = await validateForms({...passenger, birth: parseDateStringToObject(passenger.birth)}, 'passenger');
      if (hasErrors)
        return setErrors(() => mapValues(validationErrors, (e) => t(e)));
      const [status, resp] = await modifyPassenger();
      if (!/20(0|1)/.test(status)) return;
      if (passenger['docs']) {
        const status = await uploadPassport(passenger['id'] || resp.id);
        if (status !== 201) return;
      }
      if (!values.passengers)
        setValues((oldValues) => ({ ...oldValues, passengers: [] }));

      setValues((oldValues) => ({
        ...oldValues,
        passengers: [...oldValues.passengers, passenger],
      }));
      addParam({ submit: true });
    };

    useImperativeHandle(ref, () => ({
      click: () => handleFormData(),
    }));

    useEffect(() => {
      handleSection({ section: 1, subsection: 0 });
    }, []);

    useEffect(() => {
      if (passenger['docs'] && passenger['docs'].length) {
        const { hide } = cogoToast.success(
          `${t('File')}: ${passenger['docs'][0].name} ${t('uploaded')} ${t(
            'successfully',
          )}`,
          {
            hideAfter: 2,
            onClick: () => hide(),
          },
        );
      }
    }, [passenger['docs']]);

    const handleFileUpload = ({ name, value }) => {
      handlePassengerChange({ name, value: Array.from(value) });
    };

    return (
      <form onSubmit={handleFormData}>
        <input type="hidden" name="id" value={passenger['id']} />
        <Section>
          <Section.Title>{t('Contact information')}</Section.Title>
          <StyledContent>
            <FormField>
              <Input
                name="first_name"
                onChange={handlePassengerChange}
                value={passenger['first_name']}
                error={errors['first_name']}
              >
                {t('First name')}
              </Input>
            </FormField>
            <FormField>
              <Input
                name="last_name"
                onChange={handlePassengerChange}
                value={passenger['last_name']}
                error={errors['last_name']}
              >
                {t('Last name')}
              </Input>
            </FormField>
            <FormField>
              <Input
                type="email"
                name="email"
                onChange={handlePassengerChange}
                value={passenger['email']}
                error={errors['email']}
              >
                {t('Email')}
              </Input>
            </FormField>
            <FormField>
              <PhoneInput
                name="telephonenr"
                handleChange={handlePassengerChange}
                symbol="IconActionPhone"
                symbolSide="right"
                value={passenger['telephonenr']}
                error={errors['telephonenr']}
              >
                {t('Phone number')}
              </PhoneInput>
            </FormField>
            <FormField>
              <DateField
                ref={refDate}
                name="birth"
                onChange={handlePassengerChange}
                value={passenger.birth}
                error={errors['birth'] && Object.values(errors['birth']).length
                  ? t('Invalid date')
                  : undefined}
              >
                {t('Date of birth')}
              </DateField>
            </FormField>
            <FormField>
              <MaskedInput
                mask="999-9999999-99"
                name="identity_nr"
                onChange={handlePassengerChange}
                value={passenger['identity_nr']}
                error={errors['identity_nr']}
              >
                {t('Identity card number')}
              </MaskedInput>
            </FormField>
          </StyledContent>
        </Section>
        <Section>
          <Section.Title>{t('Address')}</Section.Title>
          <StyledContent>
            <Address
              handleEntityChange={setPassenger}
              entity={passenger}
              errors={errors}
            />
          </StyledContent>
        </Section>
        <Section>
          <Section.Title>{t('Passport / ID - card')}</Section.Title>
          <StyledUploader
            ref={refPassport}
            name="docs"
            icon={<PlusIcon />}
            onClick={handleFileUpload}
            supported={supportedFiles}
            errorText={t('File type not allowed!')}
          >
            {() => null}
          </StyledUploader>
        </Section>
        <StyledButton data-test-id="submit-add-passenger">
          {t('Continue')}
        </StyledButton>
      </form>
    );
  },
);

const DateField = styled(DateInput)`
  font-family: Lato;
  font-size: 1.6rem;
  line-height: 2.8rem;

  input {
    line-height: 36px;
  }

  @media (max-width: 600px) {
    width: 100%;

    input {
      width: 25%;
    }
  }
`;

DateField.displayName = 'DateField';

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 6px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledUploader = styled(Uploader)`
  width: 100%;
  margin-left: 0;
  & > label {
    background-color: ${({ withFile, theme }) =>
      withFile ? theme.brand.lightest : theme.ui.toolbar};
    margin-top: 27px;
    width: 100%;
    height: 95px;
    border: 1px dashed ${({ theme }) => theme.typo.outline};
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

StyledUploader.displayName = 'Uploader';

const StyledContent = styled(Section.Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px 57px 34px 37px;
`;

export default PassengerContact;
