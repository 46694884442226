import React from 'react';
import { string } from 'prop-types';

const Automotive = ({ className, width = 90, height = 32, fill }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.211 13.4848C83.5151 7.28119 59.5668 7.75839 59.5668 7.75839L48.3007 0.345926C23.8175 -2.16731 6.41485 9.69899 1.19092 13.8347C0.183898 14.63 -0.225205 15.9344 0.120959 17.2069L1.56855 22.3288C1.94619 23.6968 3.1735 24.6194 4.55816 24.6194H11.3556C12.2367 28.8187 15.9186 32 20.3244 32C24.7301 32 28.4435 28.8187 29.2932 24.6194H62.9026C63.7837 28.8187 67.4656 32 71.8714 32C76.2771 32 79.9905 28.8187 80.8402 24.6194H85.2144C86.5991 24.6194 87.8264 23.6968 88.204 22.3288L89.8404 16.507C90.1866 15.4572 89.9663 14.2801 89.211 13.4848ZM20.3244 28.8187C17.6495 28.8187 15.3837 27.069 14.5969 24.6194C14.4081 24.0149 14.2822 23.3786 14.2822 22.7106C14.2822 19.3384 16.9886 16.6343 20.2929 16.6343C23.6287 16.6343 26.3036 19.3702 26.3036 22.7106C26.3036 23.3786 26.2092 24.0149 25.9889 24.6194C25.2651 27.0372 22.9993 28.8187 20.3244 28.8187ZM71.8714 28.8187C69.1965 28.8187 66.9307 27.069 66.1439 24.6194C65.9551 24.0149 65.8292 23.3786 65.8292 22.7106C65.8292 19.3384 68.5356 16.6343 71.8399 16.6343C75.1757 16.6343 77.8506 19.3702 77.8506 22.7106C77.8506 23.3786 77.7562 24.0149 77.5359 24.6194C76.8121 27.0372 74.5463 28.8187 71.8714 28.8187Z"
        fill={fill}
      />
    </svg>
  );
};

Automotive.propTypes = {
  className: string,
};

export default Automotive;
