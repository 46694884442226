import React from 'react';
import { string } from 'prop-types';

const Injured = ({ className, width = 24, height = 27 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6.76 12.3583L2.616 27H0.8C0.36 27 0 26.6528 0 26.2286V19.2857C0 15.5983 2.984 12.5743 6.76 12.3583Z" />
      <path d="M19.2001 26.2285V27H16.5841L15.4961 23.1428H16.0001C17.7681 23.1428 19.2001 24.5237 19.2001 26.2285Z" />
      <path d="M24.0004 19.2858V26.2286C24.0004 26.6529 23.6404 27 23.2004 27H21.6004V26.2286C21.6004 23.2509 19.0884 20.8286 16.0004 20.8286H14.8404L12.4404 12.3429H16.8004C20.7764 12.3429 24.0004 15.4518 24.0004 19.2858Z" />
      <path d="M11.6642 2.39143L8.03223 1.58914C9.04823 0.609429 10.4482 0 12.0002 0C12.9362 0 13.8082 0.216 14.5842 0.609429L11.6642 2.39143Z" />
      <path d="M17.2878 3.61796L14.9678 3.11653L16.4958 2.18311C16.8318 2.61511 17.1038 3.09339 17.2878 3.61796Z" />
      <path d="M17.5524 6.048C17.2324 8.72485 14.8724 10.8 12.0004 10.8C8.90439 10.8 6.40039 8.38543 6.40039 5.4C6.40039 4.79057 6.50439 4.212 6.69639 3.672L17.5524 6.048Z" />
    </svg>
  );
};

Injured.propTypes = {
  className: string,
};

export default Injured;
