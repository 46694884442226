import { TextArea } from 'wg-fe-ui';
import styled from 'styled-components';

export default styled(TextArea)`
  textarea {
    resize: none;
    padding: 13px 1vw;
    box-sizing: border-box;
    height: 18.5vh;
    border-color: ${({ theme }) => theme.ui.backgroundLight};
  }
`;
