import React from 'react';
import { string } from 'prop-types';

const Approved = ({ className, width = 15, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path
        d="M8.46137 3.60954C8.46137 4.1313 8.64008 4.61325 8.94053 4.99575C8.55229 5.20398 8.2075 5.50656 7.94355 5.89595L7.94284 5.897L6.71274 7.71421C5.69018 6.85865 4.17171 6.82864 3.11032 7.71174L3.10914 7.71273C1.91535 8.70755 1.75593 10.4804 2.74808 11.6729L2.74915 11.6741L4.81174 14.1489L4.81203 14.1493C5.34687 14.7907 6.13813 15.161 6.97208 15.161C7.02848 15.161 7.07907 15.1588 7.0908 15.1583L7.09264 15.1583L7.10357 15.1578L7.11448 15.1573C7.99423 15.1124 8.80478 14.6582 9.30097 13.9247C9.30113 13.9244 9.3013 13.9242 9.30146 13.924C9.30176 13.9235 9.30206 13.9231 9.30235 13.9226L12.3152 9.47236V17.8216H2V2H8.46137V3.60954ZM4.28537 10.3938L4.28544 10.3937L4.28537 10.3938Z"
        fill="#5B5550"
        stroke="#5B5550"
        strokeWidth="4"
      />
      <path
        d="M12.3844 1.93064C12.4888 1.68502 12.6423 1.46024 12.8374 1.27437L13.041 1.47789C12.855 1.67307 12.6301 1.82639 12.3844 1.93064Z"
        fill="#5B5550"
        stroke="#5B5550"
        strokeWidth="4"
      />
    </svg>
  );
};

Approved.propTypes = {
  className: string,
};

export default Approved;
