import React, { useEffect, useRef } from 'react';
import {
  ActionButton,
  SearchSelectInput,
  DateInput,
  TextInput,
} from 'wg-fe-ui';
import styled from 'styled-components';
import Card from '../../../components/ClaimInformationCard';
import Form from '../../../components/FlightInfoForm';
import { Column } from '../../../styledComponents';
import TextArea from '../../../components/StyledTextArea';
import { useTranslation } from 'react-i18next';

const DetailsOccurrence = ({
  errors,
  handleChange,
  values,
  handleSection,
  handleFormData,
}) => {
  useEffect(() => {
    handleSection({ section: 2, subsection: 0 });
  }, []);

  const ref = useRef();
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <h2> We’re almost there. Please select what applies below </h2>
        <Card padding="30px 1.9vw">
          <Form>
            <StyledColumn>
              <TextInput
                error={errors.street}
                value={values.street}
                onChange={handleChange}
                name="street"
              >
                {t(`Street`)}
              </TextInput>
              <TextInput
                error={errors.city}
                value={values.city}
                onChange={handleChange}
                name="city"
              >
                {t(`City`)}
              </TextInput>
              <SearchSelectInput
                name="country"
                options={[{ label: 'Belgium', value: 'Belgium' }]}
                error={errors.country}
                onSelected={handleChange}
                value={
                  values.country
                    ? { label: values.country, value: values.country }
                    : null
                }
                placeholder={t('Choose your option')}
              >
                {t(`Country`)}
              </SearchSelectInput>
            </StyledColumn>
            <StyledColumn>
              <TextInput
                error={errors.housenr}
                value={values.housenr}
                onChange={handleChange}
                name="house_nr"
              >
                {t(`House number`)}
              </TextInput>
              <TextInput
                error={errors.zipcode}
                value={values.zipcode}
                onChange={handleChange}
                name="zipcode"
              >
                {t(`Zip-code`)}
              </TextInput>
              <DateInput
                ref={ref}
                error={errors.incidentDate}
                value={values.incidentDate}
                name="incidentDate"
                onChange={handleChange}
              >
                {t(`Occurrence date"`)}
              </DateInput>
            </StyledColumn>
          </Form>
        </Card>
      </Container>

      <Container style={{ width: '91%' }}>
        <h2> List of events </h2>
        <form>
          <StyledTextArea
            maxLength={100}
            error={errors.event_list}
            value={values.event_list}
            onChange={handleChange}
            name="event_list"
            placeholder="Type here to add extra information"
            counter={true}
          />
        </form>
      </Container>
      <ActionButton onClick={handleFormData}>{t(`Continue`)}</ActionButton>
    </>
  );
};

const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

const Container = styled.div`
  margin-bottom: 33px;
  width: 52vw;
  & > div {
    border: 1px solid ${({ theme }) => theme.ui.toolbar};
  }
  &:last-of-type {
    margin-bottom: 27px;
  }
  form {
    margin-top: 16px;
    display: flex;
    & > label {
      width: 100%;
      :first-child {
        margin-right: 1.25vw;
      }
    }
  }
`;

const StyledColumn = styled(Column)`
  & > div {
    margin-bottom: 20px;
  }
`;

export default DetailsOccurrence;
