import { authMe } from './apiRouterService';
import { isObjectEmpty } from './objectService';

let meData = {};

const fetchData = async () => {

  const [resp, status] = await authMe();
  if (status === 200) {
    meData = resp;
    return resp;
  }
  return null;
};

export const getMeData = async () => {
  if (isObjectEmpty(meData)) return await fetchData();

  return meData;
};
