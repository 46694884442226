import React from 'react';
import { string } from 'prop-types';

const Phone = ({ className, width = 27, height = 30, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path fill={fill} d="M18.3193 29.6905C17.635 29.5332 16.3424 29.5332 15.2779 28.9827C7.5983 25.2078 2.2758 19.2309 0.222838 10.5801C-0.68959 6.6479 1.28734 2.55843 4.55687 0.513698C5.01309 0.199123 5.69741 -0.115451 6.07759 0.0418363C8.58677 0.98556 9.34712 3.42351 10.4116 5.54689C11.0959 6.96247 10.4877 8.14213 9.42316 9.24314C6.45777 12.2316 6.38173 13.0967 8.89091 16.4783C9.7273 17.658 10.6397 18.759 11.7042 19.7814C14.4415 22.4553 15.2779 22.5339 18.0912 20.096C19.3838 18.9949 20.7525 18.5231 22.1972 19.5455C24.022 20.8824 26.151 21.9834 26.6072 24.5786C26.6833 24.9719 26.3791 25.5224 26.075 25.9156C24.2501 28.1176 21.893 29.6118 18.3193 29.6905Z" />
    </svg>
  );
};

Phone.defaultProps = {
  fill: '#C1C1C1'
};

Phone.propTypes = {
  className: string,
  fill: string
};

export default Phone;
