import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  ${({ hideShadow }) =>
    !hideShadow && 'box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1)'};
  min-width: 450px;
  width: 62.5vw;
  margin-bottom: ${({ margin }) => margin || '16px'};
  box-sizing: border-box;
  padding: ${({ padding }) => padding || '1.7vw 0 1.9vw 1.5vw'};

  @media (max-width: 1200px) {
    width: 80vw;
  }

  @media (max-width: 768px) {
    width: 90vw;
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;
function Card({ children, className, hideShadow, margin, padding }) {
  return (
    <StyledCard
      className={className}
      hideShadow={hideShadow}
      margin={margin}
      padding={padding}
    >
      {children}
    </StyledCard>
  );
}

export default Card;
