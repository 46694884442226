import React from 'react';
import { string } from 'prop-types';

const Other = ({ className, width = 21, height = 5, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="18.5" cy="2.5" r="2.5" />
      <circle cx="2.5" cy="2.5" r="2.5" />
      <circle cx="10.5" cy="2.5" r="2.5" />
    </svg>
  );
};

Other.propTypes = {
  className: string,
};

export default Other;
