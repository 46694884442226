import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { ActionButton, Section, Uploader } from 'wg-fe-ui';
import styled from 'styled-components';
import PlusIcon from '../../../icons/Plus';
import useQueryParams from '../../../hooks/useQueryParams';
import { parseDateStringToObject } from '../../../helpers/dateService';
import { uploadFileByEntity } from '../../../helpers/apiRouterService';
import {
  modifyEntity,
  getCurrentEntity,
} from '../../../helpers/entitiesService';
import { generateForm } from '../../../helpers/formsService';
import useNewClaimData from '../../../hooks/useNewClaimData.js';
import { useHistory, useParams } from 'react-router-dom';
import Address from '../../../components/Address';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';
import { supportedFiles } from '../../../constants';

const formsSchema = (birthError) => ({
  insuree_information: [
    { name: 'first_name', label: 'First name', type: 'input' },
    { name: 'last_name', label: 'Last name', type: 'input' },
    { name: 'email', label: 'Email', type: 'input' },
    { name: 'telephonenr', label: 'Phone', type: 'phone_input' },
    {
      name: 'birth',
      label: 'Date of birth',
      type: 'date',
      error:
        birthError && Object.values(birthError).length
          ? 'Invalid date'
          : undefined,
    },
    {
      name: 'identity_nr',
      label: 'Identity card number',
      type: 'masked_input',
      mask: '999-9999999-99',
    },
  ],
  insurance_information: [
    {
      name: 'iban',
      label: 'IBAN',
      type: 'input',
      placeholder: '_ _ _-_ _ _-_ _',
    },
    {
      name: 'license',
      label: 'License plate',
      type: 'input',
      placeholder: '_ _ _-_ _ _-_ _',
    },
    {
      name: 'comment',
      label: 'Comment',
      type: 'text_area',
      placeholder: 'Type here to add extra information',
    },
  ],
});

// TODO: refactoring, reduce lines of code
const InsureeInfo = forwardRef(
  (
    { setValues, handleSection, validateForms, claimId, claimData, prefix },
    ref,
  ) => {
    const [insuree, setInsuree] = useState({ country: 'Belgium' });
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const docsRef = useRef();

    const forms = formsSchema(errors.birth);

    const { getClaimData, storeClaimData } = useNewClaimData();
    const data = getClaimData();
    const { addParam } = useQueryParams();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const handleInsureeChange = ({ name, value }) => {
      setInsuree((data) => ({ ...data, [name]: value }));
    };

    const uploadPassport = async (id) => {
      const f = insuree['docs'][0];
      const [, status] = await uploadFileByEntity(data._claim_id, id, {
        filename: f.name.toLowerCase(),
        file: f.data.split(',')[1],
        category: 'IDENTITY_CARD',
        description: 'this is Passport ID file',
        title: f.title,
      });
      return status;
    };

    const handleFormData = async (e) => {
      if (e) e.preventDefault();
      const [validationErrors, hasErrors] = await validateForms(
        {
          ...insuree,
          birth: parseDateStringToObject(insuree.birth),
        },
        'insureeInfo',
      );
      if (hasErrors)
        return setErrors(() => mapValues(validationErrors, (e) => t(e)));
      const [status, resp] = await modifyEntity(insuree, claimId, 'INSUREE');
      if (!/20(0|1)/.test(status)) return;
      if (insuree['docs']) {
        const status = await uploadPassport(insuree['id'] || resp.id);
        if (status !== 201) return;
      }

      setValues((oldValues) => ({
        ...oldValues,
        insurees: oldValues.insuree
          ? [...oldValues.insurees, insuree]
          : [insuree],
      }));

      if (claimData.thirdParty === 'Know') {
        storeClaimData({ insurees: [insuree] });
        return history.push(`${prefix}/involved/${id}`);
      }
      addParam({ submit: true });
    };

    useEffect(() => {
      const currentEntity = getCurrentEntity(id);
      if (!Object.keys(currentEntity).length)
        setInsuree((old) => ({ ...old, ...claimData.currentResidence }));
      else
        setInsuree((prev) =>
          Object.keys(currentEntity).length ? currentEntity : prev,
        );
      handleSection({ section: 1, subsection: 0 });
    }, []);
    return (
      <form onSubmit={handleFormData}>
        <Section>
          <Section.Title>{t('Insuree information')}</Section.Title>
          <StyledContent>
            {generateForm(
              forms.insuree_information,
              handleInsureeChange,
              errors,
              insuree,
              t,
            )}
          </StyledContent>
        </Section>
        <Section style={{ marginBottom: '3.3rem' }}>
          <Section.Title>{t('Insuree address')}</Section.Title>
          <StyledContent>
            <Address
              handleEntityChange={setInsuree}
              entity={insuree}
              errors={errors}
            />
          </StyledContent>
        </Section>
        {data.case && data.case.category === 'Car' ? (
          <Section>
            <Section.Title>{t('Insurance information')}</Section.Title>
            <StyledContent>
              {generateForm(
                forms.insurance_information,
                handleInsureeChange,
                errors,
                insuree,
                t,
              )}
            </StyledContent>
          </Section>
        ) : null}
        <Section>
          <Section.Title>{t('Passport / ID - card')}</Section.Title>
          <StyledUploader
            ref={docsRef}
            name="docs"
            icon={<PlusIcon />}
            multiple={true}
            onClick={({ name, value }) =>
              handleInsureeChange({ name, value: Array.from(value) })
            }
            supported={supportedFiles}
            errorText={t('File type not allowed!')}
          >
            {() => null}
          </StyledUploader>
        </Section>
        <StyledButton data-test-id="continueButton">
          {t('Continue')}
        </StyledButton>
      </form>
    );
  },
);

const StyledUploader = styled(Uploader)`
  width: 100%;
  margin-left: 0;
  & > label {
    background-color: ${({ theme, withFile }) =>
      withFile ? theme.hover.primary : theme.hover.secondary};
    margin-top: 27px;
    width: 100%;
    height: 95px;
    border: 1px dashed ${({ theme }) => theme.ui.outline};
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 22px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledContent = styled(Section.Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px 57px 34px 37px;
`;

export default InsureeInfo;
