import {
  orange,
  lightBlue,
  oceanBlue,
  darkBlue,
  lightRed,
  darkRed,
  pinkPurple,
  darkGreen,
  lightGreen,
  oceanGreen,
  darkGrey,
} from 'wg-fe-ui/dist/themes';

export const getThemeObject = name => {
  switch (name) {
    case 'orange':
      return orange();
    case 'light_blue':
      return lightBlue();
    case 'ocean_blue':
      return oceanBlue();
    case 'dark_blue':
      return darkBlue();
    case 'light_red':
      return lightRed();
    case 'dark_red':
      return darkRed();
    case 'pink_purple':
      return pinkPurple();
    case 'dark_green':
      return darkGreen();
    case 'light_green':
      return lightGreen();
    case 'ocean_green':
      return oceanGreen();
    case 'dark_grey':
      return darkGrey();
    default:
      return orange();
  }
};