import React, { useRef, useEffect, useState } from 'react';
import { mainStyles } from './../helpers/utils';
import * as d3 from 'd3';

const XAxis = ({ bottom, left, height, scale }) => {
  const axis = useRef();
  const hourFormat = hour => (hour[0] === '0' ? hour.slice(1) : hour);
  useEffect(() => {
    d3.select(axis.current)
      .call(
        d3
          .axisBottom(scale)
          .ticks(d3.timeHour.every(3))
          .tickFormat(val => hourFormat(d3.timeFormat('%I %p')(val))),
      )
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('line').remove())
      .call(g =>
        g
          .selectAll('text')
          .style('font-size', '1.47rem')
          .style('font-family', 'Lato'),
      )
      .attr('color', mainStyles.lightGray)
      .attr('font-weight', 900);
  });

  return (
    <g
      className="axis x"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};

const YAxis = ({ top, left, right, scale, width, height }) => {
  const axis = useRef();

  useEffect(() => {
    d3.select(axis.current)
      .call(
        d3
          .axisRight(scale)
          .tickSize(width - right)
          .tickValues(d3.range(0, 90, 15)),
      )
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke-opacity', 0.5)
          .attr('stroke-dasharray', '2,2'),
      )
      .call(g =>
        g
          .selectAll('text')
          .style('font-size', '1.3rem')
          .style('font-family', 'Lato'),
      )
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('x', -1)
          .attr('dy', -4),
      )
      .attr('color', mainStyles.lightGray);
  });

  return (
    <>
      <text
        x={0 - height / 2}
        y={0}
        dy="1em"
        fill={mainStyles.lightGray}
        fontFamily="lato"
        fontSize="9"
        transform="rotate(-90)"
      >
        mph
      </text>
      <g
        className="axis y"
        ref={axis}
        transform={`translate(${left - 30}, ${top})`}
      />
    </>
  );
};

const Rect = ({ data, x, y, height, top, bottom, highestVal }) => {
  return (
    <g transform={`translate(${x(data.date)}, ${y(data.value)})`}>
      <rect
        width={8}
        height={height - bottom - top - y(data.value)}
        fill={data.value === highestVal ? '#FF8000' : 'rgba(255, 128, 0, 0.1)'}
        rx={5}
      />
    </g>
  );
};

const ChartWindspeed = props => {
  const generateData = (value, length = 23) =>
    d3.range(length).map((_, index) => ({
      index: index,
      date: new Date(2019, 10, 31, index + 1),
      value: !value ? Math.floor(Math.random() * 36) + 30 : value,
    }));

  // Data will be changed to prop
  const [data, setData] = useState(generateData);

  useEffect(() => {
    setData(generateData());
  }, []);

  const highestVal = Math.max(...data.map(d => d.value), 0);

  const x = d3
    .scaleTime()
    .range([0, props.width - props.left - props.right - 20])
    .domain(d3.extent(data, d => d.date));

  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 0])
    .domain([0, d3.max(data, d => d.value)]);

  return (
    <>
      <svg width={props.width} height={props.height}>
        <XAxis
          scale={x}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          height={props.height}
        />
        <YAxis
          scale={y}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          width={props.width}
          height={props.height}
        />
        <g transform={`translate(${props.left}, ${props.top})`}>
          {data.map((d, i) => (
            <Rect
              key={d.index}
              data={d}
              x={x}
              y={y}
              top={props.top}
              bottom={props.bottom}
              height={props.height}
              highestVal={highestVal}
            />
          ))}
        </g>
      </svg>
    </>
  );
};

export default ChartWindspeed;
