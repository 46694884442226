import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { ActionButton, SubTitle, Uploader } from 'wg-fe-ui';
import styled from 'styled-components';
import PlusIcon from '../../../icons/Plus';
import { uploadFile } from './../../../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';
import { supportedFiles } from '../../../constants';
import useQueryParams from '../../../hooks/useQueryParams';
import { modifyEntity } from '../../../helpers/entitiesService';
const AccidentPersonal = forwardRef(
  ({ handleSection, claimId, claim }, ref) => {
    const refDeclaration = useRef();
    const refMedical = useRef();
    const [declarations, setDeclarations] = useState([]);
    const [medicals, setMedicals] = useState([]);
    const { t } = useTranslation();
    const { addParam, removeParams, hasParam } = useQueryParams();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const uploadNewFile = async (name, f) => {
      const [, status] = await uploadFile(claimId, {
        title: f.title,
        file: f.data.split(',')[1],
        filename: f.name.toLowerCase(),
        category: name,
        description: '',
      });
      return status;
    };

    // TODO: remove
    const addInsuree = async () => {
      const insuree = {
        type: 'INSUREE',
        person_type: 'NATURAL',
        first_name: 'Test',
        last_name: 'Name',
        email: 'comp@any.com',
        telephonenr: '+3211111',
        birth: '11/11/2019',
        identification: { nr: '111-1111111-11' },
        street: 'Elsstraat',
        zipcode: 9000,
        housenr: '11',
        boxnr: null,
        city: 'Gent',
        country_code: 'BE',
        nationality: 'BE',
      };
      await modifyEntity(insuree, claimId, 'INSUREE');
    };

    const onClick = ({ name, value }) => {
      if (!value) return;
      if (name === 'DECLARATION_WORK_ACCIDENT')
        return setDeclarations(prev => [...prev, ...Array.from(value)]);
      setMedicals(prev => [...prev, ...Array.from(value)]);
    };

    const onClose = (name, data) => {
      if (name === 'DECLARATION_WORK_ACCIDENT')
        return setDeclarations(prev => prev.filter(f => f.data !== data));
      setMedicals(medicals.filter(f => f.data !== data));
    };

    const handleFormData = async e => {
      if (e) e.preventDefault();
      try {
        const statusesDecl = await Promise.all(
          declarations.map(
            async file =>
              await uploadNewFile('DECLARATION_WORK_ACCIDENT', file),
          ),
        );
        const statusesMed = await Promise.all(
          medicals.map(
            async file => await uploadNewFile('MEDICAL_CERTIFICATE', file),
          ),
        );
        if ([...statusesDecl, ...statusesMed].find(f => f !== 201)) return;
        claim.type === 'PERSONAL' && (await addInsuree());
      } catch (error) {
        console.error('error: ', error);
      }
      addParam({ submit: true });
    };

    useEffect(() => {
      handleSection({ section: 2, subsection: 0 });
    }, []);

    useEffect(() => {
      if (!declarations.length || !medicals.length)
        addParam({ disabled: true });
      else removeParams(['disabled']);
    }, [declarations, medicals]);

    return (
      <>
        <StyledH2>{t('Declaration form')}</StyledH2>
        <p>{t("We'd like to ask you to upload declaration form")}</p>
        <StyledUploader
          ref={refDeclaration}
          name="DECLARATION_WORK_ACCIDENT"
          icon={<PlusIcon />}
          multiple={true}
          onClick={onClick}
          supported={supportedFiles}
          errorText={t('File type not allowed!')}
        >
          {({ loaded }) =>
            declarations.map((file, i) => (
              <SmallFileBox
                loaded={loaded[i]}
                name={file.name}
                size={file.size}
                source={file.data}
                onClose={() => onClose('DECLARATION_WORK_ACCIDENT', file.data)}
              />
            ))
          }
        </StyledUploader>
        <StyledHeading>{t('Medical certificate')}</StyledHeading>
        <p>{t("We'd like to ask you to upload medical certificate.")}</p>
        <StyledUploader
          ref={refMedical}
          name="MEDICAL_CERTIFICATE"
          icon={<PlusIcon />}
          multiple={true}
          onClick={onClick}
          supported={supportedFiles}
          errorText={t('File type not allowed!')}
        >
          {({ loaded }) =>
            medicals.map((file, i) => (
              <SmallFileBox
                loaded={loaded[i]}
                name={file.name}
                size={file.size}
                source={file.data}
                onClose={() => onClose('MEDICAL_CERTIFICATE', file.data)}
              />
            ))
          }
        </StyledUploader>
        <StyledButton
          onClick={handleFormData}
          data-test-id="continueButton"
          disabled={hasParam('disabled')}
        >
          {t('Continue')}
        </StyledButton>
      </>
    );
  },
);

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 23px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledUploader = styled(Uploader)`
  width: 100%;
  margin-left: 0;
  & > label {
    background-color: ${({ theme }) => theme.ui.toolbar};
    margin-top: 18px;
    width: 100%;
    height: 95px;
    border: 1px dashed #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

const SmallFileBox = styled(Uploader.FileBox)`
  width: 30%;
  margin-right: 3%;
  margin-top: 45px;
`;

const StyledH2 = styled(SubTitle)`
  margin-bottom: 5px;
`;

const StyledHeading = styled(StyledH2)`
  margin-top: 43px;
`;

export default AccidentPersonal;
