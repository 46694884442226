import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import { ActionButton, LoadingSpinner } from 'wg-fe-ui';
import {
  createCase,
  getPerils,
  updateClaim,
} from '../../../helpers/apiRouterService';
import CategoryTypeClaimSection from '../../../components/CategoryTypeClaimSection';
import CategoryFlight from './../../../components/CategoryFlight';
import CategoryClaimPicker from '../../../components/CategoryClaimPicker';
import { convertArrayToSSIFriendly } from '../../../helpers/objectService';
import useQueryParams from '../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import categoryPageContent from '../../../constants/categoryPageContent.json';

const Category = forwardRef(
  (
    {
      initializeClaimType,
      initializeStorage,
      errors,
      setCategory,
      handleChange,
      values,
      resetForm,
      setErrors,
      setValues,
      handleSection,
      claim,
    },
    ref,
  ) => {
    const [claimCategory, setClaimCategory] = React.useState('');
    const [perils, setPerils] = useState([]);
    const [loading, setLoading] = useState(false);
    const { addParam, hasParam, removeParams } = useQueryParams();
    const { id } = useParams();
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    useEffect(() => {
      handleSection({ section: 0, subsection: 0 });
    }, []);

    useEffect(() => {
      if (
        !claimCategory ||
        !values.peril ||
        (claimCategory !== 'AVIATION' && !values.insuredAt)
      ) {
        addParam({ disabled: true });
      } else {
        removeParams(['disabled']);
        setValues((values) => ({ ...values, claimCategory }));
      }
    }, [claimCategory, values.peril, values.insuredAt]);

    useEffect(() => {
      if (id && claim.metadata) {
        const { type, peril, insured_at, policy_nr } = claim;
        setClaimCategory(type);
        setValues({
          type,
          peril,
          insuredAt: insured_at,
          policyNumber: policy_nr,
        });
      }
    }, [claim]);

    const callPerils = async () => {
      setLoading(true);
      const [resp, status] = await getPerils(claimCategory);
      setLoading(false);
      if (status !== 200) return;
      setPerils(convertArrayToSSIFriendly(resp.perils, t) || []);
    };

    useEffect(() => {
      if (claimCategory) {
        callPerils();
      }
    }, [claimCategory]);

    const handleFormData = async (e) => {
      if (e) e.preventDefault();
      if (id && claim && claim.metadata) {
        const [, status] = await updateClaim(claim.metadata.uid, {
          claim: {
            insured_at: values.insuredAt,
            policy_nr: values.policyNumber,
          },
        });
        if (status !== 200) return;
        addParam({ submit: true });
      } else {
        const [resp, status] = await createCase(
          claimCategory,
          values.peril,
          values.insuredAt,
          values.policyNumber,
        );
        if (status !== 201) return;
        initializeStorage(resp);
        initializeClaimType(claimCategory);
        addParam({ submit: true, id: resp.id });
      }
    };

    const handleCategory = (val) => {
      setClaimCategory(val);
      setCategory(val);
      handleSection({ section: 0, subsection: 1 });
    };

    const categoryDescription = () => {
      const category = categoryPageContent[claimCategory.toLowerCase()];
      return (
        <>
          <StyledH3>{t(category.a)}</StyledH3>
          <StyledParagraph>{t(category.b)}</StyledParagraph>
          <StyledParagraph>
            <Bold>{t(category.c)}: </Bold>
            {t(category.d)}
          </StyledParagraph>
        </>
      );
    };
    return (
      <>
        {(!id || !claim || !claim.metadata) && (
          <CategoryClaimPicker
            liftCategoryChange={handleCategory}
            category={claimCategory}
          />
        )}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <form onSubmit={handleFormData}>
            {claimCategory && categoryDescription()}
            {['home', 'personal', 'car'].includes(
              claimCategory.toLowerCase(),
            ) ? (
              <CategoryTypeClaimSection
                resetForm={resetForm}
                values={values}
                handleChange={handleChange}
                errors={errors}
                claimCategory={claimCategory}
                perils={perils}
                setErrors={setErrors}
                isEditing={claim && claim.metadata && id}
              />
            ) : ['aviation'].includes(claimCategory.toLowerCase()) ? (
              <CategoryFlight
                value={values.peril}
                perils={perils}
                claimCategory={claimCategory}
                error={errors.claimType}
                handleChange={handleChange}
              />
            ) : null}
            <StyledBtn
              disabled={hasParam('disabled')}
              data-test-id="continueButton"
            >
              {t('Continue')}
            </StyledBtn>
          </form>
        )}
      </>
    );
  },
);

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const StyledH3 = styled.h3`
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  letter-spacing: 0.924996px;
  margin-bottom: 20px;
  color: #696969;
`;

const StyledParagraph = styled.p`
  font-family: Lato;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  letter-spacing: 0.924996px;

  color: #696969;
  margin-bottom: 38px;
`;

StyledH3.displayName = 'H3';

export default Category;
