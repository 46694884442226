import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Section = forwardRef(({ className, children }, ref) => (
  <StyledSection className={className} ref={ref}>
    {children}
  </StyledSection>
));

const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-sizing: border-box;

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const Title = styled.h2`
  font-weight: 900;
`;

const Content = styled.div`
  margin-top: 15px;
  border: 1px solid #cccccc;
  padding: 30px 2.7%;
  white-space: nowrap;
  max-height: 330px;
`;

const MainData = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'normal'};
  border-bottom: 1px solid
    ${({ noBorder }) => (noBorder ? 'transparent' : '#cccccc')};
  padding-bottom: 28px;

  & > svg {
    position: absolute;
  }
`;

const SubData = styled.div`
  position: relative;
  display: flex;
  padding: 16px 0 8px;
  flex-wrap: ${({ wrap }) => (wrap === false ? 'nowrap' : 'wrap')};
  flex-basis: ${({ basis }) => basis || 'initial'};
  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid #cccccc;
      padding-top: 24px;
      margin-top: 20px;
    `};
  & > svg {
    position: absolute;
  }
`;

Section.Content = Content;
Section.Content.displayName = 'Summary.Content';
Section.Title = Title;
Section.Title.displayName = 'Summary.Title';
Section.MainData = MainData;
Section.MainData.displayName = 'Summary.MainData';
Section.SubData = SubData;
Section.SubData.displayName = 'Summary.SubData';

export default Section;
