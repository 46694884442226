/**
 * Parses date from object format to Date
 * @param {object} date {year, month, day, hour, minute, second}
 */
export const parseIncDate = (date) =>
  new Date(
    date.year,
    date.month - 1,
    date.day,
    date.hour || 0,
    date.minute || 0,
    date.second || 0,
  );

/**
 * Parses date to the locale string
 * @param {object} date {year, month, day, hour, minute, second}
 */

export const parseIncDateToString = (date, separator = '/') => {
  if(!date) return '';
  return parseIncDate(date).toLocaleDateString().replace(/\./g, separator);
};

/**
 * Parses date string to date object
 * @param {string} date
 */
export const parseDateStringToObject = (
  date,
  separator = '/',
  format = 'eu',
) => {
  if (!date) return null;
  const parsedDate = date.split(separator);
  if (format === 'eu')
    return {
      day: parseInt(parsedDate[0]),
      month: parseInt(parsedDate[1]),
      year: parseInt(parsedDate[2]),
    };
  else if (format === 'us') {
    return {
      day: parseInt(parsedDate[2]),
      month: parseInt(parsedDate[1]),
      year: parseInt(parsedDate[0]),
    };
  }
  return {};
};

/**
 * Parses time string separated by ':' to date object
 * @param {string} time
 */

export const parseTimeStringToObject = (time) => {
  if(!time) return null;
  const parsedTime = time.split(':');
  return {
    hour: parseInt(parsedTime[0]),
    minute: parseInt(parsedTime[1]),
    second: parseInt(parsedTime[2]),
  };
};
