import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BackButton, ProgressBar, SubNavigation } from 'wg-fe-ui';
import useQueryParams from '../../hooks/useQueryParams';
import useRouting from '../../hooks/useRouting';
import { useLocation } from 'react-router';
import {
  StyledSubNavigation,
  StyledToolBar,
  Content,
} from '../../styledComponents';
import { shortifyText } from './../../helpers/utils';
import { sectionsInit } from '../../helpers/mockedData';
import { subsections, stagedSections } from '../../constants/fixedSections';
import RouteSwitch from './RouteSwitch';
import { Route } from 'react-router-dom';
import BrokerVerify from './../BrokerVerify';
import { useTranslation } from 'react-i18next';
import { getMeData } from '../../helpers/meService';

const stagesTypes = [
  {
    key: 'claim_info',
    name: 'Claim information',
    id: 1,
  },
  {
    key: 'client_information',
    name: 'Client information',
    id: 2,
  },
  {
    key: 'accident_specifics',
    name: 'Accident specifics',
    id: 3,
  },
  {
    key: 'submit_claim',
    name: 'Submit claim',
    id: 4,
  },
];

const NewClaim = () => {
  const { t } = useTranslation();
  const [disableToolbarButton, setDisableToolbarButton] = useState();
  const [currentSection, setCurrentSection] = useState({
    section: 0,
    subsection: 0,
  });
  const [stages, setStages] = useState([]);
  const [sections, setSections] = useState(sectionsInit);
  const { hasParam } = useQueryParams();
  const location = useLocation();
  const { previousRoute } = useRouting();
  const [userRole, setUserRole] = useState('');
  const childRef = useRef();

  const getUserRole = async () => {
    const meData = await getMeData();
    meData && meData.me && setUserRole(meData.me.role);
  };

  useEffect(() => {
    getUserRole();
    const stages = stagesTypes.map(s => ({ ...s, name: t(s.name) }));
    setStages(stages);
  }, []);

  useEffect(() => {
    setDisableToolbarButton(hasParam('disabled'));
  }, [hasParam('disabled')]);

  const getCurrentSectionId = () => {
    const path = location.pathname;
    const route = path.split('/')[2];
    let result = 1;
    if (!route) return result;

    for (let [k, v] of Object.entries(stagedSections)) {
      if (v.includes(route)) {
        result = k;
        break;
      }
    }
    return parseInt(result) + 1;
  };

  useEffect(() => {
    const path = location.pathname;
    const newSections = [...sections];
    const currentRoute = Object.keys(subsections).find(k => path.includes(k));
    if (currentRoute) {
      const currentSubsections = subsections[currentRoute];
      newSections[currentSubsections.section]['subsections'] =
        currentSubsections.subsections;
    } else {
      newSections[0]['subsections'] = subsections.category.subsections;
    }
    setSections(newSections);
  }, [location.pathname]);

  return (
    <>
      <StyledBar>
        <StyledToolBar
          btnName={t('Continue')}
          btnDisable={disableToolbarButton}
          onClick={() => (childRef.current ? childRef.current.click() : null)}
        >
          {!location.pathname.startsWith('/broker') && (
            <BackButton onClick={previousRoute} name={t('Back')} />
          )}
        </StyledToolBar>
        {!location.pathname.startsWith('/broker') && (
          <StyledProgressBar
            height="5px"
            stages={stages}
            activeId={getCurrentSectionId()}
          />
        )}
      </StyledBar>
      <Content userRole={userRole}>
        {userRole.includes('broker') ? (
          <StyledSubNavigation>
            {sections.map(section => (
              <React.Fragment key={section.id}>
                <SubNavigation.Section>
                  {shortifyText(t(section.label))}
                </SubNavigation.Section>
                <SubNavigation.SectionContainer
                  active={currentSection.section === section.id}
                >
                  {section.subsections.map(subsection => (
                    <SubNavigation.SubSection
                      key={subsection.id}
                      active={
                        currentSection.section === section.id &&
                        currentSection.subsection === subsection.id
                      }
                    >
                      {shortifyText(t(subsection.label))}
                    </SubNavigation.SubSection>
                  ))}
                </SubNavigation.SectionContainer>
              </React.Fragment>
            ))}
          </StyledSubNavigation>
        ) : null}
        <Container>
          <Route
            path="/broker/:id"
            render={() => <BrokerVerify childRef={childRef} />}
          />
          <RouteSwitch handleSection={setCurrentSection} childRef={childRef} userRole={userRole} />
        </Container>
      </Content>
    </>
  );
};

const Container = styled.div`
  grid-area: Content;
  font-family: 'Lato';
  margin-top: 2.5rem;
  margin-left: 1.7vw;
  h2 {
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 0;
  justify-content: space-evenly;
`;

const StyledBar = styled.div`
  display: flex;
  flex-direction: column;
`;

export default NewClaim;
