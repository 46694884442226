import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './locales/en.json';
import NL from './locales/nl.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  EN,
  NL,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lng') || 'EN',

    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome


    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
