import React, { useImperativeHandle, useEffect, useState } from 'react';
import { ActionButton, LoadingSpinner, Section, TextInput } from 'wg-fe-ui';
import { useHistory, useParams } from 'react-router-dom';
import { auth, verifyBroker, getBrokerData } from '../helpers/apiRouterService';
import useQueryParams from '../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import GoogleReCaptcha from '../components/GoogleReCaptcha';


const BrokerVerify = ({ childRef }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [broker, setBroker] = useState({
    first_name: '',
    last_name: '',
    email: '',
    captcha: '',
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { addParam, hasParam, removeParams } = useQueryParams();

  const socialLogin = async () => {
    const data = {
      ask_broker_id: id,
      ask_role: 'social_login',
      captcha: broker.captcha,
      captcha_user_information: {
        first_name: broker.first_name,
        last_name: broker.last_name,
        email: broker.email,
      },
    };
    const [, status] = await auth(data);
    status === 200 && history.push('/new-claim/category');
  };

  useImperativeHandle(childRef, () => ({
    click: () => socialLogin(),
  }));

  const onClick = captcha => setBroker(b => ({ ...b, captcha }));
  const handleChange = ({ name, value }) => {
    setBroker(b => ({ ...b, [name]: value }));
  };

  const initBroker = async () => {
    try {
      setLoading(l => !l);
      const [resp, status] = await getBrokerData(id);
      if (status !== 200) {
        setLoading(l => !l);
        return;
      }
      // localStorage.setItem('logo', resp.distribution.logo);
      localStorage.setItem('theme', resp.theme.name);
      const statusRes = await verifyBroker(id);
      setLoading(l => !l);
      statusRes === 200 && setBroker(b => ({ ...b, id: resp.broker.id }));
    } catch (error) {
      console.error('error: ', error);
    }
  };

  React.useEffect(() => {
    if (!broker.id) initBroker();
  }, []);

  useEffect(() => {
    if (Object.values(broker).filter(b => !b).length) {
      addParam({ disabled: true });
    } else {
      removeParams(['disabled']);
    }
  }, [broker]);

  if (!loading && !broker.id) return null;

  return (
    <StyledSection loading={loading}>
      {loading ? (
        <LoadingSpinner />
      ) : (
          <>
            <Section.Title>{t('Verify')}</Section.Title>
            <Section.Content background>
              <Flex>
                <Flex direction="column">
                  <Row>
                    <TextInput
                      style={{ marginBottom: '1rem' }}
                      name="first_name"
                      value={broker.first_name}
                      onChange={handleChange}
                    >
                      {t('First name')}
                    </TextInput>
                    <TextInput
                      name="last_name"
                      value={broker.last_name}
                      onChange={handleChange}
                    >
                      {t('Last name')}
                    </TextInput>
                  </Row>
                  <TextInput
                    type="email"
                    name="email"
                    value={broker.email}
                    onChange={handleChange}
                  >
                    {t('Email')}
                  </TextInput>
                </Flex>
                <StyledCaptcha onClick={onClick} />
              </Flex>
            </Section.Content>
            <StyledButton
              onClick={socialLogin}
              type="submit"
              value="check"
              disabled={hasParam('disabled')}
            >
              {t('Continue')}
            </StyledButton>
          </>
        )}
    </StyledSection>
  );
};

const StyledButton = styled(ActionButton)`
  margin-top: 2rem;
  align-self: flex-end;

  @media(max-width: 600px) {
    width: 100%;
  }
`;

StyledButton.displayName = 'StyledButton';

const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: ${({ loading }) => (loading ? 'center' : 'initial')};
  justify-content: ${({ loading }) => (loading ? 'center' : 'initial')};

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

StyledSection.displayName = 'StyledSection';

const StyledCaptcha = styled(GoogleReCaptcha)`
  margin-left: 7rem;
  position: relative;
  top: 10px;

  @media(max-width: 800px) {
    margin-left: 0;
    position: relative;
    top: 0;
    left: 10px;
    right: 10px;
    padding: 10px;
    width: 100%;

    > * {
      width: 100% !important;
    }
  }
`;

StyledCaptcha.displayName = 'StyledCaptcha';

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: center;

  @media(max-width: 800px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;

  > div:first-child {
    margin-right: 5rem;
  }

  @media(max-width: 600px) {
    flex-direction: column;
  }
`;

export default BrokerVerify;
