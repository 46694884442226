import React from 'react';
import { string } from 'prop-types';

const MailBig = ({ className, width = 28, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 18.1971C0 12.655 0 7.15904 0 1.33984C3.24759 4.43418 6.26965 7.2976 9.29171 10.2072C9.74276 10.6229 10.1938 11.0847 10.6449 11.5004C13.0806 13.8096 14.7495 13.8096 17.2303 11.4542C20.7034 8.1751 24.1314 4.84984 27.7849 1.33984C27.7849 7.06668 27.7849 12.5626 27.7849 18.1971C18.6736 18.1971 9.38192 18.1971 0 18.1971Z" />
      <path d="M1.5332 0C9.9228 0 17.9065 0 26.2961 0C24.3114 1.89355 22.5523 3.55618 20.7932 5.26499C18.989 6.9738 17.1848 8.7288 15.3806 10.4376C14.5236 11.2689 13.5763 11.4537 12.6742 10.6224C9.0658 7.20472 5.45737 3.74092 1.5332 0Z" />
    </svg>
  );
};

MailBig.propTypes = {
  className: string,
};

export default MailBig;
