import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './assets/css/reset.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/hoc/ErrorBoundary';
import { ThemeProvider } from './ThemeContextProvider';
import './i18n';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const theme = localStorage.getItem('theme');

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('7l7lpz/wegroup-claims');
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(
  <HashRouter>
    <ThemeProvider theme={theme || 'orange'}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  </HashRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
