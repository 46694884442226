import React, { useEffect } from 'react';
import { ActionButton, SearchSelectInput } from 'wg-fe-ui';
import styled from 'styled-components';
import Form from '../../../components/FlightInfoForm';
import { useTranslation } from 'react-i18next';

const DetailsOccurrence = ({
  errors,
  values,
  handleChange,
  handleSection,
  handleFormData,
}) => {
  const onChange = ({ name, value }) => {
    const values = value.map(v => v.value);
    handleChange({ name, value: values });
  };
  const { t } = useTranslation();

  useEffect(() => {
    handleSection({ section: 2, subsection: 0 });
  }, []);

  return (
    <>
      <Container>
        <h2> Affected rooms </h2>
        <p>
          We’d like to ask you to upload invoices from the goods in question
        </p>
        <Form>
          <SearchSelectInput
            name="rooms"
            options={[
              { label: 'Bathroom', value: 'bathroom' },
              { label: 'Living room', value: 'living_room' },
              { label: 'Bedroom', value: 'bedroom' },
            ]}
            isMulti={true}
            error={errors.rooms}
            onSelected={onChange}
            placeholder={t('Choose your option')}
          />
        </Form>
      </Container>

      <Container>
        <h2> Damaged goods </h2>
        <p>
          We’d like to ask you to upload invoices from the goods in question
        </p>
        <form>
          <SearchSelectInput
            name="goods"
            options={[
              { label: 'Washing machine', value: 'washing_machine' },
              { label: 'Drying cabinet', value: 'drying_cabinet' },
            ]}
            isMulti={true}
            error={errors.goods}
            onSelected={onChange}
            placeholder={t('Choose your option')}
          />
        </form>
      </Container>
      <ActionButton onClick={handleFormData}>Continue</ActionButton>
    </>
  );
};

const Container = styled.div`
  width: 52vw;
  & > div {
    border: 1px solid ${({ theme }) => theme.ui.toolbar};
  }
  & > p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.highlight};
  }
  form {
    display: flex;
    & > label {
      width: 100%;
      :first-child {
        margin-right: 1.25vw;
      }
    }

    .Select__control {
      height: 95px;
      align-items: flex-start;
    }
  }
`;

export default DetailsOccurrence;
