import React from 'react';
import styled from 'styled-components';
import { CarStyledTextInput } from '../styledComponents';
const Item = ({
  className,
  title,
  name,
  value,
  children,
  input,
  onChange,
  error,
}) => {
  return (
    <div className={className}>
      {title && (
        <>
          {input ? (
            <CarStyledTextInput
              name={name}
              value={value}
              onChange={onChange}
              error={error}
            >
              {title}
            </CarStyledTextInput>
          ) : (
            <>
              <span className="title">{title}</span>
              <span className="value">{value}</span>
            </>
          )}
        </>
      )}
      {children}
    </div>
  );
};

const StyledItem = styled(Item)`
  display: flex;
  flex-direction: column;
  .title {
    color: #a29c95;
    font-size: 1.4rem;
    letter-spacing: 1.43px;
    line-height: 1.6rem;
  }

  .value {
    color: ${({ theme }) => theme.typo.highlight};
    font-weight: 900;
    font-size: 1.5rem;
    margin: 1rem 0;
    line-height: 1.5rem;
  }
`;

export default StyledItem;
