import React from 'react';
import { string } from 'prop-types';

const Collision = ({ className, width = 20, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.1591 10.1662C13.0641 9.97618 12.9216 9.83366 12.7316 9.78616L11.8765 5.79566C2.99287 5.74815 2.94537 5.70065 2.85036 5.65314L1.47268 5.41561C1.09264 5.3681 0.665083 5.27309 0.285036 5.22559C0.142518 5.22559 0 5.3206 0 5.51062V14.4417C0 14.5843 0.0950119 14.6793 0.23753 14.6793H5.32066C5.46318 16.0094 9.64364L4.98812 8.17095C4.84561 8.12345 4.70309 8.07594 4.65558 7.93342L3.08789 6.60332 17.1021 7.981 17.1021C9.35867 17.1021 10.4988 16.0569 10.6413 14.6793H12.5416C12.8266 14.6793 13.0641 14.4892 13.1116 14.2042C13.3017 13.4916 13.5867 12.1139 13.5867 11.6864C13.6342 11.3539 13.3492 10.5938 13.1591 10.1662ZM9.45368 14.6793C9.31116 15.3443 8.69359 15.8669 7.981 15.8669C7.26841 15.8669 6.65083 15.3443 6.50831 14.6793C6.50831 14.5843 6.46081 14.4892 6.46081 14.3942C6.46081 13.5866 7.12589 12.874 7.93349 12.874C8.74109 12.874 9.40617 13.5391 9.40617 14.3942C9.50119 14.4892 9.50119 14.5843 9.45368 14.6793Z"
        fill="white"
      />
      <path
        d="M13.9668 9.40595C14.0618 9.50096 14.1093 9.64348 14.1568 9.786C14.5369 10.7361 14.7269 11.3062 14.7269 11.7337C14.7269 12.3038 14.3944 13.729 14.2043 14.5366C14.2043 14.5841 14.1568 14.6791 14.1568 14.7266C14.2993 16.0568 15.4395 17.1494 16.8172 17.1494C18.1948 17.1494 19.335 16.1043 19.4775 14.7266H19.7625C19.905 14.7266 20.0001 14.6316 20.0001 14.4891V8.45583C20.0001 8.31331 19.8575 8.1708 19.715 8.2183L13.9668 9.40595ZM18.2423 14.6791C18.0998 15.3442 17.4822 15.8668 16.7696 15.8668C16.0571 15.8668 15.4395 15.3442 15.297 14.6791C15.297 14.5841 15.2495 14.4891 15.2495 14.3941C15.2495 13.5865 15.9145 12.8739 16.7221 12.8739C17.5297 12.8739 18.1948 13.539 18.1948 14.3941C18.2898 14.4891 18.2898 14.5841 18.2423 14.6791Z"
        fill="white"
      />
      <path
        d="M14.5754 6.65083C14.4804 6.65083 14.4329 6.65083 14.3379 6.60332C14.1003 6.46081 14.0528 6.17577 14.1478 5.93824L15.193 4.13302L13.3877 5.17815C13.2452 5.22565 13.1027 5.27316 12.9602 5.22565C12.8177 5.17815 12.7227 5.08313 12.6752 4.94062L11.82 2.3753L11.25 5.32066C11.2025 5.46318 11.1075 5.6057 10.9649 5.65321C10.8224 5.70071 10.6799 5.70071 10.5374 5.65321L8.96969 4.75059L9.77729 5.93824C9.91981 6.17577 9.8723 6.46081 9.63477 6.60332C9.39724 6.74584 9.11221 6.69834 8.96969 6.46081L7.06945 3.61045C6.92693 3.42043 6.97444 3.1829 7.11696 3.04038C7.25947 2.89786 7.497 2.85036 7.68703 2.94537L10.4424 4.51306L11.25 0.380047C11.2975 0.190024 11.4875 0 11.6775 0C11.9151 0 12.1051 0.142518 12.1526 0.332542L13.3877 4.038L16.1906 2.4228C16.3806 2.32779 16.6181 2.32779 16.7607 2.51781C16.9032 2.66033 16.9507 2.89786 16.8557 3.08789L14.9554 6.4133C14.9079 6.55582 14.7179 6.65083 14.5754 6.65083Z"
        fill="white"
      />
    </svg>
  );
};

Collision.propTypes = {
  className: string,
};

export default Collision;
