import React from 'react';
import {
  Approved,
  Expertise,
  Filing,
  Loupe,
  Repair,
  Uploading,
} from './../icons';

const mockedUsers = {
  2019: {
    September: [
      {
        id: 1,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
      {
        id: 2,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'second',
      },
    ],
    August: [
      {
        id: 3,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'processing',
      },
    ],
    July: [
      {
        id: 4,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'approved',
      },
      {
        id: 5,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
    ],
    June: [
      {
        id: 6,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
      {
        id: 7,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
      {
        id: 8,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
      {
        id: 5,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
    ],
  },
  2018: {
    November: [
      {
        id: 9,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
      {
        id: 10,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
      {
        id: 11,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
    ],
    October: [
      {
        id: 12,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
    ],
    September: [
      {
        id: 13,
        name: 'Laura Cox',
        created_on: '18 September',
        insured_at: 'AG Insurance',
        damage_type: 'Bagage claim',
        status: 'filing',
      },
    ],
  },
};

const sections = [
  {
    id: 1,
    label: '2019',
    subsections: [
      {
        id: 1,
        label: 'September',
      },
      {
        id: 2,
        label: 'August',
      },
      {
        id: 3,
        label: 'July',
      },
      {
        id: 4,
        label: 'June',
      },
    ],
  },
  {
    id: 2,
    label: '2018',
    subsections: [
      {
        id: 1,
        label: 'November',
      },
      {
        id: 2,
        label: 'October',
      },
      {
        id: 3,
        label: 'September',
      },
    ],
  },
];

const detailSections = {
  aviation: [{
    id: 1,
    label: 'Claim Details',
    subsections: [
      {
        id: 1,
        label: 'Claim information',
      },
      {
        id: 2,
        label: 'Passengers',
      },
      {
        id: 3,
        label: 'Flights',
      },
      {
        id: 4,
        label: 'Uploaded documents',
      },
      {
        id: 5,
        label: 'Extra expenses',
      },
    ],
  }],
  car: [{
    id: 2,
    label: 'Claim Details',
    subsections: [
      {
        id: 1,
        label: 'Client information',
      },
      {
        id: 2,
        label: 'Claim information',
      },
      {
        id: 3,
        label: 'Car information',
      },
      {
        id: 4,
        label: 'Involved parties',
      },
      {
        id: 5,
        label: 'Extra expenses',
      },
    ],
  }],
  home: [{
    id: 3,
    label: 'Claim Details',
    subsections: [
      {
        id: 1,
        label: 'Client details',
      },
      {
        id: 2,
        label: 'Claim information',
      },
      {
        id: 3,
        label: 'Detailed report',
      },
      {
        id: 4,
        label: 'Uploaded documents',
      },
      {
        id: 5,
        label: 'Extent of the damage',
      },
    ],
  }],
  personal: [{
    id: 4,
    label: 'Claim Details',
    subsections: [
      {
        id: 1,
        label: 'Claim information',
      },
      {
        id: 2,
        label: 'Passengers',
      },
      {
        id: 3,
        label: 'Flights',
      },
      {
        id: 4,
        label: 'Uploaded documents',
      },
      {
        id: 5,
        label: 'Extra expenses',
      },
    ],
  }]
};


// NewClaim
const sectionsInit = [
  {
    id: 0,
    label: 'Claim information',
    subsections: [
      {
        id: 0,
        label: 'Claim category',
      },
      {
        id: 1,
        label: 'Claim type',
      },
    ],
  },
  {
    id: 1,
    label: 'Client information',
    subsections: [
      {
        id: 0,
        label: 'Client list',
      },
      {
        id: 1,
        label: 'Register third party',
      },
    ],
  },
  {
    id: 2,
    label: 'Accident specifics',
    subsections: [
      {
        id: 0,
        label: 'Occurance place',
      },
      {
        id: 1,
        label: 'List of events',
      },
    ],
  },
  {
    id: 3,
    label: 'Submit claim',
    subsections: [
      {
        id: 0,
        label: 'Final',
      },
    ],
  },
];

// FlightInfo
const departureAirports = [
  {
    label: '(BRU) Brussels Airport',
    value: 'bru_airport_1',
  },
];
const airLines = [
  {
    label: '(FR) Ryanair',
    value: 'fr_airline_1',
  },
  {
    label: '(RU) Red Wings Airlines',
    value: 'ru_airline_1',
  },
  {
    label: '(RU) Royal Flight',
    value: 'ru_airline_2',
  },
];

const arrivalAirports = [
  {
    label: '(LIS) Lisbon PortelaAirport',
    value: 'lis_airport_1',
  },
];

const flights = [
  {
    id: 'FR2985',
    company: 'Ryanair',
    cityFrom: 'Brussels',
    cityFromShort: 'BRU',
    departure: '17:55',
    cityTo: 'Lisbon',
    cityToShort: 'LIS',
    arrival: '13:10',
  },
  {
    id: 'FR2986',
    company: 'Ryanair',
    cityFrom: 'Brussels',
    cityFromShort: 'BRU',
    departure: '17:55',
    cityTo: 'Lisbon',
    cityToShort: 'LIS',
    arrival: '13:10',
  },
];

// TrackingBar steps

const stages = [
  {
    key: 'Claim Opened',
    name: 'Claim Opened',
    icon: <Filing />,
    id: 1,
  },
  {
    key: 'Uploading',
    name: 'Uploading Documents',
    icon: <Uploading />,
    id: 2,
  },
  {
    key: 'Data Analysis',
    name: 'Data Analysis',
    icon: <Loupe />,
    id: 3,
  },
  {
    key: 'Performing Expertise',
    name: 'Performing Expertise',
    icon: <Expertise />,
    id: 4,
  },
  {
    key: 'Approved',
    name: 'Approved',
    icon: <Approved />,
    id: 5,
  },
  {
    key: 'Authorized Repairer',
    name: 'Authorized Repairer',
    icon: <Repair />,
    id: 6,
  },
];

const files = [
  {
    id: 0,
    name: 'Filename.ext',
    size: '1.3 mb',
  },
  {
    id: 1,
    name: 'Filename.ext',
    size: '1.3 mb',
  },
  {
    id: 2,
    name: 'Filename.ext',
    size: '1.3 mb',
  },
];

const initResidenceObject = {
  zipcode: '',
  city: '',
  street: '',
  housenr: '',
  boxnr: null,
};

const mockedCities = [
  { label: 'Brussel', value: 'brussel' },
  { label: 'Warsaw', value: 'warsaw' },
];

const mockedStreets = [
  { label: 'StreetA', value: 'streetA' },
  { label: 'StreetB', value: 'streetB' },
];

export {
  mockedUsers,
  sections,
  sectionsInit,
  detailSections,
  stages,
  departureAirports,
  airLines,
  arrivalAirports,
  flights,
  files,
  initResidenceObject,
  mockedStreets,
  mockedCities,
};
