import React from 'react';
import { string } from 'prop-types';

const Dashboard = ({ className }) => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1.80733"
        y="1.27852"
        width="11.1202"
        height="11.1202"
        rx="3.9854"
        strokeWidth="2.12051"
      />
      <rect
        x="16.6535"
        y="1.27852"
        width="11.1202"
        height="11.1202"
        rx="3.9854"
        strokeWidth="2.12051"
      />
      <rect
        x="1.80733"
        y="16.124"
        width="11.1202"
        height="11.1202"
        rx="3.9854"
        strokeWidth="2.12051"
      />
      <rect
        x="17.0402"
        y="16.124"
        width="11.1202"
        height="11.1202"
        rx="3.9854"
        strokeWidth="2.12051"
      />
    </svg>
  );
};

Dashboard.propTypes = {
  className: string,
};

export default Dashboard;
