import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { LoadingSpinner } from 'wg-fe-ui';
import { orderBy } from 'lodash';

const SearchVersionList = ({ filterdversionList, disabled, onSelected }) => {
  const [selectedVersion, setSelectedVersion] = useState();
  const [displayedList, setDisplayedList] = useState([]);
  const [sorted, setSorted] = useState();
  const [sortedOrder, setSortedOrder] = useState();
  const [areVersionsLoading, setAreVersionsLoading] = useState(true);

  useEffect(() => {
    onSelected(selectedVersion);
  }, [selectedVersion]);

  useEffect(() => {
    setDisplayedList(filterdversionList);
  }, [filterdversionList]);

  useEffect(() => {
    setAreVersionsLoading(!displayedList.length);
  }, [displayedList]);

  const handleSort = type => {
    let sortedList = [...filterdversionList];
    const sortBy = sortedOrder === 'desc' ? 'asc' : 'desc';
    sortedList = orderBy(sortedList, [type], [sortBy]);
    setSortedOrder(sortBy);
    setDisplayedList(sortedList);
    setSorted(type);
  };
  return (
    <div>
      <VersionLabel>
        <VersionSpan
          className="version"
          sorted={sorted === 'version'}
          sortedOrder={sortedOrder}
          onClick={() => handleSort('version')}
        >
          {i18n.t('Version')}
        </VersionSpan>
        <VersionSpan
          sorted={sorted === 'kw'}
          onClick={() => handleSort('kw')}
          sortedOrder={sortedOrder}
        >
          {i18n.t('Kw')}
        </VersionSpan>
        <VersionSpan
          sorted={sorted === 'model_year'}
          sortedOrder={sortedOrder}
          onClick={() => handleSort('model_year')}
        >
          {i18n.t('Model year')}
        </VersionSpan>
      </VersionLabel>
      <VersionList disabled={disabled}>
        {areVersionsLoading && !disabled ? (
          <LoadingBox>
            <LoadingSpinner className="spinner" />
          </LoadingBox>
        ) : null}
        {displayedList.map((versionObj, i) => {
          const { kw, model_year, version } = versionObj;
          return (
            <Version
              key={`${version}-${i}`}
              onClick={() => setSelectedVersion({ kw, model_year, version })}
              isSelected={
                JSON.stringify(versionObj) === JSON.stringify(selectedVersion)
              }
            >
              <span className="version">{version}</span>
              <span>{kw}</span>
              <span>{model_year}</span>
            </Version>
          );
        })}
      </VersionList>
    </div>
  );
};

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const VersionLabel = styled.li`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.typo.interactive};

  & .version {
    width: 58%;
  }

  & span {
    cursor: pointer;
  }
`;

const VersionSpan = styled.span`
  ${({ sorted }) => (sorted ? `font-weight: 900; color: #6d6d6d;` : null)}
  position: relative;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 1.4px;

  &::after {
    position: absolute;
    left: ${({ className }) => (className === 'version' ? '28%' : '-1.5rem')};
    content: 'v';
    display: flex;
    align-items: center;
    top: 0;
    height: 100%;
    transition: all 0.3s ease;
    opacity: ${({ sorted }) => (sorted ? 1 : 0)};
    transform: ${({ sortedOrder }) =>
      sortedOrder === 'desc'
        ? 'rotate(0) translateY(-1px)'
        : 'rotate(-180deg) translateY(-3px)'};
  }
`;

const Version = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: left;
  color: ${({ theme, isSelected }) =>
    isSelected ? 'white' : theme.typo.interactive};

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.brand.primary : 'none'};
  cursor: pointer;
  padding: 1rem 2rem;

  & > span:last-child {
    text-align: right;
  }

  & .version {
    width: 50%;
  }

  & .power {
    width: 22%;
  }

  & .year {
    width: 14%;
  }

  :hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
  }
`;

const VersionList = styled.ul`
  background-color: ${({ disabled }) => (disabled ? 'hsl(0,0%,95%)' : 'white')};
  border: 1px solid ${({ theme }) => theme.ui.outline};
  border-radius: 3px;
  height: 20rem;
  overflow-y: scroll;
  margin-bottom: 3rem;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #afafaf;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #afafaf;
  }
`;

export default SearchVersionList;
