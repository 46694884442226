import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { ActionButton, SubTitle, Uploader } from 'wg-fe-ui';
import styled from 'styled-components';
import PlusIcon from '../../../icons/Plus';
import { deleteFile, uploadFile } from './../../../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';
import { supportedFiles } from '../../../constants';

const AccidentUpload = forwardRef(
  ({ handleChange, handleSection, handleFormData, claimId }, ref) => {
    const refPicture = useRef();
    const refInvoice = useRef();
    const refOther = useRef();
    const [accidentPictures, setAccidentPictures] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [other, setOther] = useState([]);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const uploadNewFile = async (name, f) => {
      const [resp, status] = await uploadFile(claimId, {
        title: f.title,
        file: f.data.split(',')[1],
        filename: f.name.toLowerCase(),
        category: name,
        description: 'this is cool file',
      });
      if (name === 'PICTURE')
        setAccidentPictures(b => [
          ...b,
          { id: resp.id, name: f.name, data: f.data, size: f.size },
        ]);
      else if (name === 'INVOICE')
        setInvoices(b => [
          ...b,
          { id: resp.id, name: f.name, data: f.data, size: f.size },
        ]);
      else if (name === 'RECEIPT')
        setOther(r => [
          ...r,
          { id: resp.id, name: f.name, data: f.data, size: f.size },
        ]);
      return status;
    };

    const removeFile = async (id, cat) => {
      const [, status] = await deleteFile(id);
      if (status !== 200) return;
      if (cat === 'PICTURE')
        setAccidentPictures(book => book.filter(b => b.id !== id));
      if (cat === 'INVOICE')
        setInvoices(board => board.filter(b => b.id !== id));
      if (cat === 'RECEIPT')
        setOther(receipt => receipt.filter(r => r.id !== id));
    };

    const onClick = async ({ name, value }) => {
      if (value.dispatchConfig) return;
      let statuses = [];
      try {
        statuses = await Promise.all(
          [...value].map(async (file, i) => {
            return await uploadNewFile(name, file);
          }),
        );
        if (statuses.find(f => f !== 200)) return;
        handleChange({ name: name, value: [...value].map(val => val.name) });
      } catch (error) {
        console.error('error: ', error);
      }
    };

    useEffect(() => {
      handleSection({ section: 2, subsection: 0 });
    }, []);

    return (
      <>
        <StyledH2>{t('Accident pictures')}</StyledH2>
        <p>
          {t('We\'d like to ask you to upload documents, pictures of the accident etc.')}
        </p>
        <StyledUploader
          ref={refPicture}
          name="PICTURE"
          icon={<PlusIcon />}
          multiple={true}
          onClick={onClick}
          supported={supportedFiles}
          errorText={t('File type not allowed!')}
        >
          {({ loaded }) =>
            accidentPictures.map((file, i) => (
              <SmallFileBox
                loaded={loaded[i]}
                name={file.name}
                size={file.size}
                source={file.data}
                onClose={() => removeFile(file.id, 'PICTURE')}
              />
            ))
          }
        </StyledUploader>
        <StyledHeading>{t('Invoices damaged goods')}</StyledHeading>
        <p>
          {t('We\'d like to ask you to upload invoices from the goods in question.')}
        </p>
        <StyledUploader
          ref={refInvoice}
          name="INVOICE"
          icon={<PlusIcon />}
          multiple={true}
          onClick={onClick}
          supported={supportedFiles}
          errorText={t('File type not allowed!')}
        >
          {({ loaded }) =>
            invoices.map((file, i) => (
              <SmallFileBox
                loaded={loaded[i]}
                name={file.name}
                size={file.size}
                source={file.data}
                onClose={() => removeFile(file.id, 'INVOICE')}
              />
            ))
          }
        </StyledUploader>
        <StyledHeading>{t('Other')}</StyledHeading>
        <p>
          {t('Have any other related pictures or documents that are not mentioned above please upload them down below.')}
        </p>
        <StyledUploader
          ref={refOther}
          name="RECEIPT"
          icon={<PlusIcon />}
          multiple={true}
          onClick={onClick}
          supported={supportedFiles}
          errorText={t('File type not allowed!')}
        >
          {({ loaded }) =>
            other.map((file, i) => (
              <SmallFileBox
                loaded={loaded[i]}
                name={file.name}
                size={file.size}
                source={file.data}
                onClose={() => removeFile(file.id, 'RECEIPT')}
              />
            ))
          }
        </StyledUploader>
        <StyledButton onClick={handleFormData} data-test-id='continueButton'>{t('Continue')}</StyledButton>
      </>
    );
  },
);

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 23px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledUploader = styled(Uploader)`
  width: 100%;
  margin-left: 0;
  & > label {
    background-color: ${({ theme }) => theme.ui.toolbar};
    margin-top: 18px;
    width: 100%;
    height: 95px;
    border: 1px dashed #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

const SmallFileBox = styled(Uploader.FileBox)`
  width: 30%;
  margin-right: 3%;
  margin-top: 45px;
`;

const StyledH2 = styled(SubTitle)`
  margin-bottom: 5px;
`;

const StyledHeading = styled(StyledH2)`
  margin-top: 43px;
`;

export default AccidentUpload;
