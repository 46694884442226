import React, { useState } from 'react';
import { SearchSelectInput } from 'wg-fe-ui';
import { callCities, callStreets, debouncer } from '../helpers/addressService';
import { Input, FormField } from '../styledComponents';
import { countries } from '../constants';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Address = ({ handleEntityChange, entity, errors }) => {
  const [cityKey, setCityKey] = useState(0);
  const { t } = useTranslation();

  const handleChange = ({ name, value }) => {
    if (name === 'zipcode')
      debouncer(() => {
        setCityKey((val) => (val === 0 ? 1 : 0));
      });
    else if (name === 'city') {
      return handleEntityChange((data) => ({
        ...data,
        [name]: value.val,
        zipcode: value.zipcode,
      }));
    }
    handleEntityChange((data) => ({ ...data, [name]: value }));
  };

  return (
    <>
      <FormField>
        <Input
          name="zipcode"
          onChange={({ name, value }) =>
            handleChange({ name, value: parseInt(value) })
          }
          value={entity['zipcode'] || ''}
          error={errors['zipcode']}
        >
          {t('Zip-code')}
        </Input>
      </FormField>
      <FormField>
        <SearchSelectInput
          async
          name="city"
          loadOptions={(q, cb) => callCities(q, cb, entity)}
          defaultOptions={entity.zipcode ? true : []}
          onSelected={handleChange}
          error={errors['city']}
          cacheOptions={false}
          key={cityKey}
          value={
            entity.city ? { label: entity.city, value: entity.city } : undefined
          }
          placeholder={t('Choose your option')}
        >
          {t('City')}
        </SearchSelectInput>
      </FormField>
      <FormField>
        <SearchSelectInput
          async
          name="street"
          defaultOptions={entity.zipcode && entity.city ? true : []}
          loadOptions={(q, cb) => callStreets(q, cb, entity)}
          onSelected={handleChange}
          error={errors['street']}
          cacheOptions={false}
          disabled={!entity.zipcode || !entity.city}
          value={
            entity.street
              ? { label: entity.street, value: entity.street }
              : undefined
          }
          placeholder={t('Choose your option')}
        >
          {t('Street')}
        </SearchSelectInput>
      </FormField>
      <FormField>
        <Input
          name="housenr"
          onChange={handleChange}
          value={entity['housenr']}
          error={errors['housenr']}
          disabled={!entity.street}
        >
          {t('House number')}
        </Input>
      </FormField>
      <FormField>
        <Input
          name="boxnr"
          onChange={handleChange}
          value={entity['boxnr']}
          error={errors['boxnr']}
          disabled={!entity.housenr}
        >
          {t('Box')}
        </Input>
      </FormField>
      <FormField>
        <SearchSelectInput
          placeholder="Belgium"
          name="country"
          options={countries}
          error={errors.country}
          value={{ label: entity.country, value: entity.country }}
          onSelected={handleChange}
          disabled
        >
          {t(`Country`)}
        </SearchSelectInput>
      </FormField>
    </>
  );
};

Address.displayName = 'Address';
Address.propTypes = {
  handleEntityChange: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Address;
