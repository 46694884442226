import React from 'react';
import { string } from 'prop-types';

const Tools = ({ className, width = 21, height = 21, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.7916 1.52627C5.67559 0.425265 4.04128 0.147297 2.6638 0.692366L5.33064 3.32334L3.16818 5.45672L0.518152 2.84233C-0.00478011 4.18985 0.284514 5.77416 1.38546 6.86029C2.4864 7.94643 4.09114 8.23069 5.45702 7.71479L17.7772 19.8693C18.3744 20.4584 19.3426 20.4584 19.9397 19.8693C20.5368 19.2802 20.5368 18.3251 19.9397 17.7359L7.63687 5.59857C8.18937 4.23961 7.90762 2.62728 6.7916 1.52627Z"
        fill="#C1C1C1"
      />
      <path
        d="M10.9002 11.9343L15.202 7.69045C16.0258 7.83173 16.8635 7.55261 17.354 6.86113L20.149 3.13429C20.2916 2.94383 20.258 2.66357 20.0695 2.47769L18.3222 0.753828C18.1338 0.567944 17.8497 0.534771 17.6566 0.675471L13.879 3.43285C13.1781 3.91729 12.8952 4.74376 13.0384 5.55593L8.73663 9.79981C7.96904 9.69171 7.16146 9.92907 6.57127 10.5113L1.16107 15.8488C0.165061 16.8314 0.165061 18.4248 1.16107 19.4075C2.15707 20.3901 3.77225 20.3901 4.76826 19.4075L10.1785 14.07C10.7692 13.4883 11.0098 12.691 10.9002 11.9343Z"
        fill="#C1C1C1"
      />
    </svg>
  );
};

Tools.propTypes = {
  className: string,
};

export default Tools;
