import React from 'react';
import styled from 'styled-components';
import { Input } from '../styledComponents';
import { useHistory } from 'react-router-dom';

const SummaryList = ({ className, children }) => {
  return <StyledList className={className}>{children}</StyledList>;
};

const SummaryListItem = ({
  className,
  title,
  value,
  error,
  warning,
  destination,
  children,
}) => {
  const history = useHistory();
  const onClick = () => {
    if (!destination) return;
    history.push(destination);
  };

  return (
    <li className={className} onClick={onClick}>
      {children ? (
        children
      ) : (
          <Input value={value} disabled error={error} warning={warning}>
            {title}
          </Input>
        )}
    </li>
  );
};

const StyledList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledListItem = styled(SummaryListItem)`
  margin-bottom: 3rem;
  flex-basis: 50%;
  max-width: 40rem;
  &,
  input,
  textarea {
    cursor: pointer;
  }
  &:nth-last-of-type(2),
  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    flex-basis: 100%;
    width: 100%;
    display: block;
  }
`;

SummaryList.displayName = 'SummaryList';

SummaryList.Item = StyledListItem;

SummaryList.Item.displayName = 'SummaryListItem';

export default SummaryList;
