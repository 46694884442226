import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { ActionButton, Section } from 'wg-fe-ui';
import styled from 'styled-components';
import useQueryParams from '../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';


const ThankYou = forwardRef(({ claim, handleSection, userRole }, ref) => {
  const { addParam } = useQueryParams();
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    click: () => {
      addParam({ submit: true });
    },
  }));

  useEffect(() => {
    handleSection({ section: 3, subsection: 0 });
  }, []);

  const onClick = () => addParam({ submit: true });

  return (
    <>
      <Section>
        <Section.Title>{t('Claim completed.')}</Section.Title>
        <Section.Content>
          {t(
            'Thank you for filling in your claim your broker will contact you in the near future',
          )}
          <StyledP>
            <StyledLink to={`/claim/${claim.metadata && claim.metadata.uid}`}>
              {t('Click here to see your claim details.')}
            </StyledLink>
          </StyledP>
          {userRole.startsWith('broker') && (
            <StyledBtn name="submit" onClick={onClick}>
              <a href="/#/cases">{t('go to List of claims')}</a>
            </StyledBtn>)}
          <StyledP>{t('This page can be closed.')}</StyledP>
        </Section.Content>
      </Section>
    </>
  );
});

const StyledP = styled.p`
  margin-top: 2rem;
  color: ${({ theme }) => theme.typo.highlight};
`;

const StyledLink = styled(Link)`
  font-weight: 900;
`;

const StyledBtn = styled(ActionButton)`
  margin-top: 2rem;
  @media(max-width: 600px) {
    width: 100%;
  }
`

export default ThankYou;
