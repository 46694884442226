import React, { useState, useEffect } from 'react';
import { ActionButton, CheckOption, Section } from 'wg-fe-ui';
import styled from 'styled-components';
import {
  CertifiedRepairerIcon,
  MaterialDamageOtherCarsIcon,
  MaterialDamageOtherObjIcon,
  PVActiveIcon,
} from '../../../icons';
import { Input } from '../../../styledComponents';
import { useTranslation } from 'react-i18next';

const options = [
  {
    id: 1,
    label: 'The client wants to work with certified repairer',
    value: 'delayed',
    icon: <CertifiedRepairerIcon />,
  },
  {
    id: 2,
    label: 'There is material damage to other cars then A and B',
    value: 'missed',
    icon: <MaterialDamageOtherCarsIcon />,
  },
  {
    id: 3,
    label: 'There is material damage to other objects other then car A and B',
    value: 'canceled',
    icon: <MaterialDamageOtherObjIcon />,
  },
  {
    id: 4,
    label: 'There is already a PV active',
    value: 'active',
    icon: <PVActiveIcon />,
  },
];

const SpecialCircumstances = ({ handleSection, handleFormData }) => {
  const [checked, setChecked] = useState({
    delayed: false,
    missed: false,
    canceled: false,
    active: false,
  });
  const { t } = useTranslation();

  const handleClick = ({ name, value }) => {
    setChecked((old) => ({ ...old, [name]: !checked[value] }));
  };

  useEffect(() => {
    handleSection({ section: 0, subsection: 0 });
  }, []);

  return (
    <>
      <StyledSection>
        <Section.Title>{t('Special circumstances')}</Section.Title>
        <Section.Content>
          <OptionsContainer>
            {options.map((option) => (
              <CheckOption
                key={option.id}
                icon={option.icon}
                name={option.value}
                value={option.value}
                checked={checked[option.value]}
                handleClick={handleClick}
              >
                {t(option.label)}
              </CheckOption>
            ))}
          </OptionsContainer>
        </Section.Content>
      </StyledSection>
      {checked['active'] ? (
        <StyledSection active={checked['active']}>
          <Section.Title>{t('Email police zone')}</Section.Title>
          <Section.Content>
            <StyledInput name="police" type="email">
              {t('Email')}
            </StyledInput>
          </Section.Content>
        </StyledSection>
      ) : null}
      <StyledButton onClick={handleFormData} data-test-id="continueButton">
        {t('Continue')}
      </StyledButton>
    </>
  );
};

const StyledSection = styled(Section)`
  margin-bottom: ${({ active }) => (active ? '0' : '1rem')};
  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 20.8vw;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  label {
    margin: 0 2.6vw 15px 0;
    flex-basis: 40%;
  }

  @media (max-width: 600px) {
    label {
      width: 100%;
      flex-basis: 100%;
      padding: 0 10px;
    }
  }
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export default SpecialCircumstances;
