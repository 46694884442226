import React from 'react';
import { Content } from '../../styledComponents';
import styled from 'styled-components';
import RouteSwitch from './RouteSwitch';

const SummaryLayout = () => {
  return (
    <Content>
      <Container>
        <RouteSwitch prefix="/summary" handleSection={() => {}}/>
      </Container>
    </Content>
  );
};

const Container = styled.div`
  grid-area: Content;
  font-family: 'Lato';
  margin-top: 2.5rem;
  margin-left: 1.7vw;
  h2 {
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;

export default SummaryLayout;
