import { parseDateStringToObject } from './dateService';
import { addOrEditEntity } from './apiRouterService';
import { checkIfFullAddress } from './addressService';

export const getPayloadFromPerson = (entity, type) => {
  const country_code = entity.country
    ? entity.country.toUpperCase().slice(0, 2)
    : 'BE';
  return {
    type,
    person_type: 'NATURAL',
    first_name: entity.first_name,
    last_name: entity.last_name,
    email: entity.email,
    telephonenr: entity.telephonenr,
    mobilephonenr: entity.mobilephonenr,
    birth: entity.birth ? parseDateStringToObject(entity.birth) : null,
    identification: entity.identity_nr
      ? {
          nr: entity.identity_nr,
        }
      : null,
    // comment: entity.comment,
    correspondence_address: checkIfFullAddress(entity)
      ? {
          street: entity.street,
          zipcode: entity.zipcode,
          housenr: entity.housenr,
          boxnr: entity.boxnr,
          city: entity.city,
          country_code,
        }
      : undefined,
    nationality: country_code,
  };
};

export const modifyEntity = async (entity, claimId, type) => {
  const method = entity['id'] ? 'PUT' : 'POST';
  const resource = entity['id'] ? `/${entity['id']}` : '';
  const [resp, status] = await addOrEditEntity(
    claimId,
    resource,
    method,
    getPayloadFromPerson(entity, type),
  );
  return [status, resp];
};

/**
 * Gets current entity from storage
 * @param {string} id
 */
export const getCurrentEntity = (id) => {
  let storageArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];

  const claim = storageArray.find((e) => e._id === id);
  return claim && claim._entity ? claim._entity : {};
};
