import * as yup from 'yup';
import { parseIncDate } from '../helpers/dateService';

// function isPassportValid(passport) {
//   let isCorrect = true;
//   if (passport && this.parent.country === 'Belgium') {
//     const regex = /\d+/g;
//     const passportId = passport.match(regex).join('');
//     isCorrect =
//       97 - (passportId.slice(0, 9) % 97) === parseInt(passportId.slice(9));
//   }
//   return isCorrect;
// }

const cityStreetRegex = /^[A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- ''.&’]{1,150}$/;
const houseAndBoxNrRegex = /^[a-z0-9\- /]{1,20}$/;
const passportRegex = /^\d{3}-\d{7}-\d{2}$/;

const requiredMessage = 'This is required!';
const regexMessage = 'Invalid value.';
const minZipcodeMessage = 'Postal must be greater than 1000!';
const maxZipcodeMessage = 'Postal must be less than 9999!';
const dateMessage = 'Invalid date';

function isDateValid(date) {
  const parsedDate = parseIncDate(date);
  const now = Date.now();
  return now >= parsedDate;
}

const dateSchema = yup.object({
  day: yup.number().min(1).max(31).required(requiredMessage),
  month: yup.number().min(1).max(12).required(requiredMessage),
  year: yup.number().min(1800).max(2100).required(requiredMessage),
});

const timeSchema = yup.object({
  hour: yup.number().min(0).max(23),
  minute: yup.number().min(0).max(59),
  second: yup.number().min(0).max(59),
}).nullable();

const personSchema = {
  first_name: yup.string().required(requiredMessage),
  last_name: yup.string().required(requiredMessage),
  email: yup.string().email().required(requiredMessage),
  telephonenr: yup.string().required(requiredMessage),
};

const validateZipcode = (required = true) => {
  const mainValidation = yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1000, minZipcodeMessage)
    .max(9999, maxZipcodeMessage);
  return required ? mainValidation.required(requiredMessage) : mainValidation;
};

const schema = {
  category: {
    type: yup.object().shape({
      peril: yup.string().required(requiredMessage),
      insuredAt: yup.string().required(requiredMessage),
      policyNumber: yup.string(),
      thirdParty: yup.string(),
    }),
    aviation: yup.object().shape({
      peril: yup.string().required(requiredMessage),
    }),
  },
  accidentDesc: yup.object({
    accidentDate: dateSchema.test(dateMessage, dateMessage, isDateValid),
    accidentTime: timeSchema,
    description: yup.string(),
  }),
  flights: yup.object().shape({
    flights_taken: yup.array().of(yup.object().shape({})),
  }),
  flightInfo: yup.object().shape({
    departure_airport: yup.string().required(requiredMessage),
    airline: yup.string().required(requiredMessage),
    arrival_airport: yup.string().required(requiredMessage),
    departure_date: dateSchema
      .test(dateMessage, dateMessage, isDateValid)
      .required(requiredMessage),
    flight_number: yup.string(),
  }),
  claimDetails: yup.object().shape({
    checkedCircumstances: yup.string(),
    remark: yup.string(),
  }),
  issues: yup.object().shape({
    checked: yup.object().shape({
      delayed: yup.bool(),
      other: yup.bool(),
      denied: yup.bool(),
      missed: yup.bool(),
      canceled: yup.bool(),
    }),
    issue_description: yup.string(),
    incident_date: yup.string(),
  }),
  flightIssues: yup.object().shape({
    checked: yup.object().shape({
      delayed: yup.bool(),
      other: yup.bool(),
      denied: yup.bool(),
      missed: yup.bool(),
      canceled: yup.bool(),
    }),
    issue_description: yup.string(),
    incident_date: yup.string(),
  }),
  passengerContact: yup.object().shape({}),
  passenger: yup.object().shape({
    first_name: yup.string().required(requiredMessage),
    last_name: yup.string().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
    telephonenr: yup.string().required(requiredMessage),
    birth: dateSchema.test(dateMessage, dateMessage, isDateValid),
    identity_nr: yup
      .string()
      .matches(passportRegex, regexMessage)
      //.test('passport', 'passport checkSum is invalid', isPassportValid)
      .required(requiredMessage),
    street: yup.string().required(requiredMessage),
    housenr: yup.string().required(requiredMessage),
    city: yup.string().required(requiredMessage),
    zipcode: yup.string().required(requiredMessage),
    country: yup.string().required(requiredMessage),
    docs: yup.array().of(yup.string()),
  }),
  contactPerson: yup.object().shape({
    first_name: yup.string().required(requiredMessage),
    last_name: yup.string().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
    telephonenr: yup.string().required(requiredMessage),
    mobilephonenr: yup.string().required(requiredMessage),
  }),
  companyContact: yup.object().shape({
    name: yup.string().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
    street: yup.string().required(requiredMessage),
    housenr: yup.string().required(requiredMessage),
    city: yup.string().required(requiredMessage),
    zipcode: yup.string().required(requiredMessage),
    country: yup.string().required(requiredMessage),
  }),
  contactInfo: yup.object().shape({}),
  residence: yup.object({
    zipcode: validateZipcode(),
    city: yup
      .string()
      .required(requiredMessage)
      .matches(cityStreetRegex, regexMessage),
    street: yup
      .string()
      .required(requiredMessage)
      .matches(cityStreetRegex, regexMessage),
    housenr: yup
      .string()
      .required(requiredMessage)
      .matches(houseAndBoxNrRegex, regexMessage),
    boxnr: yup.string().nullable().matches(houseAndBoxNrRegex, regexMessage),
  }),
  addressConfirm: yup.object({
    certifiedRepaier: yup.bool(),
    clientWantsRepaier: yup.bool(),
  }),
  repairerInfo: yup.object({}),
  insureeInfo: yup.object().shape({
    ...personSchema,
    zipcode: validateZipcode(),
    city: yup
      .string()
      .nullable()
      .matches(cityStreetRegex, regexMessage)
      .required(requiredMessage),
    street: yup
      .string()
      .nullable()
      .matches(cityStreetRegex, regexMessage)
      .required(requiredMessage),
    housenr: yup
      .string()
      .nullable()
      .matches(houseAndBoxNrRegex, regexMessage)
      .required(requiredMessage),
    birth: dateSchema.test(dateMessage, dateMessage, isDateValid),
    country: yup.string().required(requiredMessage),
    identity_nr: yup
      .string()
      .matches(passportRegex, regexMessage)
      .required(requiredMessage),
  }),
  insuree: yup.object().shape({
    first_name: yup.string().required(requiredMessage),
    last_name: yup.string().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
    telephonenr: yup.string().required(requiredMessage),
    street: yup.string().required(requiredMessage),
    housenr: yup.string().required(requiredMessage),
    city: yup.string().required(requiredMessage),
    zipcode: yup.string().required(requiredMessage),
    country: yup.string().required(requiredMessage),
    iban: yup.string(),
    license: yup.string(),
    comment: yup.string(),
    check1: yup.string(),
    check2: yup.string(),
    docs: yup.array().of(yup.string()),
  }),
  thirdPartyInfo: yup.object().shape({
    ...personSchema,
    zipcode: validateZipcode(),
    city: yup
      .string()
      .nullable()
      .matches(cityStreetRegex, regexMessage)
      .required(requiredMessage),
    street: yup
      .string()
      .nullable()
      .matches(cityStreetRegex, regexMessage)
      .required(requiredMessage),
    housenr: yup
      .string()
      .nullable()
      .matches(houseAndBoxNrRegex, regexMessage)
      .required(requiredMessage),
    country: yup.string().required(requiredMessage),
    iban: yup.string().required(requiredMessage),
    policy_nr: yup.string().required(requiredMessage),
    insured_at: yup.string().required(requiredMessage),
    license_plate: yup.string(),
    comment: yup.string(),
  }),
  accidentForm: yup.object().shape({
    upload: yup.array().of(yup.string()),
  }),
  accidentUpload: yup.object().shape({
    pictures: yup.array().of(yup.string()),
    goods: yup.array().of(yup.string()),
  }),
  passengers: yup.object().shape({}),
  involved: yup.object().shape({}),
  witnessInfo: yup.object({
    ...personSchema,
    zipcode: validateZipcode(false),
    city: yup.string().nullable().matches(cityStreetRegex, regexMessage),
    street: yup.string().nullable().matches(cityStreetRegex, regexMessage),
    housenr: yup.string().nullable().matches(houseAndBoxNrRegex, regexMessage),
    boxnr: yup.string().nullable().matches(houseAndBoxNrRegex, regexMessage),
    description: yup.string().nullable(),
  }),
  witnessList: yup.object({}),
  injured: yup.object({}),
  injuredInfo: yup.object({}),
  travel: yup.object().shape({
    booking: yup.array().of(yup.string()),
    boarding: yup.array().of(yup.string()),
    receipts: yup.array().of(yup.string()),
  }),
  expenses: yup.object().shape({
    extra_docs: yup.array().of(yup.string()),
  }),
  last: yup.object().shape({
    contacted_airline: yup.bool(),
    luggage_issue: yup.bool(),
    accepted_another_flight: yup.bool(),
    cancellation_date: dateSchema.test(dateMessage, dateMessage, isDateValid),
  }),
  detailsOccurrence: yup.object().shape({
    street: yup.string().required(requiredMessage),
    housenr: yup.string().required(requiredMessage),
    city: yup.string().required(requiredMessage),
    zipcode: yup.string().required(requiredMessage),
    country: yup.string().required(requiredMessage),
    incidentDate: dateSchema.test(dateMessage, dateMessage, isDateValid),
    event_list: yup.string().required(requiredMessage),
  }),
  specialCircumstances: yup.object().shape({
    checkedCircumstances: yup.string(),
    police: yup.string(),
  }),
  detailsOccurrenceHome: yup.object().shape({
    rooms: yup.array(yup.string()).required(requiredMessage),
    goods: yup.array(yup.string()).required(requiredMessage),
  }),
  adminLogin: yup.object().shape({
    email: yup.string().required(requiredMessage),
    password: yup.string().required(requiredMessage),
  }),
  timeFrame: yup.object().shape({
    accidentDate: yup.string().required(requiredMessage),
    accidentTime: yup.string(),
    accidentDescription: yup.string(),
  }),
  claimSubtype: yup.object(),
  contactOptions: yup
    .object()
    .shape({
      sms: yup.bool(),
      email: yup.bool(),
      whatsapp: yup.bool(),
      call: yup.bool(),
    })
    .oneOf([true], 'Please select at least 1 option'),
  repairer: yup.object({
    name: yup.string().nullable().required(requiredMessage),
    zipcode: validateZipcode(),
    city: yup
      .string()
      .nullable()
      .matches(cityStreetRegex, regexMessage)
      .required(requiredMessage),
    street: yup
      .string()
      .nullable()
      .matches(cityStreetRegex, regexMessage)
      .required(requiredMessage),
    housenr: yup
      .string()
      .nullable()
      .matches(houseAndBoxNrRegex, regexMessage)
      .required(requiredMessage),
    telephonenr: yup.string().nullable().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
    country: yup.string().required(requiredMessage),
    iban: yup.string().nullable(),
  }),
  repairerSelect: yup.object({
    telephonenr: yup.string().nullable().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
  }),
  carCardInputs: yup.object({
    engine: yup.string().required(requiredMessage),
    registrationFirst: dateSchema
      .required(requiredMessage)
      .required(requiredMessage),
    model_year: yup.number(),
    power: yup.number().required(requiredMessage),
    emissions: yup.number(),
    car_value: yup.number().min(1, ' ').required(requiredMessage),
  }),
  carCard: yup.object({
    registrationFirst: dateSchema.required(requiredMessage),
    registrationLast: dateSchema.required(requiredMessage),
    car_value: yup.number().required(requiredMessage),
    technical: yup.object({
      kw: yup.number().required(requiredMessage),
      cc: yup.number().required(requiredMessage),
      co2: yup.number().required(requiredMessage),
      model_year: yup
        .number()
        .min(1900, ' ')
        .max(new Date().getFullYear(), ' ')
        .required(requiredMessage),
      motorType: yup.string().required(requiredMessage),
    }),
  }),
};

export default schema;
