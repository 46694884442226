import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { ActionButton, FlightSelectBar, LoadingSpinner } from 'wg-fe-ui';
import styled from 'styled-components';
import FlightIcon from '../../../icons/Flight';
import useQueryParams from '../../../hooks/useQueryParams';
import NewClaimFlightInfoLookup from '../../../components/NewClaimFlightInfoLookup';
import { padVal } from './../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shortifyText } from '../../../helpers/utils';


const storeFlight = (f, airports) => {
  const departureAirport = airports.find(a => a.value === f.departure_airport_iata);
  const arrivalAirport = airports.find(a => a.value === f.arrival_airport_iata);
  return JSON.stringify({
    flight_number: f.flight_number,
    arrival_airport_iata: f.arrival_airport_iata,
    arrival_airport: shortifyText(arrivalAirport ? arrivalAirport.label : ''),
    departure_airport_iata: f.departure_airport_iata,
    departure_airport: shortifyText(departureAirport ? departureAirport.label : ''),
    airline: f.airline,
    departure_date: {
      ...f.departure_date,
      month: padVal(f.departure_date.month),
      day: padVal(f.departure_date.day),
      hour: padVal(f.departure_date.hour),
      minute: padVal(f.departure_date.minute),
      second: padVal(f.departure_date.second),
    },
    callsign: f.callsign,
    distance: f.distance,
  });
};

const storeFlightData = data => {
  const id = sessionStorage.getItem('_id');
  const storageArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];
  const claim = storageArray.find(e => e._id === id);
  if (!claim) return;
  if (!claim.claim_data.flights_taken) claim.claim_data.flights_taken = [];
  claim.claim_data.flights_taken = [{ ...data }]

  const oldStorage = storageArray.filter(s => s._id !== id);
  const storageData = [...oldStorage, { ...claim }];
  sessionStorage.setItem('claimStorage', JSON.stringify(storageData));
};

const FlightInfo = forwardRef(({
  handleChange,
  handleSubmit,
  values,
  setValues,
  handleSection,
  validateForms
}, ref) => {
  const history = useHistory();
  const { id } = useParams();
  const [airports, setAirports] = useState([]);
  const [checked, setChecked] = useState('');
  const [flights, setFlights] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { removeParams, addParam, hasParam } = useQueryParams();


  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    click: () => history.push(`/new-claim/flights/${id}`)
  }));

  useEffect(() => {
    if (!checked) {
      addParam({ disabled: true });
    } else {
      removeParams(['disabled']);
      setValues(values => ({
        ...values,
        ...flights.find(f => f.flight_number === checked),
      }));
    }
  }, [checked]);

  const handleFlight = ({ value }) => {
    const data = JSON.parse(value);
    const flight = flights.find(f => f.flight_number === data.flight_number);
    if (!flight) return;
    setChecked(flight.flight_number);
    storeFlightData(data);
  };

  const onSubmit = e => {
    e.preventDefault();
    history.push(`/new-claim/flights/${id}`);
  };

  return (
    <>
      <NewClaimFlightInfoLookup
        setFlights={setFlights}
        setLoading={setLoading}
        errors={errors}
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleSection={handleSection}
        setAirports={setAirports}
        validateForms={validateForms}
        setErrors={setErrors}
      />
      {loading ? (
        <LoadingSpinner />
      ) : flights ? (
        <>
          <h2>{flights.length ? t('Select a flight') : t('no Flights found')}</h2>
          {flights.length ? (
            <FlightsContainer>
              <FlightForm onSubmit={onSubmit}>
                {flights.map(f => (
                  <StyledFlight
                    style={{ borderRadius: '5px' }}
                    checked={f.flight_number === checked}
                    name="flight"
                    data-test-id="flight"
                    handleChange={handleFlight}
                    value={storeFlight(f, airports)}
                    key={f.flight_number}
                  >
                    <FlightSelectBar.FlightData
                      border
                      data={f.flight_number}
                      label={f.airline}
                    />
                    <FlightSelectBar.FlightData
                      border={false}
                      data={`${padVal(f.departure_date.hour)}:${padVal(
                        f.departure_date.minute,
                      )}`}
                      label={t("Departure")}
                    />
                    <FlightSelectBar.FlightData
                      border={false}
                      data={f.departure_airport_iata}
                      label={airports.find(a => a.value === f.departure_airport_iata) ? shortifyText(airports.find(a => a.value === f.departure_airport_iata).label) : ''}
                    />
                    <FlightBox checked={f.flight_number === checked}>
                      <FlightIcon />
                    </FlightBox>
                    <FlightSelectBar.FlightData
                      border={false}
                      data={f.arrival_airport_iata}
                      label={airports.find(a => a.value === f.arrival_airport_iata) ? shortifyText(airports.find(a => a.value === f.arrival_airport_iata).label) : ''}
                    />
                  </StyledFlight>
                ))}
                <StyledActionButton data-test-id="continue-button" disabled={hasParam('disabled')}>
                  {t('Continue')}
                </StyledActionButton>
              </FlightForm>
            </FlightsContainer>
          ) : null}
        </>
      ) : null}
    </>
  );
});

const FlightBox = styled.div`
  transform: translateX(-3%);
  position: relative;

  &::before,
  &::after {
    content: '- - - - - - - - - - - - - -';
    color: ${({ checked, theme }) => (checked ? theme.brand.secondary : theme.typo.interactive)};
    display: inline-block;
    transform: translate(-6%, -20%);
  }

  &::after {
    transform: translate(6%, -20%);
  }

  @media (max-width: 1000px) {
    &::before, &::after {
      display: none;
    }
  }
`;

const StyledFlight = styled(FlightSelectBar)`
  width: 100%;
  box-sizing: border-box;
  height: 95px;
  justify-content: space-between;

  & > div {
    &:nth-child(2) {
      margin-right: 55px;
    }
    &:nth-child(3) {
      margin-right: 55px;
    }
    &:nth-child(4) {
      margin-right: 8px;
    }
    &:nth-child(6) {
      margin-right: 55px;
    }
    &:nth-child(7) {
      margin-right: 0;
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;
    height: 260px;
    align-items: center;
    width: 60%;
    padding: 10px 0;
    margin: 10px 0;

    & > div {
      margin-left: 0 !important;
      margin-right: 0 !important; 

      &:nth-child(1) {
        border-right: none;
        border-bottom: 1px solid #FF9B21;
      }
    }
    & span:last-child {
      display: none;
    }
  }
`;

StyledFlight.displayName = 'StyledFlight';

const StyledActionButton = styled(ActionButton)`
  width: 10rem;

  @media(max-width: 600px) {
    width: 100%;
  }
`;

const FlightForm = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 2rem;
  margin-top: 2rem;
  max-width: 100rem;
`;

FlightForm.displayName = 'FlightForm';

const FlightsContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 720px;

  label {
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    min-width: 200px;
  }
`;

FlightsContainer.displayName = 'FlightsContainer';

export default FlightInfo;
