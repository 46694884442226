import React from 'react';

export const filterValues = (value, search) => {
  const filtered = Object.keys(value).reduce((acc, val) => {
    acc[val] = Object.keys(value[val]).reduce((rec, v) => {
      rec[v] = value[val][v].filter(user => {
        let hasValue = false;
        const values = Object.values(user).slice(1);
        for (let i = 0, len = values.length; i < len; i++) {
          if (values[i].toLowerCase().includes(search.toLowerCase())) {
            hasValue = true;
            break;
          }
        }
        return hasValue && user;
      });
      return rec[v] ? rec : undefined;
    }, {});
    return acc[val] ? acc : undefined;
  }, {});

  return filtered;
};

export default function useFilter(value, search, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const memoizedFilter = React.useMemo(() => filterValues(value, search), [
    value,
    search,
  ]);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      const filtered = search ? memoizedFilter : value;
      setDebouncedValue(values => ({ ...values, ...filtered }));
    }, delay);
    return () => clearTimeout(handler);
  }, [delay, search, value]);

  return debouncedValue;
}
