import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { ActionButton, CheckOption, Section } from 'wg-fe-ui';
import DataBar from '../../../components/DataBar';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import {
  SMSIcon,
  MailBigIcon,
  WhatsAppIcon,
  Phone as PhoneIcon,
} from '../../../icons';
import {
  addOrEditEntity,
  getEntities,
} from './../../../helpers/apiRouterService';
import useNewClaimData from './../../../hooks/useNewClaimData';
import useQueryParams from '../../../hooks/useQueryParams';
import { modifyEntityStorage } from '../../../helpers';
import { getItem, removeItem } from '../../../helpers/storageService';
import { useTranslation } from 'react-i18next';
import { StyledEntityButton } from './../../../styledComponents';

const options = [
  {
    id: 1,
    label: 'SMS',
    value: 'sms',
    icon: <SMSIcon />,
  },
  {
    id: 2,
    label: 'Email',
    value: 'email',
    icon: <MailBigIcon />,
  },
  {
    id: 3,
    label: 'Whatsapp',
    value: 'whatsapp',
    icon: <WhatsAppIcon />,
  },
  {
    id: 4,
    label: 'Call',
    value: 'call',
    icon: <PhoneIcon />,
  },
];

const ContactOptions = forwardRef(
  ({ handleSection, setValues, prefix }, ref) => {
    const [checked, setChecked] = useState({
      sms: false,
      email: false,
      whatsapp: false,
      call: false,
    });
    const { addParam, removeParams } = useQueryParams();
    const { id } = useParams();
    const history = useHistory();
    const { getClaimData } = useNewClaimData();
    const data = getClaimData();
    const [contactPeople, setContactPeople] = useState([]);
    const [checkedPerson, setCheckedPerson] = useState({});
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    const getContacts = async () => {
      const [resp, status] = await getEntities(data._claim_id);
      if (status !== 200 || !resp) return [];
      const contactPeople = sessionStorage['contacts']
        ? JSON.parse(getItem('contacts'))
        : [];
      let contacts = resp.values.filter(
        r =>
          ['CONTACT', 'INSUREE', 'PASSENGER'].includes(r.type) &&
          r.person_type === 'NATURAL',
      );
      const len = contactPeople.length;
      let checked;
      if (len) {
        checked = contactPeople[len - 1];
        contacts = contacts.filter(c => c.id !== checked.id);
      } else if (contacts.length === 1) {
        checked = contacts[0];
      } else {
        checked = contacts.filter(c => c.type === 'CONTACT')[0];
      }
      setCheckedPerson(checked);
      setContactPeople([...contacts, ...contactPeople]);
    };

    const updateContact = async () => {
      let contact_methods = [];
      if (checked['sms']) contact_methods.push('SMS');
      if (checked['email']) contact_methods.push('EMAIL');
      if (checked['whatsapp']) contact_methods.push('WHATSAPP');
      if (checked['call']) contact_methods.push('PHONE');
      // TODO: checked one
      const contact = { ...checkedPerson };

      const [, status] = await addOrEditEntity(
        data._claim_id,
        checkedPerson.id && checkedPerson.type === 'CONTACT'
          ? `/${checkedPerson.id}`
          : '',
        checkedPerson.id && checkedPerson.type === 'CONTACT' ? 'PUT' : 'POST',
        {
          type: 'CONTACT',
          person_type: 'NATURAL',
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          telephonenr: contact.telephonenr,
          mobilephonenr: contact.mobilephonenr,
          preferred_communication_method: contact_methods,
        },
      );
      return status;
    };

    const handleClick = ({ name, value }) => {
      setChecked(old => ({ ...old, [name]: !checked[value] }));
    };
    const handleAddClick = () => {
      history.push(`${prefix}/contact-info/${id}`);
    };
    const handleRadioChange = person => {
      setCheckedPerson({ ...person });
    };

    useEffect(() => {
      getContacts();
      handleSection({ section: 1, subsection: 0 });
    }, []);

    const isDisabled = () => {
      return !Object.values(checked).includes(true) || !contactPeople.length;
    };

    useEffect(() => {
      if (isDisabled()) {
        addParam({ disabled: true });
      } else {
        removeParams(['disabled']);
        setValues(values => ({
          ...values,
          ...checked,
          contactPerson: checkedPerson.value,
        }));
      }
    }, [checked, checkedPerson]);

    const handleFormData = async e => {
      if (e) e.preventDefault();
      const status = await updateContact();
      if (status >= 300) return;
      removeItem('contacts');
      addParam({ submit: true });
    };

    const onEdit = c => {
      modifyEntityStorage(false, c);
      handleAddClick();
    };

    modifyEntityStorage(true);

    return (
      <>
        <StyledSection>
          <Section.Title>{t('Select your contact person')}</Section.Title>
          {contactPeople.map(c => (
            <StyledDataBar
              key={c.id}
              data={{
                keys: [t('Name'), t('Phone'), t('Email')],
                values: [
                  `${c.first_name} ${c.last_name}`,
                  c.telephonenr,
                  c.email,
                ],
              }}
              isEdit={c.type === 'CONTACT'}
              checked={checkedPerson.id === c.id}
              onChange={() => handleRadioChange({ ...c })}
              name={'checkedPerson'}
              value={c.first_name}
              onEdit={() => onEdit(c)}
            />
          ))}
          <StyledEntityButton
            name={t('Add contact person')}
            onClick={handleAddClick}
            data-test-id='addEntityButton'
          />
        </StyledSection>
        <StyledSection>
          <Section.Title>
            {t('Please select at least 1 option how we can contact you')}
          </Section.Title>
          <Section.Content>
            <OptionsContainer>
              {options.map(option => (
                <CheckOption
                  key={option.id}
                  icon={option.icon}
                  name={option.value}
                  value={option.value}
                  checked={checked[option.value]}
                  handleClick={handleClick}
                >
                  {t(option.label)}
                </CheckOption>
              ))}
            </OptionsContainer>
          </Section.Content>
          <StyledButton disabled={isDisabled()} onClick={handleFormData} data-test-id='continueButton'>
            {t('Continue')}
          </StyledButton>
        </StyledSection>
      </>
    );
  },
);

const StyledSection = styled(Section)`
  margin-bottom: 2rem;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  label {
    margin: 0 2.6vw 15px 0;
    flex-basis: 15.6%;

    @media(max-width: 600px) {
      flex-basis: 100%;
      padding: 0 10px;
      margin-right: 0;
    }
  }
`;

const StyledDataBar = styled(DataBar)`
  & > div:nth-of-type(1) {
    margin-right: 5vw;
  }
  & > div:nth-of-type(2) {
    width: 10%;
  }
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 23px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export default ContactOptions;
