import { setLoggedIn, goToLogin } from './authService';
import { request } from './httpSessionService';
import { getMeData } from './meService';

/* * * * * * * * * * * * * * *
 * AUTHENTICATION
 * * * * * * * * * * * * * * */
export async function authMe() {
  const path = 'v1/api/aut/v1/api/auth/me';
  const [resp, status] = await request(path, 'GET');
  if (status === 200) {
    setLoggedIn({
      email: resp.me.email,
      language: resp.me.language,
      role: resp.me.role,
    });
  }
  return [resp, status];
}

export async function auth(data) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request(path, 'POST', {
    ...data,
  });
  return [resp, status];
}

export async function verifyInit() {
  /*
   * the verify method that is called on page load
   * it checks if you have cookies set.
   *
   * if there are expired cookies, renew them and go to dashboard
   * if no cookies found redirect to login page
   *
   */
  const path = 'v1/api/aut/v1/api/auth/verify';
  const [resp, status] = await request(path, 'GET');
  if (
    status === 401 &&
    (resp.msg === 'NOT_AUTHENTICATED' || resp.msg === 'INVALID_TOKEN)')
  )
    goToLogin();
  else {
    return getMeData();
  }
}

/* * * * * * * * * * * * * * *
 * OPENAPI
 * * * * * * * * * * * * * * */
export async function openapiSpec(service) {
  const path = `v1/api/${service}/openapi/spec.json`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * verifyBroker
 * * * * * * * * * * * * * * */
export async function verifyBroker(id) {
  const [, status] = await request(
    `v1/api/broker/v1/api/brokers/${id}/verify`,
    'GET',
  );
  return status;
}

/* * * * * * * * * * * * * * *
 * getBrokerData
 * * * * * * * * * * * * * * */
export async function getBrokerData(id) {
  const [resp, status] = await request(
    `v1/api/broker/v1/api/brokers/${id}`,
    'GET',
  );
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * getAllBrokers
 * * * * * * * * * * * * * * */
export async function getAllBrokers() {
  const path = 'v1/api/broker/v1/api/brokers';
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * GetAirports
 * * * * * * * * * * * * * * */
export const getAirports = async query => {
  const [resp, status] = await request(
    `v1/api/flight/v1/api/airports${query}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetAirports
 * * * * * * * * * * * * * * */
export const getAirlines = async query => {
  const [resp, status] = await request(
    `v1/api/flight/v1/api/airlines${query}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getFlights
 * * * * * * * * * * * * * * */
export const getFlights = async data => {
  const [resp, status] = await request(
    'v1/api/flight/v1/api/search',
    'POST',
    data,
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * CreateCase
 * * * * * * * * * * * * * * */
export const createCase = async (type, peril, insured_at, policy_nr) => {
  const [resp, status] = await request('v1/api/claims/v1/api/cases', 'POST', {
    type,
    peril,
    insured_at,
    policy_nr,
  });
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetCase
 * * * * * * * * * * * * * * */
export const getCase = async caseId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/cases/${caseId}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetPerils
 * * * * * * * * * * * * * * */
export const getPerils = async claimCategory => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/types/perils/${claimCategory}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetClaims
 * * * * * * * * * * * * * * */
export const getClaims = async () => {
  const [resp, status] = await request('v1/api/claims/v1/api/claims', 'GET');
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetClaimTypes
 * * * * * * * * * * * * * * */
export const getClaimTypes = async () => {
  const [resp, status] = await request(
    'v1/api/claims/v1/api/types/claims',
    'GET',
  );
  return [resp, status];
  // const [resp, status] = await request('', 'GET');
  // fetch('/');
  // return [{}, 403];
};

/* * * * * * * * * * * * * * *
 * GetClaim
 * * * * * * * * * * * * * * */
export const getClaim = async claimId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * UpdateClaim
 * * * * * * * * * * * * * * */
export const updateClaim = async (claimId, claim) => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}`,
    'PATCH',
    { ...claim },
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * DeleteClaim
 * * * * * * * * * * * * * * */
export const deleteClaim = async claimId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}`,
    'DELETE',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetEntities
 * * * * * * * * * * * * * * */
export const getEntities = async claimId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/entities`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetEntity
 * * * * * * * * * * * * * * */
export const getEntity = async (claimId, entityId) => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/entities/${entityId}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * ModifyEntity
 * * * * * * * * * * * * * * */
export const addOrEditEntity = async (claimId, entityId, method, data) => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/entities${entityId}`,
    method,
    data,
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * DeleteEntity
 * * * * * * * * * * * * * * */
export const deleteEntity = async (claimId, entityId) => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/entities/${entityId}`,
    'DELETE',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * UploadFileByEntity
 * * * * * * * * * * * * * * */
export const uploadFileByEntity = async (claimId, entityId, data) => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/entities/${entityId}/upload/file`,
    'POST',
    data,
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetFiles
 * * * * * * * * * * * * * * */
export const getFiles = async () => {
  const [resp, status] = await request(`v1/api/claims/v1/api/files`, 'GET');
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * GetFile
 * * * * * * * * * * * * * * */
export const getFile = async fileId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/files/${fileId}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * UploadFile
 * * * * * * * * * * * * * * */
export const uploadFile = async (claimId, data) => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/upload/file`,
    'POST',
    data,
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * DeleteFile
 * * * * * * * * * * * * * * */
export const deleteFile = async fileId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/files/${fileId}`,
    'DELETE',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getInsuranceCompanies
 * * * * * * * * * * * * * * */
export const getInsuranceCompanies = async () => {
  const [resp, status] = await request(
    'v1/api/claims/v1/api/insurance_companies',
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getAddress
 * * * * * * * * * * * * * * */
export const getAddress = async query => {
  const [resp, status] = await request(`v1/api/address/v1/api/${query}`, 'GET');
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getRepairers
 * * * * * * * * * * * * * * */
export const getRepairers = async insurance => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/repairers/${insurance}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getAddressByLatLng
 * * * * * * * * * * * * * * */

export const getAddressbyLatLng = async ({ lat, lng }) => {
  const [resp, status] = await request(
    `v1/api/address/v1/api/coordinate/search?lat=${lat}&lng=${lng}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * verifyClaim
 * * * * * * * * * * * * * * */

export const verifyClaim = async claimId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/verify`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * submitClaim
 * * * * * * * * * * * * * * */

export const submitClaim = async claimId => {
  const [resp, status] = await request(
    `v1/api/claims/v1/api/claims/${claimId}/submit`,
    'POST',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * geocodeAddress
 * * * * * * * * * * * * * * */

export const geocodeAddress = async address => {
  const [resp, status] = await request(
    `v1/api/address/v1/api/search?q=${address}`,
    'GET',
  );
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getCarVersions
 * * * * * * * * * * * * * * */

export const getCarVersions = async (brand, model) => {
  const [resp, status] = await request(`v1/api/car/v1/api/versions`, 'POST', {
    brand,
    model,
  });
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getCarDataByBrand
 * * * * * * * * * * * * * * */

export const getCarDataByBrand = async data => {
  const [resp, status] = await request(`v1/api/car/v1/api/car`, 'POST', data);
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getAllCarBrands
 * * * * * * * * * * * * * * */

export const getAllCarBrands = async () => {
  const [resp, status] = await request(`v1/api/car/v1/api/brands`, 'GET');
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getModelsByBrand
 * * * * * * * * * * * * * * */

export const getModelsByBrand = async brand => {
  const [resp, status] = await request(`v1/api/car/v1/api/models`, 'POST', {
    brand,
  });
  return [resp, status];
};

/* * * * * * * * * * * * * * *
 * getDataAboutPlateNr
 * * * * * * * * * * * * * * */

export const getDataAboutPlateNr = async plateNumber => {
  const [resp, status] = await request(`v1/api/car/v1/api/license_plate/${plateNumber.replace(/\s/g, '')}`);
  return [resp, status];
};