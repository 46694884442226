import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { getClaimRoutes } from './../helpers';

const useNewClaimData = () => {
  const { pathname } = useLocation();
  const currentStep =
    pathname.split('/').length > 3
      ? pathname.slice(0, pathname.lastIndexOf('/'))
      : pathname;

  const getClaimData = claimId => {
    const storageArray = sessionStorage['claimStorage']
      ? JSON.parse(sessionStorage.getItem('claimStorage'))
      : [];
    const id = claimId || sessionStorage.getItem('_id');
    return storageArray.find(e => e._id === id) || { claim_data: {} };
  };

  const initializeStorage = ({ case_id: id, claim_id }) => {
    const storageArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];

    // check if id exist in sessionStorage
    if (storageArray.some(e => e._id === id)) return;

    sessionStorage.setItem('_id', id);
    const storeData = {
      _id: id,
      _claim_id: claim_id,
      creation_date: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      step: '/new-claim/category',
      complete: false,
    };

    storageArray.push(storeData);
    sessionStorage.setItem('claimStorage', JSON.stringify(storageArray));
  };

  const initializeClaimType = claimType => {
    // get inital id data
    const initialData = getClaimData();

    // set claimtype in object
    initialData.case = claimType;

    // get all storage
    let storageArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];
    // remove current id from storage
    const id = sessionStorage.getItem('_id');
    storageArray = storageArray.filter(e => e._id !== id);

    // add the the new data to storage array
    storageArray.push(initialData);

    // store data in sessionStorage
    sessionStorage.setItem('claimStorage', JSON.stringify(storageArray));
  };

  const storeClaimData = data => {
    // get inital id data
    const complete = ['travel'].includes(pathname.split('/')[2]);
    let { routingObject, step } = getClaimRoutes(getClaimData);
    step = routingObject[currentStep];
    const initialData = { ...getClaimData(), step, complete };

    // set claimtype in object
    const storedClaimData = initialData.claim_data || {};

    // remove if propert is null
    Object.keys(data).forEach(
      key => data[key] === undefined && delete data[key],
    );

    // set claimtype in object
    initialData.claim_data = { ...storedClaimData, ...data };

    // get all storage
    let storageArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];

    const id = sessionStorage.getItem('_id');
    // remove current id from storage
    storageArray = storageArray.filter(e => e._id !== id);

    // add the the new data to storage array
    storageArray.push(initialData);

    // store data in sessionStorage
    sessionStorage.setItem('claimStorage', JSON.stringify(storageArray));
  };

  return {
    initializeStorage,
    initializeClaimType,
    getClaimData,
    storeClaimData,
  };
};
export default useNewClaimData;
