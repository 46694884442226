import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckOption, ActionButton, Section } from 'wg-fe-ui';
import { FlightContacted, Lugage } from '../../../icons';
import GoogleMap from '../../../components/GoogleMap';
import styled from 'styled-components';

const AddressConfirm = ({ history, setValues, prefix }) => {
  const { id } = useParams();
  const [checked, setChecked] = useState({
    clientWantsRepaier: false,
    certifiedRepaier: false,
  });

  const setCheckedValues = ({ name }) => {
    setChecked(oldVal => ({ ...oldVal, [name]: !oldVal[name] }));
  };

  const onClick = e => {
    if (e) e.preventDefault();
    setValues(old => ({ ...old, ...checked }));
    const view = checked.certifiedRepaier ? 'repairer-select' : 'repairer-info';
    history.push(`${prefix}/${view}/${id}`);
  };

  return (
    <>
      <Section>
        <Section.Title>Confirm address</Section.Title>
        <Section.Content>
          <StyledMap />
        </Section.Content>
      </Section>
      <CheckBoxes>
        <CheckOption
          icon={<Lugage />}
          handleClick={setCheckedValues}
          checked={checked.clientWantsRepaier}
          name="clientWantsRepaier"
        >
          The client wants to work with a certified repairer
        </CheckOption>
        <CheckOption
          icon={<FlightContacted />}
          handleClick={setCheckedValues}
          checked={checked.certifiedRepaier}
          name="certifiedRepaier"
        >
          Certified repairer
        </CheckOption>
      </CheckBoxes>
      <StyledSection>
        <Section.Title>Wat is gedekt onder je woningverzekering?</Section.Title>
        <Section.Content>
          Een woningverzekering dekt de aan je woning door diverse zaken, zoals
          brand, waterschade, glasbreuk, explosie, rookschade, blikseminslag en
          natuurrampen. Ook worden soms eventuele kosten ten gevolge van een
          schadegeval gedekt. Indien onderschreven, valt diefstal en vandalisme
          ook onder deze verzekering.
        </Section.Content>
        <Section.Content>
          Opgelet: indien het gaat om schade die door de verzekerde opzettelijk
          werd veroorzaakt, of om schade ten gevolge van een oorlog, een
          bezetting of inbeslagname, kan je geen beroep doen op je
          woningverzekering.
        </Section.Content>
      </StyledSection>
      <ActionButton onClick={onClick}>Confirm</ActionButton>
    </>
  );
};

const StyledMap = styled(GoogleMap)`
  margin: 3.5rem 0;
  width: 100rem;
  height: 35rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
`;

const StyledSection = styled(Section)`
  & * {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #696969;
  }
  ${Section.Title} {
    font-weight: 900;
  }

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

const CheckBoxes = styled.div`
  display: flex;
  label {
    margin-right: 2rem;
    margin-bottom: 3rem;
  }
`;

export default AddressConfirm;
