import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { CheckOption, ActionButton } from 'wg-fe-ui';
import styled from 'styled-components';
import { InjuredIcon, WitnessIcon } from '../../../icons';
import TextArea from '../../../components/StyledTextArea';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

const ClaimDetails = forwardRef(({
  setValues,
  handleSection,
  storeClaimData,
}, ref) => {
  const [checked, setChecked] = useState({
    witness: false,
    injured: false,
  });

  // temporarily
  const [desc, setDesc] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { addParam } = useQueryParams();
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    click: () => {
      handleFormData();
    },
  }));

  const handleFormData = async e => {
    if (e) e.preventDefault();
    setValues(old => ({ ...old, ...checked }));

    // const [, status] = await updateClaim(claimId, {
    //   claim: { description: desc || null },
    // });
    /* If any check is checked, it'll redirect to witnessList,
      otherwise to involved
    */
    if (Object.values(checked).includes(true)) {
      storeClaimData({ ...checked, accidentDescription: desc });
      history.push({
        pathname: `/new-claim/witness-list/${id}`,
        state: pathname,
      });
      return;
    }
    // status < 300 && addParam({ submit: true });
    addParam({ submit: true });
  };

  const handleClick = ({ name, value }) => {
    setChecked({ ...checked, [name]: !checked[value] });
  };

  useEffect(() => {
    if (checked.witness || checked.injured)
      handleSection({ section: 0, subsection: 1 });
    else handleSection({ section: 0, subsection: 0 });
  }, [checked]);

  return (
    <>
      <Container>
        <StyledHeader> {t('Special circumstances')} ({t('optional')}) </StyledHeader>
        <form>
          <CheckOption
            icon={<WitnessIcon />}
            checked={checked['witness']}
            name="witness"
            handleClick={handleClick}
            value="witness"
            key="witness"
          >
            {t('There is a witness')}
          </CheckOption>
          <CheckOption
            icon={<InjuredIcon />}
            checked={checked['injured']}
            name="injured"
            handleClick={handleClick}
            value="injured"
            key="injured"
          >
            {t('Someone was injured')}
          </CheckOption>
        </form>
      </Container>
      {checked.witness || checked.injured ? (
        <Container>
          <h2> {t('Circumstance remark')} </h2>
          <StyledDescParagraph>
            {t('Fill in all the relevant details. The more information provided, the more effectively we can investigate your claim')}
          </StyledDescParagraph>
          <form>
            <TextArea
              maxLength={1000}
              placeholder={t('Type here to add extra information')}
              onChange={({ value }) => setDesc(value)}
              name="accidentDescription"
              value={desc}
              counter={true}
            />
          </form>
        </Container>
      ) : null}
      <StyledBtn onClick={handleFormData} data-test-id='continueButton'>{t('Continue')}</StyledBtn>
    </>
  );
});

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Container = styled.div`
  margin-bottom: 33px;
  width: 52vw;
  h2 {
    line-height: 2.1rem !important;
  }
  &:last-of-type {
    margin-bottom: 27px;
  }
  form {
    display: flex;
    & > label {
      width: 100%;
      :first-child {
        margin-right: 1.25vw;
      }
      @media (max-width: 600px) {
        width: 100%;
        flex-basis: 100%;
        padding: 30px 10px;
        box-sizing: border-box;
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    form {
      flex-direction: column;

      & > label:first-child {
        margin-right: 0;
        margin-bottom: 15px; 
      }
    }
  }
`;

const StyledDescParagraph = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  margin-top: 0.3rem;
  color: ${({ theme }) => theme.typo.highlight};
`;

const StyledHeader = styled.h2`
  margin-bottom: 2.4rem;
`;

export default ClaimDetails;
