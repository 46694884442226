import React, { useState } from 'react';
import { TextInput, ActionButton } from 'wg-fe-ui';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useForm from '../hooks/useForm';
import { useTranslation } from 'react-i18next';
import validationSchemas from '../constants/validationSchemas.js';
import { auth } from '../helpers/apiRouterService.js';

const AdminLogin = () => {
  const [buttonState, setButtonState] = useState('Login');
  const { handleChange, values, handleSubmit, errors } = useForm({
    validationSchema: validationSchemas.adminLogin,
    change: () => { },
  });
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async data => {
    setButtonState('Pending');
    const [, status] = await auth(data);
    status === 200 && history.push('/cases');
  };

  return (
    <Container>
      <H1>WeGroup {t('Login')}</H1>
      <TextInput
        style={{ marginBottom: '1rem' }}
        name="email"
        type="email"
        error={errors.email}
        value={values.email}
        onChange={handleChange}
      >
        {t('Email')}
      </TextInput>
      <TextInput
        type="password"
        name="password"
        error={errors.password}
        value={values.password}
        onChange={handleChange}
      >
        {t('Password')}
      </TextInput>
      <StyledBtn
        onClick={() => {
          handleSubmit(onSubmit);
        }}
        style={{ marginTop: '2rem' }}
        type="submit"
        value={t('login')}
        disabled={buttonState !== 'Login'}
      >
        {buttonState}
      </StyledBtn>
    </Container>
  );
};

const H1 = styled.h1`
  font-size: 2.6rem;
  font-weight: 900;
  margin-bottom: 2rem;
`;

const StyledBtn = styled(ActionButton)`
  @media(max-width: 600px) {
    width: 100%;
  }
`

StyledBtn.displayName = 'StyledBtn';

const Container = styled.div`
  padding-top: 33rem;
  width: 46rem;
  margin: 0 auto;

  @media(max-width: 600px) {
    width: initial;
  }
`;

Container.displayName = 'Container';

export default AdminLogin;
