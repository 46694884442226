import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ActionButton, TextArea, Section, SearchSelectInput } from 'wg-fe-ui';
import styled from 'styled-components';
import useQueryParams from './../../../hooks/useQueryParams';
import { generateForm } from '../../../helpers/formsService';
import {
  modifyEntity,
  getCurrentEntity,
} from '../../../helpers/entitiesService';
import { useParams } from 'react-router-dom';
import { Input, FormField } from '../../../styledComponents';
import useNewClaimData from '../../../hooks/useNewClaimData';
import { getInsuranceCompanies } from '../../../helpers/apiRouterService';
import { handleCompaniesResponse } from '../../../helpers/objectService';
import Address from '../../../components/Address';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';

const forms = {
  contact_information: [
    { name: 'first_name', label: 'First name', type: 'input' },
    { name: 'last_name', label: 'Last name', type: 'input' },
    { name: 'email', label: 'Email', type: 'input' },
    { name: 'telephonenr', label: 'Phone number', type: 'phone_input' },
  ],
};

const ThirdPartyInfo = forwardRef(({
  validateForms,
  setValues,
  handleSection,
  claimId
}, ref) => {
  const [thirdParty, setThirdParty] = useState({ country: 'Belgium' });
  const { id } = useParams();
  const { addParam } = useQueryParams();
  const { getClaimData } = useNewClaimData();
  const category = getClaimData().case;
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    click: () => {
      handleFormData();
    },
  }));

  const handleThirdPartyChange = ({ name, value }) => {
    setThirdParty(data => ({ ...data, [name]: value }));
  };

  const fetchInsuranceCompanies = async () => {
    const [resp, status] = await getInsuranceCompanies();
    if (status !== 200) return;
    resp &&
      setInsuranceCompanies(handleCompaniesResponse(resp.insurance_companies));
  };

  const handleFormData = async e => {
    if (e) e.preventDefault();
    const [validationErrors, hasErrors] = await validateForms(
      {
        ...thirdParty,
      },
      'thirdpartyInfo',
    );
    setErrors(() => mapValues(validationErrors, (e) => t(e)));;
    if (hasErrors) return;

    setValues(oldValues => ({
      ...oldValues,
      thirdParties: oldValues.thirdparty
        ? [...oldValues.thirdParties, thirdParty]
        : [thirdParty],
    }));

    const [status,] = await modifyEntity(thirdParty, claimId, 'OPPOSING');
    status === 201 && addParam({ submit: true });
  };

  useEffect(() => {
    fetchInsuranceCompanies();
  }, []);

  useEffect(() => {
    const currentEntity = getCurrentEntity(id);
    setThirdParty(prev =>
      Object.keys(currentEntity).length ? currentEntity : prev,
    );
    handleSection({ section: 1, subsection: 0 });
  }, []);

  return (
    <form onSubmit={handleFormData}>
      <Section>
        <Section.Title>{t('Contact information')}</Section.Title>
        <StyledContent>
          {generateForm(
            forms.contact_information,
            handleThirdPartyChange,
            errors,
            thirdParty,
            t
          )}
        </StyledContent>
      </Section>
      <Section>
        <Section.Title>{t('Address')}</Section.Title>
        <StyledContent>
          <Address
            handleEntityChange={setThirdParty}
            entity={thirdParty}
            errors={errors}
          />
          <FormField>
            <Input
              name="iban"
              onChange={handleThirdPartyChange}
              value={thirdParty['iban'] || ''}
              error={errors['iban']}
            >
              {t('IBAN')}
            </Input>
          </FormField>
        </StyledContent>
      </Section>
      <Section>
        <Section.Title>{t('Insurance information')}</Section.Title>
        <StyledContent>
          <FormField>
            <Input
              name="policy_nr"
              type="input"
              placeholder="_ _ _-_ _ _-_ _"
              error={errors['policy_nr']}
              onChange={handleThirdPartyChange}
            >
              {t('Policy number')}
            </Input>
          </FormField>
          {category === 'CAR' && (
            <FormField>
              <Input
                name="license_plate"
                type="input"
                placeholder="_ _ _-_ _ _-_ _"
                error={errors['license_plate']}
              >
                {t('License plate')}
              </Input>
            </FormField>
          )}
          <FormField>
            <SearchSelectInput
              name="insured_at"
              type="input"
              onSelected={handleThirdPartyChange}
              error={errors['insured_at']}
              options={insuranceCompanies}
              value={
                thirdParty.insuredAt
                  ? insuranceCompanies.find(
                    c => c.value === thirdParty.insuredAt,
                  )
                  : null
              }
            >
              {t('Insured at')}
            </SearchSelectInput>
          </FormField>
          <StyledTextArea
            name="comment"
            onChange={handleThirdPartyChange}
            error={errors.comment}
            value={thirdParty.comment}
            placeholder={t('Type here to add extra information')}
          >
            {t('Comment')}
          </StyledTextArea>
        </StyledContent>
      </Section>
      <StyledButton>{t('Continue')}</StyledButton>
    </form>
  );
});

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 15px;
`;

const StyledTextArea = styled(TextArea)`
  margin: 13px 0;
  width: 100%;
  textarea {
    min-height: 140px;
    max-height: 240px;
    box-sizing: border-box;
  }
`;
const StyledContent = styled(Section.Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px 57px 34px 37px;
`;

export default ThirdPartyInfo;
