import React from 'react';
import { string } from 'prop-types';

const WhatsApp = ({ className, width = 30, height = 30 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M17.0326 0.100037C23.4218 0.738068 27.5013 4.77894 29.3967 10.8965C29.723 11.9349 29.8109 13.0483 30.0117 14.1242C30.0117 14.6246 30.0117 15.1251 30.0117 15.6255C29.9113 16.276 29.836 16.9391 29.7105 17.5771C27.9029 26.8473 18.3381 32.1768 9.53892 28.6864C8.19582 28.1484 7.05356 28.0734 5.73557 28.4987C3.92804 29.0617 2.08285 29.5121 0.262765 30.0125C0.174899 30.0125 0.0995849 30.0125 0.0117188 30.0125C0.0493757 29.8123 0.061928 29.5996 0.112137 29.412C0.664439 27.3603 1.19164 25.3085 1.8067 23.2819C2.00754 22.6063 1.93222 22.0809 1.63097 21.4678C-2.51129 13.2485 1.88201 3.1776 10.7188 0.600454C11.5598 0.350245 13.7565 -0.225235 17.0326 0.100037ZM3.56402 26.447C4.99498 26.0717 6.26277 25.7589 7.53055 25.3961C8.00754 25.2585 8.38411 25.296 8.82344 25.5462C11.2962 26.9724 13.9573 27.5354 16.7816 27.0975C23.6226 26.0467 28.1665 19.9291 27.2502 13.0608C26.3213 6.09253 19.2418 1.21346 12.3632 2.80229C3.9908 4.72889 0.0619282 13.8615 4.43013 21.2677C4.70628 21.7431 4.76904 22.1434 4.61841 22.6688C4.24185 23.8699 3.92804 25.0834 3.56402 26.447Z" />
      <path d="M18.8779 22.0313C17.1582 21.9187 15.5892 21.2932 14.1331 20.4174C11.4469 18.8161 9.41343 16.5767 7.98247 13.8244C6.96573 11.8853 7.24188 10.0338 8.59753 8.55756C9.08707 8.01961 10.1289 7.74438 10.7816 8.04463C10.995 8.14471 11.1833 8.40743 11.2837 8.64513C11.6854 9.55839 12.1122 10.4717 12.4134 11.4099C12.5138 11.7227 12.313 12.1355 12.2251 12.4983C12.2 12.6109 12.0871 12.6985 11.9992 12.7986C11.1456 13.8495 11.1456 13.8495 11.9615 14.9754C13.1038 16.5517 14.5724 17.7027 16.3674 18.4658C16.8193 18.6535 17.108 18.5784 17.3841 18.2031C17.6854 17.8028 18.0369 17.44 18.313 17.0271C18.5766 16.6393 18.8904 16.5767 19.2795 16.7644C20.2586 17.2273 21.2502 17.6777 22.2042 18.1781C22.38 18.2656 22.5557 18.5534 22.5557 18.7536C22.5557 19.7294 22.2921 20.5926 21.4385 21.1931C20.6854 21.731 19.8569 22.0813 18.8779 22.0313Z" />
    </svg>
  );
};

WhatsApp.propTypes = {
  className: string,
};

export default WhatsApp;
