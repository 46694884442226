import React from 'react';
import { string } from 'prop-types';

const Mail = ({ className, width = 26, height = 16, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.8124 8.25322L21.6384 4.24121V12.161C21.6384 12.4215 21.43 12.6195 21.1799 12.6195H4.73586C4.47534 12.6195 4.27734 12.4111 4.27734 12.161V4.26205L11.9783 8.2428C12.2597 8.38869 12.5827 8.47206 12.8954 8.47206C13.208 8.47206 13.531 8.39911 13.8124 8.25322Z"
        fill="#C1C1C1"
      />
      <path
        d="M24.4635 1.02124L25.8182 0.323045C25.9745 0.239679 25.9224 0 25.7348 0H0.172587C-0.00456679 0 -0.0670916 0.239679 0.0892205 0.323045L12.6984 6.84647C12.8234 6.909 12.9693 6.909 13.0944 6.84647L23.3485 1.59438L23.3172 11.4525L21.9833 14.7559C21.9312 14.8913 22.025 15.0268 22.1709 15.0268H22.6294L23.1922 14.2765L23.4631 15.0268H25.5994C25.7452 15.0268 25.839 14.8809 25.7869 14.7559L24.4218 11.3691L24.4635 1.02124Z"
        fill="#C1C1C1"
      />
    </svg>
  );
};

Mail.propTypes = {
  className: string,
};

export default Mail;
