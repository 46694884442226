import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { ActionButton, Label, Section } from 'wg-fe-ui';
import styled from 'styled-components';
import DataBar from './../../../components/DataBar';
import { useHistory, useParams } from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';
import { getEntities, deleteEntity } from '../../../helpers/apiRouterService';
import { modifyEntityStorage } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { StyledEntityButton } from './../../../styledComponents';

const WitnessList = forwardRef(({ handleSection, claimData, claimId, prefix }, ref) => {
  const { id } = useParams();
  const history = useHistory();
  const [witnesses, setWitnesses] = useState([]);
  const [injureds, setInjureds] = useState([]);
  const { addParam } = useQueryParams();
  const { t } = useTranslation();
  const witnessCheck = claimData.witness;
  const injuredCheck = claimData.injured;

  useImperativeHandle(ref, () => ({
    click: () => {
      handleFormData();
    },
  }));

  const getAllEntities = async () => {
    const [resp, status] = await getEntities(claimId);
    if (status > 300) return;
    const witnessPeople = resp.values.filter(r => r.type === 'WITNESS');
    const injuredPeople = resp.values.filter(r => r.type === 'INJURED');
    setWitnesses(witnessPeople);
    setInjureds(injuredPeople);
  };

  const onEdit = (e, type) => {
    modifyEntityStorage(false, e);
    if (type === 'WITNESS') witnessClick();
    else if (type === 'INJURED') injuredClick();
  };

  const onDelete = async (id, type) => {
    const [, status] = await deleteEntity(claimId, id);
    if (status !== 200) return;

    if (type === 'WITNESS')
      setWitnesses(witnesses => witnesses.filter(p => p.id !== id));
    else if (type === 'INJURED')
      setInjureds(injureds => injureds.filter(p => p.id !== id));

    modifyEntityStorage(true);
  };

  modifyEntityStorage(true);

  const witnessClick = () => {
    history.push(`${prefix}/witness-info/${id}`);
  };
  const injuredClick = () => {
    history.push(`${prefix}/injured-info/${id}`);
  };

  const handleFormData = e => {
    if (e) e.preventDefault();
    if (claimData.thirdParty === 'Know')
      return history.push(`${prefix}/involved/${id}`);
    addParam({ submit: true });
  };

  useEffect(() => {
    getAllEntities();
    handleSection({ section: 1, subsection: 0 });
  }, []);

  return (
    <>
      {witnessCheck && (
        <Section>
          <Section.Title>{t('Witness list')}</Section.Title>
          <Desc>{t('Add a witness and fill in all contact information')}</Desc>
          {witnesses.map((w, id) => (
            <DataBar
              key={id}
              isEdit
              isDelete
              data={{
                keys: [t('Name'), t('Phone'), t('Email')],
                values: [
                  `${w.first_name} ${w.last_name}`,
                  w.telephonenr,
                  w.email,
                ],
              }}
              onEdit={() => onEdit(w, 'WITNESS')}
              onDelete={() => onDelete(w.id, 'WITNESS')}
            />
          ))}
          <StyledEntityButton onClick={witnessClick} name={t('Add a witness')} data-test-id='addWitnessButton'/>
        </Section>
      )}
      {injuredCheck && (
        <StyledSection>
          <Section.Title>{t('Injured list')}</Section.Title>
          <Desc>{t('Add a injured and fill in all contact information')}</Desc>
          {injureds.map((i, id) => (
            <DataBar
              key={id}
              isEdit
              isDelete
              data={{
                keys: [t('Name'), t('Phone'), t('Email')],
                values: [
                  `${i.first_name} ${i.last_name}`,
                  i.telephonenr,
                  i.email,
                ],
              }}
              onEdit={() => onEdit(i, 'INJURED')}
              onDelete={() => onDelete(i.id, 'INJURED')}
            />
          ))}
          <StyledEntityButton onClick={injuredClick} name={t('Add a injured')} data-test-id='addInjuredButton'/>
        </StyledSection>
      )}
      <ActionButton onClick={handleFormData} data-test-id='continueButton'>{t(`Continue`)}</ActionButton>
    </>
  );
});

const Desc = styled(Label)`
  margin-bottom: 1.1rem;
  color: ${({ theme }) => theme.typo.hightlight};
  line-height: 2rem;
  margin-top: 0.5rem;
`;

const StyledSection = styled(Section)`
  margin-bottom: 2.8rem;

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

export default WitnessList;
