import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import {
  FlightSelectBar,
  ActionButton,
  Section,
  SearchSelectInput,
  PhoneInput,
} from 'wg-fe-ui';
import styled from 'styled-components';
import { geocodeAddress } from '../../../helpers/apiRouterService';
import useQueryParams from '../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { updateClaim } from '../../../helpers/apiRouterService';
import RepairerInfo from '../../../components/RepairerInfo';
import { Input } from '../../../styledComponents';
import { callCities } from '../../../helpers/addressService';
import { mapValues } from 'lodash';
import { API_KEY } from '../../../env.config.js';

const RepairerSelect = forwardRef(
  ({ handleSection, claimId, validateForms, setValues }, ref) => {
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);
    const [repairerSearchData, setRepairerSearchData] = useState({
      name: '',
      city: '',
    });
    const [foundRepairers, setFoundRepairers] = useState([]);
    const [selectedRepairer, setSelectedRepairer] = useState({
      name: '',
      telephonenr: '',
      email: '',
    });
    const [uncertifiedForm, setUncertifiedForm] = useState(false);
    const { addParam, removeParams } = useQueryParams();
    const { t } = useTranslation();
    const scriptRef = useRef();
    const placesNodeRef = useRef();
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, () => ({
      click: () => {
        handleFormData();
      },
    }));

    useEffect(() => {
      if (!searchButtonDisabled || !selectedRepairer) {
        addParam({ disabled: true });
      } else {
        removeParams(['disabled']);
      }
    }, [selectedRepairer]);

    useEffect(() => {
      handleSection({ section: 2, subsection: 0 });
      scriptRef.current = document.createElement('script');
      scriptRef.current.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
      window.document.body.appendChild(scriptRef.current);

      return () => {
        window.document.body.removeChild(scriptRef.current);
      };
    }, []);

    const searchAddressByQuery = async () => {
      const service = new window.google.maps.places.PlacesService(
        placesNodeRef.current,
      );
      service.findPlaceFromQuery(
        {
          query: `${repairerSearchData.name} ${repairerSearchData.city}`,
          fields: ['name', 'formatted_address', 'place_id'],
        },
        async (res, status) => {
          if (status !== 'OK') {
            setFoundRepairers([]);
            return;
          }
          const parsedRepairers = await parseRepairers(res);
          setFoundRepairers(parsedRepairers);
        },
      );
    };

    /**
     * Return parsed repairers array
     * @param {object[]} repairersArray
     */
    const parseRepairers = async (repairersArray) => {
      const output = repairersArray
        .map(async (repairer) => {
          const [res, status] = await geocodeAddress(
            repairer.formatted_address,
          );
          if (status >= 300) return null;
          return {
            address: res[0],
            name: repairer.name,
          };
        })
        .filter((r) => r !== null);
      return await Promise.all(output);
    };
    const handleSearchClick = async () => {
      setSearchButtonDisabled(true);
      searchAddressByQuery();
    };
    const handleRepairerChange = ({ name, value }) => {
      if (name === 'city')
        setRepairerSearchData((old) => ({ ...old, [name]: value.val }));
      else setRepairerSearchData((old) => ({ ...old, [name]: value }));
      setSearchButtonDisabled(false);
    };

    const handleFormData = async (e) => {
      if (e) e.preventDefault();
      const [validationErrors, hasErrors] = await validateForms(
        {
          ...selectedRepairer,
        },
        'repairerSelect',
      );
      setErrors(() => mapValues(validationErrors, (e) => t(e)));
      if (hasErrors) return;
      const [, status] = await updateClaim(claimId, {
        claim: { repairer: selectedRepairer },
      });

      status < 300 && addParam({ submit: true });
      addParam({ submit: true });
    };

    const handleSelectedRepairerChange = ({ name, value }) => {
      setSelectedRepairer((old) => ({ ...old, [name]: value }));
    };

    const goToUncertified = () => {
      setUncertifiedForm(true);
    };

    if (!uncertifiedForm)
      return (
        <>
          <Section>
            <Section.Title>{t('Select a certified repairer')}</Section.Title>
            <StyledContent background padding>
              <InputsRow margin={!!selectedRepairer.name}>
                <Input
                  name="name"
                  onChange={handleRepairerChange}
                  value={repairerSearchData.name}
                >
                  {t('Repairer name')}
                </Input>
                <SearchSelectInput
                  async
                  name="city"
                  loadOptions={(q, cb) => callCities(q, cb, repairerSearchData)}
                  defaultOptions={true}
                  onSelected={handleRepairerChange}
                  cacheOptions={false}
                  value={
                    repairerSearchData.city
                      ? {
                          label: repairerSearchData.city,
                          value: repairerSearchData.city,
                        }
                      : undefined
                  }
                  placeholder={t('Choose your option')}
                >
                  {t(`City`)}
                </SearchSelectInput>
              </InputsRow>
              {selectedRepairer.name && (
                <InputsRow>
                  <Input
                    name="email"
                    error={errors['email']}
                    value={selectedRepairer.email}
                    onChange={handleSelectedRepairerChange}
                  >
                    {t(`Email`)}
                  </Input>
                  <PhoneInput
                    name="telephonenr"
                    error={errors['telephonenr']}
                    symbol="IconActionPhone"
                    symbolSide="right"
                    value={selectedRepairer.telephonenr}
                    handleChange={handleSelectedRepairerChange}
                  >
                    {t(`Phone number`)}
                  </PhoneInput>
                </InputsRow>
              )}
            </StyledContent>
            <SearchBox>
              <StyledBtn
                onClick={handleSearchClick}
                disabled={searchButtonDisabled}
                data-test-id="searchButton"
              >
                {t('Search')}
              </StyledBtn>
              <StyledPLink onClick={goToUncertified}>
                {t('Want to use a non certified repairer?')}
              </StyledPLink>
            </SearchBox>
            {foundRepairers.length
              ? foundRepairers.map((r) => (
                  <StyledContent key={r.name}>
                    <FlightSelectBar
                      value={JSON.stringify(r)}
                      name="selectedRepairer"
                      checked={selectedRepairer.name === r.name}
                      handleChange={({ value }) => {
                        setSelectedRepairer(JSON.parse(value));
                      }}
                    >
                      <FlightSelectBar.FlightData
                        border
                        data={r.name}
                        label={t('Name')}
                      />
                      <FlightSelectBar.FlightData
                        data={`${r.address.street} ${r.address.housenr}, ${
                          r.address.zipcode
                        } ${r.address.city || ''}`}
                        label={t('Address')}
                      />
                      <FlightSelectBar.FlightData
                        data="2.5 km"
                        label={t('Distance')}
                      />
                    </FlightSelectBar>
                  </StyledContent>
                ))
              : null}
          </Section>
          {foundRepairers.length ? (
            <StyledBtn
              onClick={handleFormData}
              disabled={!searchButtonDisabled && !selectedRepairer}
              data-test-id="continueButton"
            >
              {t('Continue')}
            </StyledBtn>
          ) : null}
          <div ref={placesNodeRef}></div>
        </>
      );
    return (
      <RepairerInfo
        validateForms={validateForms}
        setValues={setValues}
        claimId={claimId}
      />
    );
  },
);

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const StyledContent = styled(Section.Content)`
  padding: ${({ padding }) => (padding ? '3rem 1.8vw 1rem' : null)};
  & > * {
    box-sizing: border-box;
    width: 100%;
  }

  @media (max-width: 600px) {
    padding: 15px 30px;
  }
`;

const InputsRow = styled.div`
  display: flex;
  margin-bottom: ${({ margin }) => (margin ? '2rem' : null)};
  & > div {
    max-width: 400px;
    &:last-of-type {
      margin-left: 10rem;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    & > div {
      min-width: 100%;
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  p {
    margin-left: 6rem;
    max-width: 796px;
  }
  p,
  span {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #666666;
    letter-spacing: 0.924996px;
    span {
      font-weight: 900;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    p {
      margin-left: 0;
    }
  }
`;

const StyledPLink = styled.p`
  font-size: 1.4rem;
  line-height: 1.8rem;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;

  color: #3297fd !important;
`;

StyledPLink.displayName = 'PLink';

RepairerSelect.displayName = 'RepairerSelect';

export default RepairerSelect;
