import React from 'react';
import styled, { css } from 'styled-components';
import { Label, TextBold } from 'wg-fe-ui';
import PropTypes from 'prop-types';
import CloseIcon from '../icons/Close';

const DataBar = ({
  className,
  children,
  data,
  isEdit,
  isDelete,
  onEdit,
  onDelete,
  checked,
  onChange,
  name,
  ...otherProps
}) => {
  //const [data, setData] = useState({ ...records });

  // const handleClick = () => {
  //   setData(prev => ({
  //     keys: [...prev.keys],
  //     values: [...prev.values.map(() => '')],
  //   }));
  //   onDelete();
  // };

  const handleChange = e => {
    onChange({ name: e.target.name, value: e.target.value });
  };

  if (onChange) {
    return data.values.length ? (
      <Box className={className} clickable={true} checked={checked}>
        <Data>
          <StyledCheck checked={checked} />
          <StyledInput
            type="radio"
            onChange={handleChange}
            checked={checked}
            name={name}
            {...otherProps}
          />
          {data.keys.map((k, i) => (
            <Field key={i} isEdit isDelete>
              <StyledLabel>{k}</StyledLabel>
              <Entry>{data.values[i]}</Entry>
            </Field>
          ))}
        </Data>
        {children}
        {isEdit || isDelete ? (
          <Actions>
            {isEdit && <span onClick={onEdit}>Edit</span>}
            {isDelete && <CloseIcon onClick={onDelete} />}
          </Actions>
        ) : null}
      </Box>
    ) : null;
  }
  return data.values.length ? (
    <Box className={className}>
      <Data>
        {data.keys.map((k, i) => (
          <Field key={i}>
            <StyledLabel>{k}</StyledLabel>
            <Entry>{data.values[i]}</Entry>
          </Field>
        ))}
      </Data>
      {children}
      {isEdit || isDelete ? (
        <Actions>
          {isEdit ? <span onClick={onEdit}>Edit</span> : <span></span>}
          {isDelete && <CloseIcon onClick={onDelete} />}
        </Actions>
      ) : null}
    </Box>
  ) : null;
};

const Box = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 26px;
  border: 1px solid ${({ theme }) => theme.ui.outline};
  height: 95px;
  box-sizing: border-box;
  flex-basis: 100%;
  margin-top: 15px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  ${({ checked, theme }) => checked
    && css`
      background-color: ${theme.brand.lightest};
      border-color: ${theme.brand.secondary};`
  }

  ${({ clickable }) => (clickable ? 'cursor: pointer' : null)}

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3%;

  @media(max-width: 600px) {
    width: 100%;
    align-items: center;
  }
`;

Field.displayName = 'Field';

const Data = styled.div`
  display: flex;
  justify-content: flex-start;
  width: calc(90% - 5vw);

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  width: 10%;

  * {
    cursor: pointer;
  }

  span {
    font-family: Lato;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.typo.highlight};
  }

  svg {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    align-self: initial;
    margin-top: 10px;
  }
`;

const StyledLabel = styled(Label)`
  font-family: Lato;
`;

StyledLabel.displayName = 'StyledLabel';

const Entry = styled(TextBold)`
  font-family: Lato;

  color: ${({ theme }) => theme.typo.highlight};
`;

const StyledCheck = styled.span`
  height: 16px;
  min-width: 16px;
  border-radius: 10px;
  margin: auto 1.6vw;
  border: ${({ checked }) => (checked ? '5px solid' : '1.2px solid')};
  border-color: ${({ checked, theme }) =>
    checked ? theme.brand.light : theme.ui.outline};
  box-sizing: border-box;
`;

DataBar.defaultProps = {
  isEdit: false,
  isDelete: false,
  data: { keys: [], values: [] },
};

DataBar.propTypes = {
  onChange: Function.prototype,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  data: PropTypes.shape({ keys: PropTypes.array, values: PropTypes.array }),
  onEdit: Function.prototype,
  onDelete: Function.prototype,
};

export default DataBar;
