import styled from 'styled-components';
import Card from './Card';

const ClaimInformationCard = styled(Card)`
  /* TODO: cccc will be removed */
  border: 1px solid #cccccc;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  width: 52vw;
  margin-top: 1.6rem;
  min-width: 950px;
  font-size: 1.4rem;
  padding: ${({ padding }) => (padding ? padding : null)};
  flex-direction: ${({ flexDir }) => (flexDir ? flexDir : null)};

  @media (max-width: 1200px) {
    min-width: 400px;
    margin: auto;
  }
`;

export default ClaimInformationCard;
