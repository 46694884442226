import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import {
  ActionButton,
  CheckOption,
  Section,
  DateInput,
  TimeInput,
} from 'wg-fe-ui';
import styled from 'styled-components';
import FlightCanceledIcon from './../../../icons/FlightCanceled';
import FlightContactedIcon from './../../../icons/FlightContacted';
import LugageIcon from './../../../icons/Lugage';
import useQueryParams from '../../../hooks/useQueryParams';
import useNewClaimData from '../../../hooks/useNewClaimData.js';
import { updateClaim } from '../../../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';
import {
  parseDateStringToObject,
  parseTimeStringToObject,
} from '../../../helpers/dateService';
import { mapValues } from 'lodash';

const Last = forwardRef(
  ({ setValues, values, claimId, validateForms }, ref) => {
    const [checked, setChecked] = useState({
      already_contacted_airline_or_filed_a_claim: false,
      had_bagage_issues: false,
      contacted_and_accepted_other_flight: false,
      did_not_travel: false,
    });
    const { getClaimData } = useNewClaimData();
    const data = getClaimData();
    const issue = data.claim_data.issue;
    const refDate = useRef();
    const { addParam } = useQueryParams();
    const [errors, setErrors] = useState({});
    const [cancellationTime, setCancellationTime] = useState();
    const [cancellationDate, setCancellationDate] = useState();

    const { t } = useTranslation();

    const sendCause = async (date, time) => {
      const reqBody = {
        claim: {
          ...checked,
          cancellation_notice_date: date ? { ...date, ...time } : null,
        },
      };
      const [, status] = await updateClaim(claimId, { ...reqBody });
      return status;
    };

    const handleFormData = async () => {
      let date, time;
      if (issue === 'CANCELLED_OR_REROUTED') {
        date = parseDateStringToObject(cancellationDate);
        time = parseTimeStringToObject(cancellationTime);
        const [validationErrors, hasErrors] = await validateForms(
          {
            cancellation_date: date,
            cancellation_time: time,
          },
          'last',
        );
        if (hasErrors)
          return setErrors(() => mapValues(validationErrors, e => t(e)));
      }
      const status = await sendCause(date, time);
      setValues(values => ({
        ...values,
        cancellation_notice_date: date ? { ...date, ...time } : null,
      }));
      status < 300 && addParam({ submit: true });
    };

    useImperativeHandle(ref, () => ({
      click: () => handleFormData(),
    }));

    const handleClick = ({ name }) => {
      setChecked({ ...checked, [name]: !checked[name] });
    };

    React.useEffect(() => {
      setValues(values => ({ ...values, ...checked }));
    }, [checked]);

    return (
      <>
        <Section style={{ marginBottom: '34px' }}>
          <Section.Title>
            {t("We're almost there. Please select what applies below")}
          </Section.Title>
          <Wrapper data-test-id="last-flight-info">
            {(issue === 'OTHER' ||
              issue === 'ENTRY_DENIED' ||
              issue === 'CANCELLED_OR_REROUTED') &&
              (issue !== 'OTHER' ? (
                <StyledCheck
                  icon={<FlightCanceledIcon />}
                  handleClick={handleClick}
                  checked={checked['contacted_and_accepted_other_flight']}
                  name="contacted_and_accepted_other_flight"
                >
                  {t('I received and')}{' '}
                  <Bold>{t('accepted another flight')}</Bold>{' '}
                  {t('from theairline')}
                </StyledCheck>
              ) : (
                <StyledCheck
                  icon={<FlightContactedIcon />}
                  handleClick={handleClick}
                  checked={checked['did_not_travel']}
                  name="did_not_travel"
                >
                  {t('I traveled')} <Bold>{t('after being notified')}</Bold>{' '}
                  {t('of the issues')}
                </StyledCheck>
              ))}
            <StyledCheck
              icon={<LugageIcon />}
              handleClick={handleClick}
              checked={checked['had_bagage_issues']}
              name="had_bagage_issues"
            >
              {t('I encountered issues with')} <Bold>{t('my lugage')}</Bold>
            </StyledCheck>
            <StyledCheck
              icon={<FlightContactedIcon />}
              handleClick={handleClick}
              checked={checked['already_contacted_airline_or_filed_a_claim']}
              name="already_contacted_airline_or_filed_a_claim"
            >
              {t('I already')} <Bold>{t('contacted the airline')} </Bold>
              {t('of filed a claim')}
            </StyledCheck>
          </Wrapper>
        </Section>
        {issue === 'CANCELLED_OR_REROUTED' && (
          <Section style={{ marginBottom: '34px' }}>
            <Section.Title>
              {t(
                'When did you receive notice of the cancellation of the flight',
              )}
            </Section.Title>
            <StyledContent background>
              <StyledDateInput
                ref={refDate}
                error={
                  errors['cancellation_date'] &&
                  Object.values(errors['cancellation_date']).length
                    ? t('Invalid date')
                    : undefined
                }
                value={values.cancellationDate}
                name="cancellation_date"
                onChange={({ value }) => setCancellationDate(value)}
              >
                {t('Date')}
              </StyledDateInput>
              <TimeInput
                name="cancellation_time"
                isSecond={false}
                onChange={({ value }) => setCancellationTime(value)}
                is12HourFormat={false}
              >
                {t('Time')} ({t('optional')})
              </TimeInput>
            </StyledContent>
          </Section>
        )}
        <StyledButton data-test-id="continue-flight" onClick={handleFormData}>
          {t('Continue')}
        </StyledButton>
      </>
    );
  },
);

const StyledContent = styled(Section.Content)`
  display: flex;
  align-items: baseline;

  & > div {
    &:first-child {
      margin-right: 107px;
    }
    min-width: 45%;
    width: auto;
  }
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 23px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

StyledButton.displayName = 'StyledButton';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    margin-top: 20px;
    margin-right: 24px;
    flex-basis: 40%;
  }

  @media (max-width: 600px) {
    label {
      width: 100%;
      flex-basis: 100%;
      margin-right: 0;
      padding: 0 10px;
    }
  }
`;

const StyledCheck = styled(CheckOption)`
  margin-top: 20px;
  margin-right: 24px;

  /* &:nth-child(2n) {
        margin-right: 0;
    } */
`;

StyledCheck.displayName = 'StyledCheck';

const Bold = styled.span`
  font-weight: bold;
`;

const StyledDateInput = styled(DateInput)`
  width: 20%;
  margin-top: 1rem;
`;
export default Last;
