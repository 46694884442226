import { BASE_URL } from '../env.config';

let calls = 0;
let prevCall = '';
let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = () => {
  refreshSubscribers.forEach(async (cb) => await cb());
};

const handleRefresh = async () => {
  await refresh();
  onRefreshed();
  refreshSubscribers = [];
};
const subscribeRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
};

export async function refresh() {
  isRefreshing = true;
  const path = 'v1/api/aut/v1/api/auth/refresh';
  await request(path, 'POST', {});
  isRefreshing = false;
}

export async function request(path, method, payload = null) {
  if (prevCall === path) {
    calls++;
  } else {
    calls = 0;
    prevCall = path;
  }

  if (calls >= 100) return [400, 'ratelimit'];

  let [resp, status] = await _request(path, method, payload);
  if (status === 401) {
    if (resp.msg === 'INVALID_TOKEN') {
      await logout();
    } else if (resp.msg === 'AUTHENTICATION_EXPIRED') {
      if (!isRefreshing) {
        handleRefresh();
      }
      return await new Promise((r) =>
        subscribeRefresh(() => r(_request(path, method, payload))),
      );
    }
  }
  return [resp, status];
}

export async function logout() {
  const path = 'v1/api/aut/v1/api/logout';
  const [resp, status] = await request(path, 'GET');
  if (status === 200) {
    // loggedIn = false;
  } else {
    console.error(resp, status);
  }
}

async function _request(path, method, payload) {
  calls++;
  let req = { status: 'error' },
    resp;
  try {
    const url = BASE_URL + path;
    if (!payload) {
      req = await fetch(`${url}`, {
        method,
        headers,
        credentials: 'include',
      });
    } else {
      req = await fetch(`${url}`, {
        method,
        headers,
        body: JSON.stringify(payload),
        credentials: 'include',
      });
    }
    resp = await req.json();
  } catch (error) {
    console.error('error: ', error);
  } finally {
    return [resp, req.status];
  }
}
