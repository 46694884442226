export const locations = () => ({
  aviationLocations: {
    '/new-claim/category': '/new-claim/flights',
    '/new-claim/flight-info': '/new-claim/flights',
    '/new-claim/flights': '/new-claim/flight-issues',
    '/new-claim/flight-issues': '/new-claim/last',
    '/new-claim/last': '/new-claim/passengers',
    '/new-claim/passenger-contact': '/new-claim/passengers',
    '/new-claim/passengers': '/new-claim/contact-options',
    '/new-claim/contact-info': '/new-claim/contact-options',
    '/new-claim/contact-options': '/new-claim/travel',
    '/new-claim/travel': '/new-claim/summary',
    '/new-claim/summary': '/new-claim/thank-you',
  },
  homeLocations: {
    '/new-claim/category': '/new-claim/residence',
    '/new-claim/residence': '/new-claim/accident-desc',
    '/new-claim/accident-desc': '/new-claim/issues',

    '/new-claim/issues': '/new-claim/involved',
    '/new-claim/witness-list': '/new-claim/insuree-info',
    '/new-claim/insuree-info': '/new-claim/contact-options',
    '/new-claim/witness-info': '/new-claim/witness-list',
    '/new-claim/injured-info': '/new-claim/witness-list',

    '/new-claim/third-party-info': '/new-claim/involved',

    '/new-claim/involved': '/new-claim/contact-options',
    '/new-claim/contact-info': '/new-claim/contact-options',
    '/new-claim/contact-options': '/new-claim/accident-upload',
    '/new-claim/accident-upload': '/new-claim/summary',
    '/new-claim/summary': '/new-claim/thank-you',
  },
  personalLocations: {
    '/new-claim/category': '/new-claim/claim-subtype',
    '/new-claim/claim-subtype': '/new-claim/time-frame',
    '/new-claim/time-frame': '/new-claim/company-contact',
    '/new-claim/company-contact': '/new-claim/accident-personal',
    '/new-claim/accident-personal': '/new-claim/accident-upload',
    '/new-claim/accident-upload': '/new-claim/summary',
    '/new-claim/summary': '/new-claim/thank-you',
  },
  carLocations: {
    '/new-claim/category': '/new-claim/car-lookup',
    '/new-claim/car-lookup': '/new-claim/residence',
    '/new-claim/residence': '/new-claim/accident-desc',
    '/new-claim/accident-desc': '/new-claim/special-circumstances',
    '/new-claim/special-circumstances': '/new-claim/issues',
    '/new-claim/issues': '/new-claim/involved',
    '/new-claim/witness-list': '/new-claim/insuree-info',
    '/new-claim/insuree-info': '/new-claim/contact-options',
    '/new-claim/witness-info': '/new-claim/witness-list',
    '/new-claim/injured-info': '/new-claim/witness-list',

    '/new-claim/third-party-info': '/new-claim/involved',

    '/new-claim/involved': '/new-claim/contact-options',
    '/new-claim/contact-info': '/new-claim/contact-options',
    '/new-claim/contact-options': '/new-claim/repairer-select',
    '/new-claim/repairer-info': '/new-claim/repairer-select',
    '/new-claim/repairer-select': '/new-claim/accident-form',
    '/new-claim/accident-form': '/new-claim/accident-upload',
    '/new-claim/accident-upload': '/new-claim/summary',
    '/new-claim/summary': '/new-claim/thank-you',
  },
});
