import React from 'react';
import { string } from 'prop-types';

const CertifiedRepairer = ({ className, width = 18, height = 27 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.00045 14.5946C11.6769 14.5946 13.8466 12.3076 13.8466 9.48646C13.8466 6.66533 11.6769 4.37836 9.00045 4.37836C6.32399 4.37836 4.1543 6.66533 4.1543 9.48646C4.1543 12.3076 6.32399 14.5946 9.00045 14.5946Z"
      />
      <path
        d="M9.92077 0.0510811C9.61615 0.0145946 9.31154 0 9 0C8.68846 0 8.38385 0.0145946 8.07923 0.0510811C3.54462 0.532703 0 4.57541 0 9.48649C0 12.8286 1.64077 15.7549 4.11231 17.4478V27L9 24.0811L13.8877 27V17.4478C16.3592 15.7549 18 12.8286 18 9.48649C18 4.57541 14.4554 0.532703 9.92077 0.0510811ZM9 16.7838C5.18538 16.7838 2.07692 13.5073 2.07692 9.48649C2.07692 5.46568 5.18538 2.18919 9 2.18919C12.8146 2.18919 15.9231 5.46568 15.9231 9.48649C15.9231 13.5073 12.8146 16.7838 9 16.7838Z"
      />
    </svg>
  );
};

CertifiedRepairer.propTypes = {
  className: string,
};

export default CertifiedRepairer;
