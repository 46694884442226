import React, { useState, useEffect } from 'react';
import { ActionButton, CheckBox, Section } from 'wg-fe-ui';
import styled from 'styled-components';
import {
  addOrEditEntity,
  updateClaim,
} from '../../../helpers/apiRouterService';
import useQueryParams from '../../../hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';
import Address from '../../../components/Address';
import { FormField, Input } from '../../../styledComponents';

const CompanyContact = ({
  setValues,
  handleSection,
  validateForms,
  claimId,
}) => {
  const { id } = useParams();
  const { addParam } = useQueryParams();
  const [company, setCompany] = useState({ country: 'Belgium' });
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const [occurrenceAddress, setOccurrenceAddress] = useState(false);
  const getCompany = () => {
    let storageArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];

    // remove current id from storage
    const claim = storageArray.find((e) => e._id === id);
    if (!claim) return;
    if (claim._entity) setCompany({ ...claim._entity });
  };

  const handleCompanyChange = ({ name, value }) => {
    setCompany((data) => ({ ...data, [name]: value }));
  };

  const handleOccurrenceAddress = () => setOccurrenceAddress((occur) => !occur);

  const modifyCompany = async (address) => {
    const method = company['id'] ? 'PUT' : 'POST';
    const resource = company['id'] ? `/${company['id']}` : '';
    const [resp, status] = await addOrEditEntity(claimId, resource, method, {
      type: 'CONTACT',
      person_type: 'LEGAL',
      name: company.name,
      email: company.email,
      billing_address: address,
    });
    return [status, resp];
  };

  const handleFormData = async (e) => {
    if (e) e.preventDefault();

    const [validationErrors, hasErrors] = await validateForms(
      company,
      'companyContact',
    );
    if (hasErrors)
      return setErrors(() => mapValues(validationErrors, (e) => t(e)));

    const companyAddress = {
      street: company.street,
      zipcode: company.zipcode,
      housenr: company.housenr,
      boxnr: company.boxnr,
      city: company.city,
      country_code: company.country.toUpperCase().slice(0, 2),
    };

    let [status] = await modifyCompany(companyAddress);
    if (!/20(0|1)/.test(status)) return;
    [, status] = await updateClaim(claimId, {
      claim: {
        place_occurred: { address: companyAddress },
      },
    });
    setValues((oldValues) => ({
      ...oldValues,
      ...company,
    }));
    addParam({ submit: true });
  };

  useEffect(() => {
    getCompany();
    handleSection({ section: 1, subsection: 0 });
  }, []);

  return (
    <form onSubmit={handleFormData}>
      <input type="hidden" name="id" value={company['id']} />
      <Section>
        <Section.Title>{t('Company contact information')}</Section.Title>
        <StyledContent background>
          <FormField>
            <Input
              name="name"
              onChange={handleCompanyChange}
              value={company['name']}
              error={errors['name']}
            >
              {t('Company name')}
            </Input>
          </FormField>
          <FormField>
            <Input
              type="email"
              name="email"
              onChange={handleCompanyChange}
              value={company['email']}
              error={errors['email']}
            >
              {t('Company email')}
            </Input>
          </FormField>
          {!occurrenceAddress ? (
            <>
              <Address
                handleEntityChange={setCompany}
                entity={company}
                errors={errors}
              />
            </>
          ) : null}
          <Flex>
            <StyledCheckbox
              checked={occurrenceAddress}
              onChange={handleOccurrenceAddress}
              name="differentAddress"
            >
              {t('My company address does not correspond with the occurrence address')}
            </StyledCheckbox>
          </Flex>
        </StyledContent>
      </Section>
      {occurrenceAddress ? (
        <Section>
          <Section.Title>{t('Occurrence address')}</Section.Title>
          <StyledContent background>
            <Address
              handleEntityChange={setCompany}
              entity={company}
              errors={errors}
            />
          </StyledContent>
        </Section>
      ) : null}
      <StyledButton data-test-id="continueButton">{t('Continue')}</StyledButton>
    </form>
  );
};

const Flex = styled.div`
  display: flex;
  width: 100%;
`;

const StyledCheckbox = styled(CheckBox)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.subTitle};
`;

const StyledButton = styled(ActionButton)`
  display: flex;
  align-self: flex-start;
  margin-top: 6px;
`;

const StyledContent = styled(Section.Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px 57px 34px 37px;
`;

export default CompanyContact;
