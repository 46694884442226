import React from 'react';
import { string } from 'prop-types';

const TaxClaim = ({ className, width = 69, height = 63 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M51.9853 5.20728H16.8796C16.1357 6.86671 14.8196 8.21143 13.1602 8.9267V19.1408C14.8196 19.8847 16.1929 21.258 16.9082 22.9175H51.9566C52.6719 21.258 54.0452 19.8561 55.7047 19.1408V8.9267C54.0452 8.21143 52.7005 6.86671 51.9853 5.20728ZM34.4467 18.2253C32.1578 18.2253 30.2695 16.3656 30.2695 14.0481C30.2695 11.7306 32.1292 9.87086 34.4467 9.87086C36.7642 9.87086 38.6239 11.7306 38.6239 14.0481C38.5953 16.3656 36.7356 18.2253 34.4467 18.2253Z" />
      <path d="M7.69531 0V28.1246H61.1406V0H7.69531ZM58.5942 21.4296C56.1909 21.4296 54.274 23.3752 54.274 25.7499C54.274 25.7785 54.274 25.7785 54.274 25.8071H14.6192C14.6192 25.8071 14.6192 25.7785 14.6192 25.7499C14.6192 23.3466 12.6736 21.4296 10.2989 21.4296V6.63775C12.7022 6.63775 14.6192 4.6922 14.6192 2.31749H54.274C54.274 4.72081 56.2195 6.63775 58.5942 6.63775V21.4296Z" />
      <path d="M11.3858 39.8265L11.4716 39.9695L19.3683 35.6779C20.6844 34.9626 22.2294 34.8196 23.6599 35.2774L28.724 36.8796C31.2418 37.6807 33.5021 39.1398 35.2473 41.1426L35.5048 41.4573L44.8606 44.2326C45.6045 44.4615 46.2054 44.9479 46.5487 45.6059C46.892 46.2354 47.0065 47.0078 46.7776 47.7517C46.6917 48.0378 46.5487 48.2381 46.4056 48.467C46.0909 48.9534 45.6331 49.2967 45.1181 49.5256C45.0609 49.5542 45.0037 49.5542 44.9465 49.5828C44.4315 49.7545 43.8593 49.8117 43.287 49.6401L42.4001 49.3826L32.0715 46.3212L31.6138 47.8662L42.8293 51.1851C43.2298 51.2995 43.6304 51.2995 44.0595 51.2995L44.0881 51.3281L44.1454 51.2995C44.8606 51.2995 45.5759 51.1851 46.2054 50.8131C46.6059 50.5842 46.9492 50.2981 47.2354 49.9548L64.7453 42.4015C66.1186 41.6576 67.8353 42.1726 68.5791 43.5459C69.323 44.9192 68.808 46.6359 67.4347 47.3798L46.2626 58.8814C45.1754 59.4822 43.8879 59.5967 42.7148 59.2533L24.1177 53.76L20.1694 55.9059L20.2552 56.0489C20.6844 56.85 20.3983 57.8514 19.5971 58.2806L11.3 62.8011C10.4989 63.2303 9.49747 62.9442 9.06831 62.1431L0.198904 45.9206C-0.230261 45.1195 0.0558492 44.1181 0.856957 43.689L9.15414 39.1684C9.95525 38.7393 10.9566 39.0254 11.3858 39.8265Z" />
    </svg>
  );
};

TaxClaim.propTypes = {
  className: string,
};

export default TaxClaim;
