import React from 'react';
import {
  Input,
  DateField,
  StyledTextArea,
  FormField,
} from '../styledComponents';
import { SearchSelectInput, MaskedInput, PhoneInput } from 'wg-fe-ui';

export const handleOptions = (val, opt) => opt.find((o) => o.value === val);

export const generateForm = (fields, onChange, errors, entity, trans) => {
  const ref = { current: '' };
  let resultFields = fields.map((f) => {
    const resultField = (() => {
      switch (f.type) {
        case 'input':
          return (
            <Input
              name={f.name}
              error={f.error ? f.error : errors[f.name]}
              value={entity[f.name]}
              onChange={onChange}
            >
              {trans(f.label)}
            </Input>
          );
        case 'search_select':
          return (
            <SearchSelectInput
              name={f.name}
              error={f.error ? f.error : errors[f.name]}
              value={handleOptions(entity[f.name], f.options)}
              onSelected={onChange}
              options={f.options}
              placeholder={f.placeholder || `Select ${f.label}`}
            >
              {trans(f.label)}
            </SearchSelectInput>
          );
        case 'date':
          return (
            <DateField
              ref={ref}
              name={f.name}
              onChange={onChange}
              value={entity[f.name]}
              error={f.error ? f.error : errors[f.name]}
            >
              {trans(f.label)}
            </DateField>
          );
        case 'text_area':
          return (
            <StyledTextArea
              name={f.name}
              onChange={onChange}
              value={entity[f.name]}
              error={f.error ? f.error : errors[f.name]}
              placeholder={f.placeholder}
            >
              {trans(f.label)}
            </StyledTextArea>
          );
        case 'masked_input':
          return (
            <MaskedInput
              name={f.name}
              value={entity[f.name]}
              onChange={onChange}
              error={f.error ? f.error : errors[f.name]}
              mask={f.mask}
            >
              {trans(f.label)}
            </MaskedInput>
          );
        case 'phone_input':
          return (
            <PhoneInput
              name={f.name}
              value={entity[f.name]}
              handleChange={onChange}
              error={f.error ? f.error : errors[f.name]}
              symbol="IconActionPhone"
              symbolSide="right"
            >
              {trans(f.label)}
            </PhoneInput>
          );
        default:
          return null;
      }
    })();

    return <FormField key={f.name}>{resultField}</FormField>;
  });
  return <>{resultFields}</>;
};

/*
    Method for handling date error object.
    Passes error object {} to one string.
*/
export const handleDateError = (error) =>
  error && Object.values(error).length ? 'Invalid date!' : undefined;
