import React, { useState } from 'react';
import styled from 'styled-components';
import { callCities, callStreets, debouncer } from '../helpers/addressService';
import { useTranslation } from 'react-i18next';
import {
  Section,
  TextInput,
  SearchSelectInput,
  CheckBox,
  ActionButton,
} from 'wg-fe-ui';
import { AccidentSection } from '../styledComponents';
import { mapValues } from 'lodash';

const AccidentDescAddress = ({
  setOccurenceResidence,
  validateForms,
  setErrors,
  getCoordinates,
  occurenceResidence,
  errors,
}) => {
  const { t } = useTranslation();
  const [residenceNoCorrespond, setResidenceNoCorrespond] = useState(false);
  const [cityKey, setCityKey] = useState(0);
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);

  const handleResidenceCheckClick = () => {
    setResidenceNoCorrespond(!residenceNoCorrespond);
  };

  const updateOccurenceResidence = ({ name, value }) => {
    if (name === 'zipcode')
      debouncer(() => {
        setCityKey((val) => (val === 0 ? 1 : 0));
      });
    setOccurenceResidence((old) => ({ ...old, [name]: value.value || value }));
    setSearchButtonDisabled(false);
  };

  const handleSearchClick = async () => {
    const [validationErrors, hasErrors] = await validateForms(
      occurenceResidence,
      'residence',
    );
    setErrors(() => mapValues(validationErrors, (e) => t(e)));
    if (hasErrors) return;

    setSearchButtonDisabled(true);
    await getCoordinates();
  };

  return (
    <>
      <AccidentSection padding>
        <Section.Title>{t('Occurrence address')}</Section.Title>
        <Section.Content background>
          <StyledCheckbox
            checked={residenceNoCorrespond}
            onChange={handleResidenceCheckClick}
          >
            {t(
              'My current residence does not correspond with the occurrence address',
            )}
          </StyledCheckbox>
          <CurrentResidenceInputs>
            <TextInput
              name="zipcode"
              onChange={({ name, value }) =>
                updateOccurenceResidence({ name, value: parseInt(value) })
              }
              disabled={!residenceNoCorrespond}
              value={occurenceResidence.zipcode || ''}
              error={errors['zipcode']}
            >
              {t('Zip-code')}
            </TextInput>
            <SearchSelectInput
              async
              name="city"
              loadOptions={(q, cb) => callCities(q, cb, occurenceResidence)}
              defaultOptions={
                residenceNoCorrespond && occurenceResidence.zipcode ? true : []
              }
              error={errors['city']}
              cacheOptions={false}
              onSelected={updateOccurenceResidence}
              value={{
                label: occurenceResidence.city,
                value: occurenceResidence.city,
              }}
              disabled={!residenceNoCorrespond || !occurenceResidence.zipcode}
              key={cityKey}
              placeholder={t('Choose your option')}
            >
              {t('City')}
            </SearchSelectInput>
            <SearchSelectInput
              async
              loadOptions={(q, cb) => callStreets(q, cb, occurenceResidence)}
              cacheOptions={false}
              defaultOptions={
                residenceNoCorrespond &&
                occurenceResidence.zipcode &&
                occurenceResidence.city
                  ? true
                  : []
              }
              name="street"
              onSelected={updateOccurenceResidence}
              value={{
                label: occurenceResidence.street,
                value: occurenceResidence.street,
              }}
              disabled={!residenceNoCorrespond}
              error={errors['street']}
              placeholder={t('Choose your option')}
            >
              {t('Street')}
            </SearchSelectInput>
            <TextInput
              name="housenr"
              onChange={updateOccurenceResidence}
              value={occurenceResidence.housenr}
              disabled={!residenceNoCorrespond}
              error={errors['housenr']}
            >
              {t('House number')}
            </TextInput>
            <TextInput
              name="boxnr"
              onChange={updateOccurenceResidence}
              value={occurenceResidence.boxnr}
              disabled={!residenceNoCorrespond}
              error={errors['boxnr']}
            >
              {t('Box')}
            </TextInput>
          </CurrentResidenceInputs>
        </Section.Content>
      </AccidentSection>
      <SearchBox>
        <StyledButton
          onClick={handleSearchClick}
          disabled={searchButtonDisabled}
        >
          {t('Search')}
        </StyledButton>
        <p>
          <span>{t('Please note')}: </span>
          {t(
            'if it concerns damage caused intentionally by the insured, or damage caused by a war, an occupation or seizure.',
          )}
        </p>
      </SearchBox>
    </>
  );
};

const StyledCheckbox = styled(CheckBox)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.subTitle};
  margin-bottom: 2.4rem;
`;

StyledCheckbox.displayName = 'CheckBox';

const SearchBox = styled.div`
  display: flex;
  p {
    margin-left: 6rem;
    max-width: 796px;
  }
  p,
  span {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #666666;
    letter-spacing: 0.924996px;
    span {
      font-weight: 900;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 15px;

    p {
      margin-left: 0;
    }
  }
`;

const StyledButton = styled(ActionButton)`
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    width: 100%;
  }
  @media (max-width: 1400px) {
    margin-bottom: 4rem;
  }
`;

StyledButton.displayName = 'ActionButton';

const CurrentResidenceInputs = styled.div`
  display: flex;
  & > div {
    margin-right: 1.2rem;
    &:nth-of-type(1) {
      width: 15.5%;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 29.1%;
    }
    &:nth-of-type(4) {
      width: 13.8%;
    }
    &:nth-of-type(5) {
      width: 9.1%;
    }
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;

    & > div {
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 36.1%;
      }
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: 45%;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    & > div {
      width: 100% !important;
    }
  }
`;

export default AccidentDescAddress;
