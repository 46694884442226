import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  CarLogoContainer,
  CarContainer,
  CarSelectedRect,
  CarInfoHeader,
  CarInfoContainer,
  CarCardColumn,
  CarCardLabel,
  CarDateInput,
  CarCardSearchSelect,
  CarLogoIMG,
} from '../styledComponents';
import {
  CarTerrainwagenIcon,
  CarCabrioIcon,
  CarSportwagenIcon,
} from '../icons';
import { LoadingSpinner, ActionButton } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import SuffixInput from './SuffixInput';
import { getAllCarBrands } from '../helpers/apiRouterService';
import { fetchModels } from '../helpers/carService';
import { engineTypes } from '../constants';
import Item from './CarCardItem';
import VatButtonInput from './VatButtonInput';
import CarCardExtrasButton from './CarCardExtrasButton';
import { mapValues } from 'lodash';

const initCarData = {
  brand: { name: '', logo: '' },
  model: '',
  engine: '',
  car_value: 0,
  model_year: '',
  suv: false,
  cabriolet: false,
  coupe: false,
  registrationFirst: { day: 1, month: 1, year: 2020 },
};

const CarCardInputs = ({ className, handleFormData, validateForms }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [selectedCar, setSelectedCar] = useState(initCarData);
  const [errors, setErrors] = useState({});

  const enginesList = Object.entries(engineTypes).map(([k, v]) => ({
    label: v,
    value: k,
  }));

  const handleChange = ({ name, value }) => {
    setSelectedCar(old => ({ ...old, [name]: value }));
  };

  const callBrands = async () => {
    const [res, status] = await getAllCarBrands();
    if (status === 200)
      setBrands(
        Object.entries(res).map(([k, v]) => ({
          label: k,
          value: { name: k, logo: v },
        })),
      );
  };

  const callModels = async () => {
    setModelsLoading(true);
    setSelectedCar(old => ({ ...old, version: {}, model: '' }));
    const res = await fetchModels(selectedCar.brand.name);
    setModels(res);
    setModelsLoading(false);
  };

  const handleSubmit = async () => {
    const [validationErrors, hasErrors] = await validateForms(
      selectedCar,
      'carCardInputs',
    );
    setErrors(() => mapValues(validationErrors, (e) => t(e)));;
    if (hasErrors) return;

    handleFormData();
  };

  useEffect(() => {
    callBrands();
  }, []);

  useEffect(() => {
    if (selectedCar.brand.name) callModels();
  }, [selectedCar.brand]);

  return (
    <CarContainer className={className}>
      <CarLogoContainer>
        {selectedCar.brand.logo ? (
          <CarLogoIMG
            src={selectedCar.brand.logo}
            alt={selectedCar.brand.name}
          />
        ) : null}
        <CarSelectedRect>
          <span className="title">{t('Car')}</span>
          <span className="value">{`${selectedCar.brand.name} ${selectedCar.model}`}</span>
        </CarSelectedRect>
      </CarLogoContainer>
      <CarInfoContainer>
        <CarInfoHeader>{t('Specificaties')}</CarInfoHeader>
        <ItemsContainer>
          <CarCardColumn>
            <CarCardSearchSelect
              name="brand"
              options={brands}
              onSelected={handleChange}
              value={{
                label: selectedCar.brand.name,
                value: selectedCar.brand.name,
              }}
              placeholder={t('Choose your option')}
            >
              {t('Brand')}
            </CarCardSearchSelect>

            <CarCardSearchSelect
              name="engine"
              options={enginesList}
              onSelected={handleChange}
              error={errors['engine']}
              value={{
                label: engineTypes[selectedCar.engine] || '',
                value: selectedCar.engine,
              }}
              placeholder={t('Choose your option')}
            >
              {t('Fuel type')}*
            </CarCardSearchSelect>
            <Item
              title={t('Model year')}
              value={selectedCar.model_year}
              error={errors['model_year']}
              input
            />
            <CarCardLabel isEditing={true}>
              {t('When was the')}
              <span className="bold"> {t('first registration')}?*</span>
            </CarCardLabel>

            <CarCardLabel>
              {t('What is the')}
              <span className="bold"> {t('catalog value')}?*</span>
            </CarCardLabel>
          </CarCardColumn>
          <CarCardColumn>
            {modelsLoading ? (
              <LoadingSpinner />
            ) : (
              <CarCardSearchSelect
                name="model"
                options={models}
                onSelected={handleChange}
                value={{ label: selectedCar.model, value: selectedCar.model }}
                placeholder={t('Choose your option')}
              >
                {t('Model')}
              </CarCardSearchSelect>
            )}

            <SuffixInput suffix="KW" error={errors['power']}>
              {t('Power')}*
            </SuffixInput>
            <SuffixInput suffix="g CO2/km" error={errors['emissions']}>
              {t('Emissions')}
            </SuffixInput>

            <CarDateInput
              name="registrationFirst"
              value={selectedCar.registrationFirst}
              ref={ref}
              error={errors['registrationFirst']}
            />
            <VatButtonInput
              value={selectedCar.car_value}
              name="car_value"
              handleChange={handleChange}
              setCarData={setSelectedCar}
              error={errors['car_value']}
            />
          </CarCardColumn>
        </ItemsContainer>
        <CarInfoHeader>{t('Extras')}</CarInfoHeader>
        <ItemsContainer>
          <CarCardExtrasButton
            name="suv"
            value={selectedCar.suv}
            onClick={handleChange}
          >
            <CarTerrainwagenIcon />
            {t('Suv')}
          </CarCardExtrasButton>
          <CarCardExtrasButton
            name="coupe"
            value={selectedCar.coupe}
            onClick={handleChange}
          >
            <CarSportwagenIcon />
            {t('Coupe')}
          </CarCardExtrasButton>
          <CarCardExtrasButton
            name="cabriolet"
            value={selectedCar.cabriolet}
            onClick={handleChange}
          >
            <CarCabrioIcon />
            {t('Cabriolet')}
          </CarCardExtrasButton>
        </ItemsContainer>
        <ContinueButton onClick={handleSubmit}>{t('Continue')}</ContinueButton>
      </CarInfoContainer>
    </CarContainer>
  );
};

CarCardInputs.displayName = 'CarCard';

const ItemsContainer = styled.div`
  display: flex;
`;

const ContinueButton = styled(ActionButton)`
  margin: 3.5rem 0 2rem;
  align-self: flex-end;
  width: 9vw;
`;

export default CarCardInputs;
