/* 
  @accept array of objects

  [
    {test: test},
    {test: test},
    {test: test},
    {test: test},
  ]
*/

export const removeDuplicates = arr => {
  const uniq = new Set(arr.map(e => JSON.stringify(e)));
  return Array.from(uniq).map(e => JSON.parse(e));
};

/* 
  @accept object, arrary of strings

  nestedObj = {
    id: 101,
    email: 'jack@dev.com',
    personalInfo: {
        name: 'Jack',
        address: {
            line1: 'westwish st',
            line2: 'washmasher',
            city: 'wallas',
            state: 'WX'
        }
    }
  }

  pathArr = ['personalInfo', 'name'] 

  this safely's returns 'Jack'
*/

export const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    nestedObj,
  );
};

/* 
  @accept object

  returns true if object is empty
*/

export const isObjectEmpty = obj => {
  return Object.entries(obj).length === 0;
};

/* 
  @accept object

  returns true if object is an object
*/

export const isObject = obj => {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
};

/**
 Returns SearchSelectInput friendly options array from array
 @accept array
 */
export const convertArrayToSSIFriendly = (array, t) =>
  array.map(a => ({ label: t(a), value: a }));

/**
 Returns SearchSelectInput friendly options array from object
 @accept object
 */
export const convertObjectToSSIFriendly = object =>
  Object.entries(object).map(([key, val]) => ({ label: key, value: val }));

/**
 * Maps insurance_companies object to SSI friendly array
 * @param {object} insuranceCompanies
 */
export const handleCompaniesResponse = insuranceCompanies =>
  Object.entries(insuranceCompanies).map(([k, v]) => ({ label: v, value: k }));

/**
 * Implementation of Lodash set method
 * @param {object} obj
 * @param {string} path
 * @param {*} value
 */
export const set = (obj, path, value) => {
  if (Object(obj) !== obj) return obj;

  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path
    .slice(0, -1)
    .reduce(
      (a, c, i) =>
        Object(a[c]) === a[c]
          ? a[c]
          : (a[c] = Math.abs(path[i + 1]) >> 0 === +path[i + 1] ? [] : {}),
      obj,
    )[path[path.length - 1]] = value;
  return obj;
};
