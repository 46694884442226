import React, { useState, useEffect } from 'react';
import { SearchSelectInput, LoadingSpinner, ActionButton } from 'wg-fe-ui';
import { getAllCarBrands } from '../helpers/apiRouterService';
import { fetchModels, fetchVersions } from '../helpers/carService';
import { useTranslation } from 'react-i18next';
import {
  CarContainer,
  CarInfoContainer,
  CarInfoHeader,
  CarLogoContainer,
  CarSelectedRect,
  CarButtonsContainer,
  CarLogoIMG,
  CarContinueButton
} from '../styledComponents';
import styled from 'styled-components';
import SearchList from './SearchVersionList';

const CarSearch = ({ className, cannotFindClick, handleFormData }) => {
  const [selectedCar, setSelectedCar] = useState({
    brand: { name: '', logo: '' },
    model: '',
    version: {},
  });
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [versions, setVersions] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [versionsLoading, setVersionsLoading] = useState(false);
  const { t } = useTranslation();

  const callBrands = async () => {
    setBrandsLoading(true);
    const [res, status] = await getAllCarBrands();
    if (status === 200)
      setBrands(
        Object.entries(res).map(([k, v]) => ({
          label: k,
          value: { name: k, logo: v },
        })),
      );
    setBrandsLoading(false);
  };

  const callData = async type => {
    if (type === 'models') {
      setModelsLoading(true);
      setSelectedCar(old => ({ ...old, version: {}, model: '' }));
      const res = await fetchModels(selectedCar.brand.name);
      setModels(res);
      setModelsLoading(false);
    } else if (type === 'versions') {
      setVersionsLoading(true);
      setSelectedCar(old => ({ ...old, version: {} }));
      const res = await fetchVersions(
        selectedCar.brand.name,
        selectedCar.model,
      );
      setVersions(res);
      setVersionsLoading(false);
    }
  };

  const handleChange = ({ name, value }) => {
    setSelectedCar(old => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    callBrands();
  }, []);

  useEffect(() => {
    if (selectedCar.brand.name) callData('models');
  }, [selectedCar.brand]);

  useEffect(() => {
    if (selectedCar.model) callData('versions');
  }, [selectedCar.model]);

  return (
    <CarContainer className={className}>
      <CarLogoContainer>
        <CarLogoIMG src={selectedCar.brand.logo} alt={selectedCar.brand.name} />
        {selectedCar.brand.name && (
          <CarSelectedRect>
            <span className="title">{t('Car')}</span>
            <span className="value">
              {selectedCar.brand.name} {selectedCar.model}
              {selectedCar.version ? selectedCar.version.version : ''}
            </span>
          </CarSelectedRect>
        )}
      </CarLogoContainer>
      <CarInfoContainer>
        <CarInfoHeader>{t('Specificaties')}</CarInfoHeader>
        <InputsContainer>
          {brandsLoading ? (
            <LoadingSpinner />
          ) : (
            <SearchSelectInput
              name="brand"
              options={brands}
              onSelected={handleChange}
              value={{
                label: selectedCar.brand.name,
                value: selectedCar.brand.name,
              }}
              placeholder={t('Choose your option')}
            >
              {t('Brand')}
            </SearchSelectInput>
          )}
          {modelsLoading ? (
            <LoadingSpinner />
          ) : (
            <SearchSelectInput
              name="model"
              options={models}
              onSelected={handleChange}
              value={{ label: selectedCar.model, value: selectedCar.model }}
              placeholder={t('Choose your option')}
            >
              {t('Model')}
            </SearchSelectInput>
          )}
          {versionsLoading || (modelsLoading && selectedCar.version.version) ? (
            <LoadingSpinner />
          ) : versions.length ? (
            <SearchList
              filterdversionList={versions}
              onSelected={version =>
                handleChange({ name: 'version', value: version })
              }
            />
          ) : null}
        </InputsContainer>
        <CarButtonsContainer>
          <CarContinueButton bgColor="#cdcdcd" onClick={cannotFindClick}>
            {t("I can't find my car")}
          </CarContinueButton>
          <CarContinueButton onClick={handleFormData}>
            {t('Continue')}
          </CarContinueButton>
        </CarButtonsContainer>
      </CarInfoContainer>
    </CarContainer>
  );
};

const InputsContainer = styled.div`
  display: flex;
  padding-right: 3rem;
  flex-direction: column;
`;

export default CarSearch;
