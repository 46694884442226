import { getModelsByBrand, getCarVersions } from './apiRouterService';
import { debounce } from 'lodash';
/**
 * Default debouncer for calling zipcode
 */
export const debouncer = debounce(f => f(), 500);

export const fetchModels = async brand => {
  const [resp, status] = await getModelsByBrand(brand);
  if (status !== 200 || !resp) return [];
  return resp.map(m => ({ label: m, value: m }));
};

export const fetchVersions = async (brand, model) => {
  const [resp, status] = await getCarVersions(brand, model);
  if (status !== 200 || !resp) return [];
  return resp;
};

export const callModels = (brand, cb) => {
  if (!brand) return cb([]);
  return debounce(() => {
    fetchModels(brand).then(res => cb(res));
  }, 500)();
};

export const callVersions = (brand, model, cb) => {
  if (!brand) return cb([]);
  return debounce(() => {
    fetchVersions(brand, model).then(res => cb(res));
  }, 500)();
};
