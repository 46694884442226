import React from 'react';
import { string } from 'prop-types';

const MaterialDamageOtherCars = ({ className, width = 27, height = 29 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.5694 7.54C26.4451 6.75179 26.9997 5.60667 26.9997 4.32769C26.9997 1.93333 25.0732 0 22.7015 0C21.2932 0 20.0453 0.684102 19.2572 1.74C18.9945 1.6359 18.7026 1.56897 18.3961 1.56897C17.1045 1.57641 16.0537 2.63231 16.0537 3.93359C16.0537 5.07128 16.8564 6.01564 17.9145 6.24615C17.7248 6.61795 17.6153 7.03436 17.6153 7.47308C17.6153 8.89333 18.688 10.0459 20.0599 10.1946C20.2059 10.7746 20.4978 11.2951 20.9137 11.6967C20.8043 11.9718 20.7386 12.2692 20.7386 12.5815C20.7386 12.7303 20.7532 12.879 20.7824 13.0277C20.4321 13.5036 20.0307 13.9423 19.5637 14.3141L17.7102 15.8013C17.4621 16.0021 17.6372 16.3962 17.9437 16.3515L19.717 16.0987C20.8407 15.9351 21.9061 15.5262 22.8475 14.9164C22.9278 14.9238 23.0007 14.9387 23.081 14.9387C24.3799 14.9387 25.4234 13.8828 25.4234 12.5815C25.4234 12.2692 25.3578 11.9718 25.2483 11.6967C25.8394 11.1241 26.2043 10.3285 26.2043 9.43615C26.2188 8.72231 25.9707 8.06795 25.5694 7.54Z"
      />
      <path
        d="M19.7903 18.3593C19.71 18.1882 19.4765 17.7421 19.0824 17.7421L8.77135 17.7272C8.56703 17.7272 8.49405 17.4446 8.68378 17.348L17.2435 12.6411C17.4989 12.4998 17.3968 12.1131 17.1122 12.1131C14.7186 12.1131 12.3543 12.6634 10.2016 13.7341L7.48703 15.0726C7.32649 15.1321 7.14405 15.08 7.04189 14.9387L4.57541 11.6C4.49514 11.5034 4.40027 11.4439 4.29081 11.4141L0.532703 10.6111C0.291892 10.559 0.0583784 10.7523 0.0583784 11.0052L0 24.9029C0 25.1259 0.175135 25.3044 0.394054 25.3044H8.07081C8.29703 27.3864 9.9973 29 12.0697 29C14.1495 29 15.8424 27.3864 16.0686 25.3044H18.973C19.3962 25.3044 19.7465 25.007 19.8486 24.598C20.1041 23.4826 20.5784 21.3485 20.5784 20.6941C20.5711 20.1662 20.1041 19.0062 19.7903 18.3593ZM14.2662 25.3044C14.0546 26.3603 13.157 27.1485 12.0697 27.1485C10.9897 27.1485 10.0849 26.3603 9.87324 25.3044C9.85135 25.1557 9.83676 24.9995 9.83676 24.8434C9.83676 23.5718 10.8365 22.5308 12.0697 22.5308C13.303 22.5308 14.3027 23.5644 14.3027 24.8434C14.3027 24.9995 14.2881 25.1557 14.2662 25.3044Z"
      />
    </svg>
  );
};

MaterialDamageOtherCars.propTypes = {
  className: string,
};

export default MaterialDamageOtherCars;
