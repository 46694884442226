import validationSchemas from './../constants/validationSchemas';
import { cities, countries } from './../constants';
import { locations } from '../constants/fixedRoutes';
import { authMe } from './apiRouterService';

/**
 * Method for checking if axios request and response should be intercepted
 * @param {object} config
 */
const isHandlerEnabled = (config = {}) => {
  if (!config.url) return false;
  return !config.url.includes('auth');
};

/**
 * Method for building risk types under insurance section
 * @param {array} data
 */

const buildTypesMenu = (riskTypes, data) => {
  let typesMenu = [];
  if (!data) return;
  typesMenu = data.map((d, i) => {
    const risk = riskTypes.find(r => r.type === d.type);
    if (!d.policy.insurance.options) return risk;
    risk.items = d.policy.insurance.options;
    return risk;
  });

  return typesMenu;
};

const capitalize = text =>
  `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

/**
 * Checks equality of two jsons
 * @param {object} first
 * @param {object} second
 */
const compareJsons = (first, second) =>
  JSON.stringify(first) === JSON.stringify(second);

/**
 * get yup Schema based on location page
 * @param {string} claim
 */

const getSchema = claim => {
  const parts = window.location.hash.split('/');
  const pathname = parts[2]
    .split('-')
    .map((part, i) => {
      if (i === 0) return part;
      return `${part[0].toUpperCase()}${part.substring(1)}`;
    })
    .join('')
    .replace('?disabled=true', '');
  if (pathname === 'category') {
    if (claim.toLowerCase() !== 'aviation')
      return validationSchemas[pathname].type;
    return validationSchemas[pathname].aviation;
  }
  return validationSchemas[pathname];
};

const getClaimRoutes = getClaimData => {
  const { _id, case: claimCase, step } = getClaimData() || {};
  let routingObject = {};
  const locationsObject = locations();

  switch (claimCase) {
    case 'AVIATION':
      routingObject = locationsObject.aviationLocations;
      break;
    case 'HOME':
      routingObject = locationsObject.homeLocations;
      break;
    case 'PERSONAL':
      routingObject = locationsObject.personalLocations;
      break;
    case 'CAR':
      routingObject = locationsObject.carLocations;
      break;
    default:
      break;
  }

  return { _id, routingObject, step };
};

const getCityWithCode = code => cities[code.toLowerCase()];

const getCountryWithCode = code => countries[code.toLowerCase()];

/* Format Date  */

const isDate = d => /\d{2}\/\d{2}\/\d{4}/.test(d);
const padVal = v => (parseInt(v) > 9 ? v : `0${v}`);

const padDate = d => {
  if (typeof d !== 'object') return d;
  const day = padVal(d.day);
  const month = padVal(d.month);
  return `${day}/${month}/${d.year}`;
};

const padTime = t => {
  if (typeof t !== 'object') return t;
  const hour = padVal(t.hour);
  const minute = padVal(t.minute);
  const second = padVal(t.second);
  return `${hour}:${minute}:${second}`;
};

const modifyEntityStorage = (toRemove, p) => {
  const storeArray = JSON.parse(sessionStorage.getItem('claimStorage')) || [];

  const id = sessionStorage.getItem('_id');
  // remove current id from storage
  const storageArray = storeArray.filter(e => e._id !== id);
  const data = storeArray.find(e => e._id === id);
  if (!data) return;
  if (toRemove) delete data._entity;
  else {
    data._entity = { ...p };
  }

  // add the the new data to storage array
  storageArray.push({ ...data });

  // store data in sessionStorage
  sessionStorage.setItem('claimStorage', JSON.stringify(storageArray));
};

const getPassenger = getClaimData => {
  const claim = getClaimData();
  if (claim._entity)
    return {
      ...claim._entity.correspondence_address,
      ...claim._entity,
      country: getCountryWithCode(claim._entity.nationality),
    };
  return { country: 'Belgium' };
};

const fetchRole = async setRole => {
  const [resp, status] = await authMe();
  if (status === 200) setRole(resp.me.role);
};

export {
  buildTypesMenu,
  capitalize,
  getCityWithCode,
  getCountryWithCode,
  getClaimRoutes,
  getPassenger,
  getSchema,
  isDate,
  isHandlerEnabled,
  compareJsons,
  modifyEntityStorage,
  padDate,
  padVal,
  padTime,
  fetchRole,
};
