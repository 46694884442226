import React, { useEffect, useRef } from 'react';
import { CAPTCHA_KEY } from '../env.config';
import PropTypes from 'prop-types';

const GoogleReCaptcha = ({ className, onClick }) => {
  const ref = useRef();
  const scriptRef = useRef();

  const onSuccess = (res) => onClick(res);

  const onExpired = (expire) => {
    console.warn('expired: ', expire);
  };

  const onError = (error) => {
    console.error('error: ', error);
  };

  window.initReCaptcha = function () {
    window.grecaptcha &&
      window.grecaptcha.render(ref.current, {
        sitekey: CAPTCHA_KEY,
        callback: onSuccess,
        'expired-callback': onExpired,
        'error-callback': onError,
      });
  };

  useEffect(() => {
    scriptRef.current = document.createElement('script');
    scriptRef.current.src = `https://www.google.com/recaptcha/api.js?onload=initReCaptcha&render=explicit`;
    window.document.body.appendChild(scriptRef.current);

    return () => {
      window.document.body.removeChild(scriptRef.current);
    };
  }, []);

  return <div ref={ref} className={className}></div>;
};

GoogleReCaptcha.defaultProps = {
  onClick: () => {},
};

GoogleReCaptcha.propTypes = {
  onClick: PropTypes.func,
};

export default GoogleReCaptcha;
