import React from 'react';
import styled from 'styled-components';

const Summary = ({ className, children }) => (
  <StyledSummary className={className}>{children}</StyledSummary>
);

const StyledSummary = styled.div`
  padding-bottom: 30px;
  margin-top: 5rem;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  svg {
    align-self: center;
    margin-top: auto;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 900;
  margin-bottom: 10px;
`;

const Value = styled.span``;

Summary.Column = Column;
Summary.Column.displayName = 'Summary.Column';
Summary.Title = Title;
Summary.Title.displayName = 'Summary.Title';
Summary.Value = Value;
Summary.Value.displayName = 'Summary.Value';

export default Summary;
