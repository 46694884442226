import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useNewClaimData from './hooks/useNewClaimData';
import Main from './pages/Main';
import Layout from './layout';
import { getClaimRoutes } from './helpers';

function App(props) {
  const { getClaimData } = useNewClaimData();
  const history = useHistory();
  const { pathname, state } = useLocation();

  useEffect(() => {
    if (!pathname.includes('new-claim')) return;
    let lastStepId = 0;
    let stepId = 0;
    const { _id: id, routingObject, step } = getClaimRoutes(getClaimData);
    Object.keys(routingObject).forEach((v, i) => {
      if (v.endsWith(step)) lastStepId = i;
      if (pathname.startsWith(v)) stepId = i;
    });
    if (stepId > lastStepId && pathname !== state) {
      history.replace({
        pathname: `${step}/${id}`,
        state: `${step}/${id}`,
      });
    }
  }, [pathname]);

  return (
    <Layout>
      <Main {...props} />
    </Layout>
  );
}

export default App;
