import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImageSelectBox, LoadingSpinner, Section } from 'wg-fe-ui';
import { getClaimTypes } from './../helpers/apiRouterService';
import { useTranslation } from 'react-i18next';
import {
  PlaneIcon,
  HouseIcon,
  WorkAccidentIcon,
  AutomotiveIcon,
} from '../icons';

const CategoryClaimPicker = ({ liftCategoryChange, category }) => {
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const categoryIcons = {
    aviation: <PlaneIcon />,
    home: <HouseIcon />,
    car: <AutomotiveIcon />,
    personal: <WorkAccidentIcon />,
  };

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  const getIcon = (type) => {
    return categoryIcons[type.toLowerCase()];
  };

  const callClaims = async () => {
    try {
      setLoading(true);
      const [resp, status] = await getClaimTypes();
      setLoading(false);
      status === 200 && setCategories(resp.types);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  React.useEffect(() => {
    let ignore = false;
    if (!ignore && !categories.length && !loading) {
      callClaims(ignore);
    }
    return () => {
      ignore = true;
    };
  }, [categories, loading]);

  const categoryHandleChange = ({ value }) => {
    setSelectedCategory(value);
    liftCategoryChange(value);
  };
  const title = selectedCategory
    ? 'Claim category'
    : 'Choose a claim category to start';
  return (
    <StyledSection>
      <Section.Title>{t(title)}</Section.Title>
      <Section.Content>
        <SectionInputWrapper>
          {loading ? (
            <LoadingSpinner />
          ) : (
            categories.map((c) => (
              <ImageSelectBox
                key={c}
                onChange={categoryHandleChange}
                selected={selectedCategory}
                value={c}
                name="claimCategory"
                label={t(c)}
                icon={getIcon(c)}
              />
            ))
          )}
        </SectionInputWrapper>
      </Section.Content>
    </StyledSection>
  );
};

const SectionInputWrapper = styled.div`
  display: flex;

  label {
    margin: 0 2rem;
    width: 14rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    label {
      margin: 10px auto;
      width: 100%;
      height: 40vw;

      &:first-child {
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }
    }
  }
`;

const StyledSection = styled(Section)`
  margin-bottom: 2rem;

  ${Section.Content} {
    @media (max-width: 600px) {
      padding: 15px 30px;
    }
  }
`;

export default CategoryClaimPicker;
