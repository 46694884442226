import React from 'react';
import { string } from 'prop-types';

const Sportwagen = ({ className, width = 34, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.842 2.92061L18.717 0.128429C9.72453 -0.809404 3.3514 3.6453 1.43328 5.17994C1.06203 5.47834 0.917654 5.96857 1.0414 6.43749L1.57765 8.35578C1.72203 8.86733 2.17578 9.20836 2.67078 9.20836H32.247C32.7627 9.20836 33.2164 8.86733 33.3402 8.35578L33.9383 6.18171C34.0414 5.77674 33.9589 5.35045 33.6908 5.05205C31.6283 2.72878 22.842 2.92061 22.842 2.92061Z"
      />
      <path
        d="M8.44551 11.4033C9.98328 11.4033 11.2299 10.115 11.2299 8.52588C11.2299 6.93671 9.98328 5.64844 8.44551 5.64844C6.90774 5.64844 5.66113 6.93671 5.66113 8.52588C5.66113 10.115 6.90774 11.4033 8.44551 11.4033Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.3576 11.4033C28.8954 11.4033 30.142 10.115 30.142 8.52588C30.142 6.93671 28.8954 5.64844 27.3576 5.64844C25.8198 5.64844 24.5732 6.93671 24.5732 8.52588C24.5732 10.115 25.8198 11.4033 27.3576 11.4033Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

Sportwagen.propTypes = {
  className: string,
};

export default Sportwagen;
