import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Section, ActionButton, LoadingSpinner } from 'wg-fe-ui';
import { useParams } from 'react-router-dom';
import {
  verifyClaim,
  submitClaim,
  getInsuranceCompanies,
} from '../../../helpers/apiRouterService';
import { handleCompaniesResponse } from '../../../helpers/objectService';
import SummaryList from '../../../components/SummaryList';
import { parseIncDateToString } from '../../../helpers/dateService';
import useQueryParams from '../../../hooks/useQueryParams';
import { set } from 'lodash';
import { StyledTextArea } from '../../../styledComponents';
import { useTranslation } from 'react-i18next';

const Summary = forwardRef(({ handleFormData, handleSection, claim }, ref) => {
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactUrl, setContactUrl] = useState('');
  const { addParam, removeParams } = useQueryParams();
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    click: () => {
      doSubmitClaim();
    },
  }));
  const doVerifyClaim = async () => {
    setLoading(true);
    if (!Object.keys(claim).length) return;
    const [res, status] = await verifyClaim(claim.metadata.uid);
    let resultErrors = {};
    let resultWarnings = {};
    if (status !== 200) return;
    if (res.errors) {
      res.errors.forEach(obj => set(resultErrors, obj.loc, obj.msg.en));
    }
    if (res.warnings) {
      res.warnings.forEach(obj => set(resultWarnings, obj.loc, obj.msg.en));
    }
    setErrors(resultErrors.claim || {});
    setWarnings(resultWarnings.claim || {});
    setLoading(false);
  };

  const doSubmitClaim = async () => {
    const [, status] = await submitClaim(claim.metadata.uid);
    status === 200 && handleFormData();
  };

  const fetchInsuranceCompanies = async () => {
    const [resp, status] = await getInsuranceCompanies();
    if (status !== 200) return;
    resp &&
      setInsuranceCompanies(handleCompaniesResponse(resp.insurance_companies));
  };

  /**
   * Find and returns valid company from
   * insurance companies
   */
  const findCompany = () => {
    if (claim.insured_at) {
      const found = insuranceCompanies.find(c => c.value === claim.insured_at);
      if (found) return found.label;
      return null;
    }
  };

  React.useEffect(() => {
    handleSection({ section: 3, subsection: 0 });
    !insuranceCompanies.length && fetchInsuranceCompanies();
  }, [insuranceCompanies]);

  useEffect(() => {
    if (loading || Object.keys(errors).length) addParam({ disabled: true });
    else removeParams(['disabled']);
  }, [errors, loading]);

  useEffect(() => {
    doVerifyClaim();
    setContactUrl(
      claim && claim.type
        ? `/summary/${
            claim.type === 'PERSONAL' ? 'company-contact' : 'contact-options'
          }/${id}`
        : '',
    );
  }, [claim]);

  if (!claim) return null;
  const date =
    claim.type && claim.type.toLowerCase() === 'aviation'
      ? claim.flights_taken[0].departure_date
      : claim.date_occurred;
  if (loading) return <LoadingSpinner />;
  return (
    <>
      <Section>
        <Section.Title>{t('Claim information')}</Section.Title>
        <Section.Content>
          <SummaryList>
            <SummaryList.Item
              title={t('Claim category')}
              value={t(claim.type)}
              error={errors.type}
              warning={warnings.type}
            />
            <SummaryList.Item
              title={t('Insured at')}
              value={findCompany()}
              error={errors.insured_at}
              warning={warnings.insured_at}
              destination={`/summary/category/${id}`}
            />
            <SummaryList.Item
              title={t('Policy number')}
              value={claim.policy_nr}
              error={errors.policy_nr}
              warning={warnings.policy_nr}
              destination={`/summary/category/${id}`}
            />
            <SummaryList.Item
              title={t('Claim type')}
              value={t(claim.peril)}
              error={errors.peril}
              warning={warnings.peril}
            />
            <SummaryList.Item
              destination={`/summary/${
                claim.type === 'PERSONAL' ? 'time-frame' : 'accident-desc'
              }/${id}`}
            >
              <StyledTextArea
                value={claim.description || ''}
                error={errors.description}
                warning={warnings.description}
                disabled
              >
                {t('Description')}
              </StyledTextArea>
            </SummaryList.Item>
          </SummaryList>
        </Section.Content>
      </Section>
      <Section>
        <Section.Title>{t('Occurrence')}</Section.Title>
        <Section.Content>
          <SummaryList>
            <SummaryList.Item
              title={t('Date')}
              value={date ? parseIncDateToString(date) : null}
              error={errors.date_occurred}
              warning={warnings.date_occurred}
              destination={`/summary/${
                claim.type === 'PERSONAL' ? 'time-frame' : 'accident-desc'
              }/${id}`}
            />
            <SummaryList.Item
              title={t('Address')}
              value={
                claim.place_occurred
                  ? addressObjectToString(claim.place_occurred.address)
                  : null
              }
              error={errors.place_occurred}
              warning={warnings.place_occurred}
              destination={`/summary/${
                claim.type === 'PERSONAL' ? 'company-contact' : 'accident-desc'
              }/${id}`}
            />
          </SummaryList>
        </Section.Content>
      </Section>
      <Section>
        <Section.Title>
          {claim.contact && claim.contact.person_type === 'LEGAL'
            ? t('Contact company')
            : t('Contact person')}
        </Section.Title>
        <Section.Content>
          {claim.contact ? (
            <SummaryList>
              <SummaryList.Item
                title={t('Name')}
                value={`${
                  claim.contact.person_type === 'LEGAL'
                    ? claim.contact.name
                    : `${claim.contact.first_name} ${claim.contact.last_name}`
                }`}
                error={errors.contact}
                destination={contactUrl}
              />
              <SummaryList.Item
                title={t('Phone')}
                value={claim.contact.telephonenr}
                destination={contactUrl}
              />
              <SummaryList.Item
                title={t('Email')}
                value={claim.contact.email}
                destination={contactUrl}
              />
            </SummaryList>
          ) : (
            <ErrorLink to={contactUrl}>{errors.contact}</ErrorLink>
          )}
        </Section.Content>
      </Section>
      {claim.type !== 'PERSONAL' && (
        <Section>
          <Section.Title>{t('Insuree')}</Section.Title>
          <Section.Content>
            {claim.insuree ? (
              <SummaryList>
                <SummaryList.Item
                  title={t('Name')}
                  value={`${claim.insuree.first_name} ${claim.insuree.last_name}`}
                  destination={`/summary/involved/${id}`}
                />
                <SummaryList.Item
                  title={t('Phone')}
                  value={claim.insuree.telephonenr}
                  destination={`/summary/involved/${id}`}
                />
                <SummaryList.Item
                  title={t('Email')}
                  value={claim.insuree.email}
                  destination={`/summary/involved/${id}`}
                />
                <SummaryList.Item
                  title={t('Address')}
                  value={addressObjectToString(
                    claim.insuree.correspondence_address,
                  )}
                  destination={`/summary/involved/${id}`}
                />
              </SummaryList>
            ) : (
              <ErrorLink to={`/summary/involved/${id}`}>
                {errors.insuree}
              </ErrorLink>
            )}
          </Section.Content>
        </Section>
      )}
      <StyledBtn
        data-test-id="submit-summary"
        disabled={Object.keys(errors).length}
        onClick={doSubmitClaim}
      >
        {t('Submit')}
      </StyledBtn>
    </>
  );
});

const addressObjectToString = addressObj =>
  `${addressObj.street} ${addressObj.housenr}, ${addressObj.zipcode} ${addressObj.city}`;

const StyledBtn = styled(ActionButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

StyledBtn.displayName = 'StyledBtn';

const ErrorLink = styled(Link)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.status.error} !important;
`;

export default Summary;
